import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  checkAccountAuth,
  logout,
  setAccountLoginLoadingFlag, setAuthNotFirstCheck
} from "../redux/actions/auth";
import AccountSignIn from "../pages/Account/AccountSignIn";
import InactiveStatusShow from "./InactiveStatusShow";
import Loading from "./Loading";
import {useNavigate} from "react-router-dom";

const AccountProtected = ({
                              WrappedComponent
                            }) => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatchLogout = () => dispatch(logout('account', navigate));
  const dispatchIsDataLoading = useSelector(state => state.accountAuth.isDataLoading);

  const dispatchAccountAuthAuthChecked = useSelector(state => state.accountAuth?.authChecked);
  const dispatchAccountAuthLoggedIn = useSelector(state => state.accountAuth?.loggedIn);
  const dispatchAccountAuthCurrentUserEmailVerifiedAt = useSelector(state => state.accountAuth?.currentUser?.email_verified_at);
  const dispatchAccountAuthCurrentUserActive = useSelector(state => state.accountAuth?.currentUser?.active);

  const dispatchAccountAuthCodeChecked = useSelector(state => state.accountAuth?.codeChecked);
  const dispatchAccountAuthIsFirstCheck = useSelector(state => state.accountAuth?.isFirstCheck);


  useEffect(() => {
    if ((!dispatchIsDataLoading && !dispatchAccountAuthAuthChecked)) {
      dispatch(setAccountLoginLoadingFlag())
      dispatch(checkAccountAuth())
    }
  }, [
    dispatch,
    dispatchIsDataLoading,
    dispatchAccountAuthAuthChecked
  ])

  useEffect(() => {
    if (dispatchAccountAuthCodeChecked && dispatchAccountAuthIsFirstCheck) {
      if (!dispatchIsDataLoading) {
        dispatch(setAuthNotFirstCheck(true, 'account'))
        dispatch(setAccountLoginLoadingFlag())
        dispatch(checkAccountAuth())
      }
    }

  }, [
    dispatch,
    dispatchIsDataLoading,
    dispatchAccountAuthCodeChecked,
    dispatchAccountAuthIsFirstCheck
  ])

  return (
    dispatchAccountAuthAuthChecked
    && dispatchAccountAuthLoggedIn
    && dispatchAccountAuthCurrentUserEmailVerifiedAt
  ) ?
    dispatchAccountAuthCurrentUserActive
      ? <>{WrappedComponent}</>
      : <InactiveStatusShow
        onClick={dispatchLogout}
        role={'account'}
      />
    : dispatchIsDataLoading
      ? <Loading/>
      : <AccountSignIn/>
}

export default AccountProtected;
