import React, {useEffect, useState} from 'react';
import {
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer, useDisclosure, Box,
  useRadioGroup,
  HStack,
  Flex,
  Spacer,
  Link
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {setLoadingFlag, getAuditorFirmList, requestAccessToFirm} from "../../redux/actions/firms";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import {Outlet, useParams, Link as ReachLink} from "react-router-dom";
import RadioCard from "../../components/RadioCard";

function AuditorFirms() {

  const {id: hideListing} = useParams();

  const thStyles = {
    color: '#1A1E29',
    fontSize: '14px',
    textTransform: 'none',
    opacity: '0.5',
    fontWeight: 400,
    border: 'none',
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (!hideListing) {
      dispatch(setLoadingFlag());
      dispatch(getAuditorFirmList('approved'));
    }
  }, [
    dispatch,
    hideListing
  ])

  const {isOpen, onOpen, onClose} = useDisclosure();

  const [firmId, setFirmId] = useState(null)

  const dispatchFirmList = useSelector(state => state.firm);
  const dispatchIsDataLoading = useSelector(state => state.firm?.isDataLoading);
  const dispatchList = (credentials) => dispatch(getAuditorFirmList(credentials, 'pending'))
  const dispatchLoadingFlag = (credentials) => dispatch(setLoadingFlag(credentials))
  const dispatchFirmRequestAccess = (id) => dispatch(requestAccessToFirm(id))

  const onChangeStatus = (id = null) => {
    setFirmId(id);
    onOpen();
  }

  const options = ['Show All', 'Approved', 'Pending']

  const {getRadioProps} = useRadioGroup({
    name: 'filter',
    defaultValue: 'Approved',
    onChange: (value) => {
      switch (value) {
        case 'Approved':
          dispatch(setLoadingFlag());
          dispatchList('approved');
          break;
        case 'Pending':
          dispatch(setLoadingFlag());
          dispatchList('pending');
          break;
        default:
          dispatch(setLoadingFlag('approved'));
          dispatchList();
          break;
      }
    },
  })

  return (
    <>
      <Outlet/>

      <Alert
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        header={`Request Access`}
        text={`Are you sure you want to request access to view this Law Firm?`}
        action={() => {
          dispatchFirmRequestAccess(firmId)
          .then((res) => {
            dispatchLoadingFlag();
            dispatchList();
          })

        }}
      />

      {!hideListing ? <Box>
      <HStack as={Flex}>
          <Heading
            fontSize={'32px'}
            lineHeight={'48px'}
          >Law Firms</Heading>

        <Spacer />

        <HStack
          as={Flex}
          mb={10}
        >

        {options.map((value, idx) => {
          const radio = getRadioProps({value})
          return (<RadioCard key={value} {...radio}>
            {value}
          </RadioCard>)
        })}
        </HStack>
        </HStack>

          {dispatchIsDataLoading
            ? <Loading/>
            : dispatchFirmList.data
              ? <> <TableContainer
                backgroundColor={'#F9FBFC'}
                mt={'44px'}
                color={'#1A1E29'}
              >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th
                        {...thStyles}
                      >Company Name</Th>
                      <Th
                        {...thStyles}
                      >Email</Th>
                      <Th
                        {...thStyles}
                      >Status</Th>
                      <Th
                        {...thStyles}
                      ></Th>                      
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dispatchFirmList?.data?.data?.map(({firm_id, name, email, status}) => {
                      return (
                        <Tr key={firm_id}>
                          <Td
                            fontSize={'18px'}
                            minWidth={'270px'}
                          >
                            {name}
                          </Td>
                          <Td
                            fontSize={'18px'}
                          >
                              {email}
                            </Td>
                          <Td>
                            {status === null
                              ? 
                              <Button
                                colorScheme='green'
                                backgroundColor='#000'
                                size='xs'
                                fontWeight={400}
                                fontSize={'14px'}
                                w={'100%'}
                              >
                                No Access
                              </Button>
                              : 
                              <>
                              {status.toString() === "pending"
                                ?
                              <Button
                                colorScheme='orange'                                
                                color={'#FFFFFF'}
                                size='xs'
                                fontWeight={400}
                                fontSize={'14px'}
                                w={'100%'}
                              >
                                Pending
                              </Button>
                                :
                                <Button
                                colorScheme='green'
                                size='xs'
                                fontWeight={400}
                                fontSize={'14px'}
                                w={'100%'}
                              >
                                Active
                              </Button>
                              }
                              </>
                            }
                          </Td>
                          <Td>
                            {status === null
                              ? <Button
                                colorScheme='blue'                                
                                color={'#FFFFFF'}
                                size='sm'
                                fontWeight={600}
                                fontSize={'14px'}
                                w={'100%'}
                                onClick={() => onChangeStatus(firm_id)}
                              >
                                Request Access
                              </Button>
                            : 
                            <>
                            {status.toString() === "approved"
                            ? 
                            <Link
                              to={firm_id}
                              as={ReachLink}
                            >
                              <Button
                                colorScheme='green'                                
                                color={'#FFFFFF'}
                                size='sm'
                                fontWeight={600}
                                fontSize={'14px'}
                                w={'100%'}
                              >
                                View
                              </Button>
                            </Link>

                            : <></>}
                            </>
                            }
                          </Td>
                        </Tr>
                      )
                    })
                    }
                  </Tbody>
                </Table>
              </TableContainer>

              </>
              : <Loading/>
          }
        </Box>
        : null}

    </>
  );
}

export default AuditorFirms;
