import React, {useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
  Text,
  Textarea,  
  Select
} from '@chakra-ui/react';
import BackButton from './BackButton';
import AlertComponent from './AlertComponent';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import {
  // getRiskForm,
  setRiskFormUpdate
} from "../redux/actions/clients";
import DatePicker from 'react-datepicker';
import {  questionsStatus, questionsFace, questionsLocation, questionsId, questionsProfile,
          questionsType, questionsValue, questionsSource, questionsDestination, questionsDyt } 
          from '../constants/RiskAssessmentQuestions'

const RiskAssessmentForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectErrorMessage = useSelector(state => state.clients.errorMessage)
  const selectGetById = useSelector(state => state.clients?.getById)
  var existingRiskForm = selectGetById?.data?.riskForm

  const [dateNext, setDateNext] = useState(new Date());
  const [dateNextSaved, setDateNextSaved] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const {id} = useParams()
  const queryParameters = new URLSearchParams(window.location.search)
  var version = queryParameters.get("version")
  if (!version) { version = "1"; }

  if (version === "2") {
    existingRiskForm = selectGetById?.data?.riskForm2
  }
  else if (version === "3") {
    existingRiskForm = selectGetById?.data?.riskForm3
  }

  const handleSubmit = async (values, method = 'post') => {

    if (buttonDisabled) { return; }
    setButtonDisabled(true);

    // if method already set, try to set status
    let status = method === 'put' ? 'pending' : 'submitted'

    // No form id and need to save draft
    if (!existingRiskForm?.id && method === 'put') {
      status = 'pending';
      method = 'post'
    }

    // form exist and need to post it
    if (existingRiskForm?.id && method === 'post') {
      status = 'submitted';
      method = 'put'
    }

    // No method and need to determinate if form exist
    if (!method) {
      existingRiskForm?.id ? method = 'put' : method = 'post';
    }

    function addOtherQuestions(questionArray) {
      questionArray?.questions?.forEach((question) => {
        otherQuestions[question.key] = values[question.key];
      });      
    }

    var obligatoryQuestions = { }
    var otherQuestions = { }
    addOtherQuestions(questionsStatus)
    addOtherQuestions(questionsFace)
    addOtherQuestions(questionsLocation)
    addOtherQuestions(questionsId)
    addOtherQuestions(questionsProfile)
    addOtherQuestions(questionsType)
    addOtherQuestions(questionsValue)
    addOtherQuestions(questionsSource)
    addOtherQuestions(questionsDestination)
    addOtherQuestions(questionsDyt)

    dispatchSetRiskFormUpdate({
      client_id: id, 
      risk_status: values.dyt_1, 
      form_status: status, 
      date_next: format(dateNext, 'dd/MM/yyyy'), 
      version: version,
      obligatory_questions: obligatoryQuestions, 
      other_questions: otherQuestions,

    }, method, existingRiskForm?.id).then(() => {
      setStep(2);
    })
      .catch(// Also another method for receiving err
        (err) => err.message ? console.log(err.message) : console.warn('Error'))
  }

  var initialValues = { 
    "date_next" : existingRiskForm?.date_next ?? '',
    "version" : existingRiskForm?.version ?? ''
  }

  if (!dateNextSaved) {
    setDateNextSaved(true)
    if (existingRiskForm?.date_next) {
      let [year, month, day] = existingRiskForm?.date_next.split('-')
      setDateNext(new Date(+year, +month - 1, +day))
    }
  }

  addInitialValues(questionsStatus)
  addInitialValues(questionsFace)
  addInitialValues(questionsLocation)
  addInitialValues(questionsId)
  addInitialValues(questionsProfile)
  addInitialValues(questionsType)
  addInitialValues(questionsValue)
  addInitialValues(questionsSource)
  addInitialValues(questionsDestination)
  addInitialValues(questionsDyt)

  function addInitialValues(questionArray) {
    questionArray?.questions?.forEach((question) => {

      // see if there are values from an earlier form
      if (version === "2") {

        // is there a version 2 form
        if (selectGetById?.data?.riskForm2) {
          initialValues[question.key] = selectGetById?.data?.riskForm2?.other_questions[question.key] ?? ''
        }

        // no, use version 1 form
        else {
          initialValues[question.key] = selectGetById?.data?.riskForm?.other_questions[question.key] ?? ''
        }
      }

      else if (version === "3") {

        // is there a version 3 form
        if (selectGetById?.data?.riskForm3) {
          initialValues[question.key] = selectGetById?.data?.riskForm3?.other_questions[question.key] ?? ''
        }

        // no, use version 2 form
        else {
          initialValues[question.key] = selectGetById?.data?.riskForm2?.other_questions[question.key]
        }
      }

      else {
        initialValues[question.key] = existingRiskForm?.other_questions[question.key] ?? ''
      }
    });
  }
  
  const formik = useFormik({
    initialValues: initialValues, 
    onSubmit: handleSubmit
  })

  const dispatchSetRiskFormUpdate = (data, method, id) => dispatch(setRiskFormUpdate(data, method, id))
  // const dispatchGetRiskForm = (id) => dispatch(getRiskForm(id))
  const [step, setStep] = useState(1)

  const inputStyles = {
    type: 'text',
    required: true,
    _placeholder: {fontSize: {base: 12, md: 14, lg: 16}},
    placeholder: 'Answer',
    fontSize: '16px',
  }

  return (<>
    <Flex
      flexDirection={'column'}
      alignItems={'flex-start'}
    >
      <BackButton
        tabIndex={9}
        onClickHandler={() => {
          navigate(-1)
        }}
        ml={'-17px'}
        mb={'37px'}
      />

      {selectErrorMessage && (<AlertComponent
        message={selectErrorMessage}
      />)}

      <Heading
        position={'relative'}
        textAlign='center'
        display={'flex'}
        // alignItems={'flex-end'}
        fontSize='22px'
        fontWeight='700'
        paddingBottom={3}
      >
      {version==="1" ? 'Initial Risk Assessment and Document Your Thoughts':''}
      {version==="2" ? 'Interim Risk Assessment and Document Your Thoughts':''}
      {version==="3" ? 'Final Risk Assessment and Document Your Thoughts':''}      
      </Heading>

    </Flex>

    {step === 1 ? 

      <>
      <Heading
        position={'relative'}
        textAlign='left'
        display={'flex'}
        // alignItems={'flex-end'}
        fontSize='14px'
        fontWeight='700'
        paddingBottom={3}
      >
      Your risk assessment can be completed in stages by using the 'Save as Draft' button. Once you are ready to permanently save your answers, click Submit. Onboarding is not considered completed until this form is Submitted. A copy will be available for you to download once submitted.
      </Heading>

    <Box>
      {existingRiskForm?.updated_at && <Text color={'#6E7997'}
                                                        fontWeight={'400'}
                                                        fontSize={'18px'}
                                                        paddingBottom={10}
      >Last update {new Date(existingRiskForm?.updated_at).toUTCString()}</Text>}
      <form
        onSubmit={async (evt) => {
          evt.preventDefault();
          await handleSubmit(formik.values, 'post');
        }}
      >

        <Stack spacing='5' paddingBottom={10}>
          
          <RiskAssessmentQuestions questions={questionsStatus} form={formik}/>
          <RiskAssessmentQuestions questions={questionsFace} form={formik}/>
          <RiskAssessmentQuestions questions={questionsLocation} form={formik}/>
          <RiskAssessmentQuestions questions={questionsId} form={formik}/>
          <RiskAssessmentQuestions questions={questionsProfile} form={formik}/>
          <RiskAssessmentQuestions questions={questionsType} form={formik}/>
          <RiskAssessmentQuestions questions={questionsValue} form={formik}/>
          <RiskAssessmentQuestions questions={questionsSource} form={formik}/>
          <RiskAssessmentQuestions questions={questionsDestination} form={formik}/>
          <RiskAssessmentQuestions questions={questionsDyt} form={formik}/>

          {version !== "3"
          ?        
          <><Heading
            size={'s'}
            paddingTop={6}
            paddingBottom={0}
          >Next Risk Assessment</Heading>

      <Heading
        position={'relative'}
        textAlign='left'
        display={'flex'}
        // alignItems={'flex-end'}
        fontSize='14px'
        fontWeight='700'
        paddingBottom={3}
      >
      Please choose an approximate date to receive a reminder email to fill in your interim and final risk assessment. 
      </Heading>

          <FormControl>
            <Grid templateColumns={'1fr 1fr'}
                  mb={5}
                  gap={10}>
              <GridItem>
                <FormLabel
                  htmlFor='statedSourceOfWealth'
                  fontSize={'16px'}>
                  Choose date of next risk assessment:
                </FormLabel>

              </GridItem>
              <GridItem>
                
                <DatePicker
                  {...inputStyles}
                  className={'chakra-input css-7p9xsp'}
                  placeholderText='DD/MM/YYYY'
                  dateFormat='dd/MM/yyyy'
                  selected={dateNext}
                  onChange={(date) => {
                    setDateNext(date)
                  }}
                  name="date"
              />
              </GridItem>
            </Grid>

          </FormControl></>
          :''}

        </Stack>

        <HStack spacing='6'
                text-align={'right'}
                as={Flex}
                alignItems={'flex-start'}
                py={'20px'}>
          {/* TODO: implement pending/submit check here */}
          <Button
            type='button'
            fontSize={{base: 12, md: 14}}
            textTransform={'capitalize'}
            backgroundColor={'#9a9a9a'}
            minH={'auto'}
            onClick={async () => await handleSubmit(formik.values, 'put')}
            variant='primary'>Save as draft</Button>

          <Button
            type='submit'
            fontSize={{base: 12, md: 14}}
            textTransform={'capitalize'}
            backgroundColor={'#4285F4'}
            minH={'auto'}
            disabled={buttonDisabled}
            variant='primary'>Submit</Button>
        </HStack>

      </form>

      <b>Disclaimer</b><br />
      <Text style={{fontStyle: 'italic'}}>
      LawComply accepts no responsibility for any compliance failures or loss incurred as a result of reliance on these forms. Solicitors’ firms must always ensure they are in compliance with all of their statutory AML obligations. LawComply is grateful to the Law Society of Ireland on whose template forms this process is based and adapted.
      </Text>

    </Box></> : <Text>Thank you for filling out the risk assessment form for this client.</Text>}
  </>)
};

export default RiskAssessmentForm;

function RiskAssessmentQuestions(props) {

  var title = props.questions.title
  var questions = props.questions.questions

  return (
<>
  <Heading size={'xs'} paddingTop={6} paddingBottom={4}>{title}</Heading>

    {questions.map((question) => (
      <RiskAssessmentQuestion 
        key={question.key}
        question={question.question}
        questionKey={question.key}
        questionOptions={question.options}
        form={props.form}
        optional={question.optional}
      />
    ))}
</>
  );  
}

function RiskAssessmentQuestion(props) {
  
  var required = true
  if (props.optional) {
    required = false
  }

  const inputStyles = {
    type: 'text',
    required: required,
    _placeholder: {fontSize: {base: 12, md: 14, lg: 16}},
    placeholder: 'Answer',
    fontSize: '16px',
  }

  return (
          <FormControl>
            <Grid templateColumns={'1fr 1fr'}
                  mb={5}
                  gap={10}>
              <GridItem>
                <FormLabel
                  htmlFor='{props.questionKey}'
                  fontSize={'16px'}>
                    {props.question}
                </FormLabel>
              </GridItem>

              <GridItem>

                {props?.questionOptions ?
                  <Select name={props.questionKey} 
                          onChange={props.form.handleChange}
                          defaultValue={props.form.initialValues[props.questionKey]}>
                    <option value=""></option>
                    {props?.questionOptions?.map((theOption) => (
                      <option key={theOption} 
                              value={theOption}                              
                      >{theOption}</option>
                    ))}
                  </Select>
                :

                 <Textarea
                  id={props.questionKey}
                  {...inputStyles}
                  name={props.questionKey}
                  onChange={props.form.handleChange}
                  value={props.form.values[props.questionKey]}
                  /> 
                }

              </GridItem>
            </Grid>
          </FormControl>

          );
  }

