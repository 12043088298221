import { Flex, Grid, GridItem, Heading, Text, HStack } from "@chakra-ui/react";
import {isDateExpired} from "../../../services/utils";

function CaseViewHeader(data) {

    const dispatchItem = data?.dispatchItem

    return (    
        <>        
            <HStack style={{marginTop: '5px'}}>
              <Grid
                gridTemplateColumns={"90px 1fr 200px"}
                mt={2}
                width={"100%"}
              >
                <GridItem>
                  <Text as={Flex} justifyContent={"center"} alignItems={"center"} borderRadius={"100%"}
                    bgColor={"#4285F4"} width={"66px"} height={"66px"} color={"#ffffff"}
                    style={{fontSize: '18px', fontWeight: '500'}}
                    >
                    {dispatchItem?.data?.name ?
                        dispatchItem?.data?.name[0].toUpperCase() :
                        dispatchItem?.data?.main_contact?.first_name[0].toUpperCase() + ' ' + dispatchItem?.data?.main_contact?.last_name[0].toUpperCase()
                    }
                  </Text>
                </GridItem>

                <GridItem>
                  <Heading as={"h3"} fontSize={"28px"}>
                    {dispatchItem?.data?.name ?
                        dispatchItem?.data?.name :
                        dispatchItem?.data?.main_contact?.first_name + ' ' + dispatchItem?.data?.main_contact?.last_name
                    }
                  </Heading>
                  <Text fontSize={"18px"} fontWeight={"400"} color={"#6E7997"} marginTop={0}>
                    ID {dispatchItem.data.id}
                  </Text>
                </GridItem>

                <GridItem display={"flex"} alignItems={"center"}>
                  {dispatchItem?.data?.riskForm?.submitted_at &&
                    isDateExpired(
                      dispatchItem?.data?.riskForm?.submitted_at,
                      3
                    ) && (
                      <Text
                        as={"span"}
                        bgColor={"#F9FBFC"}
                        fontWeight={"400"}
                        fontSize={"18px"}
                        color={"#343B55"}
                        display={"flex"}
                        borderRadius={"3px"}
                        p={2}
                        textAlign={"center"}
                        justifyContent={"center"}
                      >
                        {" "}
                        Risk Assessment expired
                      </Text>
                    )}
                </GridItem>
              </Grid>
            </HStack>
              </>);
  };
  export default CaseViewHeader;

