import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

import { Logo } from "../components/Logo";
import { IconApproved } from "../assets/icons/sprite";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCorporateClientDetailsBySecret,
  setLoadingFlag,
  approveShareholdersRequest,
  setNewClientBySecretNoDispatch,
} from "../redux/actions/clients";
import Loading from "../components/Loading";
import Step from "../components/Step";
import AlertComponent from "../components/AlertComponent";
import { useFormik } from "formik";

const ShareHolders = ({
  corporateChild,
  setStepTwoDone,
  setStep,
  mainContact,
}) => {
  const { secret } = useParams();
  const [shareholders, setShareholders] = useState(corporateChild);
  const [shareholdersErrorMessages, setShareholdersErrorMessages] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastSaved, setLastSaved] = useState(true);
  const [secretarySaved, setSecretarySaved] = useState(false);
  const changeValue = (event, index) => {
    const clone = [...shareholders];
    clone[index][event.target.name] = event.target.value;
    setShareholders(clone);
  };
  const [removedSecretary, setRemovedSecretary] = useState(false)

  const [secretaryFirstName, setSecretaryFirstName] = useState("");
  const [secretaryLastName, setSecretaryLastName] = useState("");
  const [secretaryEmail, setSecretaryEmail] = useState("");
  const [secretaryId, setSecretaryId] = useState("");

  const secretaryFirstNameRef = useRef(null);
  const secretaryLastNameRef = useRef(null);
  const secretaryEmailRef = useRef(null);

  useEffect(() => {
    if (!removedSecretary) {
      shareholders.map((v, index) => {
        if (v.type === "corporate_secretary") {
          setSecretaryFirstName(v.first_name);
          setSecretaryLastName(v.last_name);
          setSecretaryEmail(v.email);
          setSecretaryId(v.id);
          setSecretarySaved(true);
        }
        return "";
      });  
    }
  });

  const form = useRef();
  const sendShareholders = async (event) => {
    event.preventDefault();
    if (form?.current.reportValidity()) {
      if (!isLoading) {
        try {
          setIsLoading(true);

          var secretary = {};
          secretary["first_name"] = secretaryFirstNameRef.current.value;
          secretary["last_name"] = secretaryLastNameRef.current.value;
          secretary["email"] = secretaryEmailRef.current.value;
          secretary["id"] = secretaryId;

          const resp = await approveShareholdersRequest(secret, {
            clients: shareholders,
            secretary: secretary,
          });
          if (resp?.data?.result === "error") {
            setShareholdersErrorMessages(resp);
          } else {
            setStepTwoDone(true);
            setStep("form");
          }
        } catch (e) {
          setShareholdersErrorMessages(e);
        }
        setIsLoading(false);
      }
    }
  };

  var inputProps = {
    disabled: false,
  };

  const saveClickSecretary = () => {
    if (secretarySaved) {
      secretaryFirstNameRef.current.value = "";
      secretaryLastNameRef.current.value = "";
      secretaryEmailRef.current.value = "";
      setRemovedSecretary(true);
    }
    else {
      setRemovedSecretary(false);
    }
    setSecretarySaved(!secretarySaved);
  };

  const saveRemoveClick = (index, force = false) => {
    if (shareholders.length - 1 === index && !lastSaved && !force) {
      setLastSaved(true);
    } else {
      setShareholders((prevState) =>
        prevState.filter((el, idx) => idx !== index)
      );
    }
  };

  return (
    <form id="inviteForm" ref={form} onSubmit={(e) => sendShareholders(e)}>
      <Flex
        justifyContent={"space-between"}
        alignItems={"start"}
        minH={"100vh"}
        flexDir={"column"}
      >
        <>
          <Box>
            <Box
              w={"636px"}
              marginLeft={"80px"}
              marginRight={"80px"}
              marginTop={"100px"}
            >
              <Heading fontSize={"25"} textAlign={"left"} mb={8}>
                Add Directors and Beneficial Owners
              </Heading>
              <Text fontSize={"18"} textAlign={"left"} mb={8}>
                Please enter contact details for your directors and beneficial
                owners.
              </Text>
              {shareholdersErrorMessages && (
                <AlertComponent message={shareholdersErrorMessages} />
              )}
            </Box>

            <Grid
              templateColumns={"1fr 1fr 2fr 1fr"}
              bgColor={"#F9FBFC"}
              padding={"20px"}
              borderRadius={"8px"}
              marginLeft={"80px"}
              marginRight={"80px"}
              gap={"20px"}
            >
              <GridItem fontSize={"14px"} color={"#6E7997"} pl={4}>
                First Name
              </GridItem>
              <GridItem fontSize={"14px"} color={"#6E7997"} pl={4}>
                Last Name
              </GridItem>
              <GridItem fontSize={"14px"} color={"#6E7997"} pl={4}>
                Email
              </GridItem>
              <GridItem
                fontSize={"14px"}
                color={"#6E7997"}
                pl={4}
                justifyContent={"center"}
                textAlign={"center"}
              >
                Action
              </GridItem>

              {shareholders.map((v, index) => (
                <React.Fragment key={index}>
                  {mainContact.id === v.id ||
                  shareholders.length - 1 !== index ||
                  lastSaved
                    ? (inputProps.disabled = true)
                    : (inputProps.disabled = false)}

                  {v.type === "corporate_child" ? (
                    <>
                      <GridItem>
                        <Input
                          {...inputProps}
                          background={"#fff"}
                          name={"first_name"}
                          value={v.first_name}
                          onChange={(event) => {
                            changeValue(event, index);
                          }}
                          required
                        />
                      </GridItem>
                      <GridItem>
                        <Input
                          {...inputProps}
                          background={"#fff"}
                          name={"last_name"}
                          value={v.last_name}
                          onChange={(event) => {
                            changeValue(event, index);
                          }}
                          required
                        />
                      </GridItem>
                      <GridItem>
                        <Input
                          {...inputProps}
                          background={"#fff"}
                          name={"email"}
                          value={v.email}
                          type={"email"}
                          onChange={(event) => {
                            changeValue(event, index);
                          }}
                          required
                        />
                      </GridItem>
                      <GridItem
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        {mainContact.id !== v.id ? (
                          shareholders.length - 1 === index ? (
                            lastSaved ? (
                              <Button
                                bgColor={"#C8392D"}
                                color={"#ffffff"}
                                onClick={() => {
                                  saveRemoveClick(index);
                                }}
                              >
                                Remove
                              </Button>
                            ) : (
                              <>
                                <Button
                                  mr={2}
                                  bgColor={"#28a745"}
                                  color={"#ffffff"}
                                  onClick={() => {
                                    saveRemoveClick(index);
                                  }}
                                >
                                  Save
                                </Button>{" "}
                                <Button
                                  bgColor={"#C8392D"}
                                  color={"#ffffff"}
                                  onClick={() => {
                                    saveRemoveClick(index, true);
                                  }}
                                >
                                  Remove
                                </Button>
                              </>
                            )
                          ) : (
                            <Button
                              bgColor={"#C8392D"}
                              color={"#ffffff"}
                              onClick={() => {
                                saveRemoveClick(index);
                              }}
                            >
                              Remove
                            </Button>
                          )
                        ) : (
                          ""
                        )}
                      </GridItem>
                    </>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}

              {/* end of map */}

              <GridItem></GridItem>
              <GridItem></GridItem>
              <GridItem></GridItem>
              <GridItem
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  color={"#ffffff"}
                  fontSize={"18px"}
                  variant="primary"
                  minHeight={"40px"}
                  minWidth={"101px"}
                  onClick={() => {
                    setLastSaved(false);
                    setShareholders((prevState) => [
                      ...prevState,
                      {
                        first_name: "",
                        last_name: "",
                        email: "",
                        type: "corporate_child",
                      },
                    ]);
                  }}
                >
                  Add
                </Button>
              </GridItem>
            </Grid>

            <Box
              w={"636px"}
              marginLeft={"80px"}
              marginRight={"80px"}
              marginTop={"30px"}
            >
              <Heading fontSize={"25"} textAlign={"left"} mb={8}>
                Add Company Secretary
              </Heading>
              <Text fontSize={"18"} textAlign={"left"} mb={8}>
                Please enter the contact details for the company secretary.
              </Text>
              {shareholdersErrorMessages && (
                <AlertComponent message={shareholdersErrorMessages} />
              )}
            </Box>

            <Grid
              templateColumns={"1fr 1fr 2fr 1fr"}
              bgColor={"#F9FBFC"}
              padding={"20px"}
              borderRadius={"8px"}
              marginLeft={"80px"}
              marginRight={"80px"}
              gap={"20px"}
            >

              <GridItem fontSize={"14px"} color={"#6E7997"} pl={4}>
                First Name
              </GridItem>
              <GridItem fontSize={"14px"} color={"#6E7997"} pl={4}>
                Last Name
              </GridItem>
              <GridItem fontSize={"14px"} color={"#6E7997"} pl={4}>
                Email
              </GridItem>
              <GridItem fontSize={"14px"} color={"#6E7997"} pl={4}></GridItem>

              <GridItem>
                <Input
                  disabled={secretarySaved}
                  ref={secretaryFirstNameRef}
                  background={"#fff"}
                  name={"company_secretary_first_name"}
                  defaultValue={secretaryFirstName}
                  required
                />
              </GridItem>
              <GridItem>
                <Input
                  ref={secretaryLastNameRef}
                  disabled={secretarySaved}
                  background={"#fff"}
                  name={"company_secretary_last_name"}
                  defaultValue={secretaryLastName}
                  required
                />
              </GridItem>
              <GridItem>
                <Input
                  ref={secretaryEmailRef}
                  disabled={secretarySaved}
                  background={"#fff"}
                  name={"company_secretary_email"}
                  type={"email"}
                  defaultValue={secretaryEmail}
                  required
                />
              </GridItem>

              <GridItem
                justifyContent={"center"}
                textAlign={"left"}
                mt={2}
                ml={4}
              >
                {secretarySaved ? (
                  <Button
                    bgColor={"#C8392D"}
                    color={"#ffffff"}
                    onClick={() => {
                      saveClickSecretary();
                    }}
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    bgColor={"#28a745"}
                    color={"#ffffff"}
                    onClick={() => {
                      saveClickSecretary();
                    }}
                  >
                    Save
                  </Button>
                )}
              </GridItem>

              <Input
                type={"hidden"}
                name={"company_secretary_id"}
                value={secretaryId}
              />
            </Grid>
          </Box>

          <Box
            boxShadow={"0 25px 50px 12px rgba(0, 0, 0, 0.25)"}
            display={"inline-flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            width={"100%"}
            p={4}
          >
            <Flex>
              <Button
                maxWidth={"295px"}
                tabIndex={7}
                type="submit"
                fontSize={"18px"}
                disabled={
                  shareholders?.filter((v) => v.email).length === 0 || isLoading
                }
                onClick={sendShareholders}
                marginRight={"70px"}
                variant="primary"
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </>
      </Flex>
    </form>
  );
};
const CorporateForm = () => {
  const { secret } = useParams();

  const dispatch = useDispatch();
  const [step, setStep] = useState(null);
  const [stepTwoDone, setStepTwoDone] = useState(false);
  // eslint-disable-next-line
  const [stepThreeDone, setStepThreeDone] = useState(false);
  const selectIsDataLoading = useSelector(
    (state) => state.clients?.isDataLoading
  );
  const selectSolicitorEmail = useSelector(
    (state) => state.clients?.getClientBySecret?.data?.user?.email
  );
  const selectSolicitorName = useSelector(
    (state) => state.clients?.getClientBySecret?.data?.user?.name
  );
  const corporateChild = useSelector(
    (state) => state.clients?.getClientBySecret?.data?.corporate_children
  );
  const mainContact = useSelector(
    (state) => state.clients?.getClientBySecret?.data?.main_contact
  );
  const corporateClient = useSelector(
    (state) => state.clients?.getClientBySecret?.data
  );
  const [formErrorMessages, setFormErrorMessages] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);

  let corporateItems;
  if (typeof corporateChild === "object") {
    corporateItems = [
      // {...main_contact, main_contact: true},
      ...corporateChild,
    ];
  }
  const handleSubmit = async (values) => {
    if (!formSubmitting) {
      setFormSubmitting(true);
      try {
        const resp = await setNewClientBySecretNoDispatch({
          ...values,
          secret,
          type: "corporate",
        });
        if (resp.result === "success") {
          setStepThreeDone(true);
        } else {
          setFormErrorMessages(resp);
        }
      } catch (e) {
        setFormErrorMessages(e);
      }
      setFormSubmitting(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: corporateClient?.name,
      address: corporateClient?.address,
      address_trading: corporateClient?.address_trading,
      company_number: corporateClient?.company_number,
      vat_number: corporateClient?.vat_number,
    },
    onSubmit: handleSubmit,
  });
  const FormSubmitted = () => {
    return (
      <Flex
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        minH={"100vh"}
      >
        <Box
          bgColor={"#F9FBFC"}
          borderRadius={"50%"}
          padding={8}
          marginBottom={8}
        >
          <IconApproved width={"156px"} height={"194px"} />
        </Box>

        {selectSolicitorEmail ? (
          <>
            <Text fontSize={"28px"} fontWeight={700} mb={4}>
              Add Directors, Secretary and Beneficial Owners
            </Text>
            <Text>
              For compliance reasons we need to capture, verify and store ID and
              Proof of Address.
            </Text>
            <Button
              tabIndex={7}
              type="submit"
              fontSize={"18px"}
              onClick={() => {
                setStep("shareholders");
              }}
              mt={4}
              variant="primary"
            >
              Continue
            </Button>
          </>
        ) : (
          "The client was not found. Please make sure you entered the URL correctly."
        )}
      </Flex>
    );
  };

  const getState = (documents_ = null, children_ = null, submitted = false) => {
    if (submitted) {
      setStep("form");
      setStepTwoDone(true);
      setStepThreeDone(true);
      return;
    }
  };
  useEffect(() => {
    dispatch(setLoadingFlag());
    dispatch(getCorporateClientDetailsBySecret(secret, getState));
    // eslint-disable-next-line
  }, [dispatch, secret]);

  return selectIsDataLoading ? (
    <Loading />
  ) : (
    <Grid gridTemplateColumns={{ md: "344px 1fr" }} minH={"100vh"}>
      <GridItem
        minH={"100vh"}
        bgColor={"#F9FBFC"}
        pt={12}
        pb={12}
        pl={10}
        pr={10}
        display={"flex"}
        flexDir={"column"}
      >
        <Logo width={"183px"} height={"41px"} mb={"55px"} />

        <Box bgColor={"#FFFFFF"} p={4} mb={8}>
          <Text fontWeight={700} fontSize={"18px"}>
            Your solicitor:
          </Text>
          {selectSolicitorName && (
            <Text fontWeight={400}>{selectSolicitorName}</Text>
          )}
        </Box>

        <Grid templateColumns={"56px 1fr"} gap={"32px"}>
          <Step
            done={stepTwoDone}
            number={1}
            active={step === "shareholders"}
            name={"Add directors, secretary and beneficial owners."}
          />
          <Step
            done={stepThreeDone}
            number={2}
            active={step === "form"}
            name={"Fill out account opening form."}
          />
        </Grid>

        <Box
          display={"flex"}
          flexGrow={1}
          alignItems={"flex-start"}
          justifyContent={"flex-end"}
          flexDirection={"column"}
        >
          {selectSolicitorEmail && (
            <>
              <Text color={"#6E7997"} fontWeight={700}>
                Have any questions?
              </Text>

              <Link
                color={"#6E7997"}
                href={`mailto:${selectSolicitorEmail}?subject=feedback from Corporate Account Opening Form`}
                textDecoration={"underline"}
              >
                Contact Your Solicitor
              </Link>
            </>
          )}
        </Box>
      </GridItem>

      {step !== "shareholders" && step !== "form" ? (
        <FormSubmitted />
      ) : step !== "form" ? (
        <ShareHolders
          corporateChild={corporateItems}
          setStep={setStep}
          setStepTwoDone={setStepTwoDone}
          mainContact={mainContact}
        />
      ) : (
        <Flex justifyContent={"left"} alignContent={"left"}>
          <Box w={"728px"}>
            {!stepThreeDone ? (
              <>
                <Box
                  w={"636px"}
                  marginLeft={"80px"}
                  marginRight={"80px"}
                  marginTop={"100px"}
                >
                  <Heading fontSize={"25"} textAlign={"left"} mb={8}>
                    Fill Out Account Opening Form
                  </Heading>
                  <Text fontSize={"18"} textAlign={"left"} mb={8}>
                    Please fill out all of the information in the form below to
                    complete your application.
                  </Text>
                </Box>
              </>
            ) : null}

            <GridItem>
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                flexDir={"column"}
              >
                <Box w={"552px"}>
                  {!stepThreeDone ? (
                    <form onSubmit={formik.handleSubmit}>
                      <Stack spacing="5">
                        {formErrorMessages && (
                          <AlertComponent message={formErrorMessages} />
                        )}
                        <FormControl>
                          <FormLabel htmlFor="name" fontSize={"18px"}>
                            Company Name
                          </FormLabel>
                          <Input
                            tabIndex={3}
                            id="name"
                            type="text"
                            required
                            placeholder={"Enter Company Name"}
                            fontSize={"18px"}
                            onChange={formik.handleChange}
                            value={formik.values.name}                            
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel htmlFor="address" fontSize={"18px"}>
                            Registered Address
                          </FormLabel>
                          <Input
                            tabIndex={4}
                            id="address"
                            type="text"
                            required
                            placeholder={"Enter Registered Address"}
                            fontSize={"18px"}
                            onChange={formik.handleChange}
                            value={formik.values.address}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel
                            htmlFor="address_trading"
                            fontSize={"18px"}
                          >
                            Trading Address
                          </FormLabel>
                          <Input
                            tabIndex={5}
                            id="address_trading"
                            type="text"
                            required
                            placeholder={"Enter Trading Address"}
                            fontSize={"18px"}
                            onChange={formik.handleChange}
                            value={formik.values.address_trading}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel htmlFor="company_number" fontSize={"18px"}>
                            Company Registration Number
                          </FormLabel>
                          <Input
                            tabIndex={7}
                            id="company_number"
                            type="text"
                            required
                            placeholder={"Enter Registration Number"}
                            fontSize={"18px"}
                            onChange={formik.handleChange}
                            value={formik.values.company_number}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel htmlFor="vat_number" fontSize={"18px"}>
                            VAT Number
                          </FormLabel>
                          <Input
                            tabIndex={7}
                            id="vat_number"
                            type="text"
                            required
                            placeholder={"Enter VAT Number"}
                            fontSize={"18px"}
                            onChange={formik.handleChange}
                            value={formik.values.vat_number}
                          />
                        </FormControl>
                      </Stack>

                      <Stack spacing="6" text-align={"right"} py={"20px"}>
                        <Button
                          tabIndex={7}
                          type="submit"
                          fontSize={"18px"}
                          variant="primary"
                        >
                          Continue
                        </Button>
                      </Stack>
                    </form>
                  ) : (
                    <Flex
                      justifyContent={"left"}
                      flexDirection={"column"}
                      alignItems={"left"}
                      paddingTop={"100px"}
                    >
                      <Text fontSize={"28px"} fontWeight={700} mb={4}>
                        Thank You
                      </Text>
                      <Text fontSize={"18px"}>
                        Thanks for taking the time to submit your company
                        information. It's now been sent to your solicitor and
                        they'll be in touch soon.
                      </Text>
                    </Flex>
                  )}
                </Box>
              </Flex>
            </GridItem>
          </Box>
        </Flex>
      )}
    </Grid>
  );
};

export default CorporateForm;
