import React from 'react';
import {GridItem} from "@chakra-ui/react";
import {WhiteCheckIcon} from "../assets/icons/sprite";

const Step = ({active, done, number, name}) => {
    return <>
        {done ? <GridItem bgColor={'#4285F4'}
                          borderRadius={'50%'}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          height={'56px'}
                          width={'56px'}
        >
            <WhiteCheckIcon width={'36px'}
                            height={'36px'}

            />
        </GridItem> : <GridItem bgColor={'#ffffff'}
                                borderRadius={'50%'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                border={'2px solid #4285F4'}
                                color={'#4285F4'}
                                fontWeight={700}
                                fontSize={'18px'}
                                height={'56px'}
                                width={'56px'}
        >
            {number}
        </GridItem>}
        <GridItem fontSize={'18px'}
                  fontWeight={active ? 700 : 400}
                  display={'flex'}
                  alignItems={'center'}
        >
            {name}
        </GridItem>
    </>
}
export default Step;
