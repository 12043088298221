import React, { useEffect, useState } from "react";
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Link,
  Button,
  HStack,
  Flex,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Alert from "../../components/Alert";
import {
  setLoadingFlag,
  getSolicitorsList,
  setSolicitorData,
  setNewSolicitor,
  setSolicitorErrorMessage,
} from "../../redux/actions/solicitors";
import { Link as ReachLink, Outlet, useParams, useNavigate } from "react-router-dom";
import {
  DoneIcon,
  PencilSecondaryIcon,
  TrashBinIcon,
  AdminClientsLight,
  UserLoginIcon
} from "../../assets/icons/sprite";
import { useFormik } from "formik";
import AlertComponent from "../../components/AlertComponent";
import { getClientsList } from "../../redux/actions/clients";
import { loginFirmAsSolicitor } from "../../redux/actions/auth";

const FirmSolicitors = () => {
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("Add Solicitor");

  const dispatchErrorMessage = useSelector(
    (state) => state.solicitors.errorMessage
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatchSetSolicitorErrorMessage = (message) =>
    dispatch(setSolicitorErrorMessage(message));
  const dispatchSetNewSolicitor = (data) => dispatch(setNewSolicitor(data));
  const isAdmin = useSelector(state => state.adminAuth?.isAdmin);

  const handleSubmit = async (values) => {
    // validation
    if (values.name.replaceAll(" ", "").length === 0) {
      dispatchSetSolicitorErrorMessage({
        result: "error",
        message: "The name must be not empty.",
      });
      return;
    }
    if (!values.name) {
      dispatchSetSolicitorErrorMessage({
        result: "error",
        message: "Please fill out name field.",
      });
      return;
    }

    // Go to step 2

    dispatchSetNewSolicitor({
      name: values.name,
      email: values.email,
      phone: values.number,
    }).then(() => {
      setTitle("Invite has been sent");
      setStep(2);
      dispatch(setLoadingFlag());
      dispatch(getSolicitorsList());
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      number: "",
      email: "",
    },
    onSubmit: handleSubmit,
  });

  const { id: hideListing } = useParams();
  const PAGE_PATH = "solicitors?page=";

  const thStyles = {
    color: "#1A1E29",
    fontSize: "14px",
    textTransform: "none",
    opacity: "0.5",
    fontWeight: 400,
    border: "none",
  };

  useEffect(() => {
    if (!hideListing) {
      dispatch(setLoadingFlag());
      dispatch(getSolicitorsList());
    }
  }, [dispatch, hideListing]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDelOpen,
    onOpen: onDelOpen,
    onClose: onDelClose,
  } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  // eslint-disable-next-line
  const [solicitorId, setSolicitorId] = useState(null);

  const dispatchSolicitorList = useSelector((state) => state.solicitors);
  const dispatchListPagination = useSelector(
    (state) => state.solicitors?.data?.meta?.pagination
  );
  const dispatchIsDataLoading = useSelector(
    (state) => state.solicitors?.isDataLoading
  );
  const dispatchList = (credentials) =>
    dispatch(getSolicitorsList(credentials));
  const dispatchLoadingFlag = (credentials) =>
    dispatch(setLoadingFlag(credentials));
  const dispatchSolicitorDel = (data) => dispatch(setSolicitorData(data));



  const resetDialog = () => {
    if (step === 2) {
      formik.values.name = "";
      formik.values.email = "";
      formik.values.number = "";
    }
    setStep(1);
    setTitle("Add Solicitor");
    dispatchSetSolicitorErrorMessage(null);
    onClose();
  };

  const {
    isOpen: isSolicitorLoginOpen,
    onOpen: onSolicitorLoginOpen,
    onClose: onSolicitorLoginClose,
  } = useDisclosure();

  const onSolicitorLogin = (id = null) => {
    setSolicitorId(id);
    onSolicitorLoginOpen();
  };

  const onDeleteStatus = async (id) => {
    setSolicitorId(id);
    const dispatchList = await dispatch(
      getClientsList("clients", null, "solicitor", id)
    );

    dispatchList?.payload?.data?.length ? onAlertOpen() : onDelOpen();
  };

  return (
    <>
      <Outlet />
      <Alert
        isOpen={isSolicitorLoginOpen}
        onOpen={onSolicitorLoginOpen}
        onClose={onSolicitorLoginClose}
        header={`Login as Solicitor`}
        text={`Are you sure you want to login as this solicitor?`}
        action={(act) => {
          dispatch(loginFirmAsSolicitor(solicitorId)).then(res => navigate('/solicitor/cases', {replace:true}))
        }}
      ></Alert>
      <Alert
        isOpen={isAlertOpen}
        onOpen={onAlertOpen}
        onClose={onAlertClose}
        header={`Delete Solicitor`}
        text={`It's not possible to delete this solicitor as they have active cases associated with them.`}
        action={null}
      />

      <Alert
        isOpen={isDelOpen}
        onOpen={onDelOpen}
        onClose={onDelClose}
        header={`Delete Solicitor`}
        text={`Are you sure you want to delete this Solicitor?`}
        action={() => {
          dispatch(setLoadingFlag());
          dispatchSolicitorDel({
            newData: { deleted: true },
            id: solicitorId,
          }).then(() => {
            dispatchList();
          });
        }}
      />

      <Alert
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={resetDialog}
        header={title}
        text={
          step === 1 ? (
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing="5">
                {dispatchErrorMessage && (
                  <AlertComponent message={dispatchErrorMessage} />
                )}

                <FormControl>
                  <FormLabel htmlFor="Name" fontSize={"18px"}>
                    Name
                  </FormLabel>
                  <Input
                    tabIndex={1}
                    id="name"
                    type="text"
                    required
                    placeholder={"Enter name"}
                    fontSize={"18px"}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="number" fontSize={"18px"}>
                    Mobile
                  </FormLabel>
                  <Input
                    tabIndex={2}
                    id="number"
                    type="text"
                    required
                    placeholder={"Enter number"}
                    fontSize={"18px"}
                    onChange={formik.handleChange}
                    value={formik.values.number}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="email" fontSize={"18px"}>
                    Email
                  </FormLabel>
                  <Input
                    tabIndex={3}
                    id="email"
                    type="email"
                    required
                    placeholder={"Enter email"}
                    fontSize={"18px"}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </FormControl>
              </Stack>

              <Stack spacing="6" text-align={"right"} py={"20px"}>
                <Button
                  tabIndex={4}
                  type="submit"
                  fontSize={"18px"}
                  variant="primary"
                >
                  Add Solicitor
                </Button>
              </Stack>
            </form>
          ) : step === 2 ? (
            <VStack marginBottom={10}>
              <DoneIcon width={93} height={93} marginTop={6} marginBottom={4} />

              <Text paddingX={"100px"} pb={8} align={"center"}>
                This solicitor will be sent an email with a link to setup their
                password, before being granted access to the system.
              </Text>

              <Button
                tabIndex={7}
                type="submit"
                fontSize={"18px"}
                variant="primary"
                minWidth={184}
                minHeight={"48px"}
                height={"48px"}
                onClick={resetDialog}
              >
                Ok
              </Button>
            </VStack>
          ) : null
        }
        action={null}
        size={"3xl"}
        contentPaddingY={0}
      />

      {!hideListing ? (
        <Box>
          <HStack as={Flex}>
            <Heading
              fontSize={"32px"}
              lineHeight={"48px"}
              mb={"32px"}
              flexGrow={1}
            >
              Solicitors
            </Heading>
            <Button
              tabIndex={6}
              type="button"
              paddingX={9}
              alignSelf={"baseline"}
              fontSize={"18px"}
              height={"48px"}
              minHeight={"48px"}
              onClick={onOpen}
              variant="primary"
            >
              Add New Solicitor
            </Button>
          </HStack>
          {dispatchIsDataLoading ? (
            <Loading />
          ) : dispatchSolicitorList.data ? (
            <>
              {" "}
              <TableContainer
                backgroundColor={"#F9FBFC"}
                color={"#1A1E29"}
                pl={"16px"}
                pt={"12px"}
                pr={"20px"}
              >
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th {...thStyles}>Name</Th>
                      <Th {...thStyles}>Email</Th>
                      <Th {...thStyles} textAlign={"center"}>
                        Actions
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dispatchSolicitorList?.data?.data?.map(
                      ({ id, name, email, active }) => {
                        return (
                          <Tr key={id}>
                            <Td fontSize={"18px"} minWidth={"330px"}>
                              <Link to={id} as={ReachLink} color={"#1A1E29"}>
                                {name}
                              </Link>
                            </Td>
                            <Td fontSize={"18px"} color={"#B4BAC9"}>
                              <Link to={id} as={ReachLink} color={"#B4BAC9"}>
                                {email}
                              </Link>
                            </Td>
                            <Td textAlign={"center"}>
                              <Link
                                to={`/firm/solicitors/${id}/cases`}
                                as={ReachLink}
                              >
                                <AdminClientsLight
                                  fill={"none"}
                                  w={8}
                                  h={8}
                                  marginBottom={"0px"}
                                  marginRight={"7px"}
                                  stroke={"#223268"}
                                />
                              </Link>

                              <Link to={id} as={ReachLink}>
                                <PencilSecondaryIcon
                                  fill={"none"}
                                  w={8}
                                  h={8}
                                  marginBottom={"-10px"}
                                  stroke={"#223268"}
                                />
                              </Link>

                              <Link
                                onClick={() => onDeleteStatus(id)}
                                textAlign={"right"}
                              >
                                <TrashBinIcon
                                  fill={"none"}
                                  w={8}
                                  h={8}
                                  marginBottom={"-10px"}
                                  stroke={"#223268"}
                                />
                              </Link>
                             {isAdmin && <Link onClick={() => onSolicitorLogin(id)}>
                                <UserLoginIcon
                                  fill={"none"}
                                  w={6}
                                  h={6}
                                  marginBottom={"-2px"}
                                  stroke={"#b6bcca"}
                                />
                              </Link>}
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
              <Pagination
                dispatchList={dispatchList}
                dispatchListPagination={dispatchListPagination}
                dispatchLoadingFlag={dispatchLoadingFlag}
                pagePath={PAGE_PATH}
                name={"Solicitors"}
              />
            </>
          ) : (
            <Loading />
          )}
        </Box>
      ) : null}
    </>
  );
};

export default FirmSolicitors;
