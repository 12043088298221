import {
  SET_DATA_LOADING_TRUE,
  SET_DATA_LOADING_FALSE,
  ACCOUNTS_FORM_LIST_DOWNLOADED,
  ACCOUNTS_FORM_LIST_NOT_DOWNLOADED,
  ACCOUNTS_FORM_CHANGE_STATUS,
} from "../actions";

const initialState = {
  data: null,
  errorMessage: null,
  isDataLoading: false,
  getById: null,
}

export default function formsManageReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNTS_FORM_LIST_DOWNLOADED:
      return {
        ...state,
        data: action.payload,
        isDataLoading: false
      }
    case ACCOUNTS_FORM_CHANGE_STATUS:
      const oldData = {...state.data};
      const item = oldData.data.find(v => v.id === action.payload.id);
      item.status = action.payload.status;
      return {
        ...state,
        data: oldData,
        isDataLoading: false
      }
    case ACCOUNTS_FORM_LIST_NOT_DOWNLOADED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case SET_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }
    case SET_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }
    default:
      return state
  }
}
