import React, {useEffect, useState} from 'react';
import {
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Flex,
  Spacer,
  TableContainer, useDisclosure, Link, Box, Stack, FormControl, FormLabel, Input, VStack, Text, Select
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
// import {deleteFirm} from "../redux/actions/firms";
// import {setNewClient, setClientErrorMessage} from "../redux/actions/clients";
import {setNewAuditor, getAuditorList, setLoadingFlag, deleteAuditor, setAuditorData, setAuditorErrorMessage} from "../../redux/actions/auditors";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Alert from "../../components/Alert";
import {Outlet, Link as ReachLink, useParams} from "react-router-dom";
import {PencilSecondaryIcon, TrashBinIcon, DoneIcon} from "../../assets/icons/sprite";
import {useFormik} from "formik";
import AlertComponent from "../../components/AlertComponent";

function AdminAuditors() {

  const {id: hideListing} = useParams();
  const PAGE_PATH = 'admin/firms?page=';

  const thStyles = {
    color: '#1A1E29',
    fontSize: '14px',
    textTransform: 'none',
    opacity: '0.5',
    fontWeight: 400,
    border: 'none',
  }

  const [auditorId, setAuditorId] = useState(null);
  const [step, setStep] = useState(1);
  const [mode, setMode] = useState('invite');
  const [title, setTitle] = useState('Invite Auditor');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!hideListing) {
      dispatch(setLoadingFlag());
      dispatch(getAuditorList());
    }
  }, [
    dispatch,
    hideListing
  ])

  const {isOpen: isDelOpen, onOpen: onDelOpen, onClose: onDelClose} = useDisclosure();
  const {isOpen: isInviteOpen, onOpen: onInviteOpen, onClose: onInviteClose} = useDisclosure();

  const dispatchList = (credentials) => dispatch(getAuditorList(credentials))
  const dispatchLoadingFlag = (credentials) => dispatch(setLoadingFlag(credentials))
  const dispatchAuditorDel = (id) => dispatch(deleteAuditor(id))
  const dispatchSetNewAuditor = (data) => dispatch(setNewAuditor(data))
  const dispatchSetAuditorData = (data) => dispatch(setAuditorData(data))
  const dispatchSetErrorMessage = (message) => dispatch(setAuditorErrorMessage(message))

  const dispatchAuditorsList = useSelector(state => state.auditors);
  const dispatchListPagination = useSelector(state => state.auditors?.data?.meta?.pagination);
  const dispatchIsDataLoading = useSelector(state => state.auditors?.isDataLoading);
  const dispatchErrorMessage = useSelector(state => state.auditors?.errorMessage)

  const onDeleteStatus = (id = null) => {
    setAuditorId(id);
    onDelOpen();
  }

  const onEditStatus = (id = null, name = null, email = null, active = null) => {
    setAuditorId(id);
    formik.values.auditorId = id;
    formik.values.name = name;
    formik.values.email = email;
    formik.values.active = active;
    setStep(1);
    setMode('edit');
    setTitle('Edit Auditor');
    onInviteOpen();    
  }

  const handleSubmit = async values => {
    if (values.auditorId !== "") {
      dispatchSetAuditorData({
        "id": values.auditorId,
        "email": values.email,
        "name": values.name,
        "active": values.active,
        "expiry": values.expiry
      }).then(() => {
        dispatch(setLoadingFlag());
        dispatch(getAuditorList());
        onInviteClose();
      })
    }
    else {
      dispatchSetNewAuditor({
        "email": values.email,
        "name": values.name,
        "expiry": values.expiry
      }).then(() => {
        setTitle('Invite Sent');
        setStep(2);
        dispatch(setLoadingFlag());
        dispatch(getAuditorList());
      }).catch((error) => {
        dispatchSetErrorMessage(error);
      })
    }
  }

  const resetDialog = () => {
    formik.values.auditorId = '';
    formik.values.name = '';
    formik.values.email = '';
    formik.values.active = '';
    formik.values.expiry = '';
    dispatchSetErrorMessage();
    setStep(1);
    setTitle('Invite Auditor');
    setMode('invite');
    onInviteClose();
  }

  const formik = useFormik({
    initialValues: {
      name: '', email: '', auditorId: '', active: '', expiry: ''
    }, onSubmit: handleSubmit
  })

  return (
    <>
      <Outlet/>

      <Alert
        isOpen={isDelOpen}
        onOpen={onDelOpen}
        onClose={onDelClose}
        header={`Delete Auditor`}
        text={`Are you sure you want to delete this Auditor?`}
        action={() => {
          dispatch(setLoadingFlag());
          dispatchAuditorDel(auditorId)
            .then((res) => {
              dispatchList();
            });
        }}
      />

    <Alert
      isOpen={isInviteOpen}
      onOpen={onInviteOpen}
      onClose={resetDialog}
      header={title}
      text={
        step === 1 ? 
          <form 
          onSubmit={formik.handleSubmit}
          >

        <Input
              id="auditorId"
              type="hidden"
              value={formik.values.auditorId}
            />

        <Input
              id="status"
              type="hidden"
              value={formik.values.status}
            />

        <Stack spacing="5">

          {dispatchErrorMessage && (<AlertComponent
            message={dispatchErrorMessage}
          />)}

          <FormControl>
            <FormLabel
              htmlFor="name"
              fontSize={'18px'}>
              Name
            </FormLabel>
            <Input
              id="name"
              type="text"
              required
              placeholder={'Enter name'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="email" fontSize={'18px'}>Email</FormLabel>
            <Input
              id="email"
              type="email"
              required
              placeholder={'Enter email'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel
              htmlFor="expiry"
              fontSize={'18px'}>
              How long for?
            </FormLabel>

            <Select labelId="expiry"
              name="expiry"
              id="expiry"
              label="expiry"
              onChange={formik.handleChange}>
              <option value='3_day'>3 Days</option>
              <option value='1_week'>1 Week</option>
              <option value='1_month'>1 Month</option>
              <option value='3_month'>3 Months</option>
              <option value='6_month'>6 Months</option>
            </Select>
          </FormControl>          

        </Stack>

        <Stack spacing="6" text-align={'right'} py={'20px'}>
          <Button
            type="submit"
            fontSize={'18px'}
            variant="primary">
            {mode === "invite" 
            ?
            'Invite'
            : 'Save'}
            </Button>
        </Stack>
      </form> : step === 2 ? <VStack
        marginBottom={10}
      >
        <DoneIcon
          width={93}
          height={93}
          marginTop={6}
          marginBottom={4}/>

        <Text
          paddingX={'100px'}
          pb={8}
          align={'center'}>This auditor has now been sent an invitation.</Text>

        <Button
          type="submit"
          fontSize={'18px'}
          variant="primary"
          minWidth={184}
          minHeight={'48px'}
          height={'48px'}
          onClick={resetDialog}>Ok</Button>
      </VStack> : null}
      action={null}
      size={'3xl'}
      contentPaddingY={0}
    />

      {!hideListing ? <Box>

      <HStack as={Flex}>
          <Heading
            fontSize={'32px'}
            lineHeight={'48px'}
          >Auditors</Heading>

          <Spacer />

          <Button
            bgColor={'#4285F4'}
            color={'#ffffff'}
            fontWeight={'700'}
            type="button"
            paddingX={9}
            alignSelf={'flexEnd'}
            fontSize={'18px'}
            height={'48px'}
            minHeight={'48px'}
            variant="primary"
            transition='all 0.2s'
            borderRadius='md'
            borderWidth='1px'
            _hover={{bg: '#72a9ff'}}
            _expanded={{bg: '#4285F4'}}
            _focus={{boxShadow: 'outline'}}
            onClick={onInviteOpen}
          >
            Invite Auditor
          </Button>
        </HStack>

          {dispatchIsDataLoading
            ? <Loading/>
            :

            dispatchAuditorsList.data
              ? <> <TableContainer
                backgroundColor={'#F9FBFC'}
                mt={'44px'}
                color={'#1A1E29'}
              >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      {/*<Th*/}
                      {/*  {...thStyles}*/}
                      {/*>Id</Th>*/}
                      <Th
                        {...thStyles}
                      >Name</Th>
                      <Th
                        {...thStyles}
                      >Email</Th>
                      <Th
                        {...thStyles}
                      >Status</Th>
                      <Th
                        {...thStyles}
                      >Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dispatchAuditorsList?.data?.data?.map(({id, name, email, active}) => {
                      return (
                        <Tr key={id}>
                          {/*<Td*/}
                          {/*>{id}</Td>*/}
                          <Td
                            fontSize={'18px'}
                            minWidth={'270px'}
                          >
                            <Link to={id}
                                  as={ReachLink}
                                  color={'#1A1E29'}
                            >{name}</Link>
                          </Td>
                          <Td
                            fontSize={'18px'}
                          >
                            <Link to={id}
                                  as={ReachLink}
                                  color={'#B4BAC9'}
                            >
                              {email}
                            </Link></Td>
                          <Td>

                            {active.toString() === 'false'
                              ? <Button
                                colorScheme='orange'
                                backgroundColor={'#C8392D'}
                                color={'#FFFFFF'}
                                size='xs'
                                fontWeight={400}
                                fontSize={'14px'}
                                w={'100%'}
                              >
                                Inactive
                              </Button>
                              : <Button
                                colorScheme='green'
                                size='xs'
                                fontWeight={400}
                                fontSize={'14px'}
                                w={'100%'}
                              >
                                Active
                              </Button>

                            }
                          </Td>
                          <Td>
                            {/*  Link*/}
                            {/*  to={id}*/}
                            {/*  as={ReachLink}*/}
                            {/*  bg={'none'}*/}
                            {/*  borderRadius={'7px'}*/}
                            {/*  border={'1.5px solid #6E7997'}*/}
                            {/*  color={'#6E7997'}*/}
                            {/*  fontSize={'12px'}*/}
                            {/*  padding={'6px'}*/}
                            {/*  display={'flex'}*/}
                            {/*  width={'25px'}*/}
                            {/*  minWidth={'25px'}*/}
                            {/*  height={'25px'}*/}
                            {/*  justifyContent={'center'}*/}
                            {/*  alignItems={'center'}*/}
                            {/*>...</Link>*/}

                            <Link 
                              onClick={() => onEditStatus(id, name, email, active)}
                            >
                              <PencilSecondaryIcon
                                fill={'none'}
                                w={8}
                                h={8}
                                marginBottom={'-10px'}
                                stroke={'#223268'}

                              /></Link>
                            <Link
                              textAlign={"right"}
                              onClick={() => onDeleteStatus(id)}
                            >
                              <TrashBinIcon
                                fill={'none'}
                                w={8}
                                h={8}
                                marginBottom={'-10px'}
                                stroke={'#223268'}
                              /></Link>
                          </Td>
                        </Tr>
                      )
                    })
                    }
                  </Tbody>
                </Table>
              </TableContainer>

                <Pagination
                  dispatchList={dispatchList}
                  dispatchListPagination={dispatchListPagination}
                  dispatchLoadingFlag={dispatchLoadingFlag}
                  pagePath={PAGE_PATH}
                  name="auditors"
                />
              </>
              : <Loading/>
          }
        </Box>
        : null}

    </>
  );
}

export default AdminAuditors;
