import {
  ADMIN_AUTHENTICATED,
  ADMIN_LOGIN_AS_FIRM,
  ADMIN_CANNOT_LOGIN_AS_FIRM,
  ADMIN_NOT_AUTHENTICATED,
  FIRM_NOT_AUTHENTICATED,
  FIRM_AUTHENTICATED,
  FIRM_LOGIN_AS_SOLICITOR,
  FIRM_CANNOT_LOGIN_AS_SOLICITOR ,
  FIRM_CODE_AUTHENTICATED,
  FIRM_CODE_NOT_AUTHENTICATED,
  FIRM_CODE_SEND,
  FIRM_CODE_NOT_SEND,
  ADMIN_SET_ERR_MESSAGE,
  FIRM_SET_ERR_MESSAGE,
  FIRM_REGISTERED,
  FIRM_NOT_REGISTERED,
  FORGOT_CODE_SENT,
  FORGOT_CODE_NOT_SENT,
  FORGOT_CODE_NOT_ACCEPTED,
  FORGOT_CODE_ACCEPTED,
  FORGOT_NEW_PASS_ACCEPTED,
  FORGOT_NEW_PASS_NOT_ACCEPTED,
  SET_ADMIN_LOGIN_DATA_LOADING_TRUE,
  SET_ADMIN_LOGIN_DATA_LOADING_FALSE,
  SET_FIRM_LOGIN_DATA_LOADING_TRUE,
  SET_FIRM_LOGIN_DATA_LOADING_FALSE,
  SET_SOLICITOR_LOGIN_DATA_LOADING_FALSE,
  SET_SOLICITOR_LOGIN_DATA_LOADING_TRUE,
  SOLICITOR_FORGOT_NEW_PASS_NOT_ACCEPTED,
  SOLICITOR_FORGOT_NEW_PASS_ACCEPTED,
  SOLICITOR_FORGOT_CODE_NOT_ACCEPTED,
  SOLICITOR_FORGOT_CODE_ACCEPTED,
  SOLICITOR_FORGOT_CODE_NOT_SENT,
  SOLICITOR_FORGOT_CODE_SENT,
  SOLICITOR_NOT_REGISTERED,
  SOLICITOR_REGISTERED,
  SOLICITOR_SET_ERR_MESSAGE,
  SOLICITOR_CODE_NOT_SEND,
  SOLICITOR_CODE_SEND,
  SOLICITOR_CODE_NOT_AUTHENTICATED,
  SOLICITOR_CODE_AUTHENTICATED,
  SOLICITOR_NOT_AUTHENTICATED,
   SOLICITOR_AUTHENTICATED,
  SET_SOLICITOR_NEW_PASSWORD,
  SET_SOLICITOR_NEW_PASSWORD_FAILED,
  SET_NEW_SOLICITOR_BY_SECRET_SUCCESS,
  SET_NEW_SOLICITOR_BY_SECRET_ERR_MESSAGE,
  SET_SOLICITOR_NOT_FIRST_CHECK,
  SET_SOLICITOR_FIRST_CHECK,
  SET_FIRM_NOT_FIRST_CHECK,
  SET_FIRM_FIRST_CHECK,
  SET_ADMIN_NOT_FIRST_CHECK,
  SET_ADMIN_FIRST_CHECK,
  SET_ACCOUNT_FIRST_CHECK,
  ACCOUNT_AUTHENTICATED,
  SET_ACCOUNT_NOT_FIRST_CHECK,
  ACCOUNT_NOT_AUTHENTICATED,
  ACCOUNT_CODE_AUTHENTICATED,
  ACCOUNT_CODE_NOT_AUTHENTICATED,
  ACCOUNT_CODE_SEND,
  ACCOUNT_CODE_NOT_SEND,
  ACCOUNT_REGISTERED,
  ACCOUNT_SET_ERR_MESSAGE,
  ACCOUNT_FORGOT_CODE_SENT,
  ACCOUNT_NOT_REGISTERED,
  ACCOUNT_FORGOT_CODE_NOT_SENT,
  ACCOUNT_FORGOT_CODE_ACCEPTED,
  ACCOUNT_FORGOT_CODE_NOT_ACCEPTED,
  ACCOUNT_FORGOT_NEW_PASS_ACCEPTED,
  ACCOUNT_FORGOT_NEW_PASS_NOT_ACCEPTED,
  SET_ACCOUNT_LOGIN_DATA_LOADING_TRUE,
  SET_ACCOUNT_LOGIN_DATA_LOADING_FALSE, SET_ACCOUNT_NEW_PASSWORD, SET_ACCOUNT_NEW_PASSWORD_FAILED,

  SET_AUDITOR_FIRST_CHECK,
  AUDITOR_AUTHENTICATED,
  SET_AUDITOR_NOT_FIRST_CHECK,
  AUDITOR_NOT_AUTHENTICATED,
  AUDITOR_CODE_AUTHENTICATED,
  AUDITOR_CODE_NOT_AUTHENTICATED,
  AUDITOR_CODE_SEND,
  AUDITOR_CODE_NOT_SEND,
  AUDITOR_REGISTERED,
  AUDITOR_SET_ERR_MESSAGE,
  AUDITOR_FORGOT_CODE_SENT,
  AUDITOR_NOT_REGISTERED,
  AUDITOR_FORGOT_CODE_NOT_SENT,
  AUDITOR_FORGOT_CODE_ACCEPTED,
  AUDITOR_FORGOT_CODE_NOT_ACCEPTED,
  AUDITOR_FORGOT_NEW_PASS_ACCEPTED,
  AUDITOR_FORGOT_NEW_PASS_NOT_ACCEPTED,
  SET_AUDITOR_LOGIN_DATA_LOADING_TRUE,
  SET_AUDITOR_LOGIN_DATA_LOADING_FALSE, SET_AUDITOR_NEW_PASSWORD, SET_AUDITOR_NEW_PASSWORD_FAILED,
} from "../actions";

const initialState = {
  authChecked: false,
  loggedIn: false,
  errorMessage: null,
  codeChecked: false,
  currentUser: {},
  isLoading: false,
  isDataLoading: false,
  isFirstCheck: true,
  isAdmin: false

}



export default function adminAuthReducer(state = initialState, action) {
  switch (action.type) {

    case SET_ADMIN_NOT_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: false
      }

    case SET_ADMIN_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: true
      }

    case ADMIN_LOGIN_AS_FIRM: 
    return {
      ...state, 
      isAdmin: action.payload
    }
    

    case ADMIN_CANNOT_LOGIN_AS_FIRM: 
    
    return {
      ...state,
      errorMessage: action.payload.message,
    }

    case ADMIN_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        currentUser: action.payload,
        isLoading: false,
      }
    case ADMIN_NOT_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: false,
        currentUser: {},
        errorMessage: action.payload,
        isLoading: false,
      }
    case ADMIN_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_ADMIN_LOGIN_DATA_LOADING_TRUE:
      return {
        ...state,
        isLoading: true
      }

    case SET_ADMIN_LOGIN_DATA_LOADING_FALSE:
      return {
        ...state,
        isLoading: false
      }


    default:
      return state
  }
}

export function firmAuthReducer(state = initialState, action) {
  switch (action.type) {

    case SET_FIRM_NOT_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: false
      }

    case SET_FIRM_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: true
      }

    case FIRM_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload,
        isLoading: false
      }
    case FIRM_NOT_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: false,
        currentUser: {},
        errorMessage: action.payload,
        isLoading: false
      }

      case FIRM_LOGIN_AS_SOLICITOR: 
      return {...state, isAdmin: true}
  
      case FIRM_CANNOT_LOGIN_AS_SOLICITOR: 
      
      return {
        ...state,
        errorMessage: action.payload.message,
      }

    case FIRM_CODE_AUTHENTICATED:
      return {
        ...state,
        codeChecked: true,
        errorMessage: null
      }
    case FIRM_CODE_NOT_AUTHENTICATED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FIRM_CODE_SEND:
      return {
        ...state,
        errorMessage: null
      }
    case FIRM_CODE_NOT_SEND:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FIRM_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FIRM_REGISTERED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload
      }
    case FIRM_NOT_REGISTERED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_CODE_SENT:
      return {
        ...state,
      }
    case FORGOT_CODE_NOT_SENT:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_CODE_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case FORGOT_CODE_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_NEW_PASS_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case FORGOT_NEW_PASS_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_FIRM_LOGIN_DATA_LOADING_TRUE:
      return {
        ...state,
        isLoading: true
      }

    case SET_FIRM_LOGIN_DATA_LOADING_FALSE:
      return {
        ...state,
        isLoading: false
      }

    default:
      return state
  }
}

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SOLICITOR_NOT_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: false
      }

    case SET_SOLICITOR_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: true
      }
    case FORGOT_CODE_SENT:
      return {
        ...state,
      }
    case FORGOT_CODE_NOT_SENT:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_CODE_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case FORGOT_CODE_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_NEW_PASS_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case FORGOT_NEW_PASS_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_SOLICITOR_NEW_PASSWORD:
      return {
        ...state,
        errorMessage: null,
        solicitorBySecret: action.payload,
        isDataLoading: false
      }
    case SET_SOLICITOR_NEW_PASSWORD_FAILED:
      return {
        ...state,
        getSolicitorBySecret: null,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case SOLICITOR_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload,
        isDataLoading: false
      }
    case SOLICITOR_NOT_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: false,
        currentUser: {},
        errorMessage: action.payload,
        isDataLoading: false
      }
    case SOLICITOR_CODE_AUTHENTICATED:
      return {
        ...state,
        codeChecked: true,
        errorMessage: null
      }
    case SOLICITOR_CODE_NOT_AUTHENTICATED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SOLICITOR_CODE_SEND:
      return {
        ...state,
        errorMessage: null
      }
    case SOLICITOR_CODE_NOT_SEND:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SOLICITOR_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false
      }
    case SOLICITOR_REGISTERED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload
      }
    case SOLICITOR_NOT_REGISTERED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SOLICITOR_FORGOT_CODE_SENT:
      return {
        ...state,
      }
    case SOLICITOR_FORGOT_CODE_NOT_SENT:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SOLICITOR_FORGOT_CODE_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case SOLICITOR_FORGOT_CODE_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SOLICITOR_FORGOT_NEW_PASS_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case SOLICITOR_FORGOT_NEW_PASS_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_SOLICITOR_LOGIN_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }

    case SET_SOLICITOR_LOGIN_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }

    case SET_NEW_SOLICITOR_BY_SECRET_SUCCESS:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload.user,
        isDataLoading: false
      }

    case SET_NEW_SOLICITOR_BY_SECRET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false
      }

    default:
      return state
  }
}
export function accountAuthReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT_NOT_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: false
      }

    case SET_ACCOUNT_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: true
      }
    case FORGOT_CODE_SENT:
      return {
        ...state,
      }
    case FORGOT_CODE_NOT_SENT:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_CODE_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case FORGOT_CODE_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_NEW_PASS_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case FORGOT_NEW_PASS_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_ACCOUNT_NEW_PASSWORD:
      return {
        ...state,
        errorMessage: null,
        solicitorBySecret: action.payload,
        isDataLoading: false
      }
    case SET_ACCOUNT_NEW_PASSWORD_FAILED:
      return {
        ...state,
        getSolicitorBySecret: null,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case ACCOUNT_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload,
        isDataLoading: false
      }
    case ACCOUNT_NOT_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: false,
        currentUser: {},
        errorMessage: action.payload,
        isDataLoading: false
      }
    case ACCOUNT_CODE_AUTHENTICATED:
      return {
        ...state,
        codeChecked: true,
        errorMessage: null
      }
    case ACCOUNT_CODE_NOT_AUTHENTICATED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case ACCOUNT_CODE_SEND:
      return {
        ...state,
        errorMessage: null
      }
    case ACCOUNT_CODE_NOT_SEND:
      return {
        ...state,
        errorMessage: action.payload
      }
    case ACCOUNT_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false
      }
    case ACCOUNT_REGISTERED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload
      }
    case ACCOUNT_NOT_REGISTERED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case ACCOUNT_FORGOT_CODE_SENT:
      return {
        ...state,
      }
    case ACCOUNT_FORGOT_CODE_NOT_SENT:
      return {
        ...state,
        errorMessage: action.payload
      }
    case ACCOUNT_FORGOT_CODE_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case ACCOUNT_FORGOT_CODE_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case ACCOUNT_FORGOT_NEW_PASS_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case ACCOUNT_FORGOT_NEW_PASS_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_ACCOUNT_LOGIN_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }

    case SET_ACCOUNT_LOGIN_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }

    default:
      return state
  }
}

export function auditorAuthReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUDITOR_NOT_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: false
      }

    case SET_AUDITOR_FIRST_CHECK:
      return {
        ...state,
        isFirstCheck: true
      }
    case FORGOT_CODE_SENT:
      return {
        ...state,
      }
    case FORGOT_CODE_NOT_SENT:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_CODE_ACCEPTED:
      return {
        ...state,
        currentUser: action.payload,
        errorMessage: null
      }
    case FORGOT_CODE_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case FORGOT_NEW_PASS_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case FORGOT_NEW_PASS_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_AUDITOR_NEW_PASSWORD:
      return {
        ...state,
        errorMessage: null,
        solicitorBySecret: action.payload,
        isDataLoading: false
      }
    case SET_AUDITOR_NEW_PASSWORD_FAILED:
      return {
        ...state,
        getSolicitorBySecret: null,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case AUDITOR_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload,
        isDataLoading: false
      }
    case AUDITOR_NOT_AUTHENTICATED:
      return {
        ...state,
        authChecked: true,
        loggedIn: false,
        currentUser: {},
        errorMessage: action.payload,
        isDataLoading: false
      }
    case AUDITOR_CODE_AUTHENTICATED:
      return {
        ...state,
        codeChecked: true,
        errorMessage: null
      }
    case AUDITOR_CODE_NOT_AUTHENTICATED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case AUDITOR_CODE_SEND:
      return {
        ...state,
        errorMessage: null
      }
    case AUDITOR_CODE_NOT_SEND:
      return {
        ...state,
        errorMessage: action.payload
      }
    case AUDITOR_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false
      }
    case AUDITOR_REGISTERED:
      return {
        ...state,
        authChecked: true,
        loggedIn: true,
        errorMessage: null,
        currentUser: action.payload
      }
    case AUDITOR_NOT_REGISTERED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case AUDITOR_FORGOT_CODE_SENT:
      return {
        ...state,
      }
    case AUDITOR_FORGOT_CODE_NOT_SENT:
      return {
        ...state,
        errorMessage: action.payload
      }
    case AUDITOR_FORGOT_CODE_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case AUDITOR_FORGOT_CODE_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case AUDITOR_FORGOT_NEW_PASS_ACCEPTED:
      return {
        ...state,
        errorMessage: null
      }
    case AUDITOR_FORGOT_NEW_PASS_NOT_ACCEPTED:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_AUDITOR_LOGIN_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }

    case SET_AUDITOR_LOGIN_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }

    default:
      return state
  }
}
