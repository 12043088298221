import React, {useEffect, useState} from 'react';
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
  Button,
  HStack,
  Flex,
  Box,
  useRadioGroup
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import '../../assets/css/PhoneFields.css';
import {useNavigate, Link as ReachLink, Outlet, useParams} from "react-router-dom";
import {CorporateIcon, IndividualIcon} from "../../assets/icons/sprite";
import {
  getClientsList,
  setLoadingFlag
} from "../../redux/actions/clients";
import RadioCard from "../../components/RadioCard";
import BackButton from "../../components/BackButton";

const AuditorFirmClients = () => {

  const {firmid, solicitorid} = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const dispatchList = (data, solicitorId) => dispatch(getClientsList(data, solicitorId, 'auditor'))
  const dispatchLoadingFlag = (credentials) => dispatch(setLoadingFlag(credentials))

  const dispatchClientsList = useSelector(state => state.clients);
  const dispatchListPagination = useSelector(state => state.clients?.data?.meta?.pagination);
  const dispatchIsDataLoading = useSelector(state => state.clients?.isDataLoading);

  const hideListing = ''

  const [pagePath, setPagePath] = useState('clients?page=')

  const thStyles = {
    color: '#1A1E29',
    fontSize: '14px',
    textTransform: 'none',
    opacity: '0.5',
    fontWeight: 400,
    border: 'none'
  }

/*
  const linkStyles = {
    bg: 'none',
    borderRadius: '7px',
    border: '1.5px solid #6E7997',
    color: '#6E7997',
    fontSize: '12px',
    padding: '6px',
    display: 'flex',
    width: '25px',
    minWidth: '25px',
    height: '25px',
    justifyContent: 'center',
    alignItems: 'center',
  }*/

  const options = ['Show All', 'Individual', 'Corporate']

  const {getRadioProps} = useRadioGroup({
    name: 'filter',
    defaultValue: 'Show All',
    onChange: (value) => {
      switch (value) {
        case 'Individual':
          setPagePath('clients?type=individual&page=')
          getData('clients?type=individual', solicitorid, 'auditor')
          break;
        case 'Corporate':
          setPagePath('clients?type=corporate&page=')
          getData('clients?type=corporate', solicitorid, 'auditor')
          break;
        default:
          setPagePath('clients?page=')
          getData('clients', solicitorid, 'auditor')
          break;
      }
    },
  })

  const getData = (url = 'clients', solicitorId = null) => {
    dispatchLoadingFlag();
    dispatchList(url, solicitorId, 'auditor');
  }

  useEffect(() => {
      dispatch(setLoadingFlag());
      dispatch(getClientsList('clients', solicitorid, 'auditor'));
  }, [dispatch, solicitorid])

  return (<>
    <Outlet/>

    {!hideListing ? <Box>

        <BackButton
          tabIndex={9}
          onClickHandler={() => {
            navigate(-1)
          }}
          ml={'-17px'}
        />


      <HStack as={Flex}>
        <Heading
          fontSize={'32px'}
          lineHeight={'48px'}
          mb={'17px'}
          flexGrow={1}
        >Clients</Heading>

      </HStack>
      <HStack
        as={Flex}
        mb={10}
      >
        {options.map((value, idx) => {
          const radio = getRadioProps({value})
          return (<RadioCard key={value} {...radio}>
            {value}
          </RadioCard>)
        })}

      </HStack>

      {dispatchIsDataLoading
        ? <Loading/>
        : dispatchClientsList.data
          ? <> <TableContainer
            backgroundColor={'#F9FBFC'}
            color={'#1A1E29'}
            pl={'16px'}
            pt={'12px'}
            pr={'20px'}
          >
            <Table
              variant='simple'
            >
              <Thead>
                <Tr>
                  <Th
                    {...thStyles}
                  >Client Name</Th>
                  <Th
                    {...thStyles}
                  >Email</Th>
                  <Th
                    {...thStyles}
                  >Status</Th>
                  <Th
                    {...thStyles}
                  >Type</Th>
                  <Th
                    {...thStyles}
                    width={'70px'}
                    textAlign={'right'}
                  >Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dispatchClientsList?.data?.data?.map(({
                                                         id, email, status, first_name, last_name, type, name, main_contact
                                                       }) => {
                  id = id.toString()
                  return (<Tr key={id}>
                    <Td
                      fontSize={'18px'}
                      minWidth={'95px'}
                    ><Link
                      to={type === 'individual' ? id : null}
                      as={ReachLink}
                      color={'#1A1E29'}
                    >{type !== 'individual' ? name : `${first_name} ${last_name}`}</Link></Td>
                    <Td
                      fontSize={'18px'}
                      color={'#B4BAC9'}
                    ><Link
                      to={type === 'individual' ? id : null}
                      as={ReachLink}
                      color={'#B4BAC9'}
                    >

                    {main_contact !== null?
                    <>{main_contact.email}</>
                    :<>{email}</>}

                    </Link></Td>
                    <Td
                    >
                      {status.toString() === 'pending' ? <Button
                        colorScheme='orange'
                        backgroundColor={'#C8392D'}
                        color={'#FFFFFF'}
                        size='xs'
                        fontWeight={400}
                        fontSize={'14px'}
                        w={'100%'}
                        // onClick={() => onChangeStatus(id)}
                      >
                        Pending
                      </Button> : <Button
                        colorScheme='green'
                        size='xs'
                        fontWeight={400}
                        fontSize={'14px'}
                        w={'100%'}
                        // onClick={() => onChangeStatus(id, false)}
                      >
                        Onboard
                      </Button>

                      }
                    </Td>
                    <Td
                      fontSize={'18px'}
                    >
                      {type === 'individual'
                        ? <IndividualIcon
                          w={18}
                          h={18}
                          fill={'none'}
                          mr={'2'}/>
                        : <CorporateIcon
                          w={18}
                          h={18}
                          fill={'none'}
                          mr={'2'}/>
                      }

                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Td>
                    <Td>

                    {type === 'individual' ? 
                    <Link
                      to={'/auditor/firms/' + firmid + '/solicitor/' + solicitorid + '/clients/' + id}
                      as={ReachLink}
                    >
                    <Button
                        colorScheme='green'
                        size='sm'
                        fontWeight={600}
                        fontSize={'14px'}
                        w={'100%'}
                      >View</Button>
                    </Link>
                    :
                    <Link
                      to={'/auditor/firms/' + firmid + '/solicitor/' + solicitorid + '/clients/' + id}
                      as={ReachLink}
                    >
                    <Button
                        colorScheme='green'
                        size='xs'
                        fontWeight={400}
                        fontSize={'14px'}
                        w={'100%'}
                      >View</Button>
                    </Link>
                    }
                    </Td>
                  </Tr>)
                })}
              </Tbody>
            </Table>
          </TableContainer>

            <Pagination
              dispatchList={dispatchList}
              dispatchListPagination={dispatchListPagination}
              dispatchLoadingFlag={dispatchLoadingFlag}
              pagePath={pagePath}
              name={'Clients'}
            />
          </>
          : <Loading/>}
    </Box> : null}

  </>);
}
export default AuditorFirmClients;
