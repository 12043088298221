import {
  FIRM_LIST_DOWNLOADED,
  FIRM_LIST_NOT_DOWNLOADED,
  SET_DATA_LOADING_TRUE,
  SET_DATA_LOADING_FALSE,
  FIRM_ACTIVATE,
  FIRM_DEACTIVATE,
  FIRM_GET_BY_ID_SUCCESS,
  FIRM_SET_ERR_MESSAGE,
  SET_FIRM_NEW_DATA,
  SET_FIRM_NEW_DATA_FAILED,
  FIRM_GET_IDPAL_SUCCESS,
  FIRM_GET_IDPAL_FAILED,
  FIRM_SET_IDPAL_SUCCESS,
  FIRM_SET_IDPAL_FAILED,
  FIRM_DELETE_FAILED, FIRM_DELETE_SUCCESS,
  GET_AUDITOR_FIRM_BY_ID_SUCCESS
} from "../actions";

const initialState = {
  data: null,
  errorMessage: null,
  isDataLoading: false,
  getById: null
}

export default function firmManageReducer(state = initialState, action) {
  switch (action.type) {
    case FIRM_LIST_DOWNLOADED:
      return {
        ...state,
        data: action.payload,
        isDataLoading: false
      }
    case FIRM_LIST_NOT_DOWNLOADED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case FIRM_ACTIVATE:
      return {
        ...state
      }
    case FIRM_DEACTIVATE:
      return {
        ...state
      }

    case FIRM_DELETE_SUCCESS:
      return {
        ...state,
        date: state.data.data.filter((el) => el.id !== action.payload)
      }

    case FIRM_DELETE_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }


    case SET_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }

    case SET_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }


    case SET_FIRM_NEW_DATA:

      return {
        ...state,
        data: {
          meta: state.data.meta, data: state.data.data.map(
            (content, i) => state.data.data.findIndex((x) => x.id === action.payload?.data?.id) === i
              ? action.payload.data
              : content
          ), result: state.data.result
        },
        getSolicitorById: action.payload,
        errorMessage: null,
        isDataLoading: false
      }

    case SET_FIRM_NEW_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case GET_AUDITOR_FIRM_BY_ID_SUCCESS:
      return {
        ...state,
        getAuditorFirmById: action.payload,
        isDataLoading: false
      }

    case FIRM_GET_BY_ID_SUCCESS:
      return {
        ...state,
        getById: action.payload,
        isDataLoading: false
      }

    case FIRM_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case FIRM_GET_IDPAL_SUCCESS:
      return {
        ...state,
        getIdpal: action.payload,
        isDataLoading: false
      }

    case FIRM_GET_IDPAL_FAILED:
      return {
        ...state,
        getIdpal: null,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case FIRM_SET_IDPAL_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isDataLoading: false
      }

    case FIRM_SET_IDPAL_FAILED:
      return {
        ...state,
        getIdpal: null,
        errorMessage: action.payload,
        isDataLoading: false
      }



    default:
      return state
  }
}
