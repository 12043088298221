import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setFormLoadingFlag, setSendNewDocusignDoc, setS150FormLoading, getS150FormData, 
        getClientById, bypassSignedDocuments, undoBypassSignedDocuments} from "../../../../../redux/actions/clients";
import Alert from "../../../../Alert";
import Loading from "../../../../Loading";
import ViewAPdfFile from "../../../../ViewAPdfFile";
import AlertComponent from "../../../../AlertComponent"
import { Button, Flex, Grid, GridItem, Heading, Input, Text, useDisclosure } from "@chakra-ui/react";
import "./Items.css"
import ComplianceNumber from "../../../../../components/Solicitor/CaseView/Compliance/Number"

function ComplianceItemSignedDocuments(data) {

    const navigate = useNavigate();
    const dispatch = useDispatch();    
    const selectIsS150FormLoading = useSelector((state) => state.clients?.isS150FormLoading);
    const selectGetS150FormContent = useSelector((state) => state.clients?.getS150FormContent);
    const dispatchSetFormLoadingFlag = (flag) => dispatch(setFormLoadingFlag(flag));
    const dispatchItem = useSelector((state) => state.clients.getById);
    const dispatchSetSendNewDocusignDoc = (data) => dispatch(setSendNewDocusignDoc(data));
    const { isOpen: isS150FormOpen, onOpen: onS150FormOpen, onClose: onS150FormClose } = useDisclosure();
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
    const { isOpen: isBypassOpen, onOpen: onBypassOpen, onClose: onBypassClose } = useDisclosure();
    const dispatchErrorMessage = useSelector( (state) => state.clients.errorMessage );
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileSigned, setSelectedFileSigned] = useState(false);
    const [bypassReason, setBypassReason] = useState('')
    const [signedDocumentsStatus, setSignedDocumentsStatus] = useState(null)
    const [signedDocumentsComplete, setSignedDocumentsComplete] = useState(null)
    const [statusText, setStatusText] = useState("Not Yet Submitted")
    const [subtitleText, setSubtitleText] = useState("Upload your document or bypass this step for onboarding.")

    const fileSendHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setSelectedFileSigned(false);
        onConfirmOpen();
    };
    const fileSendHandlerSigned = (event) => {
        setSelectedFile(event.target.files[0]);
        setSelectedFileSigned(true);
        onConfirmOpen();
    };    
    const confirmS150DialogClose = () => {
        onConfirmClose();
    };
    const submitS150Document = () => {
        onConfirmClose();
        dispatchSetFormLoadingFlag();
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("client_id", dispatchItem?.data?.id);
        formData.append("signed", selectedFileSigned);
        dispatchSetSendNewDocusignDoc(formData)
            .then(() => navigate(0))
            .catch((err) => {
            dispatchSetFormLoadingFlag(false);
            alert("There was an error uploading (" + err + "). Please try again.");
            console.warn(err);
        });
    };

    const handleUndoByPassClick = () => {

        setSignedDocumentsStatus(null)
        setSignedDocumentsComplete(null)

        var data = {
            client_id: dispatchItem?.data?.id,
        }
        undoBypassSignedDocuments(data).then(resp => {
            dispatch(getClientById(dispatchItem?.data?.id)).then((client) => {
                // window.location.reload()
            })
        })        
    }

    const handleByPassClick = () => {
        onBypassOpen()
    }
    const handleBypassConfirmClick = () => {

        var data = {
            bypass_reason: bypassReason,
            client_id: dispatchItem?.data?.id,
        }
        bypassSignedDocuments(data).then(resp => {

            dispatch(getClientById(dispatchItem?.data?.id)).then((client) => {
                setSignedDocumentsStatus('bypass')
                setSignedDocumentsComplete(true)
                onBypassClose()
                // checkStatus()
            })
        })        
    };
    const handleBypassCloseClick = () => {
        onBypassClose()
    };
    const handleBypassReasonChange = event => {
        setBypassReason(event.target.value)
    }

    const dispatchSetS150FormLoading = (flag) => dispatch(setS150FormLoading(flag));
    const dispatchGetS150FormData = (clientId, isSigned, documentId) =>
        dispatch(
        getS150FormData(
            clientId,
            isSigned,
            1, 0,
            documentId
        ));

    // loop around all documents to see if they are all signed, or one is bypassed
    function checkStatus() {

        // if no documents, or only one which is shareholders
        if (dispatchItem.data?.docusigns.length === 0
            || (dispatchItem.data?.docusigns.length === 1 
                && dispatchItem.data?.docusigns[0].type === "shareholders")
            ) {
            if (signedDocumentsStatus === null) {
                setSignedDocumentsStatus('')
                setSignedDocumentsComplete(false)
            }
        }
        else {

            var myStatus = "signed"
            var myComplete = true
            var myBypassReason = ""
            var documentsCount = dispatchItem.data?.docusigns?.length;

            dispatchItem.data?.docusigns.map((signedDocument, index) => {
                
                // ignore any shareholders pdf documents
                if (signedDocument?.type !== "shareholders") {
                    if (signedDocument?.status === "pending" && myStatus !== "bypass") {
                        myStatus = "pending"
                        myComplete = false
                    }
                    else if (signedDocument?.status === "bypass") {
                        myStatus = "bypass"
                        myComplete = true
                        myBypassReason = signedDocument?.bypass_reason
                    }    

                    // if the most recent document is signed, its complete
                    else if (signedDocument?.status === "signed" 
                                && myStatus !== "bypass" 
                                && index === (documentsCount - 1)) {
                        myStatus = "signed"
                        myComplete = true
                    }
                }
                return null;
            })    

            setSignedDocumentsStatus(myStatus) 
            setSignedDocumentsComplete(myComplete)
            if (bypassReason === "" && myBypassReason !== "") { setBypassReason(myBypassReason) }
        }
    
        // set text and labels    
        if (myStatus === "signed") {
            setSubtitleText("Client signature received.");
            setStatusText("Signed");
        }
        else if (myStatus === "pending") {
            setSubtitleText("Awaiting client signature.");
            setStatusText("Pending")
        }
        else if (myStatus === "bypass") {
            setSubtitleText("The signed documents have been bypassed because '" + bypassReason + "'.");
            setStatusText("Bypassed")
        }
    }

    useEffect(() => {
        checkStatus()
    })

    return (    
<>
    <Alert
        isOpen={isBypassOpen}
        onOpen={onBypassOpen}
        onClose={onBypassClose}
        header={"Bypass Signed Documents"}
        mb={3}
        text={
          <>
            <Text>
              To bypass the signed document process please enter a reason below.      
            </Text>
            <textarea className="caseview__compliance-item-textarea" 
                onChange={handleBypassReasonChange} required={true}></textarea>
  
            <Button type="submit" onClick={handleBypassConfirmClick} variant="primary"
              fontSize={"18px"} mt={6} mr={15}
              disabled={bypassReason.length === 0 ? true: false}>
              Bypass
            </Button>
            <Button type="button" onClick={handleBypassCloseClick} variant="secondary"
              fontSize={"18px"} mt={6}>
              Cancel
            </Button>
          </>}>
    </Alert>

    <Alert
        isOpen={isConfirmOpen}
        onOpen={onConfirmOpen}
        onClose={onConfirmClose}
        header={"Confirm Upload"}
        mb={3}
        text={
          <>
            <Text>
              The document named <b>'{selectedFile?.name}</b> is{" "}
              <b>{Math.round((selectedFile?.size / 1024 / 1024) * 100) / 100}mb</b>. 
              {selectedFileSigned ? 
              ' Are you sure you want to submit this signed document?' : 
              ' Are you sure you want to submit it for signing?'}              
            </Text>
            <Button type="submit" onClick={submitS150Document} variant="primary"
              fontSize={"18px"} mt={10} mr={15}>
              Confirm
            </Button>
            <Button type="button" onClick={confirmS150DialogClose} variant="secondary"
              fontSize={"18px"} mt={10}>
              Cancel
            </Button>
          </>}>
    </Alert>

    <GridItem key={data?.number}>
        <ComplianceNumber complete={signedDocumentsComplete} number={data?.number} />
    </GridItem>
    <GridItem>

    <GridItem className="caseview__compliance-item-box" mt={1}>
        <Grid templateColumns={'1fr auto'}>
            <GridItem>
                <Heading as={'h3'} fontWeight={'700'} fontSize={'18px'}>
                    Documents
                </Heading>
                <Text color={'#6E7997'} fontWeight={'400'} mt={2} fontSize={'18px'}>
                    {subtitleText}
                </Text>
            </GridItem>
            <GridItem display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                <Text color={'#fff'} fontSize={'11px'}
                    bgColor={(() => {
                    if (signedDocumentsComplete) {
                        return '#1CBE7A' // green
                    }
                    else {
                        return '#FFBF00' // orange
                    }
                    })()}
                    paddingX={'10px'}
                    borderRadius={'4px'}>
                    {statusText}
                </Text>
            </GridItem>
        </Grid>

        {signedDocumentsStatus !== "bypass" ?

        <>
        <Grid templateColumns={'1fr 1fr'} spacing={120} style={{padding: '10px'}}>
            <GridItem>
                
                <Flex className="caseview__compliance-item-view-button-upload-new">
                    Upload and Send For Signature
                    <Input type={"file"}
                        style={{height: "100%", width: "100%", position: "absolute", top: 0, left: 0, opacity: 0 }}                              
                        aria-hidden={true}
                        name={"file"}
                        accept={"application/pdf"}
                        onChange={fileSendHandler}
                        onClick={(event) => {
                            event.target.value = null;
                        }}/>
                </Flex>

            </GridItem>
            <GridItem>

                <Flex className="caseview__compliance-item-view-button-upload-signed">
                    Store Copy of Signed Document
                    <Input type={"file"}
                        style={{height: "100%", width: "100%", position: "absolute", top: 0, left: 0, opacity: 0 }}                              
                        aria-hidden={true}
                        name={"file"}
                        accept={"application/pdf"}
                        onChange={fileSendHandlerSigned}
                        onClick={(event) => {
                            event.target.value = null;
                        }}/>
                </Flex>
            </GridItem>            
        </Grid>

                <Flex className="caseview__compliance-item-view-button-upload-bypass" 
                        style={{marginLeft: '10px', marginBottom: '20px'}}>
                    <button onClick={handleByPassClick} style={{width: '100%', height: '50px'}}>
                        Bypass This Step for Onboarding
                    </button>                     
                </Flex>
                </>:
                

                <Flex className="caseview__compliance-item-view-button-upload-bypass" 
                        style={{marginLeft: '10px', marginBottom: '20px'}}>
                    <button onClick={handleUndoByPassClick} style={{width: '100%', height: '50px'}}>
                        Undo Bypass
                    </button>                     
                </Flex>
                
                }

            {   dispatchItem.data?.docusigns.map((signedDocument) => {

                    if (signedDocument?.status === "bypass"
                            || signedDocument?.type === "shareholders"
                        ) { return '' }
                    return <>                 
                    <Alert isOpen={isS150FormOpen} onOpen={onS150FormOpen} onClose={onS150FormClose}
                header="Signed Document"
                text={
                    <>
                    {dispatchErrorMessage && (<AlertComponent message={dispatchErrorMessage}/>)}
                    {selectIsS150FormLoading ? ( <Loading />) 
                    : <ViewAPdfFile
                        data={selectGetS150FormContent?.data}
                        width={1028}/>}
                    </>}
                action={null}
                size={"6xl"}
                contentPaddingY={0}/>

            <Button className="caseview__compliance-item-view-button"
                marginTop={5}
                onClick={() => {
                    dispatchSetS150FormLoading(); 
                    dispatchGetS150FormData(signedDocument?.client_id, signedDocument?.status === "pending" ? 0 : 1, signedDocument?.id);
                    onS150FormOpen();
                }}>
                    {signedDocument?.status === "pending" ? 
                    'View Pending Document' : 
                    'View Signed Document'} ({new Date(signedDocument?.created_at).toLocaleDateString()})
            </Button>

                    </>                    
                }) 
            }
            <></>             

    </GridItem>

    </GridItem>

</>
)}

export default ComplianceItemSignedDocuments;