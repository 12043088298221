import React, {
  useEffect,
  useState
} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  AddressIcon,
  CalendarIcon,
  EmailIcon,
  MobileIcon,
  PencilIcon,
  UploadIconSecondary,
  ExpandDownIcon
} from "../../assets/icons/sprite";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack, Table, TableContainer, Tbody, Td, Thead, Th,
  Text, Tr, useDisclosure,
} from "@chakra-ui/react";
import BackButton from "../../components/BackButton";
import {isDateExpired} from "../../services/utils";
import Loading from "../../components/Loading";
import {
  getClientById, getS150FormData,
  setLoadingFlag, setS150FormLoading
} from "../../redux/actions/clients";
import AlertComponent from "../../components/AlertComponent";
import SolicitorCardItem from "../../components/EditClientCardItem";
import ClientOpeningFormItem from "../../components/ClientOpeningFormItem";
import Alert from "../../components/Alert";
import {PDFDownloadLink} from "@react-pdf/renderer";
import MyDocument from "../../components/Document";
import ViewAPdfFile from "../../components/ViewAPdfFile";

const AuditorClient = () => {
  const {clientid} = useParams();
  const dispatch = useDispatch();
  const toggleClient = (id) => {
    const clone = {...openedClients};
    clone[id] = !clone[id];
    setOpenedClients(clone);
  }  

  const questionsFieldKeys = [
    'Please Document The CDD(Customer Due Diligence) Measures To Be Applied, Have Been Applied And Any Compliance Decisions Taken',
    'Please Document The Reasons For Your Risk Assessment',
    'Client Risk',
    'Have You Met With Your Client Face-To-Face Or Is It A Non-Face-To-Face Transaction? Non-Face-To-Face Is A Factor Suggesting Potentially Higher Risk.',
    'Is Your Client A PEP?',
    'Have You Been Able To Confirm The Authenticity / Professional Status Of The Certifier Of Any Copies Of ID / Address Verification?',
    'Is Your Client Based / Resident / Linked To A High-Risk Jurisdiction / High Risk Third Word Country?',
    'Has Your Client Been Cooperative In The Process Or Have They Delayed Providing ID And Address Verification / Appeared Reluctant To Do So?',
    'How Well Do You Know Your Client And Background?',
    'Where Is Your Client Based? Locally / Ireland / EU / Other International Location?',
    'Is Your Client Been Introduced To You By A Through A Third Party? Is The Instruction From Your Client Channelled Through A Third Party? If So, Why? Are You Aware Of Your Client Having Any Links To Criminality?',
    'If Non-Face-To-Face, Are You Comfortable There Is A Legitimate Reason For This And What Is The Reason?',
    'Has Your Client Provided Acceptable Standard ID And Address Verification?',
    'Is Your Client Known To You Personally / Existing Client, For New Business Relationships?',
    'Does The Stated Source Of Wealth / Source Of Funds And The Amount Of Money Involved Stack Up With What You Know Of Your Client, For Example Given Their Age And Occupation?',
    'Has Your Client Provided Acceptable Non-Standard ID And Address Verification?',
  ]

  const dispatchErrorMessage = useSelector(state => state.clients.errorMessage)
  const dispatchIsDataLoading = useSelector(state => state.clients.isDataLoading)
  const dispatchItem = useSelector(state => state.clients.getById)
  const selectIsFormLoading = useSelector(state => state.clients?.isFormLoading)
  const [openedClients, setOpenedClients] = useState([]);

  const dispatchSetS150FormLoading = (flag) => dispatch(setS150FormLoading(flag))
  const dispatchGetS150FormData = (id, isSigned) => dispatch(
    getS150FormData(id = dispatchItem?.data?.id, isSigned = dispatchItem?.data?.docusigns[dispatchItem?.data?.docusigns.length - 1].status === 'pending'
      ? 0
      : 1, 1, 1)
  )
  const selectIsS150FormLoading = useSelector(state => state.clients?.isS150FormLoading)
  const selectGetS150FormContent = useSelector(state => state.clients?.getS150FormContent)

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {isOpen: isS150FormOpen, onOpen: onS150FormOpen, onClose: onS150FormClose} = useDisclosure();

  useEffect(() => {
    if (clientid) {
      dispatch(setLoadingFlag())
      dispatch(getClientById(clientid, 'auditor'))
    }
  }, [dispatch, clientid])

  const thStyles = {
    color: '#1A1E29',
    fontSize: '14px',
    textTransform: 'none',
    opacity: '0.5',
    fontWeight: 400,
    border: 'none'
  }

  const navigate = useNavigate()

  const editData = [
    {
      icon: <AddressIcon
        w={8}
        h={8}
        fill={'none'}/>,
      label: 'Address:',
      content: <>{dispatchItem?.data?.submissions[0] ?
          <>
            {dispatchItem?.data?.submissions[0]?.address_1 || dispatchItem?.data?.submissions[0]?.address_2
              ? <p>
                {`${dispatchItem?.data?.submissions[0]?.address_1 ?? ''}`}

                {`${dispatchItem?.data?.submissions[0]?.address_1 && dispatchItem?.data?.submissions[0]?.address_2
                  ? ', '
                  : ''}`}

                {`${dispatchItem?.data?.submissions[0]?.address_2 ?? ''}`}
              </p>
              : null
            }

            {dispatchItem?.data?.submissions[0]?.city || dispatchItem?.data?.submissions[0]?.postal_code
              ? <p>
                {`${dispatchItem?.data?.submissions[0]?.city ?? ''}`}

                {`${dispatchItem?.data?.submissions[0]?.city && dispatchItem?.data?.submissions[0]?.postal_code
                  ? ', '
                  : ''}`}

                {`${dispatchItem?.data?.submissions[0]?.postal_code ?? ''}`}

              </p>
              : null
            }
            <p>{`${dispatchItem?.data?.submissions[0]?.county ?? ''}`}</p>

            {(!dispatchItem?.data?.submissions[0]?.address_1
              && !dispatchItem?.data?.submissions[0]?.address_2
              && !dispatchItem?.data?.submissions[0]?.city
              && !dispatchItem?.data?.submissions[0]?.postal_code)
              ? 'Not set'
              : null}

          </>
          : 'Not set'}
        </>
        ?? null,
      actionIcon: null,
      action: 'address',
    }, {
      icon: <EmailIcon
        w={8}
        h={8}
        fill={'none'}/>,
      label: 'Email:',
      content: dispatchItem?.data?.email,
      actionIcon: null,
      action: null,
    }, {
      icon: <MobileIcon
        w={8}
        h={8}
        fill={'none'}/>,
      label: 'Mobile:',
      content: dispatchItem?.data?.phone,
      actionIcon: <PencilIcon
        fill={'none'}
        w={8}
        h={8}
      />, action: 'phone',
    },

    // TODO: not sure which data should be here

    // {
    //   icon: <PhoneIcon
    //     w={8}
    //     h={8}
    //     fill={'none'}/>,
    //   label: 'Landline:',
    //   content: dispatchItem?.data?.phone,
    //   actionIcon: <PencilIcon
    //     fill={'none'}
    //     w={8}
    //     h={8}
    //   />, action: 'phone',
    // },
    {
      icon: <CalendarIcon
        w={8}
        h={8}
        fill={'none'}/>,
      label: 'Last update:',
      content: new Date(dispatchItem?.data?.updated_at).toUTCString(),
      actionIcon: <PencilIcon
        fill={'none'}
        w={8}
        h={8}
      />, action: 'phone',
    },
  ]

  const cardStyle = {
    rounded: 'lg',
    bgColor: '#F9FBFC',
    // width: '466px',
    p: '24px',
    minHeight: 26,
    marginTop: 2,
    marginBottom: 1,
  }

  const viewButtonStyle = {
    bgColor: '#ffffff',
    border: '1px solid #6E7997',
    color: '#6E7997',
    fontSize: '16px',
    fontWeight: 500,
    w: '100%',
    minH: '55px',
  }

  var clientType = "individual"
  var circle3 = "/assets/img/circle-3.png"
  var circle4 = "/assets/img/circle-4.png"
  var circle5 = "/assets/img/circle-5.png"
  if (dispatchItem?.data.type === "corporate") {
    clientType = "corporate"
    circle3 = "/assets/img/circle-1.png"
    circle4 = "/assets/img/circle-2.png"
    circle5 = "/assets/img/circle-3.png"
  }

  return (<>
    {dispatchItem?.data ?
      dispatchIsDataLoading
        ? <Loading/>
        : <Box>
          <BackButton
            tabIndex={9}
            onClickHandler={() => {
              navigate(-1)
            }}
            ml={'-17px'}
          />

          {dispatchErrorMessage && (<AlertComponent
            message={dispatchErrorMessage}
          />)}

          <HStack>

            <Grid
              gridTemplateColumns={'108px 1fr 200px'}
              mt={10}
              width={'100%'}
            >
              <GridItem>
                <Text
                  as={Flex}
                  justifyContent={'center'}
                  alignItems={'center'}
                  borderRadius={'100%'}
                  bgColor={'#4285F4'}
                  width={'88px'}
                  height={'88px'}
                  color={'#ffffff'}
                  fontSize={'30px'}
                >
                  {dispatchItem?.data?.first_name && dispatchItem?.data?.first_name[0]}
                  {dispatchItem?.data?.last_name && dispatchItem?.data?.last_name[0]}
                </Text>
              </GridItem>

              <GridItem>
                <Heading
                  as={'h3'}
                  fontSize={'32px'}>
                  {dispatchItem.data.type === "corporate"?
                    <>{dispatchItem?.data?.name}</> 
                  :                    
                    <>{dispatchItem?.data?.first_name} {dispatchItem?.data?.last_name}</>}
                </Heading>

                <Text
                  fontSize={'18px'}
                  fontWeight={'400'}
                  color={'#6E7997'}
                  marginTop={4}
                >
                  ID {dispatchItem.data.id}
                </Text>
              </GridItem>

              <GridItem
                display={'flex'}
                alignItems={'center'}
              >
                {dispatchItem?.data?.riskForm?.submitted_at && isDateExpired(dispatchItem?.data?.riskForm?.submitted_at, 3) &&
                  <Text
                    as={'span'}
                    bgColor={'#F9FBFC'}
                    fontWeight={'400'}
                    fontSize={'18px'}
                    color={'#343B55'}
                    display={'flex'}
                    borderRadius={'3px'}
                    p={2}
                    textAlign={'center'}
                    justifyContent={'center'}
                  > Risk Assessment expired</Text>}
              </GridItem>
            </Grid>
          </HStack>

          <Grid
            gridTemplateColumns={'370px 1fr'}
          >
            <GridItem>
              <Heading
                fontSize={'24px'}
                lineHeight={'48px'}
                mt={'37px'}
                mb={1}
              > General Info </Heading>

              {editData.map(({
                               icon, label, content, actionIcon, action
                             }) => {

                return <Grid
                  {...cardStyle}
                  key={label}
                  templateColumns={'50px 1fr'}
                >
                  <GridItem as={Flex}
                            justifyContent={'center'}
                            alignItems={'center'}
                  >
                    <Flex
                      bgColor={'#fff'}
                      w={12}
                      h={12}
                      justifyContent={'center'}
                      alignItems={'center'}
                      borderRadius={4}
                    >
                      {icon}
                    </Flex>
                  </GridItem>
                  <GridItem pl={3}
                            as={Flex}
                            justifyContent={'center'}
                            alignItems={'flex-start'}
                            flexDirection={'column'}
                  >
                    <Text fontWeight={'700'} fontSize={'18px'}>
                      {label}
                    </Text>
                    <Box fontSize={'18px'}
                         overflowWrap={'anywhere'}
                    >
                      {content ?? 'Not set'}
                    </Box>
                  </GridItem>
                </Grid>
              })}
            </GridItem>

            <GridItem
              paddingLeft={'15px'}
            >

{/* CORPORATE START */}
{clientType === "corporate" ?

<>
                         <Heading
                fontSize={'24px'}
                lineHeight={'48px'}
                mt={'37px'}
                mb={1}
              > Shareholders and Directors </Heading>

    <TableContainer
            backgroundColor={'#F9FBFC'}
            color={'#1A1E29'}
            pl={'16px'}
            pt={'15px'}
            pr={'20px'}
          >
            <Table
              variant='simple'
            >
              <Thead>
                <Tr>
                  <Th
                    {...thStyles}
                  >Name</Th>
                  <Th
                    {...thStyles}
                  >Email</Th>
                  <Th
                    {...thStyles}
                    width={'70px'}
                    textAlign={'right'}
                  >Actions</Th>
                </Tr>
              </Thead>
              <Tbody>

        {dispatchItem?.data?.corporate_children?.map(({
                                                         id, email, first_name, last_name, name, submissions, id_pal_document, proof_of_residence
                                                       }) => {
                  id = id.toString()
                  return (<><Tr key={id}>
                    <Td
                      fontSize={'18px'}
                      minWidth={'95px'}
                    >{`${first_name} ${last_name}`}</Td>
                    <Td
                      fontSize={'18px'}
                      color={'#B4BAC9'}
                    >
                      {email}
                    </Td>
                    <Td cursor={'pointer'}
                        onClick={() => {
                      toggleClient(id);
                    }}>
                      <ExpandDownIcon
                        fill={'none'}
                        w={8}
                        h={8}
                      />

                    </Td>
                  </Tr>
                    {openedClients[id] ? <Tr>
                      <Td colSpan={3}>{submissions.length === 0 ? <Flex>
                        <SolicitorCardItem fullWidth title={'ID Document '}
                                           subtitle={'Not yet submitted'}
                                           isVerified={false}
                                           status={'0'}
                                           auditor={'1'}
                                           nestedComponent={<Button
                                               {...viewButtonStyle}
                                               disabled
                                               mt={2}
                                           >View Document</Button>}
                        />

                        <SolicitorCardItem fullWidth title={'Proof of Address'}
                                           subtitle={'Not yet submitted'}
                                           isVerified={false}
                                           status={'0'}
                                           auditor={'1'}
                                           nestedComponent={<Button
                                               {...viewButtonStyle}
                                               disabled
                                               mt={2}
                                           >View Document</Button>}
                        />
                      </Flex> : <>
                        <Flex w={'100%'}>
                        {Object.values(id_pal_document).length > 0 &&
                        <SolicitorCardItem fullWidth title={'ID Document '}
                        auditor={'1'}
                        data={id_pal_document}
                        submissionId={submissions[0]?.id}
                        />
                      }

                      {Object.values(proof_of_residence).length > 0 &&
                        <SolicitorCardItem fullWidth title={'Proof of Address '}
                        auditor={'1'}
                        data={proof_of_residence}
                        submissionId={submissions[0]?.id}
                        type={'pdf'}
                        />
                      }
                        </Flex>
                       </>
                      }</Td>
                    </Tr> : null}
                  </>)
                })}
        </Tbody>
            </Table>
          </TableContainer>
          </>
      : <></>}
{/* CORPORATE END */}


            <Grid templateColumns={'0.5fr 2fr'} gap={'6px'}>
            <GridItem></GridItem>
            <GridItem>
              <Heading
                fontSize={'24px'}
                lineHeight={'48px'}
                mt={'37px'}
                mb={1}
              > Path to Compliance </Heading>
            </GridItem>
            </Grid>

<Grid templateColumns={'0.5fr 2fr'} gap={'6px'}>

{/* ONLY SHOW ID-PAL IF INDIVIDUAL */}
{clientType === "individual" ?
<>

{/* ROW 1 */}
<GridItem paddingLeft={'50px'}>

    {
        dispatchItem.data?.submissions.length === 0 ?
            <img alt="circle 1" src="/assets/img/circle-1.png" style={{width: 56}}/>:

            dispatchItem.data?.id_pal_document.submission_status === "4" ?
                <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 56}}/>:
                <img alt="circle 1" src="/assets/img/circle-1.png" style={{width: 56}}/>
    }
</GridItem>
<GridItem>
                    {dispatchItem.data?.submissions.length === 0 && <>
                  <SolicitorCardItem title={'ID Document '}
                                     subtitle={'Not yet submitted'}
                                     isVerified={false}
                                     status={'0'}
                                     auditor={'1'}
                                     nestedComponent={<Button
                                       {...viewButtonStyle}
                                       disabled
                                       mt={2}
                                     >View Document</Button>}/></>
                }
                    {Object.values(dispatchItem.data?.id_pal_document).length > 0 &&
                  <SolicitorCardItem title={'ID Document '}
                                     auditor={'1'}
                                     data={dispatchItem.data?.id_pal_document}
                                     submissionId={dispatchItem.data.submissions[0]?.id}/>
                }
</GridItem>
{/* END OF ROW 1 */}


{/* ROW 2 */}
<GridItem paddingLeft={'50px'}>
    {
        dispatchItem.data?.submissions.length === 0 ?
            <img alt="circle 2" src="/assets/img/circle-2.png" style={{width: 56}}/>:

            dispatchItem.data?.id_pal_document.submission_status === "4" ?
                <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 56}}/>:
                <img alt="circle 2" src="/assets/img/circle-2.png" style={{width: 56}}/>
    }
</GridItem>
<GridItem>
                        {dispatchItem.data?.submissions.length === 0 && <>
                    <SolicitorCardItem title={'Proof of Address'}
                                     subtitle={'Not yet submitted'}
                                     isVerified={false}
                                     status={'0'}
                                     auditor={'1'}
                                     nestedComponent={<Button
                                       {...viewButtonStyle}
                                       disabled
                                       mt={2}
                                     >View Document</Button>}/></>}

                        {Object.values(dispatchItem.data?.proof_of_residence).length > 0 &&
                    <SolicitorCardItem title={'Proof of Address '}
                                     auditor={'1'}
                                     data={dispatchItem.data?.proof_of_residence}
                                     submissionId={dispatchItem.data.submissions[0]?.id}
                                     type={'pdf'}/>}

                        {Object.values(dispatchItem.data?.additional_documents).length > 0 &&
                    <SolicitorCardItem title={'Additional Documents'}
                                     auditor={'1'}
                                     data={dispatchItem.data?.additional_documents}
                                     submissionId={dispatchItem.data.submissions[0]?.id}
                                     subtitle={''}
                                     type={'pdf'}/>}

</GridItem>
</>
:''}
{/* END OF ROW 2 */}


{/* ROW 3 */}
<GridItem paddingLeft={'50px'}>
{
    !dispatchItem?.data?.form ?
        <img alt="circle 3" src={circle3} style={{width: 56}}/>:

        dispatchItem?.data?.form.status === "Processed" ?
            <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 56}}/>:
            <img alt="circle 3" src={circle3} style={{width: 56}}/>
}
</GridItem>
<GridItem>
    <ClientOpeningFormItem title={'Case Information'}/>
</GridItem>
{/* END OF ROW 3 */}


{/* ROW 4 */}
<GridItem paddingLeft={'50px'}>
    {
        dispatchItem.data?.docusigns[0]?.status === 'pending' ?
            <img alt="circle 4" src={circle4} style={{width: 56}}/>:

            dispatchItem.data?.docusigns[0]?.status === 'signed' ?
                <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 56}}/>:
                <img alt="circle 4" src={circle4} style={{width: 56}}/>
    }
</GridItem>
<GridItem>
<SolicitorCardItem title={'S150 Form '}
                                   auditor={'1'}
                                   subtitle={dispatchItem.data?.docusigns[0]?.status === 'pending'
                                     ? 'Not signed yet'
                                     : dispatchItem.data?.docusigns[0]?.status === 'signed'
                                       ? 'Signed'
                                       : 'Not yet submitted'
                                   }
                                   isAlert={false}
                                   status={dispatchItem.data?.docusigns[0]?.status === 'pending'
                                     ? 8
                                     : dispatchItem.data?.docusigns[0]?.status === 'signed'
                                       ? 10
                                       : 7
                                   }
                                   type={'pdf'}
                                   isVerified={false}
                                   nestedComponent={selectIsFormLoading
                                     ? <Loading/>
                                     : dispatchItem.data?.docusigns[dispatchItem.data?.docusigns.length - 1]
                                       ? <>
                                         <Alert
                                           isOpen={isS150FormOpen}
                                           onOpen={onS150FormOpen}
                                           onClose={onS150FormClose}
                                           header='S150 Form'
                                           text={<>
                                             {dispatchErrorMessage && (<AlertComponent
                                               message={dispatchErrorMessage}
                                             />)}

                                             {selectIsS150FormLoading
                                               ? <Loading/>
                                               : <ViewAPdfFile data={selectGetS150FormContent?.data} width={1028}/>
                                             }
                                           </>
                                           }
                                           action={null}
                                           size={'6xl'}
                                           contentPaddingY={0}
                                         />
                                         <Button
                                           {...viewButtonStyle}
                                           marginTop={5}
                                           onClick={() => {
                                             dispatchSetS150FormLoading();
                                             dispatchGetS150FormData()
                                             onS150FormOpen();

                                           }}
                                         >View Document</Button></>
                                       :
                                       <></>
                                   }
                />

</GridItem>
{/* END OF ROW 4 */}

{/* ROW 5 */}
<GridItem paddingLeft={'50px'}>
{
    (dispatchItem?.data?.riskForm?.form_status === 'submitted' &&
        !isDateExpired(dispatchItem?.data?.riskForm?.submitted_at, 3)) ?

     <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 56}}/>:
     <img alt="circle 5" src={circle5} style={{width: 56}}/>
 }
</GridItem>
<GridItem>
                <SolicitorCardItem title={'Risk Assessment'}
                                   auditor={'1'}
                                   subtitle={(dispatchItem?.data?.riskForm?.submitted_at
                                       && new Date(dispatchItem?.data?.riskForm.submitted_at).toUTCString())
                                     || 'Not submitted'}

                                   status={(dispatchItem?.data?.riskForm?.submitted_at
                                     && !isDateExpired(dispatchItem?.data?.riskForm?.submitted_at, 3))
                                     ? 6
                                     : (dispatchItem?.data?.riskForm?.submitted_at && isDateExpired(dispatchItem?.data?.riskForm?.submitted_at, 3))
                                       ? 9
                                       : dispatchItem?.data?.riskForm
                                         ? 8
                                         : 7

                                   }
                                   isAlert={dispatchItem?.data?.riskForm?.submitted_at || false}
                                   type={'pdf'}
                                   isVerified={false}
                                   nestedComponent={(dispatchItem?.data?.riskForm?.form_status === 'submitted' && !isDateExpired(dispatchItem?.data?.riskForm?.submitted_at, 3))
                                     ? <><Alert
                                       isOpen={isOpen}
                                       onOpen={onOpen}
                                       onClose={onClose}
                                       header='Risk Assessment'
                                       text={<>
                                         <HStack as={Flex} spacing={8}
                                                 mb={6}
                                                 mt={4}
                                                 justifyContent={'space-between'}
                                         >
                                           <Box>
                                             <b>Risk status:</b> {dispatchItem?.data?.riskForm?.risk_status}
                                           </Box>
                                           <Box><b>Submitted at:</b> {dispatchItem?.data?.riskForm?.submitted_at
                                             && new Date(dispatchItem?.data?.riskForm?.submitted_at).toUTCString()}</Box>
                                           <Button rightIcon={<UploadIconSecondary
                                             width={'24px'}
                                             height={'24px'}
                                             fill={'none'}
                                           />}
                                                   bgColor={'#4285F4'}
                                                   color={'#fff'}
                                                   _hover={{bg: '#6d9ef5'}}
                                           ><PDFDownloadLink
                                             document={<MyDocument data={
                                               dispatchItem?.data?.riskForm
                                             }/>}
                                             fileName={'risk_assessment.pdf'}>
                                             {
                                               ({blob, url, loading, error}) =>
                                                 loading
                                                   ? 'Loading document...'
                                                   : 'Export PDF'
                                             }
                                           </PDFDownloadLink></Button>


                                         </HStack>
                                         <Heading size={'md'}
                                                  mb={2}
                                         >Obligatory Questions:</Heading>

                                         <TableContainer
                                         >
                                           <Table
                                             width={'100%'}
                                           >
                                             <Tbody>
                                               {
                                                 Object.entries(dispatchItem?.data?.riskForm?.obligatory_questions).map(
                                                   ([key, value], i) => (
                                                     value
                                                     && <Tr key={key}>
                                                       <Td width={'450px'}
                                                           textTransform={'capitalize'}
                                                           whiteSpace={'pre-wrap'}
                                                           overflowWrap={'anywhere'}
                                                           pl={0}
                                                           verticalAlign={'top'}
                                                       >{questionsFieldKeys[i]}</Td>
                                                       <Td textTransform={'capitalize'}
                                                           whiteSpace={'pre-wrap'}
                                                           verticalAlign={'top'}
                                                           overflowWrap={'anywhere'}>{Array.isArray(value) && value.length
                                                         ? value.map((el, index) => (
                                                           <Text mb={4}
                                                                 key={Object.values(el)}
                                                           >{`${index + 1}: ${Object.values(el)} `}
                                                           </Text>))
                                                         : value}</Td>
                                                     </Tr>
                                                   )
                                                 )
                                               }

                                             </Tbody>
                                           </Table>
                                         </TableContainer>


                                         <TableContainer
                                         >
                                           <Table
                                             width={'100%'}
                                           >
                                             <Tbody>
                                               {
                                                 Object.entries(dispatchItem?.data?.riskForm?.other_questions).map(
                                                   ([key, value], i) => (
                                                     value
                                                     && <Tr key={key + i}>
                                                       <Td width={'450px'}
                                                           textTransform={'capitalize'}
                                                           whiteSpace={'pre-wrap'}
                                                           overflowWrap={'anywhere'}
                                                           pl={0}
                                                           verticalAlign={'top'}
                                                       >{questionsFieldKeys[i + 2]} </Td>
                                                       <Td textTransform={'capitalize'}
                                                           whiteSpace={'pre-wrap'}
                                                           verticalAlign={'top'}
                                                           overflowWrap={'anywhere'}>{Array.isArray(value) && value.length
                                                         ? value.map((el, index) => (
                                                           <Text mb={4}
                                                                 key={Object.values(el)}
                                                           >{`${index + 1}: ${Object.values(el)} `}
                                                           </Text>))
                                                         : value}</Td>
                                                     </Tr>
                                                   )
                                                 )
                                               }

                                             </Tbody>
                                           </Table>
                                         </TableContainer>

                                       </>}
                                       action={null}
                                       size={'6xl'}
                                       contentPaddingY={0}
                                     /><Box marginTop={'22px'}>
                                       <Button
                                         {...viewButtonStyle}
                                         onClick={() => {
                                           onOpen();
                                         }}
                                       >View Document</Button>
                                     </Box></>
                                     : <></>}
                />
</GridItem>
{/* END OF ROW 5 */}

</Grid>

            </GridItem>
          </Grid>
        </Box>
      :
      <> {dispatchErrorMessage
        ? (<AlertComponent
          message={dispatchErrorMessage}
        />)
        : <Loading/>}</>
    }
  </>)
    ;
};
export default AuditorClient;
