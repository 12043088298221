import React from 'react';
import {Box, Flex, Grid, GridItem, Heading, Text} from "@chakra-ui/react";

const StatsCarditem = ({
                         icon,
                         title = '',
                         subtitle = '',
                         digits = 0,
                       }) => {
  return (
    <Flex borderRadius={'8px'}
          bgColor={'#F9FBFC'}
          padding={'24px'}
    >
      <Grid templateColumns={'50px 1fr auto'}
            gap={'20px'}
            width={'100%'}
      >
        <GridItem>
          <Box bgColor={'#FFFFFF'}
               padding={'8px'}
               borderRadius={'8px'}
          >{icon}</Box>
        </GridItem>
        <GridItem>
          <Heading color={'#223268'}
                   fontWeight={700}
                   fontSize={'24px'}
          >
            {title}
          </Heading>
          <Text color={'#6E7997'}
                size={'14px'}
          >
            {subtitle}
          </Text>
        </GridItem>
        <GridItem
          textAlign={'right'}
          fontSize={'32px'}
          fontWeight={600}
        >
          {digits}
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default StatsCarditem;