import React, {useEffect, useState} from 'react';
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Button,
  HStack,
  Flex,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Link
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Alert from "../../components/Alert";
import {
  setLoadingFlag, getAccountsList, setNewAccount, setAccountErrorMessage, deleteAccount
} from "../../redux/actions/accounts";
import {Link as ReachLink, Outlet, useParams} from "react-router-dom";
import {DoneIcon, TrashBinIcon} from "../../assets/icons/sprite";
import {useFormik} from "formik";
import AlertComponent from "../../components/AlertComponent";

const SolicitorAccounts = () => {

  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('Add New User');

  const dispatchErrorMessage = useSelector(state => state.accounts.errorMessage)

  const dispatch = useDispatch();
  const dispatchSetAccountErrorMessage = (message) => dispatch(setAccountErrorMessage(message))
  const dispatchSetNewAccount = (data) => dispatch(setNewAccount(data))

  const handleSubmit = async values => {
    // validation
    if (values.name.replaceAll(' ', '').length === 0) {
      dispatchSetAccountErrorMessage({
        result: 'error', message: 'The name must be not empty.'
      })
      return
    }
    if (!values.name) {
      dispatchSetAccountErrorMessage({
        result: 'error', message: 'Please fill out name field.'
      })
      return
    }

    // Go to step 2

    dispatchSetNewAccount({
      "name": values.name,
      "email": values.email,
      "phone": values.number
    }).then(() => {
      setTitle('Invite has been sent');
      setStep(2);
      dispatch(setLoadingFlag());
      dispatch(getAccountsList());
    })
  }

  const formik = useFormik({
    initialValues: {
      name: '', number: '', email: ''
    }, onSubmit: handleSubmit
  })

  const {id: hideListing} = useParams();
  const PAGE_PATH = 'accounts?page=';

  const thStyles = {
    color: '#1A1E29', fontSize: '14px', textTransform: 'none', opacity: '0.5', fontWeight: 400, border: 'none',
  }

  useEffect(() => {
    if (!hideListing) {
      dispatch(setLoadingFlag());
      dispatch(getAccountsList());
    }
  }, [
    dispatch,
    hideListing
  ])

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {isOpen: isDelOpen, onOpen: onDelOpen, onClose: onDelClose} = useDisclosure();

  const [accountId, setAccountId] = useState(null)

  const dispatchAccountList = useSelector(state => state.accounts);
  const dispatchListPagination = useSelector(state => state.accounts?.data?.meta?.pagination);
  const dispatchIsDataLoading = useSelector(state => state.accounts?.isDataLoading);
  const dispatchList = (credentials) => dispatch(getAccountsList(credentials))
  const dispatchLoadingFlag = (credentials) => dispatch(setLoadingFlag(credentials))
  const dispatchAccountsDel = (id) => dispatch(deleteAccount(id))

  const resetDialog = () => {
    if (step === 2) {
      formik.values.name = '';
      formik.values.email = '';
      formik.values.number = '';
    }
    setStep(1);
    setTitle('Add New User');
    dispatchSetAccountErrorMessage(null);
    onClose();
  }

  const onDeleteStatus = (id = null) => {
    setAccountId(id);
    onDelOpen();
  }

  return (<>
    <Outlet/>

      <Alert
        isOpen={isDelOpen}
        onOpen={onDelOpen}
        onClose={onDelClose}
        header={`Delete User`}
        text={`Are you sure you want to delete this Accounts User?`}
        action={() => {
          dispatch(setLoadingFlag());
          dispatchAccountsDel(accountId)
            .then((res) => {
              dispatchList();
            });
        }}
      />

    <Alert
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={resetDialog}
      header={title}
      text={step === 1 ? <form
        onSubmit={formik.handleSubmit}
      >
        <Stack spacing="5">
          {dispatchErrorMessage && (<AlertComponent
            message={dispatchErrorMessage}
          />)}

          <FormControl>
            <FormLabel
              htmlFor="Name"
              fontSize={'18px'}>
              Name
            </FormLabel>
            <Input
              tabIndex={1}
              id="name"
              type="text"
              required
              placeholder={'Enter name'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="number"
              fontSize={'18px'}>
              Mobile
            </FormLabel>
            <Input
              tabIndex={2}
              id="number"
              type="text"
              required
              placeholder={'Enter number'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.number}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="email" fontSize={'18px'}>Email</FormLabel>
            <Input
              tabIndex={3}
              id="email"
              type="email"
              required
              placeholder={'Enter email'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </FormControl>


        </Stack>

        <Stack spacing="6" text-align={'right'} py={'20px'}>
          <Button
            tabIndex={4}
            type="submit"
            fontSize={'18px'}
            variant="primary">Add New User</Button>
        </Stack>
      </form> : step === 2 ? <VStack
        marginBottom={10}
      >
        <DoneIcon
          width={93}
          height={93}
          marginTop={6}
          marginBottom={4}/>

        <Text
          paddingX={'100px'}
          pb={8}
          align={'center'}>This account will be sent an email with a link to setup their password, before
          being granted access to the system.</Text>

        <Button
          tabIndex={7}
          type="submit"
          fontSize={'18px'}
          variant="primary"
          minWidth={184}
          minHeight={'48px'}
          height={'48px'}
          onClick={resetDialog}>Ok</Button>
      </VStack> : null}
      action={null}
      size={'3xl'}
      contentPaddingY={0}
    />

    {!hideListing ? <Box>

      <HStack as={Flex}>
        <Heading
          fontSize={'32px'}
          lineHeight={'48px'}
          mb={'32px'}
          flexGrow={1}
        >Accounts Users</Heading>
        <Button
          tabIndex={6}
          type="button"
          paddingX={9}
          alignSelf={'baseline'}
          fontSize={'18px'}
          height={'48px'}
          minHeight={'48px'}
          onClick={onOpen}
          variant="primary">Add New User</Button>
      </HStack>
      {dispatchIsDataLoading ? <Loading/> : dispatchAccountList.data ? <> <TableContainer
        backgroundColor={'#F9FBFC'}
        color={'#1A1E29'}
        pl={'16px'}
        pt={'12px'}
        pr={'20px'}
      >
        <Table
          variant='simple'
        >
          <Thead>
            <Tr>
              <Th
                {...thStyles}
              >Name</Th>
              <Th
                {...thStyles}
              >Email</Th><
              Th
                {...thStyles}
              >Phone</Th>
              <Th
                {...thStyles}
                textAlign={'right'}
              >Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dispatchAccountList?.data?.data?.map(({id, name, email, phone}) => {
              return (<Tr key={id}>
                <Td
                  fontSize={'18px'}
                  minWidth={'330px'}
                ><Text
                       as={ReachLink}
                       color={'#1A1E29'}
                >{name}</Text></Td>
                <Td
                  fontSize={'18px'}
                  color={'#B4BAC9'}
                ><Text
                       as={ReachLink}
                       color={'#B4BAC9'}
                >
                  {email}
                </Text></Td>
                <Td
                  fontSize={'18px'}
                  color={'#B4BAC9'}
                ><Text
                       as={ReachLink}
                       color={'#B4BAC9'}
                >
                  {phone}
                </Text></Td>
                <Td
                  textAlign={'right'}>
                  <Text as={ReachLink}
                        textAlign={"right"}>

                      <Link
                        to={id}
                        onClick={() => onDeleteStatus(id)}
                      >
                        <TrashBinIcon
                          fill={'none'}
                          w={8}
                          h={8}
                          marginBottom={'-10px'}
                          stroke={'#223268'}
                        />

                      </Link>

                    </Text>
                </Td>
              </Tr>)
            })}
          </Tbody>
        </Table>
      </TableContainer>

        <Pagination
          dispatchList={dispatchList}
          dispatchListPagination={dispatchListPagination}
          dispatchLoadingFlag={dispatchLoadingFlag}
          pagePath={PAGE_PATH}
          name={'Accounts'}
        />
      </> : <Loading/>}
    </Box> : null}

  </>);
}

export default SolicitorAccounts;
