import {extendTheme} from '@chakra-ui/react'

const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,
  fonts: {
    heading: `'Montserrat', Arial, sans-serif`, body: `'Montserrat', Arial, sans-serif`
  },
  styles: {
    global: {
      // body: {bg: 'blue'},
      'html, body': {
        color: '#343B55',
      }, placeholder: {
        color: '#B4BAC9',
      }, input: {
        minHeight: '56px', fontSize: '18px'
      }
    },
  },
  colors: {
    primary: {
      500: "#3A78F2"
    }
  },
  components: {
    Button: {
      variants: {
        primary: {
          background: '#3A78F2',
          color: '#ffffff',
          lineHeight: '22px',
          minHeight: '56px',
          _hover: {
            bg: '#1052d3',
            _disabled: {bg: '#000000'}
          },
        },

        secondary: {
          background: '#eee',
          color: '#444',
          lineHeight: '22px',
          minHeight: '56px',
          _hover: {
            bg: '#ccc',
            _disabled: {bg: '#000000'}
          },
        },        
      },

    }, BaseStyle: {
      PinInputField: {
        borderRadius: 0, borderColor: '#333333'
      },
    }
  },
})


export default theme