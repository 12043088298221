import {
  FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement, useDisclosure, useMergeRefs
} from "@chakra-ui/react";

import * as React from "react";
import {HiEye, HiEyeOff} from 'react-icons/hi'


export const PasswordField = React.forwardRef((
  {
    label = 'Password',
    name = 'password',
    iconButtonTabIndex = 0,
    ...props
  }
  , ref) => {
  const {isOpen, onToggle} = useDisclosure();
  const inputRef = React.useRef(null);
  const mergeRef = useMergeRefs(inputRef, ref);

  const onClickReveal = () => {
    onToggle()

    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true,
      })
    }
  }
  return (<FormControl>
    <FormLabel htmlFor={'Password'} fontSize={'18px'}>{label}</FormLabel>
    <InputGroup>

      <InputRightElement
        minHeight={'56px'}>
        <IconButton
          tabIndex={iconButtonTabIndex}
          variant={'link'}
          aria-label={isOpen ? 'Mask password' : 'Reveal password'}
          icon={isOpen ? <HiEyeOff/> : <HiEye/>}
          onClick={onClickReveal}
        />
      </InputRightElement>

      <Input
        id={name}
        ref={mergeRef}
        name={name}
        type={isOpen ? ' text' : 'password'}
        autoComplete={'current-password'}
        required
        {...props}
      />

    </InputGroup>
  </FormControl>)

})

PasswordField.displayName = 'PasswordField'
