import React, {useEffect, useRef, useState} from 'react';
import {
  Box, Button, Flex, FormControl, FormLabel, Grid, GridItem, Heading, HStack, Input, Text, useDisclosure, VStack
} from "@chakra-ui/react";
import BackButton from "../../components/BackButton";
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {
  getCompanyDetails,
  getCompanyDetailsByKeyword, getCompanyDirectors,
  setCompanyDetailsLoading,
  setCompanyDocumentsLoading, setEmptyResult, setInviteCorporate
} from "../../redux/actions/solicitors";
import AlertComponent from "../../components/AlertComponent";
import CorpClientFetchCard from "../../components/CorpClientFetchCard";
import {DoneIcon} from "../../assets/icons/sprite";
import Alert from "../../components/Alert";
import Loading from "../../components/Loading";

const StepsHeader = ({
                       step,
                       setStep,
                       formik,
                       setCompanyNum
                     }) => {
  return (
    <Flex paddingBottom={10}
          justifyContent={'center'}
          alignItems={'center'}
          gap={10}
    >
      <Heading
        flexGrow={1}
        fontSize={'32px'}
        lineHeight={'48px'}
      >
        New Corporate Case
      </Heading>
    </Flex>
  )
}

const SolicitorInviteCorporate = () => {

  const {isOpen, onOpen, onClose} = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputEl = useRef(null);
  const inviteForm = useRef();

  const dispatchGetCompanyDetailsByKeyword = (keyword) => dispatch(getCompanyDetailsByKeyword(keyword))
  const dispatchSetEmptyResult = () => dispatch(setEmptyResult())
  const dispatchSetCompanyDocumentsLoading = (where) => dispatch(setCompanyDocumentsLoading(where))
  const dispatchSetInviteCorporate = (data) => dispatch(setInviteCorporate(data))
  const dispatchGetCompanyDetails = (id, where) => dispatch(getCompanyDetails(id, where))
  const dispatchGetCompanyDirectors = async (id) => await dispatch(getCompanyDirectors(id))
  const dispatchSetCompanyDetailsLoading = (where) => dispatch(setCompanyDetailsLoading(where))

  const selectCompanyDetailsByKeyword = useSelector((state) => state.solicitors?.companyDetailsByKeyword)
  const selectErrorMessage = useSelector((state) => state.solicitors?.selectErrorMessage)

  const selectIsCompanyReportLoading = useSelector(state => state.solicitors.isCompanyReportLoading)
  const selectIsScheduleOfBeneficialInterestsLoading = useSelector(state => state.solicitors.isScheduleOfBeneficialInterestsLoading)
  const selectIsCertOfIncorporationLoading = useSelector(state => state.solicitors.isCertOfIncorporationLoading)
  const selectIsConstitutionLoading = useSelector(state => state.solicitors.isConstitutionLoading)

  const selectCompanyReport = useSelector(state => state.solicitors.getCompanyReport)
  const selectScheduleOfBeneficialInterests = useSelector(state => state.solicitors.getScheduleOfBeneficialInterests)
  const selectCertOfIncorporation = useSelector(state => state.solicitors.getCertOfIncorporation)
  const selectConstitution = useSelector(state => state.solicitors.getConstitution)

  const selectIsCompanyDetailsLoading = useSelector(state => state.solicitors.isCompanyDetailsLoading)

  // const selectCompanyDirectors = useSelector(state => state.solicitors.companyDirectors)

  const [step, setStep] = useState(1);

  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [companyNum, setCompanyNum] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  let solicitorId = useParams();

  useEffect(() => {
    dispatch(setEmptyResult())
  }, [dispatch])

  const handleSubmit = async (event, values) => {
    event.preventDefault()
    switch (step) {
      case 1:
        dispatchGetCompanyDetailsByKeyword(values.companyName)
        break;

      case 2:
        setData([])
        setStep(3);
        dispatchGetCompanyDirectors(companyNum).then((r) => {
          setData(r.payload.directors)
        })
        break;

      case 3:
        dispatchSetCompanyDetailsLoading('', false);
        onOpen()
        const formData = new FormData(inviteForm.current);
        const sendData = new FormData();

        sendData.append("company_name", companyName);
        sendData.append("company_number", companyNum);

        for (const pair of formData.entries()) {
          if (pair[0] === `first_name`) {
            sendData.append("first_name[]", pair[1]);
          }
          if (pair[0] === `last_name`) {
            sendData.append("last_name[]", pair[1]);
          }
          if (pair[0] === `email`) {
            sendData.append("email[]", pair[1]);
          }
        }
        sendData.append("main_contact[]", 1);

        if (typeof solicitorId.solicitor_id !== "undefined") {
          sendData.append("solicitor_id", solicitorId.solicitor_id);
        }

        // tmp the content of form echo
        for (const pair of sendData.entries()) {
          console.log(pair[0] + ', ' + pair[1]);
        }

        dispatchSetInviteCorporate(sendData)
          .then((r) => console.log(r))
          .catch((err) => console.warn(err));
        break;

      case 4:
        setCompanyName(values.companyName)
        setStep(3)
        break;

      default:
        break;
    }
  }

  const resetDialog = () => {
    onClose()
    navigate(-1)
  }

  const formik = useFormik({
    initialValues: {
      companyName: '',
      companyNamePrev: '',
    }, onSubmit: handleSubmit
  })


  return ((
      (step === 1 && <>

        <Box>
          <BackButton
            tabIndex={9}
            onClickHandler={() => {
              navigate(-1)
            }}
            ml={'-17px'}
          />
        </Box>
        {selectErrorMessage && (<AlertComponent
          message={selectErrorMessage}
        />)}

        <StepsHeader step={step} setStep={setStep} formik={formik} setCompanyNum={setCompanyNum}/>

        <Box bgColor={'#F9FBFC'}
             p={'40px'}
             borderRadius={'8px'}
        >

          <form onSubmit={(e) => handleSubmit(e, formik.values)}>

            <HStack>
              <FormControl>
                <FormLabel htmlFor={'companyName'}>
                  Company Search with Vision-net
                </FormLabel>

                <Input placeholder={'Coca-cola'}
                       id={'companyName'}
                       type={'text'}
                       name={'companyName'}
                       onChange={formik.handleChange}
                       value={formik.values.companyName}
                       ref={inputEl}
                />
              </FormControl>

              <Box
                marginTop={'10px'}
              >
                <Button
                  type="submit"
                  fontSize={'18px'}
                  variant="primary"
                  minWidth={184}
                  disabled={!formik.values.companyName}
                  marginTop={'32px'}
                  minH={'56px'}
                  height={'56px'}
                >Start search</Button>
              </Box>
            </HStack>
          </form>
        </Box>

        {selectCompanyDetailsByKeyword?.result === 'success' && selectCompanyDetailsByKeyword.companies.length > 0 &&
          <Box bgColor={'#F9FBFC'}
               p={'40px'}
               borderRadius={'8px'}
          >
            <Box color={'#6E7997'}
                 fontSize={'14px'}
            >
              Company Name
            </Box>


            {selectCompanyDetailsByKeyword.companies.map(({company_name: companyName, company_num: id}) => (<Grid
              templateColumns={'1fr auto'}
              borderBottom={'1px solid #B4BAC9'}
              alignItems={'center'}
              paddingBottom={'15px'}
              marginBottom={'15px'}
              key={id}
              gap={'30px'}
            >
              <GridItem color={'#343B55'}
                        fontSize={'18px'}
                        textTransform={'capitalize'}
              >
                {companyName.toLowerCase()}
              </GridItem>
              <GridItem>
                <Button
                  type="button"
                  onClick={(evt) => {
                    setCompanyName(companyName);
                    setCompanyNum(id);
                    setStep(2);
                  }}
                  fontSize={'18px'}
                  variant="primary"
                  minWidth={184}
                  minHeight={'48px'}
                  height={'48px'}
                >Select</Button>
              </GridItem>
            </Grid>))}
          </Box>}

        {selectCompanyDetailsByKeyword?.result === 'success' && selectCompanyDetailsByKeyword.companies.length === 0 &&

          <Text paddingLeft={10} paddingTop={3}>
            Not found.
          </Text>
        }

      </>)
      ||
      (step === 2 && <>
        <Box>
          <BackButton
            onClickHandler={() => {
              dispatchSetEmptyResult()
              setStep((prevState) => prevState - 1)
            }}
            ml={'-17px'}
          />
        </Box>
        {selectErrorMessage && (<AlertComponent
          message={selectErrorMessage}
        />)}

        <HStack>
          <Grid
            gridTemplateColumns={'108px 1fr 200px'}
            mt={10}
            width={'100%'}
          >
            <GridItem>
              <Text
                as={Flex}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'100%'}
                bgColor={'#4285F4'}
                width={'88px'}
                height={'88px'}
                color={'#ffffff'}
                fontSize={'30px'}
              >
                {companyName && companyName[0]}
              </Text>
            </GridItem>

            <GridItem>
              <Heading
                as={'h3'}
                fontSize={'32px'}>
                {companyName}
              </Heading>

              <Text
                fontSize={'18px'}
                fontWeight={'400'}
                color={'#6E7997'}
                marginTop={4}
              >
                ID {companyNum}
              </Text>
            </GridItem>

          </Grid>
        </HStack>
        <Grid templateColumns={'1fr 1fr 1fr 1fr'}
              gap={30}
              paddingTop={'88px'}
        >
          <CorpClientFetchCard title={'Company report'}
                               fetchFunction={() => {
                                 dispatchGetCompanyDetails(companyNum, 'company_report')
                               }}
                               setLoadingFlagFunction={() => {
                                 dispatchSetCompanyDocumentsLoading('company_report');
                               }}
                               isLoading={selectIsCompanyReportLoading}
                               data={selectCompanyReport}

          />

          <CorpClientFetchCard title={'UBO report'}
                               fetchFunction={() => {
                                 dispatchGetCompanyDetails(companyNum, 'ubo_report');
                               }}
                               setLoadingFlagFunction={() => {
                                 dispatchSetCompanyDocumentsLoading('ubo_report');
                               }}
                               isLoading={selectIsScheduleOfBeneficialInterestsLoading}
                               data={selectScheduleOfBeneficialInterests}
          />

          <CorpClientFetchCard title={'Copy of cert of incorporation'}
                               fetchFunction={() => {
                                 dispatchGetCompanyDetails(companyNum, 'cert_of_incorporation');
                               }}
                               setLoadingFlagFunction={() => {
                                 dispatchSetCompanyDocumentsLoading('cert_of_incorporation');
                               }}
                               isLoading={selectIsCertOfIncorporationLoading}
                               data={selectCertOfIncorporation}
          />

          <CorpClientFetchCard title={'Company constitution'}
                               fetchFunction={() => {
                                 dispatchGetCompanyDetails(companyNum, 'constitution');
                               }}
                               setLoadingFlagFunction={() => {
                                 dispatchSetCompanyDocumentsLoading('constitution');
                               }}
                               isLoading={selectIsConstitutionLoading}
                               data={selectConstitution}

          />

          <GridItem gridColumn={'1 / 5'}
                    textAlign={'center'}
                    marginTop={10}
          >
            { (selectIsCompanyReportLoading || selectIsScheduleOfBeneficialInterestsLoading || selectIsCertOfIncorporationLoading || selectIsConstitutionLoading)
            &&
            <Text fontSize={'18px'}
                  fontWeight={500}
            >Please wait while we fetch the company documents from VisionNet.</Text>
            }

            {(!selectIsCompanyReportLoading && !selectIsScheduleOfBeneficialInterestsLoading && !selectIsCertOfIncorporationLoading && !selectIsConstitutionLoading) &&
            <Button
              type="button"
              fontSize={'18px'}
              variant="primary"
              minWidth={184}
              minHeight={'48px'}
              marginTop={'0px'}
              minH={'48px'}
              height={'48px'}
              onClick={async (e) => await handleSubmit(e)}
            >Continue</Button>
            }

          </GridItem>
        </Grid>
      </>)
      ||
      (step === 3 && <>
        <Alert
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={resetDialog}
          text={
            selectIsCompanyDetailsLoading ? <Loading/> : <VStack
              marginBottom={10}
            >
              <DoneIcon
                width={93}
                height={93}
                marginTop={6}
                marginBottom={4}/>

              <Text
                // paddingX={'100px'}
                pb={8}
                fontSize={'32px'}
                align={'center'}
                fontWeight={700}>Invitation Sent Successfully.</Text>

              <Button
                type="submit"
                fontSize={'18px'}
                variant="primary"
                minWidth={184}
                minHeight={'48px'}
                height={'48px'}
                onClick={resetDialog}>Ok</Button>
            </VStack>
          }
          action={null}
          size={'3xl'}
          contentPaddingY={0}
        />

        <Box>
          <BackButton
            onClickHandler={() => {
              companyNum ?  setStep((prevState) => prevState - 1) : setStep(4)
            }}
            ml={'-17px'}
          />
        </Box>
        {selectErrorMessage && (<AlertComponent
          message={selectErrorMessage}
        />)}

        <HStack>
          <Grid
            gridTemplateColumns={'108px 1fr 200px'}
            mt={10}
            width={'100%'}
          >
            <GridItem>
              <Text
                as={Flex}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'100%'}
                bgColor={'#4285F4'}
                width={'88px'}
                height={'88px'}
                color={'#ffffff'}
                fontSize={'30px'}
              >
                {companyName && companyName[0]}
              </Text>
            </GridItem>

            <GridItem>
              <Heading
                as={'h3'}
                fontSize={'32px'}>
                {companyName}
              </Heading>

              {companyNum && <Text
                fontSize={'18px'}
                fontWeight={'400'}
                color={'#6E7997'}
                marginTop={4}
              >
                ID {companyNum}
              </Text>}
            </GridItem>

          </Grid>
        </HStack>

    
      <Heading
        flexGrow={1}
        fontSize={'24px'}
        lineHeight={'48px'}
        paddingTop={'5'}
      >
        Main Contact
      </Heading>

        <Text fontSize={'18'}
        paddingBottom={'5'}
                                          textAlign={'left'}
                                          mb={8}
                                    >
                                        Who is the main contact person for this company?
                                    </Text>

        <form id='inviteForm' ref={inviteForm} onSubmit={(e) => handleSubmit(e)}>
          <input type={'hidden'} value={'lorem'}/>
          <Grid templateColumns={'1fr 1fr 2fr'}
                bgColor={'#F9FBFC'}
                padding={'20px'}
                borderRadius={'8px'}
                gap={'20px'}
          >
            <GridItem fontSize={'14px'} color={'#6E7997'} pl={4}>First Name</GridItem>
            <GridItem fontSize={'14px'} color={'#6E7997'} pl={4}>Last Name</GridItem>
            <GridItem fontSize={'14px'} color={'#6E7997'} pl={4}>Email</GridItem>
              <GridItem><Input name={'first_name'} required/></GridItem>
              <GridItem><Input name={'last_name'} required/></GridItem>
              <GridItem><Input name={'email'} type={'email'} required/></GridItem>
          </Grid>

          <Box paddingTop={10} textAlign={'left'}>
            <Button
              // onClick={onOpen}
              type={'submit'}
              color={'#ffffff'}
              fontSize={'18px'}
              variant="primary"
              minWidth={'192px'}
            >Invite</Button>
          </Box>
        </form>
      </>))
    ||
    (step === 4 && <>
      <Box>
        <BackButton
          tabIndex={9}
          onClickHandler={() => {
            navigate('/solicitor/clients/')
          }}
          ml={'-17px'}
        />
      </Box>
      {selectErrorMessage && (<AlertComponent
        message={selectErrorMessage}
      />)}

      <StepsHeader step={step} setStep={setStep} formik={formik} setCompanyNum={setCompanyNum}/>

      <Box bgColor={'#F9FBFC'}
           p={'40px'}
           borderRadius={'8px'}
      >

        <form onSubmit={(e) => handleSubmit(e, formik.values)}>

          <HStack>
            <FormControl>
              <FormLabel htmlFor={'companyName'}>
                Company name
              </FormLabel>

              <Input placeholder={'Enter company name'}
                     id={'companyName'}
                     type={'text'}
                     name={'companyName'}
                     onChange={formik.handleChange}
                     value={formik.values.companyName}
                     ref={inputEl}
              />
            </FormControl>

            <Box
              marginTop={'10px'}
            >
              <Button
                type="submit"
                fontSize={'18px'}
                variant="primary"
                minWidth={184}
                disabled={!formik.values.companyName}
                marginTop={'32px'}
                minH={'56px'}
                height={'56px'}
              >Continue</Button>
            </Box>
          </HStack>
        </form>
      </Box>

    </>)
  )
};
export default SolicitorInviteCorporate;
