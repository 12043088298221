import {
  CLIENTS_GET_BY_ID_SUCCESS,
  CLIENTS_GET_FORBIDDEN,
  CLIENTS_GET_SUBMISSION_DATA_SUCCESS,
  CLIENTS_LIST_DOWNLOADED,
  CLIENTS_LIST_NOT_DOWNLOADED,
  CLIENTS_SET_ERR_MESSAGE,
  SET_DATA_LOADING_FALSE,
  SET_DATA_LOADING_TRUE,
  SET_NEW_CLIENTS_ERR_MESSAGE,
  SET_NEW_CLIENTS_SUCCESS,
  SET_SUBMISSION_DATA_LOADING_FALSE,
  SET_SUBMISSION_DATA_LOADING_TRUE,
  GET_CLIENT_BY_SECRET_FAILED,
  GET_CLIENT_BY_SECRET_SUCCESS,
  SET_CLIENT_BY_SECRET_SUCCESS,
  SET_CLIENT_BY_SECRET_FAILED,
  SET_NEW_RISK_FORM_SUCCESS,
  SET_NEW_RISK_FORM_FAILED,
  SET_UPDATE_RISK_FORM_SUCCESS,
  SET_UPDATE_RISK_FORM_FAILED,
  GET_RISK_FORM_SUCCESS,
  GET_RISK_FORM_FAILED,
  SET_CLIENT_IDPAL_OVERRIDE_SUCCESS,
  SET_CLIENT_IDPAL_OVERRIDE_FAILED,
  SET_SEND_NEW_DOCUSIGN_DOC_SUCCESS,
  SET_SEND_NEW_DOCUSIGN_DOC_FAILED,
  SET_FORM_LOADING_FALSE,
  SET_FORM_LOADING_TRUE,
  SET_S150FORM_PDF_LOADING_TRUE,
  SET_S150FORM_PDF_LOADING_FALSE,
  GET_S150FORM_PDF_SUCCESS,
  GET_S150FORM_PDF_FAILED,

  SET_SHAREHOLDERSFORM_PDF_LOADING_TRUE,
  SET_SHAREHOLDERSFORM_PDF_LOADING_FALSE,
  GET_SHAREHOLDERSFORM_PDF_SUCCESS,
  GET_SHAREHOLDERSFORM_PDF_FAILED,

  SET_CLIENT_IDPAL_REINVITE_SUCCESS,
  SET_CLIENT_IDPAL_REINVITE_FAILED,

  SET_CORPDOC_CR_PDF_LOADING_TRUE,
  SET_CORPDOC_CR_PDF_LOADING_FALSE,
  SET_CORPDOC_SBI_PDF_LOADING_TRUE,
  SET_CORPDOC_SBI_PDF_LOADING_FALSE,
  SET_CORPDOC_COI_PDF_LOADING_TRUE,
  SET_CORPDOC_COI_PDF_LOADING_FALSE,
  SET_CORPDOC_C_PDF_LOADING_TRUE,
  SET_CORPDOC_C_PDF_LOADING_FALSE,

  GET_CORPDOC_CR_PDF_SUCCESS,
  GET_CORPDOC_CR_PDF_FAILED,
  GET_CORPDOC_SBI_PDF_SUCCESS,
  GET_CORPDOC_SBI_PDF_FAILED,
  GET_CORPDOC_COI_PDF_SUCCESS,
  GET_CORPDOC_COI_PDF_FAILED,
  GET_CORPDOC_C_PDF_SUCCESS,
  GET_CORPDOC_C_PDF_FAILED,

  SET_CASE_OPENING_SUCCESS,
  SET_CASE_OPENING_FAILED
}
  from
    "../actions";

const initialState = {
  data: null,
  errorMessage: null,
  isDataLoading: false,
  isClientDataLoading: false,
  isSubmissionDataLoading: false,
  getById: null,
  clientBySecret: null,
  isFormLoading: false,
  isS150FormLoading: false,  
  getS150FormContent: null,
  isCorpDocLoading: false,  
  getCorpDocContent: null  
}

export default function clientsManageReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_LIST_DOWNLOADED:
      return {
        ...state,
        data: action.payload,
        isDataLoading: false
      }

    case CLIENTS_LIST_NOT_DOWNLOADED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }

    case SET_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }

    case SET_SUBMISSION_DATA_LOADING_TRUE:
      return {
        ...state,
        isSubmissionDataLoading: true
      }

    case SET_SUBMISSION_DATA_LOADING_FALSE:
      return {
        ...state,
        isSubmissionDataLoading: false
      }

    case SET_NEW_CLIENTS_SUCCESS:
      return {
        ...state,
        isDataLoading: false
      }

    case SET_NEW_CLIENTS_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case CLIENTS_GET_BY_ID_SUCCESS:
      return {
        ...state,
        getById: action.payload,
        isDataLoading: false,
        errorMessage: null
      }

    case CLIENTS_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case CLIENTS_GET_SUBMISSION_DATA_SUCCESS:
      return {
        ...state,
        getSubmissionContent: action.payload,
        isSubmissionDataLoading: false
      }

    case CLIENTS_GET_FORBIDDEN:
      return {
        ...state,
        isDataLoading: false,
        isSubmissionDataLoading: false,
        errorMessage: action.payload,
      }

    case GET_CLIENT_BY_SECRET_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        getClientBySecret: action.payload,
        isDataLoading: false
      }
    case GET_CLIENT_BY_SECRET_FAILED:
      return {
        ...state,
        getClientBySecret: null,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_CLIENT_BY_SECRET_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isDataLoading: false
      }
    case SET_CLIENT_BY_SECRET_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_CASE_OPENING_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isDataLoading: false
      }
    case SET_CASE_OPENING_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_NEW_RISK_FORM_SUCCESS:
      return {
        ...state,
        // TODO: operate with form data
        errorMessage: null,
        isDataLoading: false
      }

    case SET_NEW_RISK_FORM_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_UPDATE_RISK_FORM_SUCCESS:
      return {
        ...state,
        getById: {...state.getById, data: {...state.getById.data, riskForm: action.payload.data}},
        errorMessage: null,
        isDataLoading: false,
      }

    case SET_UPDATE_RISK_FORM_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case GET_RISK_FORM_SUCCESS:
      return {
        ...state,
        riskForm: action.payload,
        errorMessage: null,
        isDataLoading: false
      }

    case GET_RISK_FORM_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_CLIENT_IDPAL_OVERRIDE_SUCCESS:
      return {
        ...state,
      }

    case SET_SEND_NEW_DOCUSIGN_DOC_SUCCESS:
      return {
        ...state,
        // riskForm: action.payload,
        errorMessage: null,
        isDataLoading: false
      }

    case SET_CLIENT_IDPAL_OVERRIDE_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_SEND_NEW_DOCUSIGN_DOC_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_FORM_LOADING_TRUE:
      return {
        ...state,
        isFormLoading: true
      }

    case SET_FORM_LOADING_FALSE:
      return {
        ...state,
        isFormLoading: false
      }

    case SET_S150FORM_PDF_LOADING_TRUE:
      return {
        ...state,
        isS150FormLoading: true
      }

    case SET_S150FORM_PDF_LOADING_FALSE:
      return {
        ...state,
        isS150FormLoading: false
      }

    case GET_S150FORM_PDF_SUCCESS:
      return {
        ...state,
        isS150FormLoading: false,
        getS150FormContent: action.payload
      }

    case GET_S150FORM_PDF_FAILED:
      return {
        ...state,
        isS150FormLoading: false,
        errorMessage: action.payload
      }

    case SET_SHAREHOLDERSFORM_PDF_LOADING_TRUE:
      return {
        ...state,
        isShareholdersFormLoading: true
      }

    case SET_SHAREHOLDERSFORM_PDF_LOADING_FALSE:
      return {
        ...state,
        isShareholdersFormLoading: false
      }

    case GET_SHAREHOLDERSFORM_PDF_SUCCESS:
      return {
        ...state,
        isShareholdersFormLoading: false,
        getShareholdersFormContent: action.payload
      }

    case GET_SHAREHOLDERSFORM_PDF_FAILED:
      return {
        ...state,
        isShareholdersFormLoading: false,
        errorMessage: action.payload
      }

    case SET_CORPDOC_CR_PDF_LOADING_TRUE:
      return {
        ...state,
        isCorpDocCRLoading: true
      }
    case SET_CORPDOC_CR_PDF_LOADING_FALSE:
      return {
        ...state,
        isCorpDocCRLoading: false
      }

    case SET_CORPDOC_SBI_PDF_LOADING_TRUE:
      return {
        ...state,
        isCorpDocSBILoading: true
      }
    case SET_CORPDOC_SBI_PDF_LOADING_FALSE:
      return {
        ...state,
        isCorpDocSBILoading: false
      }

    case SET_CORPDOC_COI_PDF_LOADING_TRUE:
      return {
        ...state,
        isCorpDocCOILoading: true
      }
    case SET_CORPDOC_COI_PDF_LOADING_FALSE:
      return {
        ...state,
        isCorpDocCOILoading: false
      }

    case SET_CORPDOC_C_PDF_LOADING_TRUE:
      return {
        ...state,
        isCorpDocCLoading: true
      }
    case SET_CORPDOC_C_PDF_LOADING_FALSE:
      return {
        ...state,
        isCorpDocCLoading: false
      }

    case GET_CORPDOC_CR_PDF_SUCCESS:
      return {
        ...state,
        isCorpDocCRLoading: false,
        getCorpDocContent: action.payload
      }
    case GET_CORPDOC_CR_PDF_FAILED:
      return {
        ...state,
        isCorpDocCRLoading: false,
        errorMessage: action.payload
      }

    case GET_CORPDOC_SBI_PDF_SUCCESS:
      return {
        ...state,
        isCorpDocSBILoading: false,
        getCorpDocContent: action.payload
      }
    case GET_CORPDOC_SBI_PDF_FAILED:
      return {
        ...state,
        isCorpDocSBILoading: false,
        errorMessage: action.payload
      }

    case GET_CORPDOC_COI_PDF_SUCCESS:
      return {
        ...state,
        isCorpDocCOILoading: false,
        getCorpDocContent: action.payload
      }
    case GET_CORPDOC_COI_PDF_FAILED:
      return {
        ...state,
        isCorpDocCOILoading: false,
        errorMessage: action.payload
      }
    case GET_CORPDOC_C_PDF_SUCCESS:
      return {
        ...state,
        isCorpDocCLoading: false,
        getCorpDocContent: action.payload
      }
    case GET_CORPDOC_C_PDF_FAILED:
      return {
        ...state,
        isCorpDocCLoading: false,
        errorMessage: action.payload
      }

    case SET_CLIENT_IDPAL_REINVITE_SUCCESS:
      return {
        ...state,
      }

    case SET_CLIENT_IDPAL_REINVITE_FAILED:
      return {
        ...state,
        errorMessage: action.payload
      }

    default:
      return state
  }
}
