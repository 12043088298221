import React from 'react';
import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";

import {
  BigBlueCheck,
} from "../assets/icons/sprite";

const DocuSignSuccess = () => {
  return (<Flex justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                minH={'100vh'}
                minW={'100vw'}
  >
    <Flex justifyContent={'center'}
          flexDirection={'column'}
          maxW={'500px'}
          alignItems={'center'}
          textAlign={'center'}>

      <Box bgColor={'#F9FBFC'}
           borderRadius={'50%'}
           padding={8}
           marginBottom={8}
      >
        <BigBlueCheck width={'96px'} height={'96px'}/>
      </Box>

      <Text fontSize={'28px'}
            fontWeight={700}
            mb={4}
      >Thank you for signing the documents</Text>

      <Text mb={2}>This data will be sent to your solicitor.</Text>

      <Text>Please note that you will need to update verification documents every 3 months if there were any changes.
        You will receive an email reminder about it when it’s required.</Text>
    </Flex>
  </Flex>)
};

export default DocuSignSuccess;