import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import AlertComponent from "../../../../../AlertComponent";
import Alert from "../../../../../Alert";
import { useDisclosure, Text, VStack, Button } from "@chakra-ui/react";
import IconDelete from "../../../../../../assets/icons/icon-delete.png"
import { DoneIcon } from "../../../../../../assets/icons/sprite"
import { deleteClient } from "../../../../../../redux/actions/clients";

function IndividualsDelete(data) {

    const id = data?.id
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
    function handlConfirmDelete(id) {
        setIsLoading(false)
        setErrorMessage(null)
        onConfirmOpen()
    }
    const resetDialog = () => {
        window.location.reload()
    };    
    function handleDelete(id) {      
        setIsLoading(true)
        deleteClient(id).then(resp => {
            setStep(2).then(() => {
              navigate(0)
              setIsLoading(false)
            });
        }).catch(err => {
            setIsLoading(false)
            onConfirmOpen()
            setErrorMessage(err)
        });
    }

    var action = null
    if (step === 1) {
        action = () => { handleDelete(id) }
    }

    return (
<>
<Link onClick={() => handlConfirmDelete(id)} className="dropdown-item">
    <img src={IconDelete} className="individuals__delete-icon" alt=""/> Delete
</Link>

<Alert
            isOpen={isConfirmOpen}
            onOpen={onConfirmOpen}
            onClose={onConfirmClose}
            header={'Delete Person'}
            isLoading={isLoading}
            text={
            
                step === 1 ? (
<>
{errorMessage && (<AlertComponent message={errorMessage} />)}
<Text>Are you sure you want to delete this person?</Text>            
</>
                ) : (
<>                    
            <VStack marginBottom={10}>
              <DoneIcon width={93} height={93} marginTop={6} marginBottom={4} />
                  <Text paddingX={"100px"} pb={8} align={"center"}>
                    The person has now been deleted.
                  </Text>
              <Button
                type="submit"
                fontSize={"18px"}
                variant="primary"
                minWidth={184}
                minHeight={"48px"}
                height={"48px"}
                onClick={resetDialog}
              >
                OK
              </Button>
            </VStack>       
</>             
)        
            }
            action={action}
/>
</>);
};

export default IndividualsDelete;