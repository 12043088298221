
import { getToken } from "./token";

export const API_URL = process.env.REACT_APP_API_URL;

export const makeRequest = async (
  url,
  data = null,
  method = "GET",
  auth = true,
  asForm = false
) => {
  let headers;
  if (asForm) {
    headers = {};
  } else {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }
  if (auth) {
    headers.Authorization = `Bearer ${getToken()}`;
  }
  const params = {
    method: method,
    headers,
  };
  if ((method === "POST" || method === "PUT") && !asForm) {
    params.body = JSON.stringify(data) ?? null;
  }
  if (asForm) {
    params.body = data;
  }
  try {
    console.log("\n");
    console.log(params.method);
    console.log(headers);
    console.log(url);
    console.log(params.body);
    console.log("\n");

    return await fetch(url, params);
  } catch (err) {
    console.log(err)
    return err;
  }
};

export const getCase = async (id) => { 
  try {
    const response = await makeRequest(`${API_URL}clients/${id}`, null, 'GET', true);
    return response;
  } catch (error) {
    console.error("Error fetching case id:", error);
    throw error; // Rethrow or handle as needed
  }
}

export const getCases = async (type, status, unapproved, unsigned, 
                              risk_assessment_1, risk_assessment_2, 
                              risk_assessment_3, addedDate, updatedDate, 
                              keyword, page, solicitor_id) => {
  try {
    if (type === "Private") {
      type = "individual";
    }
    if (status === "all") {
      status = null;
    }

    let url = `${API_URL}clients/search`;
    let queryParams = [];

    if (type) {
        queryParams.push(`type=${type.toLowerCase()}`);
    }
    if (status) {
        queryParams.push(`status=${status.toLowerCase()}`);
    }
    if (unapproved) {
        queryParams.push("unapproved=true");
    }
    if (unsigned) {
        queryParams.push("unsigned=true");
    }
    if (risk_assessment_1) {
        queryParams.push("risk_assessment_1=true");
    }
    if (risk_assessment_2) {
        queryParams.push("risk_assessment_2=true");
    }
    if (risk_assessment_3) {
        queryParams.push("risk_assessment_3=true");
    }
    if (addedDate) {
      queryParams.push(`added_date=${encodeURIComponent(addedDate)}`);
    }
    if (updatedDate) {
      queryParams.push(`updated_date=${encodeURIComponent(updatedDate)}`);
    }
    if (keyword) {
      queryParams.push(`keyword=${keyword}`);
    }
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (solicitor_id) {
      queryParams.push(`solicitor_id=${solicitor_id}`);
    }

    if (queryParams.length > 0) {
        url += '?' + queryParams.join('&');
    }

    const response = await makeRequest(url, null, 'GET', true);
    return response;

  } catch (error) {
    console.error("Error fetching cases:", error);
    throw error; // Rethrow or handle as needed
  }
}