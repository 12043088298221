import React from 'react';
import {Document, Page, Text, View, StyleSheet} from "@react-pdf/renderer";

import {  questionsStatus, questionsFace, questionsLocation, questionsId, questionsProfile,
          questionsType, questionsValue, questionsSource, questionsDestination, questionsDyt } 
          from '../constants/RiskAssessmentQuestions'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  text: {
    fontSize: 12,
  },
  textAnswer: {
    fontSize: 12,
    marginBottom: 15
  },  
  h1: {
    fontSize: 18,
    marginBottom: 20
  },
  h2: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 10
  }
})

const MyDocument = ({data}) => {
  return (
    <Document>
      <Page size={'A4'} style={styles.page}>
        <View style={styles.section}>

          <Text style={styles.h1}>Risk Assessment Form</Text>
          <Text style={styles.text}>Risk status: {data.risk_status}</Text>
          <Text style={styles.text}>Submitted at: {new Date(data.submitted_at).toUTCString()}</Text>
          <Text style={styles.text}>Submitted by: {data?.user?.name}</Text>

          <RiskAssessmentAnswers questions={questionsStatus} data={data}/>
          <RiskAssessmentAnswers questions={questionsFace} data={data}/>
          <RiskAssessmentAnswers questions={questionsLocation} data={data}/>
          <RiskAssessmentAnswers questions={questionsId} data={data}/>
          <RiskAssessmentAnswers questions={questionsProfile} data={data}/>
          <RiskAssessmentAnswers questions={questionsType} data={data}/>
          <RiskAssessmentAnswers questions={questionsValue} data={data}/>
          <RiskAssessmentAnswers questions={questionsSource} data={data}/>
          <RiskAssessmentAnswers questions={questionsDestination} data={data}/>
          <RiskAssessmentAnswers questions={questionsDyt} data={data}/>

        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;

function RiskAssessmentAnswers(props) {

  var title = props.questions.title
  var questions = props.questions.questions;

  return (
<>
  <Text style={styles.h2}>{title}</Text>
      {questions.map((question) => {
      return (<>
      <Text style={styles.text} key={question.key}>{question.question}</Text>
      <Text style={styles.textAnswer} key={question.key}>{props.data.other_questions[question.key]}</Text>
      </>
      );
    })}
</>
  );

}
