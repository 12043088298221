import React from 'react';
import {
  Divider,
  Flex,
  HStack,
  // Input,
  // InputGroup,
  // InputLeftElement,
  Stack
} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";
import UserProfile from "./UserProfile";
import AdminNoPhoto from '../assets/img/admin-no-photo.png';
import {useSelector} from "react-redux";
// import {SearchIcon} from "../assets/icons/sprite";
// import {NotificationIcon} from "../assets/icons/sprite";
import AccountSidebar from "./AccountSidebar";



const AccountLayout = () => {

  const dispatchCurrentUser = useSelector(state=> state.solicitorAuth.currentUser)

  return (
    <Flex
      flexDirection={'row'}
    >
      <AccountSidebar/>

      {/*right content*/}\
      <Stack
        ml={'418px'}
        w={'100%'}
        mr={'80px'}
        mb={'80px'}
      >
        <HStack
          w={'100%'}
          as={Flex}
          alignItems={'left'}
          mt={'16px'}
          mb={'16px'}
          justifyContent={'flex-end'}
        >

          <UserProfile
            width={'250px'}
            name={dispatchCurrentUser.name}
            image={AdminNoPhoto}
            email={dispatchCurrentUser.email}
          />

        </HStack>

        <Divider

        />
        <Stack
          as={Flex}
          paddingTop={'10px'}
          flexDirection={"column"}
        >
          <Outlet/>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default AccountLayout;
