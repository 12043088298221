import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Flex,
  Box,
  Text, Button, FormControl, FormLabel, GridItem, Grid
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons'
import {
    setLoadingFlag, getFormsList, markProcessed, getAccountFormSecret
} from "../../redux/actions/forms";
import {Link as ReachLink, Outlet, useParams} from "react-router-dom";
import Alert from "../../components/Alert";
import {API_URL} from "../../services/request";
import {PDFIcon} from "../../assets/icons/sprite";

const titleStyle = {
    color: '#B4BAC9',
    fontWeight: '700',
    textAlign: 'left',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    textTransform: 'uppercase',
}
const AccountForms = () => {

    const dispatch = useDispatch();

    const {id: hideListing} = useParams();
    const PAGE_PATH = 'account-form?page=';
    const [openedItems, setOpenedItems] = useState({});
    const [processItem, setProcessItem] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const thStyles = {
        color: '#1A1E29', fontSize: '14px', textTransform: 'none', opacity: '0.5', fontWeight: 400, border: 'none',
    }

    useEffect(() => {
        if (!hideListing) {
            dispatch(setLoadingFlag());
            dispatch(getFormsList());
        }
    }, [
        dispatch,
        hideListing
    ])

    const onOpen = () => {
        setIsOpen(true);
    }
    const onClose = () => {
        setIsOpen(false);
    }
    const dispatchAccountList = useSelector(state => state.forms);
    const dispatchListPagination = useSelector(state => state.forms?.data?.meta?.pagination);
    const dispatchIsDataLoading = useSelector(state => state.forms?.isDataLoading);
    const dispatchList = (credentials) => dispatch(getFormsList(credentials))
    const dispatchMarkProcessed = (credentials) => dispatch(markProcessed(credentials))
    const dispatchLoadingFlag = (credentials) => dispatch(setLoadingFlag(credentials))
    const latestTimeout = useRef();

    const downloadFile = async (itemId) => {
        if (!isLoading) {
            setIsLoading(true);
            try {
                const secret = await getAccountFormSecret(itemId);
                if (secret) {
                    window.location.replace(`${API_URL}account-form/${itemId}/export/${secret}`);
                }
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        }

    }
    const exportPdf = async (itemId) => {
        if (latestTimeout.current) {
            clearTimeout(latestTimeout.current);
        }
        latestTimeout.current = setTimeout(async () => {await downloadFile(itemId)}, 1000);
        return false;
    }
    const toggleItem = (itemId) => {
        const items = {...openedItems};
        if (items[itemId]) {
            items[itemId] = false;
        } else {
            items[itemId] = true;
        }
        setOpenedItems(items);
    }
    return (<>
        <Outlet/>
        <Alert
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            header={`Are you sure you want to mark this form as ${processItem?.status === 'Pending' ? 'processed' : 'pending'}?`}
            text={`This form will be marked ${processItem?.status === 'Pending' ? 'processed' : 'pending'}.`}
            action={() => {
                const item = {...processItem};
                item.status = processItem.status === 'Pending' ? 'Processed' : 'Pending';
                dispatchMarkProcessed(item);
            }}
        />
        {!hideListing ? <Box>

            <HStack as={Flex}>
                <Heading
                    fontSize={'32px'}
                    lineHeight={'48px'}
                    mb={'32px'}
                    flexGrow={1}
                >Account Opening Forms</Heading>
            </HStack>
            {dispatchIsDataLoading ? <Loading/> : dispatchAccountList.data ? <> <TableContainer
                backgroundColor={'#F9FBFC'}
                color={'#1A1E29'}
                pl={'16px'}
                pt={'12px'}
                pr={'20px'}
            >
                <Table
                    variant='simple'
                >
                    <Thead>
                        <Tr>
                            <Th
                                {...thStyles}
                            >Name</Th>
                            <Th
                                {...thStyles}
                            >Status</Th>
                            <Th {...thStyles}>

                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {dispatchAccountList?.data?.data?.map(({id, name, status, address, phone, email, surname}) => {
                            const opened = openedItems[id];
                            return <Fragment key={id}>
                                <Tr cursor={'pointer'} onClick={() => {
                                    toggleItem(id);
                                }}
                                >
                                    <Td
                                        fontSize={'18px'}
                                        minWidth={'330px'}
                                    ><Text
                                        as={ReachLink}
                                        color={'#1A1E29'}
                                    >{name} {surname}</Text></Td>
                                    <Td
                                        fontSize={'18px'}
                                        color={'#B4BAC9'}
                                    ><Text
                                        as={ReachLink}
                                        color={'#B4BAC9'}
                                    >
                                        {status.toString() === 'Pending' ? <Button
                                            colorScheme='orange'
                                            backgroundColor={'#7B61FF'}
                                            color={'#FFFFFF'}
                                            size='xs'
                                            fontWeight={400}
                                            fontSize={'14px'}
                                            w={'100%'}
                                        >
                                            Pending
                                        </Button> : <Button
                                            colorScheme='green'
                                            backgroundColor={'#1CBE7A'}
                                            size='xs'
                                            fontWeight={400}
                                            fontSize={'14px'}
                                            w={'100%'}
                                        >
                                            Processed
                                        </Button>

                                        }
                                    </Text></Td>
                                    <Td
                                        textAlign={'right'}
                                        fontSize={'18px'}
                                        color={'#B4BAC9'}
                                    >{!opened ? <ChevronUpIcon/> : <ChevronDownIcon/>}</Td>
                                </Tr>
                                {opened ? <Tr>
                                    <Td colSpan={3}
                                        fontSize={'18px'}
                                        minWidth={'330px'}
                                    >
                                      <Grid templateColumns={'repeat(3, 1fr)'} gap={6}>
                                        <GridItem colSpan={2}>
                                        <FormControl style={{zIndex: '0'}}>
                                          <FormLabel style={titleStyle} fontSize={'18px'}>Address</FormLabel>
                                          <Text
                                              fontSize={'18px'}
                                              fontWeight={'400'}
                                          >
                                            {address}
                                          </Text>
                                          <FormLabel mt={4}  style={titleStyle} fontSize={'18px'}>MOBILE</FormLabel>
                                          <Text
                                              fontSize={'18px'}
                                              fontWeight={'400'}
                                          >
                                            {phone}
                                          </Text>
                                          <FormLabel mt={4}  style={titleStyle} fontSize={'18px'}>EMAIL</FormLabel>
                                          <Text
                                              fontSize={'18px'}
                                              fontWeight={'400'}
                                          >
                                            {email}
                                          </Text>
                                        </FormControl>
                                        </GridItem>
                                        <GridItem textAlign={'right'} display={'flex'} flexDirection={'column'}>
                                          <Button
                                              colorScheme='blue'
                                              size='md'
                                              fontWeight={400}
                                              mb={2}
                                              fontSize={'14px'}
                                              w={'200px'}
                                              onClick={() => {
                                                  setProcessItem({id, status})
                                                  setIsOpen(true);
                                                 }
                                              }
                                          >
                                              {status === 'Pending' ? 'Mark as processed' : 'Mark as pending'}
                                          </Button>
                                            <Button
                                              leftIcon={<PDFIcon w={5} h={5}/>}
                                              disabled={isLoading}
                                              colorScheme='gray'
                                              size='md'
                                              fontWeight={400}
                                              fontSize={'14px'}
                                              w={'200px'}
                                              onClick={() => {exportPdf(id)}}
                                          >
                                              Export PDF
                                          </Button>
                                        </GridItem>
                                      </Grid>

                                    </Td>
                                </Tr> : null}
                            </Fragment>
                        })}
                    </Tbody>
                </Table>
            </TableContainer>

                <Pagination
                    dispatchList={dispatchList}
                    dispatchListPagination={dispatchListPagination}
                    dispatchLoadingFlag={dispatchLoadingFlag}
                    pagePath={PAGE_PATH}
                    name={'Forms'}
                />
            </> : <Loading/>}
        </Box> : null}

    </>);
}

export default AccountForms;
