import React from 'react';
import { useState } from 'react';
import { Heading } from "@chakra-ui/react";
import { Input, Stack, FormControl, FormLabel, RadioGroup, Radio, Text, Button } from "@chakra-ui/react";
import AlertComponent from '../../components/AlertComponent';
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFlag, setNewClient } from "../../redux/actions/clients";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from '../../components/BackButton';

const SolicitorInvitePrivate = () => {

    const navigate = useNavigate();
    const {solicitor_id} = useParams();
    const dispatch = useDispatch();
    const dispatchSetNewClient = (data) => dispatch(setNewClient(data))    
    const [step, setStep] = useState(1);
    const [moreThanOne, setMoreThanOne] = useState('false');
    const dispatchErrorMessage = useSelector(state => state.clients.errorMessage)  

    const handleSubmit = async values => {  

        dispatch(setLoadingFlag());

        dispatchSetNewClient({          
          "type": "individual",
          "email": values.email,
          "first_name": values.firstName,
          "last_name": values.lastName,
          "email2": values.email2,
          "first_name2": values.firstName2,
          "last_name2": values.lastName2,
          "morethanone": moreThanOne,
          ...(solicitor_id && { "solicitor_id": solicitor_id }),
        }).then(() => {
          setStep(2);
          dispatch(setLoadingFlag());
        })
    }    
    const changeMoreThanOne = (value) => {
        setMoreThanOne(value);
    }
    
    const formik = useFormik({
        initialValues: {
          firstName: '', lastName: '', email: '', 
          firstName2: '', lastName2: '', email2: '', 
          moreThanOne: 'false', formmode: 'invite', clientid: 0
        }, onSubmit: handleSubmit
      })

    return <>

<div className="container">

{ step === 2 ?  <BackButton onClickHandler={() => { navigate(-1) }} ml={'-17px'}/>
:  <BackButton onClickHandler={() => { navigate(-1) }} ml={'-17px'}/> }

    <div className="header-container">
        <Heading className="heading">New Private Case</Heading>
    </div>

    { step === 2 ? <Text>The client/s has now been sent an email inviting them to Law Comply.</Text> : 
    
    <>
    <form onSubmit={formik.handleSubmit}>          
    <Input id="formmode" type="hidden" onChange={formik.handleChange} value={formik.values.formmode} />

    <Stack spacing="5">

          {dispatchErrorMessage && (<AlertComponent message={dispatchErrorMessage} />)}
          <>

          <Text fontSize={'20'} fontWeight={'600'} align={'left'}>Main Contact</Text>

          <FormControl>
            <FormLabel htmlFor="firstName" fontSize={'18px'}>First name</FormLabel>
            <Input id="firstName" type="text" required placeholder={'Enter first name'}
              onChange={formik.handleChange} value={formik.values.firstName} />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="lastName">Last name</FormLabel>
            <Input id="lastName" type="text" required placeholder={'Enter last name'}
              onChange={formik.handleChange} value={formik.values.lastName} />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="email" fontSize={'18px'}>Email</FormLabel>
            <Input id="email" type="email" required placeholder={'Enter email'}
              onChange={formik.handleChange} value={formik.values.email} />
          </FormControl>

          <Text align={'left'}>Is there more than one individual?</Text>

            <RadioGroup id={'moreThanOne'} name={'moreThanOne'}
              onChange={changeMoreThanOne.bind(this)} defaultValue='false'>
              <Stack direction='row'>
                <Radio value='false'>No</Radio>
                <Radio value='true'>Yes</Radio> 
              </Stack>
            </RadioGroup>

          { moreThanOne === "true" ? <><Text fontSize={'20'} fontWeight={'600'} align={'left'}>Second Individual</Text>
          
          <FormControl>
            <FormLabel htmlFor="firstName2">First name</FormLabel>
            <Input id="firstName2" type="text" required placeholder={'Enter first name'}
              onChange={formik.handleChange} value={formik.values.firstName2} />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="lastName2">Last name</FormLabel>
            <Input id="lastName2" type="text" required placeholder={'Enter last name'}
              onChange={formik.handleChange} value={formik.values.lastName2} />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="email2" fontSize={'18px'}>Email</FormLabel>
            <Input id="email2" type="email" required placeholder={'Enter email'}
              onChange={formik.handleChange} value={formik.values.email2} />
          </FormControl></>
          : null }

          </>
    </Stack>

    <Button width={184} style={{marginTop: 25}} type="submit" variant="primary">Invite Now</Button>        

    </form>
</>}
</div>    
</>
}

export default SolicitorInvitePrivate;