import React from "react";
import {useTimer} from "react-timer-hook";
import Resend from "./Resend";

const Countdown = React.forwardRef((
  {
    content,
    expiryTimestamp,
    resendCode,
    ...props
  }
  , ref) => {

  const {
    seconds,
    minutes,
    isRunning,
    restart

  } = useTimer({expiryTimestamp})

  React.useImperativeHandle(ref, () => ({
    restartTimer() {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 300);
      restart(time)
    }
  }));

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  return (isRunning ? <strong>{content}
      {formatTime(minutes)}:{formatTime(seconds)}. </strong> :
    <strong>00:00 <Resend onClick={resendCode}/></strong>)
})
export default Countdown;