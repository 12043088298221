import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { AddressIcon, CalendarIcon, EmailIcon, MobileIcon, PencilIcon } from "../../../assets/icons/sprite"

function CaseViewGeneralInfo(data) {

    const dispatchItem = data?.dispatchItem
    const editData = [
        {
          icon: <AddressIcon
            w={8}
            h={8}
            fill={'none'}/>,
          label: 'Address:',
          content: (dispatchItem?.data?.address === "") ? "Not set" : dispatchItem?.data?.address,
          actionIcon: null,
          action: 'address',
        }, {
          icon: <EmailIcon
            w={8}
            h={8}
            fill={'none'}/>,
          label: 'Email:',
          content: (dispatchItem?.data?.main_contact?.email === "") ? "Not set" : dispatchItem?.data?.main_contact?.email,
          actionIcon: null,
          action: null,
        }, {
          icon: <MobileIcon
            w={8}
            h={8}
            fill={'none'}/>,
          label: 'Mobile:',
          content: (dispatchItem?.data?.main_contact?.phone === "") ? "Not set" : dispatchItem?.data?.main_contact?.phone,
          actionIcon: <PencilIcon
            fill={'none'}
            w={8}
            h={8}
          />, action: 'phone',
        },
    
        {
          icon: <CalendarIcon
            w={8}
            h={8}
            fill={'none'}/>,
          label: 'Last update:',
          content: new Date(dispatchItem?.data?.updated_at).toUTCString(),
          actionIcon: <PencilIcon
            fill={'none'}
            w={8}
            h={8}
          />, action: 'phone',
        },
      ]
    const cardStyle = {
        rounded: "lg",
        bgColor: "#F9FBFC",
        // width: '466px',
        p: "12px",
        minHeight: 26,
        marginTop: 2,
        marginBottom: 1,
      };    

    return (    
        <>        
<GridItem>
                <Heading
                  fontSize={"24px"}
                  lineHeight={"48px"}
                  mt={"37px"}
                  mb={1}
                >
                  {" "}
                  General Info{" "}
                </Heading>

                {editData.map(
                  ({ icon, label, content, actionIcon, action }) => {
                    return (
                      <Grid
                        {...cardStyle}
                        key={label}
                        templateColumns={"50px 1fr"}
                      >
                        <GridItem
                          as={Flex}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Flex
                            bgColor={"#fff"}
                            w={12}
                            h={12}
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={4}
                          >
                            {icon}
                          </Flex>
                        </GridItem>
                        <GridItem
                          pl={3}
                          as={Flex}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          flexDirection={"column"}
                        >
                          <Text fontWeight={"700"} fontSize={"18px"}>
                            {label}
                          </Text>
                          <Box fontSize={"18px"} overflowWrap={"anywhere"}>
                            {content ?? "Not set"}
                          </Box>
                        </GridItem>
                      </Grid>
                    );
                  }
                )}
              </GridItem>
              </>);
  };
  export default CaseViewGeneralInfo;

