import React from 'react';
import {
  Button, Grid, GridItem, Heading, Table, TableContainer, Tbody, Td, Text, Tr, useDisclosure, HStack
} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import Alert from "./Alert";
import Loading from "./Loading";
import {Link as ReachLink} from "react-router-dom";

const ClientOpeningFormItem = ({isAlert = true, accountOpeningForm = false, caseInformationForm = true}) => {
  const dispatchIsSubmissionDataLoading = useSelector(state => state.clients.isSubmissionDataLoading)

  const {isOpen, onOpen, onClose} = useDisclosure();
  const dispatchItemData = useSelector(state => state.clients.getById.data)
  const tableLeftStyle = {
    color: '#6E7997',
    fontWeight: '400',
    textAlign: 'left',
    fontSize: '18px',
    verticalAlign: 'top',
    width: '40%',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
  }

  const tableRightStyle = {
    color: '#343B55',
    fontWeight: '500',
    textAlign: 'left',
    fontSize: '18px',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    verticalAlign: 'top',
  }
  
  var status = "Not submitted yet"

  // case information
  if ((caseInformationForm && !dispatchItemData?.form)
        ||
      (caseInformationForm && dispatchItemData?.form?.status === "Pending")) {
    status = "Not submitted yet"
  }
  else if (caseInformationForm && dispatchItemData?.form?.status === "Processed") {
    status = "Processed"
  }

  // account opening form
  else if ((accountOpeningForm && !dispatchItemData?.form)
        ||
      (accountOpeningForm && dispatchItemData?.form?.status_opening_form === "Pending")) {
    status = "Not submitted yet"
  }
  else if (accountOpeningForm && dispatchItemData?.form?.status_opening_form === "Processed") {
    status = "Processed"
  }

  var title = "Case Information"
  var fields = ["case_reference_number", "case_description", "solicitor_name"];
  if (accountOpeningForm === true) {
    fields = ["name", "address", "email", "address_trading", "company_number", "vat_number"]
    title = "Account Opening Form"
  }

  return (
    <GridItem className="caseview__compliance-item-box" mt={1} key={'Case Information'}>
      {isAlert && dispatchItemData?.form ? <Alert
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        header={title}
        text={dispatchIsSubmissionDataLoading ? <Loading/> : <>

          {dispatchItemData?.form &&
            <TableContainer
            >
              <Table
                width={'100%'}
              >
                <Tbody>
                  {
                    Object.entries(dispatchItemData?.form).map(
                      ([key, value], i) => key && fields.includes(key)
                        ? (
                        value
                          ? <Tr key={key}>
                            <Td width={'100px'} {...tableLeftStyle}
                                textTransform={'capitalize'}>{key === 'created_at' ? 'Submitted At' : key.replaceAll('_', ' ')}</Td>
                            <Td {...tableRightStyle}>
                              {key === 'created_at' ? new Date(value).toUTCString() : Array.isArray(value) && value.length
                                ? value.map((el, index) => (
                                  <Text mb={4}
                                        key={Object.values(el)}
                                        {...tableRightStyle}>{`${index + 1}: ${Object.values(el)} `}
                                  </Text>))
                                : value}
                            </Td>
                          </Tr>
                          : null
                      ) : null
                    )
                  }
                </Tbody>
              </Table>
            </TableContainer>
          }
        </>}
        action={null}
        size={'6xl'}
        contentPaddingY={0}
      /> : null}

      <Grid templateColumns={'1fr auto'}>
        <GridItem>
          <Heading as={'h3'} fontWeight={'700'} fontSize={'18px'}>
            {title}
          </Heading>
          <Text color={'#6E7997'} fontWeight={'400'} mt={2} fontSize={'18px'}>
            {dispatchItemData?.form?.created_at ? new Date(dispatchItemData?.form?.created_at).toUTCString() : 'Not yet submitted'}
          </Text>
        </GridItem>

        <GridItem display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Text
            color={status === 'Signed' ? '#6E7997' : '#fff'}
            fontSize={'11px'}
            marginTop={'-8px'}
            bgColor={(() => {
              switch (status) {
                case 'Not submitted yet':
                  return '#C8392D'
                case 'Processed':
                  return '#1CBE7A'                  
                case 'Processed & Case Created':
                  return '#1CBE7A'
                default:
                  return null
              }
            })()}
            paddingX={'10px'}
            borderRadius={'4px'}
          >
            {status}</Text>
        </GridItem>
      </Grid>

      <HStack mt={4}>
                {status !== "Not submitted yet" ?
                <Button className="button__white" style={{width: '100%'}}
                    onClick={() => {
                      onOpen()
                    }}>
                    View Document
                </Button>
                : ''}

                {((caseInformationForm && !dispatchItemData?.form) ||
                (caseInformationForm && (dispatchItemData?.form?.status === "Pending" || dispatchItemData?.form?.status === "Processed")))
                ?
                <Button className="button__white" style={{width: '100%'}}
                  as={ReachLink}
                  to={'case-opening'}>
                    Fill Out Form
                </Button>
                : '' }
      </HStack>
    </GridItem>
  );
};

export default ClientOpeningFormItem;
