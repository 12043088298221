import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Button, Flex, GridItem, Heading, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { ExpandDownIcon } from "../../../../../assets/icons/sprite"
import IconActions from "../../../../../assets/icons/icon-actions.png"
import IconTick from "../../../../../assets/icons/icon-tick.png"

import ComplianceItem from "../../../../../components/Solicitor/CaseView/Compliance/Item"
import IndividualsReRequest from "../../../../../components/Solicitor/CaseView/Compliance/Items/Individuals/Rerequest"
import IndividualsEdit from "./Individuals/AddEdit"
import IndividualsAdd from "./Individuals/AddEdit"
import IndividualsDelete from "./Individuals/Delete"
import { setIdPalOverride } from "../../../../../redux/actions/clients";
import { CREATED_RE_REQUEST_LIST, setStateToLocalStorage } from "../../../../../services/localStorageAPI";
import Loading from "../../../../../components/Loading";
import IndividualsReInvite from "./Individuals/ReInvite";

function ComplianceItemIndividuals(data) {

    const viewButtonStyle = {
        bgColor: "#ffffff",
        border: "1px solid #6E7997",
        color: "#6E7997",
        fontSize: "16px",
        fontWeight: 500,
        w: "100%",
        minH: "55px",
    };
        
    const { id } = data?.id
    const dispatch = useDispatch();  
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState([])    
    const [menuChanged, setMenuChanged] = useState(false);
    const [createdReRequestList] = useState(null);    
    const createdReRequestClient = createdReRequestList?.find((client) => client.id === id);
    const dispatchIsDataLoading = useSelector((state) => state.clients.isDataLoading);
    const dispatchItem = useSelector((state) => state.clients.getById);
    const [openedIndividualsMain, setOpenedIndividualsMain] = useState(false);
    const [openedClients, setOpenedClients] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(createdReRequestClient?.createdReRequest || false);
    const [loadingApproveAll, setLoadingApproveAll] = useState([]);  
    const dispatchSetIdPalOverride = (submissionId) => dispatch(setIdPalOverride(submissionId));
    const toggleClient = (id) => {
        const clone = { ...openedClients };
        clone[id] = !clone[id];
        setOpenedClients(clone);
      };
    const toggleIndividualsMain = (id) => {
        setOpenedIndividualsMain(!openedIndividualsMain);
    };
    var individualsComplete = true;
    if (!dispatchIsDataLoading) {
      if (dispatchItem?.data) {
        dispatchItem?.data?.corporate_children?.map(({ id, submissions }) => {
          // eslint-disable-next-line
          if (submissions[0]?.approved !== 1) {
            individualsComplete = false;
          }
          return null;
        });
      }
    }

    const handleApproveAll = (submissionsId, index = 0) => () => {
        if (buttonDisabled) return;
        setButtonDisabled(true);  
        var localLoadingApprovaAll = loadingApproveAll    
        localLoadingApprovaAll[index] = true
        setLoadingApproveAll(localLoadingApprovaAll);
    
        dispatchSetIdPalOverride(submissionsId)
          .then(() => {
            navigate(0);
            const indexApprovedClient = createdReRequestList.findIndex(
              (client) => client.id === id
            );
            createdReRequestList.splice(indexApprovedClient, 1);
            setStateToLocalStorage(
              CREATED_RE_REQUEST_LIST,
              JSON.stringify(createdReRequestList)
            );
          })
          .catch((err) => console.warn(err));
    };    
    
    var toggleOpen = (index) => {
      setMenuChanged(!menuChanged)
      var menuOpenLocal = menuOpen;
      if (menuOpenLocal[index] === "true") {
        menuOpenLocal[index] = "false"
          // loadData()
      }
      else {
        menuOpenLocal = [];
        menuOpenLocal[index] = "true"
      }
      setMenuOpen( menuOpenLocal );
    }
    var closeAll = (e) => {
      if (e.target.id === "dropdownMenuButton") {
        return
      }
      setMenuOpen([]);
    }  

    useEffect(() => {
    }, [menuOpen, menuChanged]);  
    
    var openStyleMain = {
      transform: 'rotate(270deg)', // right                                                                        
      margin: '-15px',
      marginTop: '-25px',
      marginRight: '0px',
    } 
    if (openedIndividualsMain) {
        openStyleMain = {
          transform: 'rotate(0deg)', // down
          margin: '-15px',
          marginTop: '-10px',
        }
    }

    return (    
<>
      <GridItem key={data?.number}>
        {individualsComplete ? (
          <img alt="circle complete" src="/assets/img/circle-complete.png" style={{ width: 50 }} />
        ) : (
          <img alt={`circle ${data?.number}`} src={`/assets/img/circle-${data?.number}.png`} style={{ width: 50 }}/>
        )}
      </GridItem>

      <GridItem className={openedIndividualsMain ? 'caseview__compliance-item-box-open':'caseview__compliance-item-box'} 
                mt={1} 
                key={data?.title}
                onClick={(e) => closeAll(e)}
                style={{minWidth: '800px'}}
        >
        <GridItem>
          <Heading as={'h3'} fontWeight={'700'} fontSize={'18px'} 
            onClick={() => { toggleIndividualsMain() }} style={{cursor: 'pointer', width: '275px'}}>
            <ExpandDownIcon cursor={"pointer"} style={openStyleMain} fill={"none"} w={8} h={8}/> 
            {data?.title}
          </Heading>
        </GridItem>
      </GridItem>

    {openedIndividualsMain ? (
      <>
        <GridItem></GridItem>
        <GridItem onClick={(e) => closeAll(e)} key={'individuals'}>

        <TableContainer backgroundColor={"#F9FBFC"} color={"#1A1E29"}>
          <Table variant="simple">
            <Tbody>
                <>
                  {dispatchItem?.data?.corporate_children?.map(
                    ({
                      id,
                      email,
                      first_name,
                      last_name,
                      submissions,
                      id_pal_document,
                      proof_of_residence,
                      additional_documents,
                      is_main_contact,
                      is_company_secretary,

                    }, index) => {
                      id = id.toString();
                      var openStyle = {
                        transform: 'rotate(270deg)', // right                                                                        
                        margin: '-15px',
                        marginTop: '-25px',
                        marginLeft: '10px',
                      } 
                      if (openedClients[id]) {
                          openStyle = {
                            transform: 'rotate(0deg)', // down
                            margin: '-15px',
                            marginTop: '-10px',
                          }
                      }
                      var menuClass = `dropdown-menu${menuOpen[index]==="true" ? " show" : ""}`;
                      var individualCellClass = `individuals__cell${loadingApproveAll[index] ? "-loading" : ""}`;
                      var name = first_name + ' ' + last_name
                      if (name.length > 20) {
                        name = name.substring(0,20) + "..."
                      }

                      return (
                        <>
                          <Tr key={id}>
                            <Td
                              cursor={"pointer"}
                              onClick={() => {
                                toggleClient(id);
                              }}
                              style={{width: '1px', 
                              textAlign: 'right', 
                              padding: '0px',
                              margin: '0px'
                            }}
                            key={id + '-1'}
                            >
                              <ExpandDownIcon
                                fill={"none"}
                                w={8}
                                h={8}
                                style={openStyle}
                              />
                            </Td> 
                            <Td fontSize={"16px"} minWidth={"95px"} style={{cursor:'pointer'}}
                              key={id + '-2'}
                              onClick={() => {
                                toggleClient(id);
                              }}>                              
                              { name }
                            </Td>
                            <Td fontSize={"16px"} color={"#B4BAC9"} key={id + '-3'}>
                              {email}
                            </Td>
                            {data?.corporate ? 
                            <Td fontSize={"16px"} color={"#B4BAC9"}>
                              {is_main_contact ? "Main Contact" 
                                : 
                                  is_company_secretary ? "Secretary" 
                                  :
                                  "Shareholder" 
                            }
                            </Td>
                            : ''}
                            <Td key={id + '-4'}>

                            <img src={IconActions} style={{width: '20px', height: '20px'}} 
                              onClick={() => toggleOpen(index)}
                              className="dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              alt=""
                            />

                            <div className={menuClass} aria-labelledby="dropdownMenuButton" style={{marginLeft: '-50px', width: '155px'}}>
                              
                              {submissions.length > 0 && !loadingApproveAll[index] && submissions[0].approved !== 1 && 
                              (<><Link className="dropdown-item"
                                                onClick={handleApproveAll(
                                                  submissions[0].id,
                                                  index
                                                )}>
                                  <img src={IconTick} className="individuals__menu-icon" alt=""/> Approve
                                </Link>
                                <hr className="individuals_menu-hr1" />
                                </>)}

                              <IndividualsEdit id={id} mode="edit" firstName={first_name} lastName={last_name} email={email}/>

                              {!loadingApproveAll[index] && 
                              ( <IndividualsReRequest id={id}/> )}

                              {!is_main_contact && !is_company_secretary ?
<>
                              <hr className="individuals_menu-hr2" />
                              <IndividualsDelete id={id} />
</> : '' }
                            </div>

                            </Td>
                          </Tr>
                          {openedClients[id] ? (
                            <Tr>
                              <Td 
                                colSpan={data?.corporate ? 4:3}
                                className={individualCellClass}>
                                {loadingApproveAll[index] ? (
                                  <><Loading /></>
                                ) : (<>

                                {submissions.length === 0 ? (
                                  <Flex>
                                    <ComplianceItem
                                      fullWidth
                                      title={"ID Document "}
                                      subtitle={"Not yet submitted"}
                                      isVerified={false}
                                      status={"0"}
                                      nestedComponent={
                                        <Button {...viewButtonStyle} disabled mt={2}>
                                          View Document
                                        </Button>
                                      }
                                    />

                                    <ComplianceItem
                                      fullWidth
                                      title={"Proof of Address"}
                                      subtitle={"Not yet submitted"}
                                      isVerified={false}
                                      status={"0"}
                                      nestedComponent={
                                        <Button {...viewButtonStyle} disabled mt={2}>
                                          View Document
                                        </Button>
                                      }
                                    />
                                  </Flex>
                                ) : (
                                  <>
                                    <Flex w={"100%"}>
                                      {Object.values(
                                        id_pal_document
                                      ).length > 0 && (
                                        <ComplianceItem
                                          fullWidth
                                          title={"ID Document"}
                                          data={id_pal_document}
                                          submissionId={submissions[0]?.id}
                                        />
                                      )}

                                      {Object.values(
                                        proof_of_residence
                                      ).length > 0 && (
                                        <ComplianceItem
                                          fullWidth
                                          title={"Proof of Address"}
                                          data={proof_of_residence}
                                          submissionId={submissions[0]?.id}
                                          type={"pdf"}
                                        />
                                      )}
                                    </Flex>

                                    {Object.values(
                                      additional_documents
                                    ).length > 0 && (
                                      <ComplianceItem
                                        fullWidth
                                        title={"Additional Documents"}
                                        data={additional_documents}
                                        submissionId={submissions[0]?.id}
                                        type={"pdf"}
                                      />
                                    )}
                                  </>
                                )}
                              </>)}

                              </Td>
                            </Tr>
                          ) : null}
                        </>
                      );
                    }
                  )}
                </>
            </Tbody>
          </Table>
        </TableContainer>
        
        <IndividualsAdd id={data?.id} mode="add" corporate={data?.corporate} />
        {data?.corporate ? <IndividualsReInvite id={data?.id}/> : '' }

      </GridItem>
      </>
    ) : (
      ""
    )}
</>
)}
export default ComplianceItemIndividuals;