import React, {useState} from 'react';
import {Button, Flex, Text} from "@chakra-ui/react";
import {Document, Page} from "react-pdf";

const ViewAPdfFile = ({data, width=450,  flex= false}) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);  
  const options = {};

  const downloadButtonStyle = {
    bgColor: '#ffffff',
    border: '1px solid #6E7997',
    color: '#6E7997',
    fontSize: '16px',
    fontWeight: 500,
    w: '100%',
    minH: '55px',
  } 

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function downloadFile() {

    // convert base64 pdf data info pdf file
    var binary = atob(data.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    var blob = new Blob( [view], { type: "application/pdf" });

    // automatically download the file
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Document.pdf`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);    
  }

  return (
    <>

      {!flex && numPages && numPages > 1 ? <>
        <Text> Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</Text>
        <Button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </Button>
        <Button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </Button>
      </> : flex && numPages && numPages > 1 ? <Flex justifyContent={'center'}>
        <Flex w={'300px'} justifyContent={'space-around'} alignItems={'center'}>
        <Text> Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</Text>
        <Button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
        >
          Previous
        </Button>
        <Button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
        >
          Next
        </Button>
        </Flex>
      </Flex> : null}
      <Document
        file={`data:application/pdf;base64,${data}`}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        <Page pageNumber={pageNumber}
              width={width}
              renderTextLayer={false}
              renderAnnotationLayer={false}
        >
        </Page>
      </Document>

      <Button
        {...downloadButtonStyle}
        width={'auto'}
        onClick={() => downloadFile()}
      >
        Download File
      </Button>

    </>
  );
};

export default ViewAPdfFile;
