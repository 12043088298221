import {makeRequest, API_URL} from "../../services/request";
import {
  SET_DATA_LOADING_FALSE,
  SET_DATA_LOADING_TRUE,
  ACCOUNTS_FORM_LIST_DOWNLOADED,
  ACCOUNTS_FORM_LIST_NOT_DOWNLOADED, ACCOUNTS_FORM_CHANGE_STATUS,
} from "./index";

export const getFormsList = (url = `account-form`) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}${url}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: ACCOUNTS_FORM_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: ACCOUNTS_FORM_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const markProcessed = ({id, status}) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}account-form/${id}/mark-processed`, {status: status}, 'POST').then((res) => {
      if (res.ok) {
        return res.json()
            .then(data => {
              return dispatch({type: ACCOUNTS_FORM_CHANGE_STATUS, payload: {id: data.data.id, status: data.data.status}});
            })
      } else {
        return res.json().then((err) => {
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getAccountFormSecret = (id) => {
  return makeRequest(`${API_URL}account-form/${id}/export`).then((res) => {
    if (res.ok) {
      return res.json()
          .then(data => {
            return data.data;
          })
    } else {
      return res.json().then((err) => {
        return Promise.reject(err)
      })
    }
  })
}

export const setLoadingFlag = (flag = true) => {
  return (flag
      ? {type: SET_DATA_LOADING_TRUE}
      : {type: SET_DATA_LOADING_FALSE}
  );
}

