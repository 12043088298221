import React, { useEffect, useState, useRef } from "react";

const ImageZoom = ({
    src,
    width,
    height,
    magnifieWidth = 500,
    zoomLevel = 2
}) => {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [imageHeight, setImageHeight] = useState(false);

    const containerRef = useRef(null);

    useEffect(() => {
        const containerHeight = containerRef.current.clientHeight;

        setImageHeight(containerHeight);
    }, [showMagnifier])

    return (
        <div
            style={{
                position: "relative",
                height: height,
                width: width
            }}
        >
            <div
                style={{
                    display: showMagnifier ? "" : "none",
                    position: "absolute",
          
                    pointerEvents: "none",
                    height: `25%`,
                    width: `${magnifieWidth / 4}px`,
                    top: `${y - imageHeight / 8}px`,
                    left: `${x - magnifieWidth / 8}px`,
                    opacity: "0.4",
                    backgroundColor: "white",
                    backgroundRepeat: "no-repeat",
          
                    backgroundSize: `${imgWidth * zoomLevel}px ${
                        imgHeight * zoomLevel
                    }px`,
          
                    backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
                    backgroundPositionY: `${-y * zoomLevel + imageHeight / 2}px`
                }}
            />
            <img
                ref={containerRef}
                src={src}
                style={{ height: height, width: width }}
                onMouseEnter={(e) => {
                    const elem = e.currentTarget;
                    const { width, height } = elem.getBoundingClientRect();
                    setSize([width, height]);
                    setShowMagnifier(true);
                }}
                onMouseMove={(e) => {
                    const elem = e.currentTarget;
                    const { top, left } = elem.getBoundingClientRect();
          
                    const x = e.pageX - left - window.pageXOffset;
                    const y = e.pageY - top - window.pageYOffset;
                    setXY([x, y]);
                }}
                onMouseLeave={() => {
                    setShowMagnifier(false);
                }}
                alt={"img"}
            />
    
            <div
                style={{
                    display: showMagnifier ? "" : "none",
                    position: "absolute",
          
                    pointerEvents: "none",
                    height: imageHeight,
                    width: `${magnifieWidth}px`,
                    top: '0',
                    right: '0',
                    opacity: "1",
                    backgroundColor: "transparent",
                    backgroundImage: `url('${src}')`,
                    backgroundRepeat: "no-repeat",
          
                    backgroundSize: `${imgWidth * zoomLevel}px ${
                      imgHeight * zoomLevel
                    }px`,

                    transform: 'translateX(100%)',
          
                    backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
                    backgroundPositionY: `${-y * zoomLevel + imageHeight / 2}px`,
                  }}
            ></div>
        </div>
    );
}

export default ImageZoom;
