import {

  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack, Text
} from "@chakra-ui/react";

import AlertComponent from "./AlertComponent";
import * as React from "react";
import HeaderFormLogo from "./HeaderFormLogo";
import BackButton from "./BackButton";
import {useNavigate} from "react-router-dom";


const ForgotForm = ({formik, dispatchErrorMessage}) => {

  const navigate = useNavigate();

  return (<>
      <BackButton
        tabIndex={9}
        onClickHandler={() => {
          navigate(`/signin`)
        }}
        mt={'20px'}
      />
      <Container
        maxW='2xl'
        py={{
          base: '12', md: '24',
        }}
        px={{
          base: '0',
        }}
      >

        <Stack spacing="8">
          <Stack as={Flex} spacing="6" alignItems="center">
            <HeaderFormLogo/>
            <Stack
              spacing={{
                base: '7', md: '3',
              }}
              textAlign="center"
            >
              <Heading
                marginTop={'46px'}
                fontSize={'54px'}
                fontWeight={'bold'}
                color={'#343B55'}
              >
                Forgot Password
              </Heading>
              <Text
                fontSize={'18px'}
                marginTop={'15px'}
              >
                Please fill in the email you have used to create Law Comply <br/>
                account and we will send you a 5-digit numeric
                code
              </Text>
            </Stack>
          </Stack>
          <Box
            py={{
              base: '0', sm: '2',
            }}
            px={{
              base: '4', sm: '10',
            }}
          >

            {dispatchErrorMessage && (<AlertComponent
              message={dispatchErrorMessage}
            />)}

            <Stack spacing="6">

              <form onSubmit={formik.handleSubmit}>
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel htmlFor="email" fontSize={'18px'}>Email</FormLabel>
                    <Input
                      tabIndex={2}
                      id="email"
                      type="email"
                      required
                      placeholder={'Enter your email'}
                      fontSize={'18px'}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  spacing="6"
                  text-align={'right'}
                  py={'32px'}>
                  <Button
                    tabIndex={6}
                    type="submit"
                    fontSize={'18px'}
                    variant="primary">Submit</Button>
                </Stack>
              </form>

            </Stack>

          </Box>
        </Stack>
      </Container></>
  )
}

export default ForgotForm;
