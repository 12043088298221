export const setToken = (token) => {
  localStorage.setItem('token', token);
  localStorage.setItem('lastLoginTime', new Date(Date.now()).getTime());
};

export const getToken = () => {
  // const now = new Date(Date.now()).getTime()
  // const anHour = 1000 * 60 * 60;
  // const timeSinceLastLogin = now - localStorage.getItem('lastLoginTime');
  // if (timeSinceLastLogin < anHour) {
  //   return localStorage.getItem('token')
  // }
  return localStorage.getItem('token')
}