var questionsStatus = {
  "title" : "Status of Client",
  "questions" : 
    [
      { "key" : "status_1",
        "question" : "How well do you know your client and background?"},
      { "key" : "status_2",
        "question" : "Is your client known to you personally / existing client, for new business relationships?"},     
      { "key" : "status_3",
        "question" : "Is your client a PEP?"},
      { "key" : "status_4",
        "question" : "Is your client been introduced to you by a through a third party? Is the instruction from your client channelled through a third party? If so, why? Are you aware of your client having any links to criminality?"},
    ]
};

var questionsFace = {
  "title" : "Face-to-Face Contact",
  "questions" : [
    { "key" : "face_1",
      "question" : "Have you met with your client face-to-face or is it a non-face-to-face transaction? Non-face-to-face is a factor suggesting potentially higher risk." },
    { "key" : "face_2",
      "question" : "If non-face-to-face, are you comfortable there is a legitimate reason for this and what is the reason?" },
  ]
};

var questionsLocation = {
  "title" : "Location of Client",
  "questions" : [
    { "key" : "location_1",
      "question" : "Where is your client based? Locally / Ireland / EU / other international location?" },
    { "key" : "location_2",
      "question" : "Is your client based / resident / linked to a high-risk jurisdiction / high risk third word country?" }
  ]
};

var questionsId = {
  "title" : "ID & Address Verification",
  "questions" : [
    { "key" : "id_1",
      "question" : "Has your client provided acceptable standard ID and address Verification?" },
    { "key" : "id_2",
      "question" : "Has your client provided acceptable non-standard ID and address verification?" },
    { "key" : "id_3",
      "question" : "Have you been able to confirm the authenticity / professional status of the certifier of any copies of ID / address verification?" },
    { "key" : "id_4",
      "question" : "Has your client been cooperative in the process or have they delayed providing ID and address verification / appeared reluctant to do so?" },
  ]
};

var questionsProfile = {
  "title" : "Financial Profile of Client",
  "questions" : [
    { "key" : "profile_1",
      "question": "Does the stated source of wealth / source of funds and the amount of money involved stack up with what you know of your client, for example given their age and occupation?" }
  ]
};

var questionsType = {
  "title" : "AML-REGULATED LEGAL SERVICE RISK. TYPE OF LEGAL SERVICE",
  "questions" : [
    { "key" : "type_1",
      "question" : "Could the type of transaction be used for the purposes of money laundering or is it at a higher risk of money laundering? e.g. Will/Power of Attorney is a lower risk. Estate Agency/Conveyancing/Commercial Property is a higher risk" },

    { "key" : "type_2",
      "question" : "Does the transaction make sense or is it overly complex given the underlying nature of the business being conducted?" },

    { "key" : "type_3",
      "question" : "Does it make sense that your client has asked your firm to carry out this type of transaction? (e.g. is it within your area of expertise/local geographical area?)" },
  ]
};

var questionsValue = {
  "title" : "VALUE OF LEGAL SERVICE/ASSET/TRANSACTION",
  "questions" : [
    { "key" : "value_1",
      "question" : "Does the value of the transaction appear to fall within the financial means of your client, given their income and savings?" }
  ]
};

var questionsSource = {
  "title" : "SOURCE OF FUNDS",
  "questions" : [
    { "key" : "source_1",
      "question" : "Is the source of funds clear and identifiable?" },
    { "key" : "source_2",
      "question" : "Are funds coming from a recognised financial/credit institution (e.g. a loan or mortgage) or are they personal funds? If no loan or mortgage, enquire into the source of wealth.  It may be prudent to ask for some supporting evidence to confirm the information provided and then reconsider the ML/TF risks involved. (If personal funds has supporting evidence been provided)" },
    { "key" : "source_3",
      "question" : "Is any funding coming from overseas? From where? From who? Connection to client?" },
    { "key" : "source_4",
      "question" : "Are any of the funds being paid by a third party otherwise unconnected to the transaction?" },
    { "key" : "source_5",
      "question" : "Does your client seek to change the source of funds at the last minute?" },
    { "key" : "source_6",
      "question" : "Has your client paid excess funds into your client account? Why/How?" },
    { "key" : "source_7",
      "question" : "Is it being proposed that funds come from outside the EU and gain entry to the EU financial system for the first time via your client account?" },
    { "key" : "source_8",
      "question" : "Could the client be trying to route funds through the solicitor without an underlying transaction?" },
  ]
};

var questionsDestination = {
  "title" : "DESTINATION OF FUNDS",
  "questions" : [
    { "key" : "destination_1",
      "question" : "Has your client requested that proceeds of a transaction be paid to someone other than a lender or themselves?" },
    { "key" : "destination_2",
      "question" : "Are proceeds of a transaction to be paid to an overseas account?" },
  ]
};

var questionsDyt = {
  "title" : "Document Your Thoughts",
  "questions" : 
    [
      { "key" : "dyt_1",
        "question": "Please indicate your overall risk level for this case:",
        "options" : ["low", "medium", "high"] },
      { "key" : "dyt_2",
        "question": "Please document the reasons for your risk assessment choice." },
      { "key" : "dyt_3",
        "question": "Please document the CDD (Customer Due Diligence) measures to be applied, have been applied and any compliance decisions taken. If enhanced CDD is applied please explain." },
      { "key" : "dyt_4",
        "question": "Optional question: If this is your interim or final DYT record. Has a review been undertaken and consideration given to any changes in this matter that might effect your initial risk assessment? Are there any changes that effect the risk level? Have there been any last minute changes that give you cause for concern?  (If no change, quick note stating such, signed and dated – this evidences that a review has been undertaken and consideration given)",
        "optional" : true }
    ]  
};

export { questionsStatus, questionsFace, questionsLocation, questionsId, questionsProfile };
export { questionsType, questionsValue, questionsSource, questionsDestination, questionsDyt };


