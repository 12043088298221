import Alert from "../../../components/Alert";
import { Box, Button, Flex, Heading, HStack, Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { UploadIconSecondary } from "../../../assets/icons/sprite"
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../../../components/Document";
import {  questionsStatus, questionsFace, questionsLocation, questionsId, questionsProfile,
  questionsType, questionsValue, questionsSource, questionsDestination, questionsDyt } 
  from '../../../constants/RiskAssessmentQuestions'

function RiskAssessmentView(props) {

    var title = ""; var riskForm = {};
    if (props.version === 1) { title = "Initial Risk Assessment"; riskForm = props.dispatchItem?.data?.riskForm }
    if (props.version === 2) { title = "Interim Risk Assessment"; riskForm = props.dispatchItem?.data?.riskForm2; }
    if (props.version === 3) { title = "Final Risk Assessment"; riskForm = props.dispatchItem?.data?.riskForm3 }
  
    return (
                              <Alert
                                isOpen={props.isRiskFormOpen}
                                onOpen={props.onRiskFormOpen}
                                onClose={props.onRiskFormClose}
                                header={title}
                                text={<>
  
                                  <HStack
                                      as={Flex}
                                      spacing={8}
                                      mb={6}
                                      mt={4}
                                      justifyContent={"space-between"}
                                    >
                                      <Box>
                                        <b>Risk status:</b>{" "}<br />
                                        { riskForm?.risk_status }
                                      </Box>
                                      <Box>
                                        <b>Submitted at:</b>{" "}<br />
                                        {riskForm
                                          ?.submitted_at &&
                                          new Date(
                                            riskForm?.submitted_at
                                          ).toUTCString()}
                                      </Box>
                                      <Box>
                                        <b>Submitted by:</b>{" "}<br />
                                        {riskForm?.user?.name}
                                      </Box>
                                      <Button
                                        rightIcon={
                                          <UploadIconSecondary
                                            width={"24px"}
                                            height={"24px"}
                                            fill={"none"}
                                          />
                                        }
                                        bgColor={"#4285F4"}
                                        color={"#fff"}
                                        _hover={{ bg: "#6d9ef5" }}
                                      >
                                        <PDFDownloadLink
                                          document={
                                            <MyDocument
                                              data={riskForm}
                                            />
                                          }
                                          fileName={"risk_assessment_" + props.version + ".pdf"}
                                        >
                                          {({ blob, url, loading, error }) =>
                                            loading
                                              ? "Loading document..."
                                              : "Export PDF"
                                          }
                                        </PDFDownloadLink>
                                      </Button>
                                    </HStack>
  
                                    <RiskAssessmentAnswers questions={questionsStatus} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsFace} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsLocation} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsId} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsProfile} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsType} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsValue} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsSource} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsDestination} riskForm={riskForm}/>
                                    <RiskAssessmentAnswers questions={questionsDyt} riskForm={riskForm}/>
  
                                    </>
                                }
                                action={null}
                                size={"6xl"}
                                contentPaddingY={0}
                              />
                            )
  }
  
  function RiskAssessmentAnswers(props) {
  
    var title = props.questions.title
    var questions = props.questions.questions;
  
    return (
  <>
    <Heading size={"md"} mt={8} mb={2}>{title}</Heading>
    <TableContainer>
      <Table width={"100%"}>
        <Tbody>
  
        {questions.map((question) => {
  
        return (
        <>
        <Tr key="{question.key}">
            <Td
              width={"450px"}
              whiteSpace={"pre-wrap"}
              overflowWrap={"anywhere"}
              pl={0}
              verticalAlign={"top"}
            >
              {question.question}
            </Td>
  
            <Td
              textTransform={"capitalize"}
              whiteSpace={"pre-wrap"}
              verticalAlign={"top"}
              overflowWrap={"anywhere"}
            >
              <Text
                mb={4}
                key="asdasd">
                {props.riskForm?.other_questions[question.key]}
              </Text>                                            
            </Td>
          </Tr>
  </>
        );
      })}
  
        </Tbody>
      </Table>
    </TableContainer>
  </>
    );
  
  }
  
  export default RiskAssessmentView;
  
  