import {
  ACCOUNTS_LIST_DOWNLOADED,
  ACCOUNTS_LIST_NOT_DOWNLOADED,
  ACCOUNTS_GET_BY_ID_SUCCESS,
  ACCOUNTS_SET_ERR_MESSAGE,
  SET_DATA_LOADING_TRUE,
  SET_DATA_LOADING_FALSE,
  SET_NEW_ACCOUNT_ERR_MESSAGE,
  SET_NEW_ACCOUNT_SUCCESS,
  SET_ACCOUNT_NEW_DATA,
  SET_ACCOUNT_NEW_DATA_FAILED,
} from "../actions";

const initialState = {
  data: null,
  errorMessage: null,
  isDataLoading: false,
  getById: null,
}

export default function accountsManageReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNTS_LIST_DOWNLOADED:
      return {
        ...state,
        data: action.payload,
        isDataLoading: false
      }
    case ACCOUNTS_LIST_NOT_DOWNLOADED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case SET_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }
    case SET_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }
    case ACCOUNTS_GET_BY_ID_SUCCESS:
      return {
        ...state,
        getById: action.payload,
        isDataLoading: false
      }
    case ACCOUNTS_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case SET_NEW_ACCOUNT_SUCCESS:
      return {
        ...state,
      }
    case SET_NEW_ACCOUNT_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_ACCOUNT_NEW_DATA:

      return {
        ...state,
        data: {
          meta: state.data.meta, data: state.data.data.map(
            (content, i) => state.data.data.findIndex((x) => x.id === action.payload?.data?.id) === i
              ? action.payload.data
              : content
          ), result: state.data.result
        },
        getSolicitorById: action.payload,
        errorMessage: null,
        isDataLoading: false
      }
    case SET_ACCOUNT_NEW_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }


    default:
      return state
  }
}
