import {
  AUDITOR_LIST_DOWNLOADED,
  AUDITOR_LIST_NOT_DOWNLOADED,
  SET_DATA_LOADING_TRUE,
  SET_DATA_LOADING_FALSE,
  AUDITOR_ACTIVATE,
  AUDITOR_DEACTIVATE,
  AUDITOR_GET_BY_ID_SUCCESS,
  AUDITOR_SET_ERR_MESSAGE,
  SET_AUDITOR_NEW_DATA,
  SET_AUDITOR_NEW_DATA_FAILED,
  AUDITOR_DELETE_FAILED, AUDITOR_DELETE_SUCCESS,
  SET_NEW_AUDITORS_ERR_MESSAGE
} from "../actions";

const initialState = {
  data: null,
  errorMessage: null,
  isDataLoading: false,
  getById: null
}

export default function auditorManageReducer(state = initialState, action) {
  switch (action.type) {

  case SET_NEW_AUDITORS_ERR_MESSAGE:
    return {
      ...state,
        errorMessage: action.payload,
        isDataLoading: false
    }

    case AUDITOR_LIST_DOWNLOADED:
      return {
        ...state,
        data: action.payload,
        isDataLoading: false
      }
    case AUDITOR_LIST_NOT_DOWNLOADED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case AUDITOR_ACTIVATE:
      return {
        ...state
      }
    case AUDITOR_DEACTIVATE:
      return {
        ...state
      }

    case AUDITOR_DELETE_SUCCESS:
      return {
        ...state,
        date: state.data.data.filter((el) => el.id !== action.payload)
      }

    case AUDITOR_DELETE_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case SET_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }

    case SET_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }

    case SET_AUDITOR_NEW_DATA:

      return {
        ...state,
        data: {
          meta: state.data.meta, data: state.data.data.map(
            (content, i) => state.data.data.findIndex((x) => x.id === action.payload?.data?.id) === i
              ? action.payload.data
              : content
          ), result: state.data.result
        },
        getSolicitorById: action.payload,
        errorMessage: null,
        isDataLoading: false
      }

    case SET_AUDITOR_NEW_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case AUDITOR_GET_BY_ID_SUCCESS:
      return {
        ...state,
        getById: action.payload,
        isDataLoading: false
      }

    case AUDITOR_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }

    default:
      return state
  }
}
