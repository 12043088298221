import {
  Box, Button, Container, FormControl, FormLabel, Grid, GridItem, Heading, HStack, Input, Stack, Text,
} from "@chakra-ui/react";

import * as React from 'react';
import {
  sendVerificationCode,
  setFirmErrorMessage,
  signUpFirm
} from "../../redux/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import AlertComponent from "../../components/AlertComponent";
import {useFormik} from "formik";
import {useState, useEffect} from "react";
import {PasswordField} from "../../components/PasswordField";

import EnterCode from "../../components/EnterCode";
import bgImageX2 from '../../assets/img/firm-signin-bg@x2.png'
import HeaderFormLogo from "../../components/HeaderFormLogo";

const FirmSignUp = () => {

  const dispatch = useDispatch();
  const dispatchErrorMessage = useSelector(state => state.firmAuth.errorMessage)

  const dispatchSendVerificationCode = (credentials) => dispatch(sendVerificationCode(credentials));
  const dispatchSetFirmErrorMessage = (credentials) => dispatch(setFirmErrorMessage(credentials));
  const dispatchSignUpFirm = (credentials) => dispatch(signUpFirm(credentials));

  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [hideCodeText, setHideCodeText] = useState(false);

  useEffect(() => {
    dispatch(setFirmErrorMessage(null))
  }, [dispatch]);

  const handleSubmit = async values => {

    if (values.password !== values.confirmPassword) {
      dispatchSetFirmErrorMessage({
        result: 'error',
        message: 'The password confirmation does not match.'
      })
      return
    }

    if (values.firmName.replaceAll(' ', '').length === 0) {
      dispatchSetFirmErrorMessage(
        {
          result: 'error',
          message: 'The name must be not empty.'
        })
      return
    }

    if (!values.firmName) {
      dispatchSetFirmErrorMessage({
          result: 'error',
          message: 'Please fill out name field.'
        }
      )
      return
    }

    if (values.password.replaceAll(' ', '').length === 0) {
      dispatchSetFirmErrorMessage(
        {
          result: 'error',
          message: 'The password must be not empty.'
        })
      return
    }

    if (values.password.length < 8) {
      dispatchSetFirmErrorMessage(
        {
          result: 'error',
          message: 'The password must be at least 8 characters.'
        })
      return
    }

    step === 1 ?
      dispatchSendVerificationCode({email: values.email, checkEmailInUse: true}).then(() => {
        setStep(2);
        return
      })
        .catch(
          // Also another method for receiving err
          (err) => err.message ? console.warn(err.message) : console.warn('Error')
        )
      :
      dispatchSignUpFirm(
        {
          name: values.firmName,
          email: values.email,
          password: values.password,
          password_confirmation: values.confirmPassword,
          code: values.code
        }
      ).then(() => navigate('/firm/')).catch(
        // Also another method for receiving err
        (err) => err.message
          ? err.message === 'Code expired after 3 attempts, please request a new code.'
            ? setHideCodeText(true)
            : setHideCodeText(false)
          : console.warn('Error')
      )

  }


  const formik = useFormik({
    initialValues: {
      firmName: '',
      email: '',
      password: '',
      confirmPassword: '',
      code: ''
    },
    onSubmit: handleSubmit
  })

  return (step === 1
    ? <Grid
      templateColumns='50.8% 49.2%'
      minWidth={'100vw'}
      // minHeight={'100vh'}
      m={0}
      p={0}
    >
      <GridItem
        // minHeight='100vh'
      >
        <Container
          maxW='2xl'
          py={{
            base: '12', md: '24',
          }}
          px={{
            base: '0',
          }}
        >

          <Stack spacing="8">
            <HeaderFormLogo/>
            <Heading
              marginTop={'46px'}
              fontSize={'54px'}
              fontWeight={'bold'}
              color={'#343B55'}
              textAlign={'center'}
            >
              Sign Up
            </Heading>
            <Box
              py={{
                base: '0', sm: '3',
              }}
              px={{
                base: '4', sm: '10',
              }}
            >

              {dispatchErrorMessage && (<AlertComponent
                message={dispatchErrorMessage}
              />)}

              <Stack spacing="6">

                <form
                  onSubmit={formik.handleSubmit}
                >
                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel
                        htmlFor="firmName"
                        fontSize={'18px'}>
                        Law Firm Name
                      </FormLabel>
                      <Input
                        tabIndex={1}
                        id="firmName"
                        type="text"
                        required
                        placeholder={'Enter law firm name'}
                        fontSize={'18px'}
                        onChange={formik.handleChange}
                        value={formik.values.firmName}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="email" fontSize={'18px'}>Email</FormLabel>
                      <Input
                        tabIndex={2}
                        id="email"
                        type="email"
                        required
                        placeholder={'Enter email'}
                        fontSize={'18px'}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                    </FormControl>


                    <PasswordField
                      placeholder={'Enter password'}
                      fontSize={'18px'}
                      onChange={formik.handleChange}
                      tabIndex={3}
                      iconButtonTabIndex={4}
                      required
                    />

                    <PasswordField
                      placeholder={'Enter password'}
                      label={'Confirm password'}
                      fontSize={'18px'}
                      onChange={formik.handleChange}
                      name={'confirmPassword'}
                      tabIndex={5}
                      iconButtonTabIndex={6}
                      required
                    />

                  </Stack>


                  <Stack spacing="6" text-align={'right'} py={'20px'}>>
                    <Button
                      tabIndex={7}
                      type="submit"
                      fontSize={'18px'}
                      variant="primary">Submit</Button>
                  </Stack>

                  <HStack justify="space-between" justifyContent={'center'} py={'20px'}>
                    <Text><Button
                      as={Link}
                      to={'/signin/'}
                      tabIndex={8}
                      variant="link"
                      fontSize={'18px'}
                      color='#4285F4'
                      size="sm">
                      Sign in
                    </Button> <Text as={'span'}
                                    color='#4285F4'
                                    fontSize={'18px'}
                    >to existing account.</Text></Text>
                  </HStack>

                </form>

              </Stack>
            </Box>
          </Stack>
        </Container>
      </GridItem>

      <GridItem
        minHeight='100vh'
        bg='blue.500'

        backgroundImage={bgImageX2}
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
        backgroundPosition={'center center'}
      />

    </Grid>
    : <EnterCode
      dispatchErrorMessage={dispatchErrorMessage}
      setStep={setStep}
      formik={formik}
      hideCodeText={hideCodeText}
      setHideCodeText={setHideCodeText}
      dispatchSendVerificationCode={dispatchSendVerificationCode}
      dispatchSetFirmErrorMessage={dispatchSetFirmErrorMessage}
    />)
}

export default FirmSignUp;
