import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Textarea,
  Select, Checkbox
} from "@chakra-ui/react";

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCaseOpeningForm } from "../redux/actions/clients";
import BackButton from "./BackButton";
import AlertComponent from "./AlertComponent";
import { useFormik } from "formik";

const CaseOpeningForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectErrorMessage = useSelector((state) => state.clients.errorMessage);
  const dispatchSetCaseOpeningForm = (data) =>
    dispatch(setCaseOpeningForm(data));
  const [solicitorName, setSolicitorName] = useState(null);
  const [solicitorId, setSolicitorId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [formValues, setFormValues] = useState({
    caseReferenceNumber: "",
    caseDescription: "",
    solicitorName: "",
    notify: false
  });
  const caseDataState = useSelector((state) => state.clients.getById);

  const handleSubmit = async (values, method = "post") => {

    var nameToSubmit = values.solicitorName;
    if (currentUser?.type !== "solicitor" && dispatchFirmUser) {
      nameToSubmit = solicitorName;
    }

    dispatchSetCaseOpeningForm({
          client_id: id,
          id_assigned: solicitorId,
          case_reference_number: values.caseReferenceNumber,
          case_description: values.caseDescription,
          solicitor_name: nameToSubmit,
          notify: values.notify,
        })
          .then(() => {
            setStep(2);
          })
          .catch(
            // Also another method for receiving err
            (err) =>
              err.message ? console.log(err.message) : console.warn("Error")
          )
  };

  const dispatchFirmUser = useSelector((state) => state.firmAuth.currentUser);
  const dispatchSolicitorUser = useSelector(
    (state) => state.solicitorAuth.currentUser
  );

  let formik = useFormik({
    initialValues: formValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (dispatchSolicitorUser?.type === "solicitor") {
      setCurrentUser(dispatchSolicitorUser);
    } else if (dispatchFirmUser) {
      setCurrentUser(dispatchFirmUser);
    }
    if (caseDataState) {
      const formData = caseDataState.data.form;
      if (formData) {
        const values = {
          caseReferenceNumber: formData.case_reference_number,
          caseDescription: formData.case_description,
          solicitorName: formData.solicitor_name,
        };
        setSolicitorId(formData.id_assigned);
        setFormValues(values);
      }
    }
  }, [caseDataState, dispatchSolicitorUser, dispatchFirmUser]);

  const [step, setStep] = useState(1);
  const [originalSolicitorId, setOriginalSolicitorId] = useState(0)

  const inputStyles = {
    type: "text",
    required: true,
    _placeholder: { fontSize: { base: 12, md: 14, lg: 16 } },
    placeholder: "Answer",
    fontSize: "16px",
  };

  var checkBoxstyles = {
    type: "checkbox",
    marginTop: 1,
    paddingX: 1
  }

  const updateSolicitorName = (e) => {
    setSolicitorId(e.target.value);
    setOriginalSolicitorId(e.target.value)
    setSolicitorName(
      currentUser?.solicitors?.find(
        (solicitor) => solicitor.id === e.target.value
      )?.name
    );
  };

  var url = window.location.href;
  if (url.includes("firm/solicitors")) {
    var urlArray = url.split("/").slice(3)
    urlArray.pop(); urlArray.pop();
    if (url.includes("corporate")) {
      urlArray.pop();
    }
    url = "/" + urlArray.join("/")
  }
  else {
    url = "/solicitor/cases"
  }
  
  return (
<>    
      {step === 1 ? (
<>
      <Flex flexDirection={"column"} alignItems={"flex-start"}>
        <BackButton
          tabIndex={9}
          onClickHandler={() => {
            navigate(-1);
          }}
          ml={"-17px"}
          mb={"37px"}
        />

        {selectErrorMessage && <AlertComponent message={selectErrorMessage} />}

        <Heading
          position={"relative"}
          textAlign="center"
          display={"flex"}
          // alignItems={'flex-end'}
          fontSize="32px"
          fontWeight="700"
          paddingBottom={3}
        >
          Case Information
        </Heading>
      </Flex>

        <Box>
          <form
            onSubmit={async (evt) => {
              evt.preventDefault();
              await handleSubmit(formik.values, "post");
            }}
          >
            <Stack spacing="5" width={"50%"}>
              {selectErrorMessage && (
                <AlertComponent message={selectErrorMessage} />
              )}

              <FormControl>
                <FormLabel htmlFor="email" fontSize={"18px"}>
                  Case Reference Number
                </FormLabel>
                <Input
                  tabIndex={20}
                  id="caseReferenceNumber"
                  type="text"
                  required
                  placeholder={"Enter case reference number"}
                  fontSize={"18px"}
                  onChange={formik.handleChange}
                  value={formik.values.caseReferenceNumber}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="caseDescription" fontSize={"18px"}>
                  Case Description
                </FormLabel>
                <Textarea
                  tabIndex={21}
                  id="caseDescription"
                  {...inputStyles}
                  onChange={formik.handleChange}
                  value={formik.values.caseDescription}
                  placeholder={"Enter case description"}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="solicitorName" fontSize={"18px"}>
                  Solicitor Name
                </FormLabel>

                  <Select
                    id="solicitorName"
                    name="solicitorName"
                    onChange={updateSolicitorName}
                    value={solicitorId}
                    required
                  >
                    {!formik.values.solicitorName && <option></option>}
                    {currentUser?.solicitors?.map(({ id, name, email }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
              </FormControl>

              <FormControl>
              <Checkbox
                id="notify"
                name="notify"
                onChange={formik.handleChange}
                value={formik.values.notify}
                {...checkBoxstyles}
              /> Notify solicitor of assignment.
              </FormControl>              

            </Stack>

            <HStack
              spacing="6"
              text-align={"right"}
              as={Flex}
              alignItems={"flex-start"}
              py={"20px"}
            >
              <Button
                tabIndex={23}
                type="submit"
                fontSize={{ base: 12, md: 14 }}
                textTransform={"capitalize"}
                backgroundColor={"#4285F4"}
                minH={"auto"}
                variant="primary"
              >
                Submit
              </Button>
            </HStack>
          </form>
        </Box>
        </>
      ) : (

      <>
      <Flex flexDirection={"column"} alignItems={"flex-start"}>
        <BackButton
          tabIndex={9}
          onClickHandler={() => {
            if (originalSolicitorId !== 0) {
              navigate(url);  
            }
            else {
              navigate(-1);
            }
          }}
          ml={"-17px"}
          mb={"37px"}
        />

        <Heading
          position={"relative"}
          textAlign="center"
          display={"flex"}
          // alignItems={'flex-end'}
          fontSize="32px"
          fontWeight="700"
          paddingBottom={3}
        >
          Case Information
        </Heading>
      </Flex>        

        <Text>
          Thank you for filling out the case opening form for this client.
        </Text>
        </>

      )}
    </>
  );
};

export default CaseOpeningForm;
