import React from 'react';
import {
  AlertDialog,
  AlertDialogBody, AlertDialogCloseButton,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  // useDisclosure
} from "@chakra-ui/react";
import Loading from "./Loading";

const Alert = ({isOpen, onClose, action, header, text, 
                confirmText = 'Confirm',
                showCancel = true,
                size = 'xl', 
                contentPaddingY = 5, 
                closeOnOverlayClick = false, 
                isLoading = false}) => {
  const cancelRef = React.useRef()
  const buttonStyle = {
    type: 'button',
    paddingX: 3,
    alignSelf: 'baseline',
    fontSize: '18px',
    height: '48px',
    minHeight: '48px',
    variant: 'primary',
    onClick: onClose,
    minWidth: '150px',
  };
  return (
    <>
      <AlertDialog
        closeOnOverlayClick={closeOnOverlayClick}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={size}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            padding={10}>
            <AlertDialogHeader
              fontSize={'26px'}
              fontWeight={'bold'}
              textAlign={'center'}
              lineHeight={'20px'}>
              {header}
            </AlertDialogHeader>
            <AlertDialogCloseButton/>

            <AlertDialogBody
              py={contentPaddingY}
              fontSize={'18px'}
              textAlign={'center'}
              >
              {text}
            </AlertDialogBody>
            {action ?
              <AlertDialogFooter
                justifyContent={'space-evenly'}>

                {showCancel ?
                <Button
                  disabled={isLoading}
                  ref={cancelRef}
                  {...buttonStyle}
                  variant="secondary" 
                >Cancel</Button>
                : '' }

                <Button
                  disabled={isLoading}
                  {...buttonStyle}
                  onClick={() => {
                    action();
                    // onClose();
                  }}
                  variant="primary">                    
                    {isLoading ? 
                      <Loading size={'xs'} marginRight={'15px'} marginBottom={'3px'}/>
                    : ''}
                    {confirmText}
                  </Button>

              </AlertDialogFooter>
              : null}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Alert;