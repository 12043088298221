export const ADMIN_AUTHENTICATED = 'ADMIN_AUTHENTICATED';
export const ADMIN_LOGIN_AS_FIRM = 'ADMIN_LOGIN_AS_FIRM';
export const ADMIN_CANNOT_LOGIN_AS_FIRM = 'ADMIN_CANNOT_LOGIN_AS_FIRM' 
export const ADMIN_NOT_AUTHENTICATED = 'ADMIN_NOT_AUTHENTICATED';
export const ADMIN_SET_ERR_MESSAGE = 'ADMIN_SET_ERR_MESSAGE';
export const FIRM_AUTHENTICATED = 'FIRM_AUTHENTICATED';
export const FIRM_NOT_AUTHENTICATED = 'FIRM_NOT_AUTHENTICATED';
export const FIRM_LOGIN_AS_SOLICITOR = 'FIRM_LOGIN_AS_SOLICITOR';
export const FIRM_CANNOT_LOGIN_AS_SOLICITOR = 'FIRM_CANNOT_LOGIN_AS_SOLICITOR' 
export const FIRM_CODE_NOT_AUTHENTICATED = 'FIRM_CODE_NOT_AUTHENTICATED';
export const FIRM_CODE_AUTHENTICATED = 'FIRM_CODE_AUTHENTICATED';
export const FIRM_CODE_SEND = 'FIRM_CODE_SEND';
export const FIRM_CODE_NOT_SEND = 'FIRM_CODE_NOT_SEND';
export const FIRM_REGISTERED = 'FIRM_REGISTERED';
export const FIRM_NOT_REGISTERED = 'FIRM_NOT_REGISTERED';
export const FORGOT_CODE_SENT = 'FORGOT_CODE_SENT';
export const FORGOT_CODE_NOT_SENT = 'FORGOT_CODE_NOT_SENT';
export const FORGOT_CODE_ACCEPTED = 'FORGOT_CODE_ACCEPTED';
export const FORGOT_CODE_NOT_ACCEPTED = 'FORGOT_CODE_NOT_ACCEPTED';
export const FORGOT_NEW_PASS_ACCEPTED = 'FORGOT_NEW_PASS_ACCEPTED';
export const FORGOT_NEW_PASS_NOT_ACCEPTED = 'FORGOT_NEW_PASS_NOT_ACCEPTED';
export const FIRM_LIST_DOWNLOADED = 'FIRM_LIST_DOWNLOADED'
export const FIRM_LIST_NOT_DOWNLOADED = 'FIRM_LIST_NOT_DOWNLOADED'
export const SET_DATA_LOADING_TRUE = 'SET_DATA_LOADING_TRUE'
export const SET_DATA_LOADING_FALSE = 'SET_DATA_LOADING_FALSE'
export const FIRM_ACTIVATE = 'FIRM_ACTIVATE'
export const FIRM_ACTIVATE_FAILED = 'FIRM_ACTIVATE_FAILED'
export const FIRM_ACTIVATE_SUCCESS = 'FIRM_ACTIVATE_SUCCESS'
export const FIRM_DEACTIVATE = 'FIRM_DEACTIVATE'
export const FIRM_DEACTIVATE_FAILED = 'FIRM_DEACTIVATE_FAILED'
export const FIRM_DEACTIVATE_SUCCESS = 'FIRM_DEACTIVATE_SUCCESS'
export const FIRM_GET_BY_ID_SUCCESS = 'FIRM_DEACTIVATE_SUCCESS'
export const FIRM_SET_ERR_MESSAGE = 'FIRM_SET_ERR_MESSAGE'

export const SOLICITORS_DEACTIVATE_FAILED = 'SOLICITORS_DEACTIVATE_FAILED'
export const SOLICITORS_DEACTIVATE_SUCCESS = 'SOLICITORS_DEACTIVATE_SUCCESS'
export const SOLICITORS_ACTIVATE_FAILED = 'SOLICITORS_ACTIVATE_FAILED'
export const SOLICITORS_ACTIVATE_SUCCESS = 'SOLICITORS_ACTIVATE_SUCCESS'
export const SOLICITORS_LIST_DOWNLOADED = 'SOLICITORS_LIST_DOWNLOADED'
export const SOLICITORS_LIST_NOT_DOWNLOADED = 'SOLICITORS_LIST_NOT_DOWNLOADED'
export const SOLICITORS_ACTIVATE = 'SOLICITORS_ACTIVATE'
export const SOLICITORS_DEACTIVATE = 'SOLICITORS_DEACTIVATE'
export const SOLICITORS_GET_BY_ID_SUCCESS = 'SOLICITORS_GET_BY_ID_SUCCESS'
export const SOLICITORS_SET_ERR_MESSAGE = 'SOLICITORS_SET_ERR_MESSAGE'

export const SET_ADMIN_LOGIN_DATA_LOADING_TRUE = 'SET_ADMIN_LOGIN_DATA_LOADING_TRUE'
export const SET_ADMIN_LOGIN_DATA_LOADING_FALSE = 'SET_ADMIN_LOGIN_DATA_LOADING_FALSE'
export const SET_FIRM_LOGIN_DATA_LOADING_TRUE = 'SET_FIRM_LOGIN_DATA_LOADING_TRUE'
export const SET_FIRM_LOGIN_DATA_LOADING_FALSE = 'SET_FIRM_LOGIN_DATA_LOADING_FALSE'
export const SET_NEW_SOLICITOR_SUCCESS = 'SET_NEW_SOLICITOR_SUCCESS'
export const SET_NEW_SOLICITOR_ERR_MESSAGE = 'SET_NEW_SOLICITOR_ERR_MESSAGE'
export const SET_SOLICITOR_NEW_DATA = 'SET_SOLICITOR_NEW_DATA'
export const SET_SOLICITOR_NEW_DATA_FAILED = 'SET_SOLICITOR_NEW_DATA_FAILED'

export const GET_SOLICITOR_DETAILS_BY_SECRET_SUCCESS = 'SOLICITOR_GET_DETAILS_BY_SECRET_SUCCESS'
export const GET_SOLICITOR_DETAILS_BY_SECRET_FAILED = 'SOLICITOR_GET_DETAILS_BY_SECRET_FAILED'

export const SET_SOLICITOR_NEW_PASSWORD = 'SET_SOLICITOR_NEW_PASSWORD'
export const SET_SOLICITOR_NEW_PASSWORD_FAILED = 'SET_SOLICITOR_NEW_PASSWORD_FAILED'


export const SOLICITOR_AUTHENTICATED = 'SOLICITOR_AUTHENTICATED'
export const SOLICITOR_NOT_AUTHENTICATED = 'SOLICITOR_NOT_AUTHENTICATED'
export const SOLICITOR_CODE_AUTHENTICATED = 'SOLICITOR_CODE_AUTHENTICATED'
export const SOLICITOR_CODE_NOT_AUTHENTICATED = 'SOLICITOR_CODE_NOT_AUTHENTICATED'
export const SOLICITOR_CODE_SEND = 'SOLICITOR_CODE_SEND'
export const SOLICITOR_CODE_NOT_SEND = 'SOLICITOR_CODE_NOT_SEND'
export const SOLICITOR_SET_ERR_MESSAGE = 'SOLICITOR_SET_ERR_MESSAGE'
export const SOLICITOR_REGISTERED = 'SOLICITOR_REGISTERED'
export const SOLICITOR_NOT_REGISTERED = 'SOLICITOR_NOT_REGISTERED'
export const SOLICITOR_FORGOT_CODE_SENT = 'SOLICITOR_FORGOT_CODE_SENT'
export const SOLICITOR_FORGOT_CODE_NOT_SENT = 'SOLICITOR_FORGOT_CODE_NOT_SENT'
export const SOLICITOR_FORGOT_CODE_ACCEPTED = 'SOLICITOR_FORGOT_CODE_ACCEPTED'
export const SOLICITOR_FORGOT_CODE_NOT_ACCEPTED = 'SOLICITOR_FORGOT_CODE_NOT_ACCEPTED'
export const SOLICITOR_FORGOT_NEW_PASS_ACCEPTED = 'SOLICITOR_FORGOT_NEW_PASS_ACCEPTED'
export const SOLICITOR_FORGOT_NEW_PASS_NOT_ACCEPTED = 'SOLICITOR_FORGOT_NEW_PASS_NOT_ACCEPTED'
export const SET_SOLICITOR_LOGIN_DATA_LOADING_TRUE = 'SET_SOLICITOR_LOGIN_DATA_LOADING_TRUE'
export const SET_SOLICITOR_LOGIN_DATA_LOADING_FALSE = 'SET_SOLICITOR_LOGIN_DATA_LOADING_FALSE'

export const SET_NEW_SOLICITOR_BY_SECRET_SUCCESS = 'SET_NEW_SOLICITOR_BY_SECRET_SUCCESS'
export const SET_NEW_SOLICITOR_BY_SECRET_ERR_MESSAGE = 'SET_NEW_SOLICITOR_BY_SECRET_ERR_MESSAGE'

export const SET_NEW_ACCOUNT_BY_SECRET_SUCCESS = 'SET_NEW_ACCOUNT_BY_SECRET_SUCCESS'
export const SET_NEW_AUDITOR_BY_SECRET_SUCCESS = 'SET_NEW_AUDITOR_BY_SECRET_SUCCESS'

export const SET_FIRM_NEW_DATA = 'SET_FIRM_NEW_DATA'
export const SET_FIRM_NEW_DATA_FAILED = 'SET_FIRM_NEW_DATA_FAILED'

export const FIRM_GET_IDPAL_SUCCESS = 'FIRM_GET_IDPAL_SUCCESS'
export const FIRM_GET_IDPAL_FAILED = 'FIRM_GET_IDPAL_FAILED'

export const FIRM_SET_IDPAL_SUCCESS = 'FIRM_SET_IDPAL_SUCCESS'
export const FIRM_SET_IDPAL_FAILED = 'FIRM_SET_IDPAL_FAILED'

export const CLIENTS_LIST_DOWNLOADED = 'CLIENTS_LIST_DOWNLOADED'

export const CLIENTS_LIST_NOT_DOWNLOADED = 'CLIENTS_LIST_NOT_DOWNLOADED'

export const SET_NEW_CLIENTS_SUCCESS = 'SET_NEW_CLIENTS_SUCCESS'
export const SET_NEW_CLIENTS_ERR_MESSAGE = 'SET_NEW_CLIENTS_ERR_MESSAGE'

export const FIRM_DELETE_SUCCESS = 'FIRM_DELETE_SUCCESS'
export const FIRM_DELETE_FAILED = 'FIRM_DELETE_FAILED'

export const SOLICITOR_DELETE_SUCCESS = 'SOLICITOR_DELETE_SUCCESS'
export const SOLICITOR_DELETE_FAILED = 'SOLICITOR_DELETE_FAILED'

export const CLIENTS_GET_BY_ID_SUCCESS = 'CLIENTS_GET_BY_ID_SUCCESS'
export const CLIENTS_SET_ERR_MESSAGE = 'CLIENTS_SET_ERR_MESSAGE'

export const CLIENTS_GET_SUBMISSION_DATA_SUCCESS = 'CLIENTS_GET_SUBMISSION_DATA_SUCCESS'

export const SET_SUBMISSION_DATA_LOADING_TRUE = 'SET_SUBMISSION_DATA_LOADING_TRUE'
export const SET_SUBMISSION_DATA_LOADING_FALSE = 'SET_SUBMISSION_DATA_LOADING_FALSE'

export const CLIENTS_GET_FORBIDDEN = 'CLIENTS_GET_FORBIDDEN'

export const SET_SOLICITOR_NOT_FIRST_CHECK = 'SET_SOLICITOR_NOT_FIRST_CHECK'
export const SET_SOLICITOR_FIRST_CHECK = 'SET_SOLICITOR_FIRST_CHECK'

export const SET_FIRM_NOT_FIRST_CHECK = 'SET_FIRM_NOT_FIRST_CHECK'
export const SET_FIRM_FIRST_CHECK = 'SET_FIRM_FIRST_CHECK'

export const SET_ADMIN_NOT_FIRST_CHECK = 'SET_ADMIN_NOT_FIRST_CHECK'
export const SET_ADMIN_FIRST_CHECK = 'SET_ADMIN_FIRST_CHECK'

export const SET_ACCOUNT_NOT_FIRST_CHECK = 'SET_ACCOUNT_NOT_FIRST_CHECK'
export const SET_ACCOUNT_FIRST_CHECK = 'SET_ACCOUNT_FIRST_CHECK'

export const GET_CLIENT_BY_SECRET_SUCCESS = 'GET_CLIENT_BY_SECRET_SUCCESS'
export const GET_CLIENT_BY_SECRET_FAILED = 'GET_CLIENT_BY_SECRET_FAILED'

export const SET_CLIENT_BY_SECRET_SUCCESS = 'SET_CLIENT_BY_SECRET_SUCCESS'
export const SET_CLIENT_BY_SECRET_FAILED = 'SET_CLIENT_BY_SECRET_FAILED'

export const ACCOUNTS_LIST_DOWNLOADED = 'ACCOUNTS_LIST_DOWNLOADED'
export const ACCOUNTS_LIST_NOT_DOWNLOADED = 'ACCOUNTS_LIST_NOT_DOWNLOADED'
export const ACCOUNTS_GET_BY_ID_SUCCESS = 'ACCOUNTS_GET_BY_ID_SUCCESS'
export const ACCOUNTS_SET_ERR_MESSAGE = 'ACCOUNTS_SET_ERR_MESSAGE'
export const SET_NEW_ACCOUNT_SUCCESS = 'SET_NEW_ACCOUNT_SUCCESS'
export const SET_NEW_ACCOUNT_ERR_MESSAGE = 'SET_NEW_ACCOUNT_ERR_MESSAGE'
export const SET_ACCOUNT_NEW_DATA = 'SET_ACCOUNT_NEW_DATA'
export const SET_ACCOUNT_NEW_DATA_FAILED = 'SET_ACCOUNT_NEW_DATA_FAILED'
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS'
export const ACCOUNT_DELETE_FAILED = 'ACCOUNT_DELETE_FAILED'

export const ACCOUNTS_FORM_CHANGE_STATUS = 'ACCOUNTS_FORM_CHANGE_STATUS'
export const ACCOUNTS_FORM_LIST_DOWNLOADED = 'ACCOUNTS_FORM_LIST_DOWNLOADED'
export const ACCOUNTS_FORM_LIST_NOT_DOWNLOADED = 'ACCOUNTS_FORM_LIST_NOT_DOWNLOADED'


export const ACCOUNT_AUTHENTICATED = 'ACCOUNT_AUTHENTICATED'
export const ACCOUNT_NOT_AUTHENTICATED = 'ACCOUNT_NOT_AUTHENTICATED'
export const ACCOUNT_CODE_AUTHENTICATED = 'ACCOUNT_CODE_AUTHENTICATED'
export const ACCOUNT_CODE_NOT_AUTHENTICATED = 'ACCOUNT_CODE_NOT_AUTHENTICATED'
export const ACCOUNT_CODE_SEND = 'ACCOUNT_CODE_SEND'
export const ACCOUNT_CODE_NOT_SEND = 'ACCOUNT_CODE_NOT_SEND'
export const ACCOUNT_SET_ERR_MESSAGE = 'ACCOUNT_SET_ERR_MESSAGE'
export const ACCOUNT_REGISTERED = 'ACCOUNT_REGISTERED'
export const ACCOUNT_NOT_REGISTERED = 'ACCOUNT_NOT_REGISTERED'
export const ACCOUNT_FORGOT_CODE_SENT = 'ACCOUNT_FORGOT_CODE_SENT'
export const ACCOUNT_FORGOT_CODE_NOT_SENT = 'ACCOUNT_FORGOT_CODE_NOT_SENT'
export const ACCOUNT_FORGOT_CODE_ACCEPTED = 'ACCOUNT_FORGOT_CODE_ACCEPTED'
export const ACCOUNT_FORGOT_CODE_NOT_ACCEPTED = 'ACCOUNT_FORGOT_CODE_NOT_ACCEPTED'
export const ACCOUNT_FORGOT_NEW_PASS_ACCEPTED = 'ACCOUNT_FORGOT_NEW_PASS_ACCEPTED'
export const ACCOUNT_FORGOT_NEW_PASS_NOT_ACCEPTED = 'ACCOUNT_FORGOT_NEW_PASS_NOT_ACCEPTED'
export const SET_ACCOUNT_LOGIN_DATA_LOADING_TRUE = 'SET_ACCOUNT_LOGIN_DATA_LOADING_TRUE'
export const SET_ACCOUNT_LOGIN_DATA_LOADING_FALSE = 'SET_ACCOUNT_LOGIN_DATA_LOADING_FALSE'

export const SET_ACCOUNT_NEW_PASSWORD = 'SET_ACCOUNT_NEW_PASSWORD'
export const SET_ACCOUNT_NEW_PASSWORD_FAILED = 'SET_ACCOUNT_NEW_PASSWORD_FAILED'

export const SET_NEW_RISK_FORM_SUCCESS = 'SET_NEW_RISK_FORM_SUCCESS'
export const SET_NEW_RISK_FORM_FAILED = 'SET_NEW_RISK_FORM_FAILED'

export const SET_UPDATE_RISK_FORM_SUCCESS = 'SET_UPDATE_RISK_FORM_SUCCESS'
export const SET_UPDATE_RISK_FORM_FAILED = 'SET_UPDATE_RISK_FORM_FAILED'

export const GET_RISK_FORM_SUCCESS = 'GET_RISK_FORM_SUCCESS'
export const GET_RISK_FORM_FAILED = 'GET_RISK_FORM_FAILED'


export const SET_NEWSLETTER_FORM_SUCCESS = 'SET_NEWSLETTER_FORM_SUCCESS'
export const SET_NEWSLETTER_FORM_FAILED = 'SET_NEWSLETTER_FORM_FAILED'

export const SET_DEMO_REQUEST_FORM_SUCCESS = 'SET_DEMO_REQUEST_FORM_SUCCESS'
export const SET_DEMO_REQUEST_FORM_FAILED = 'SET_DEMO_REQUEST_FORM_FAILED'

export const SET_CLIENT_IDPAL_OVERRIDE_SUCCESS = 'SET_SOLICITOR_IDPAL_OVERRIDE_SUCCESS'
export const SET_CLIENT_IDPAL_OVERRIDE_FAILED = 'SET_SOLICITOR_IDPAL_OVERRIDE_FAILED'
export const SET_SEND_NEW_DOCUSIGN_DOC_SUCCESS = 'SET_SEND_NEW_DOCUSIGN_DOC_SUCCESS'
export const SET_SEND_NEW_DOCUSIGN_DOC_FAILED = 'SET_SEND_NEW_DOCUSIGN_DOC_FAILED'
export const SET_FORM_LOADING_TRUE = 'SET_FORM_LOADING_TRUE'
export const SET_FORM_LOADING_FALSE = 'SET_FORM_LOADING_FALSE'

export const SET_S150FORM_PDF_LOADING_TRUE = 'SET_S150FORM_PDF_LOADING_TRUE'
export const SET_S150FORM_PDF_LOADING_FALSE = 'SET_S150FORM_PDF_LOADING_FALSE'

export const GET_S150FORM_PDF_SUCCESS = 'GET_S150FORM_PDF_SUCCESS'
export const GET_S150FORM_PDF_FAILED = 'GET_S150FORM_PDF_FAILED'
export const SET_CLIENT_IDPAL_REINVITE_SUCCESS = 'SET_CLIENT_IDPAL_REINVITE_SUCCESS'
export const SET_CLIENT_IDPAL_REINVITE_FAILED = 'SET_CLIENT_IDPAL_REINVITE_FAILED'

export const GET_SOLICITOR_DASHBOARD_META_SUCCESS = 'GET_SOLICITOR_DASHBOARD_META_SUCCESS'
export const GET_SOLICITOR_DASHBOARD_META_FAILED = 'GET_SOLICITOR_DASHBOARD_META_FAILED'
export const SET_SOLICITOR_DASHBOARD_META_LOADING = 'SET_SOLICITOR_DASHBOARD_META_LOADING'

export const GET_SOLICITOR_DASHBOARD_LOG_SUCCESS = 'GET_SOLICITOR_DASHBOARD_LOG_SUCCESS'
export const GET_SOLICITOR_DASHBOARD_LOG_FAILED = 'GET_SOLICITOR_DASHBOARD_LOG_FAILED'
export const SET_SOLICITOR_DASHBOARD_LOG_LOADING = 'SET_SOLICITOR_DASHBOARD_LOG_LOADING'

export const SET_COMPANY_DETAILS_LOADING_TRUE = 'SET_COMPANY_DETAILS_LOADING_TRUE'
export const SET_COMPANY_DETAILS_LOADING_FALSE = 'SET_COMPANY_DETAILS_LOADING_FALSE'

export const GET_COMPANY_DETAILS_BY_KEYWORD_SUCCESS = 'GET_COMPANY_DETAILS_BY_KEYWORD_SUCCESS'
export const GET_COMPANY_DETAILS_BY_KEYWORD_FAILED = 'GET_COMPANY_DETAILS_BY_KEYWORD_FAILED'

export const GET_COMPANY_CONTACTS_BY_ID_SUCCESS = 'GET_COMPANY_CONTACTS_BY_ID_SUCCESS'
export const GET_COMPANY_CONTACTS_BY_ID_FAILED = 'GET_COMPANY_CONTACTS_BY_ID_FAILED'

export const SET_INVITE_CORPORATE_SUCCESS = 'SET_INVITE_CORPORATE_SUCCESS'
export const SET_INVITE_CORPORATE_FAILED = 'SET_INVITE_CORPORATE_FAILED'
export const SET_COMPANY_DOCUMENTS_COMPANY_REPORT_LOADING_TRUE = 'SET_COMPANY_DOCUMENTS_COMPANY_REPORT_LOADING_TRUE'
export const SET_COMPANY_DOCUMENTS_UBO_REPORT_LOADING_TRUE = 'SET_COMPANY_DOCUMENTS_UBO_REPORT_LOADING_TRUE'
export const SET_COMPANY_DOCUMENTS_CERT_OF_INCORPORATION_LOADING_TRUE = 'SET_COMPANY_DOCUMENTS_CERT_OF_INCORPORATION_LOADING_TRUE'
export const SET_COMPANY_DOCUMENTS_CONSTITUTION_LOADING_TRUE = 'SET_COMPANY_DOCUMENTS_CONSTITUTION_LOADING_TRUE'
export const GET_COMPANY_COMPANY_REPORT_FAILED = 'GET_COMPANY_COMPANY_REPORT_FAILED'
export const GET_COMPANY_UBO_REPORT_FAILED = 'GET_COMPANY_UBO_REPORT_FAILED'
export const GET_COMPANY_CERT_OF_INCORPORATION_FAILED = 'GET_COMPANY_CERT_OF_INCORPORATION_FAILED'
export const GET_COMPANY_CONSTITUTION_FAILED = 'GET_COMPANY_CONSTITUTION_FAILED'

export const GET_COMPANY_COMPANY_REPORT_SUCCESS = 'GET_COMPANY_COMPANY_REPORT_SUCCESS'
export const GET_COMPANY_UBO_REPORT_SUCCESS = 'GET_COMPANY_UBO_REPORT_SUCCESS'
export const GET_COMPANY_CERT_OF_INCORPORATION_SUCCESS = 'GET_COMPANY_CERT_OF_INCORPORATION_SUCCESS'
export const GET_COMPANY_CONSTITUTION_SUCCESS = 'GET_COMPANY_CONSTITUTION_SUCCESS'
export const GET_COMPANY_DIRECTORS_SUCCESS = 'GET_COMPANY_DIRECTORS_SUCCESS'
export const GET_COMPANY_DIRECTORS_FAILED = 'GET_COMPANY_DIRECTORS_FAILED'
export const SET_COMPANY_EMPTY_DETAILS_BY_KEYWORD_SUCCESS = 'SET_COMPANY_EMPTY_DETAILS_BY_KEYWORD_SUCCESS'

export const SET_CORPDOC_CR_PDF_LOADING_TRUE = 'SET_CORPDOC_CR_PDF_LOADING_TRUE'
export const SET_CORPDOC_CR_PDF_LOADING_FALSE = 'SET_CORPDOC_CR_PDF_LOADING_FALSE'
export const SET_CORPDOC_SBI_PDF_LOADING_TRUE = 'SET_CORPDOC_SBI_PDF_LOADING_TRUE'
export const SET_CORPDOC_SBI_PDF_LOADING_FALSE = 'SET_CORPDOC_SBI_PDF_LOADING_FALSE'
export const SET_CORPDOC_COI_PDF_LOADING_TRUE = 'SET_CORPDOC_COI_PDF_LOADING_TRUE'
export const SET_CORPDOC_COI_PDF_LOADING_FALSE = 'SET_CORPDOC_COI_PDF_LOADING_FALSE'
export const SET_CORPDOC_C_PDF_LOADING_TRUE = 'SET_CORPDOC_C_PDF_LOADING_TRUE'
export const SET_CORPDOC_C_PDF_LOADING_FALSE = 'SET_CORPDOC_C_PDF_LOADING_FALSE'

export const GET_CORPDOC_CR_PDF_SUCCESS = 'GET_CORPDOC_CR_PDF_SUCCESS'
export const GET_CORPDOC_CR_PDF_FAILED = 'GET_CORPDOC_CR_PDF_FAILED'
export const GET_CORPDOC_SBI_PDF_SUCCESS = 'GET_CORPDOC_SBI_PDF_SUCCESS'
export const GET_CORPDOC_SBI_PDF_FAILED = 'GET_CORPDOC_SBI_PDF_FAILED'
export const GET_CORPDOC_COI_PDF_SUCCESS = 'GET_CORPDOC_COI_PDF_SUCCESS'
export const GET_CORPDOC_COI_PDF_FAILED = 'GET_CORPDOC_COI_PDF_FAILED'
export const GET_CORPDOC_C_PDF_SUCCESS = 'GET_CORPDOC_C_PDF_SUCCESS'
export const GET_CORPDOC_C_PDF_FAILED = 'GET_CORPDOC_C_PDF_FAILED'

export const AUDITOR_LIST_DOWNLOADED = 'AUDITOR_LIST_DOWNLOADED'
export const AUDITOR_LIST_NOT_DOWNLOADED = 'AUDITOR_LIST_NOT_DOWNLOADED'
export const AUDITOR_ACTIVATE = 'AUDITOR_ACTIVATE'
export const AUDITOR_DEACTIVATE = 'AUDITOR_DEACTIVATE'
export const AUDITOR_GET_BY_ID_SUCCESS = 'AUDITOR_GET_BY_ID_SUCCESS'
export const AUDITOR_SET_ERR_MESSAGE = 'AUDITOR_SET_ERR_MESSAGE'
export const SET_AUDITOR_NEW_DATA = 'SET_AUDITOR_NEW_DATA'
export const SET_AUDITOR_NEW_DATA_FAILED = 'SET_AUDITOR_NEW_DATA_FAILED'
export const AUDITOR_DELETE_FAILED = 'AUDITOR_DELETE_FAILED'
export const AUDITOR_DELETE_SUCCESS = 'AUDITOR_DELETE_SUCCESS'
export const SET_NEW_AUDITORS_ERR_MESSAGE = 'SET_NEW_AUDITORS_ERR_MESSAGE'
export const SET_NEW_AUDITORS_SUCCESS = 'SET_NEW_AUDITORS_SUCCESS'

export const AUDITOR_ACTIVATE_SUCCESS = 'AUDITOR_ACTIVATE_SUCCESS'
export const AUDITOR_ACTIVATE_FAILED = 'AUDITOR_ACTIVATE_FAILED'
export const AUDITOR_DEACTIVATE_SUCCESS = 'AUDITOR_DEACTIVATE_SUCCESS'
export const AUDITOR_DEACTIVATE_FAILED = 'AUDITOR_DEACTIVATE_FAILED'

export const AUDITOR_AUTHENTICATED = 'AUDITOR_AUTHENTICATED'
export const AUDITOR_NOT_AUTHENTICATED = 'AUDITOR_NOT_AUTHENTICATED'
export const AUDITOR_CODE_AUTHENTICATED = 'AUDITOR_CODE_AUTHENTICATED'
export const AUDITOR_CODE_NOT_AUTHENTICATED = 'AUDITOR_CODE_NOT_AUTHENTICATED'
export const AUDITOR_CODE_SEND = 'AUDITOR_CODE_SEND'
export const AUDITOR_CODE_NOT_SEND = 'AUDITOR_CODE_NOT_SEND'
export const AUDITOR_REGISTERED = 'AUDITOR_REGISTERED'
export const AUDITOR_NOT_REGISTERED = 'AUDITOR_NOT_REGISTERED'
export const AUDITOR_FORGOT_CODE_SENT = 'AUDITOR_FORGOT_CODE_SENT'
export const AUDITOR_FORGOT_CODE_NOT_SENT = 'AUDITOR_FORGOT_CODE_NOT_SENT'
export const AUDITOR_FORGOT_CODE_ACCEPTED = 'AUDITOR_FORGOT_CODE_ACCEPTED'
export const AUDITOR_FORGOT_CODE_NOT_ACCEPTED = 'AUDITOR_FORGOT_CODE_NOT_ACCEPTED'
export const AUDITOR_FORGOT_NEW_PASS_ACCEPTED = 'AUDITOR_FORGOT_NEW_PASS_ACCEPTED'
export const AUDITOR_FORGOT_NEW_PASS_NOT_ACCEPTED = 'AUDITOR_FORGOT_NEW_PASS_NOT_ACCEPTED'
export const SET_AUDITOR_LOGIN_DATA_LOADING_TRUE = 'SET_AUDITOR_LOGIN_DATA_LOADING_TRUE'
export const SET_AUDITOR_LOGIN_DATA_LOADING_FALSE = 'SET_AUDITOR_LOGIN_DATA_LOADING_FALSE'

export const SET_AUDITOR_NEW_PASSWORD = 'SET_AUDITOR_NEW_PASSWORD'
export const SET_AUDITOR_NEW_PASSWORD_FAILED = 'SET_AUDITOR_NEW_PASSWORD_FAILED'

export const SET_AUDITOR_NOT_FIRST_CHECK = 'SET_AUDITOR_NOT_FIRST_CHECK'
export const SET_AUDITOR_FIRST_CHECK = 'SET_AUDITOR_FIRST_CHECK'

export const SET_FIRM_ERR_MESSAGE = 'SET_FIRM_ERR_MESSAGE'
export const GET_AUDITOR_FIRM_BY_ID_SUCCESS = 'GET_AUDITOR_FIRM_BY_ID_SUCCESS'

export const SET_SHAREHOLDERSFORM_PDF_LOADING_TRUE = 'SET_SHAREHOLDERSFORM_PDF_LOADING_TRUE'
export const SET_SHAREHOLDERSFORM_PDF_LOADING_FALSE = 'SET_SHAREHOLDERSFORM_PDF_LOADING_FALSE'
export const GET_SHAREHOLDERSFORM_PDF_SUCCESS = 'GET_SHAREHOLDERSFORM_PDF_SUCCESS'
export const GET_SHAREHOLDERSFORM_PDF_FAILED = 'GET_SHAREHOLDERSFORM_PDF_FAILED'

export const SET_CASE_OPENING_SUCCESS = 'SET_CASE_OPENING_SUCCESS'
export const SET_CASE_OPENING_FAILED = 'SET_CASE_OPENING_FAILED'