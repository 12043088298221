import React from 'react';
import {
  Divider,
  Flex,
  Stack,
} from "@chakra-ui/react";
import {LogoWhite} from "./Logo";
import NavButton from "./NavButton";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {FirmsIcon, HelpIcon, LogOutIcon} from "../assets/icons/sprite"
import {logout} from "../redux/actions/auth";

// FIXME: Must create just one of copy this component for all type of users

export const FirmSidebar = () => {

  const location = useLocation()

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatchLogoutFirm = (credential) => dispatch(logout(credential, navigate))

  const navStyle = {
    as: Link,
    size: '22px',
    fontSize: '18px',
    color: '#ffffff',
  }

  return (

    <Flex
      flex={1}
      bg={'#3A78F2'}
      color={'on-accent'}
      overflowY={'auto'}
      width={250}
      maxW={{base: 'fill', sm: 'sm'}}
      py={{base: '8', sm: '12'}}
      px={{base: '7', sm: '8'}}
      position={'fixed'}
      minH={'100vh'}
    >
      <Stack
        justify={'space-between'}
        spacing={1}
        pb={'25px'}
      >
        <Stack spacing={{base: '5', sm: '75'}} shouldWrapChildren>
          <Link
            to={'/'}
          >
            <LogoWhite px={{base: '2', sm: '4'}}
            />
          </Link>

          <Stack spacing={10}>
            <NavButton
              {...navStyle}
              fontWeight={location.pathname.includes('solicitors') ? 700 : 500}
              to={'/firm/solicitors/'}
              label={'Solicitors'}
              icon={FirmsIcon}
            />

            {/*}
            <NavButton
              {...navStyle}
              fontWeight={location.pathname.includes('users') ? 700 : 500}
              to={'/firm/users/'}
              label={'Accounts Users'}
              icon={AuditorsIcon}
            />*/}
          </Stack>
        </Stack>

        <Stack
          spacing={{
            base: '5',
            sm: '6'
          }}
          alignItems={'flex-start'}
        >

          <Divider
            borderColor={'#FFFFFF'}
          />
          <NavButton
            as={Link}
            fontWeight={location.pathname === '/firm/help/' ? 700 : 500}
            size={'24px'}
            fontSize={'18px'}
            to={'/firm/help/'}
            label={'Help'}
            icon={HelpIcon}
            aria-current={'page'}
            color={'#ffffff'}
          />

          <NavButton
            as={Link}
            size={'24px'}
            fontSize={'18px'}
            fontWeight={500}
            label={'Log out'}
            icon={LogOutIcon}
            aria-current={'page'}
            color={'#ffffff'}
            onClick={() => dispatchLogoutFirm()}
          />

        </Stack>
      </Stack>
    </Flex>)


};

export default FirmSidebar;
