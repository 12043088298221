import React, {} from 'react';
import {
  Box, Button, Grid, GridItem, Heading, Text, 
} from "@chakra-ui/react";
import {pdfjs} from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const IdPalItem = ({
                     title,
                     subtitle = null,
                     status = null,
                     id = null,
                     documentType = null,
                     clientId = null,

                     isAlert = true,
                     isVerified = true,
                     nestedComponent = null,
                     data = null,

                     unsetBg = false                     
                   }) => {

  const cardStyle = {
    rounded: 'lg',
    bgColor: '#F9FBFC', // width: '466px',
    p: '24px',
    minHeight: 26,
    marginTop: 2,
    marginBottom: 1,
  }

  const viewButtonStyle = {
    bgColor: '#ffffff',
    border: '1px solid #6E7997',
    color: '#6E7997',
    fontSize: '16px',
    fontWeight: 500,
    w: '100%',
    minH: '55px',
  }

  var templateColumns = '1fr 1fr'
  if (title.trim() !== "ID Document") {
    templateColumns = '1fr'
  }  

  return (

    <GridItem
      {...cardStyle}
      mt={1}
      bgColor={unsetBg ? '' : cardStyle.bgColor}
    >

      <Grid templateColumns={templateColumns} gap={6}>

        <GridItem>
          <Heading
            as={'h3'}
            fontWeight={'700'}
            fontSize={'18px'}
          >
            {title}

          </Heading>

          {/* subtitle */}
          <Text
            color={'#6E7997'}
            fontWeight={'400'}
            mt={2}
            fontSize={'18px'}
          >
            {subtitle || data?.subtitle}
          </Text>
        </GridItem>

        {/* status */}
        <GridItem
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
        >
          <Text
            color={'#fff'}
            fontSize={'11px'}
            bgColor={(() => {
              switch (status) {
                case "":
                  return '#C8392D'
                case "approved":
                  return '#1CBE7A'
                case "pending":
                  return '#1CBE7A'
                default:
                  return null
              }
            })()}
            paddingX={'10px'}
            borderRadius={'4px'}
          >
            {(() => {
              switch (status) {
                case "":
                  return "Unavailable"
                case "approved":
                  return 'Approved'
                case "pending":
                  return 'Available'
                default:
                  return null
              }
            })()}
          </Text>

        </GridItem>
      </Grid>

      {nestedComponent ? nestedComponent : <Box marginTop={'22px'}>
        <Button
          {...viewButtonStyle}
          disabled={!status}
          onClick={() => {}}
        >View Document</Button>
      </Box>}
    </GridItem>

  );
};

export default IdPalItem;
