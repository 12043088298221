import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {setShareholdersFormLoading, getShareholdersFormData, resendShareHolderForm } from "../../../../../redux/actions/clients";
import Alert from "../../../../Alert";
import Loading from "../../../../Loading";
import ViewAPdfFile from "../../../../ViewAPdfFile";
import AlertComponent from "../../../../AlertComponent"
import { Button, Grid, GridItem, Heading, HStack, Text, useDisclosure } from "@chakra-ui/react";
import "./Items.css"
import ComplianceNumber from "../../../../../components/Solicitor/CaseView/Compliance/Number"

function ComplianceItemShareholderForm(data) {

    const dispatch = useDispatch();      
    const dispatchErrorMessage = useSelector(state => state.clients.errorMessage)
    const selectIsShareholdersFormLoading = useSelector(state => state.clients?.isShareholdersFormLoading)
    const selectGetShareholdersFormContent = useSelector(state => state.clients?.getShareholdersFormContent)
    const {isOpen: isShareholdersFormOpen, onOpen: onShareholdersFormOpen, onClose: onShareholdersFormClose} = useDisclosure();  
    const dispatchSetShareholdersFormLoading = (flag) => dispatch(setShareholdersFormLoading(flag))
    const dispatchGetShareholdersFormData = (id, isSigned) => dispatch(
        getShareholdersFormData(id = data?.dispatchItem?.data?.id, isSigned = data?.status === 'pending' ? 0 : 1)
    )    
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);

    function handleResend(id) {      
        setIsLoading(true)
        setErrorMessage(null)
        resendShareHolderForm({
          "client_id": id,
        }).then(resp => {          
            setIsLoading(false)
            setStep(2).then(() => {        
              onConfirmOpen()
            })
        }).catch(err => {
            onConfirmOpen()
            setErrorMessage(err)
            setIsLoading(false)
        });
    }  

    var statusText = "Not yet submitted.";
    var labelText = "Pending";
    if (data?.status === "signed") { statusText = "Signed."; labelText = "Signed"; }
    else if (data?.status === "pending") { statusText = "Not signed yet."; labelText = "Not Signed Yet"; }

    return (
<>
<Alert
    isOpen={isShareholdersFormOpen}
    onOpen={onShareholdersFormOpen}
    onClose={onShareholdersFormClose}
    header='Shareholders Form'
    text={<>
        {dispatchErrorMessage && (<AlertComponent message={dispatchErrorMessage} />)}
        {selectIsShareholdersFormLoading ?  <Loading/>
        :  <ViewAPdfFile data={selectGetShareholdersFormContent?.data} width={1028}/> }
    </>
    }
    action={null}
    size={'6xl'}
    contentPaddingY={0}
/>

<Alert
    isOpen={isConfirmOpen}
    onOpen={onConfirmOpen}
    onClose={onConfirmClose}
    header={`Re-Send Shareholder Form`}
    isLoading={isLoading}
    confirmText={step === 1 ? 'Confirm' : 'OK'}
    showCancel={step === 1 ? true : false}
    text={
        step === 1 ? (
<>
{errorMessage && (<AlertComponent message={errorMessage} />)}
<Text>Are you sure you want to re-send the shareholders form for signing?</Text>
</>
) : (
  <Text>The shareholders form has now been re-sent for signing.</Text>
)
    }
    action={() => {
        step === 1 ? handleResend(data?.id) : onConfirmClose();
    }}    
/>

    <GridItem key={data?.number}>
        <ComplianceNumber complete={data?.complete} number={3} />
    </GridItem>

    <GridItem>
        <GridItem className="caseview__compliance-item-box" mt={1}>
            <Grid templateColumns={'1fr auto'}>
                <GridItem>
                    <Heading as={'h3'} fontWeight={'700'} fontSize={'18px'}>Shareholders Form</Heading>
                    <Text color={'#6E7997'} fontWeight={'400'} mt={2} fontSize={'18px'}>{statusText}</Text>
                </GridItem>
                <GridItem display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                    <Text color={'#fff'} fontSize={'11px'}
                        bgColor={(() => {
                            if (data?.complete) { return '#1CBE7A' } // green
                            else { return '#FFBF00' } // orange
                        })()}
                        paddingX={'10px'}
                        borderRadius={'4px'}>
                        {labelText}
                    </Text>
                </GridItem>
            </Grid>

            <HStack mt={4}>
                {data?.dispatchItem?.data?.shareholders_document ? 
                <Button className="button__white" style={{width: '100%'}}
                    onClick={() => {
                        dispatchSetShareholdersFormLoading();
                        dispatchGetShareholdersFormData()
                        onShareholdersFormOpen();
                    }}
                >
                    View Document
                </Button>:''}
                <Button className="button__white" style={{width: '100%'}}
                    onClick={() => {
                        onConfirmOpen();
                    }}
                >
                    Re-Send
                </Button>
            </HStack>

        </GridItem>
    </GridItem>


</>);

}

export default ComplianceItemShareholderForm;