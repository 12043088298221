import React from 'react';
import {Button, HStack, Icon, Text} from "@chakra-ui/react";

const NavButton = (props) => {
  const {icon, size, label, ...buttonProps} = props;
  return (<Button variant="ghost-on-accent" justifyContent="start" {...buttonProps}>
      <HStack spacing="3">
        <Icon
          as={icon}
          fill={'none'}
          boxSize={size}
        />
        <Text>{label}</Text>
      </HStack>
    </Button>);
};

export default NavButton;


