// import React, {useState} from 'react';
import React from 'react';
import {
  Box, Button, Container, Flex, Grid, GridItem,
  // ButtonGroup, FormControl, FormLabel, Icon, Input, SimpleGrid, Square
  // Stack, Text, Textarea
  Heading,
  Img, Link, 
} from "@chakra-ui/react";
// import {Form} from "formik";
// import {BigBlueCheck} from "../assets/icons/sprite";
import {LandingLogo} from "../../assets/icons/sprite";
import {Link as ReachLink} from "react-router-dom";
import "@fontsource/montserrat/900.css";
import LandingBg from '../../assets/img/landing-hero-bg.png'
// import LandingAbout from '../assets/img/landing-about.png'

// import {BsFillMoonFill, BsStars} from "react-icons/bs";
// import {useFormik} from "formik";
// import {useDispatch} from "react-redux";
// import {setDemoForm, setNewsletterForm} from "../redux/actions/landing";

const Landing = () => {

  // const dispatch = useDispatch();

  // const dispatchSetNewsletterForm = (data) => dispatch(setNewsletterForm(data))
  // const dispatchSetDemoForm = (data) => dispatch(setDemoForm(data))

  // const [requestDemoStep, setRequestDemoStep] = useState(1);

  // const [requestSubscribeNewsletterStep, setRequestSubscribeNewsletterStep] = useState(1);

/*
  const handleSubmit = async (values, type) => {

    if (type === 'demo') {
      dispatchSetDemoForm({
        email: values.email,
        name: values.name,
        message: values.message
      }).then(setRequestDemoStep(2))
        .catch((err) => console.warn(err))
    } else if (type === 'newsletter') {
      dispatchSetNewsletterForm({email: values.newsletterEmail})
        .then(setRequestSubscribeNewsletterStep(2))
        .catch((err) => console.warn(err))
    }
  }
*/

  // const formik = useFormik({
  //   initialValues: {
  //     name: '', email: '', message: '', newsletterEmail: ''
  //   }, onSubmit: handleSubmit
  // })

/*
  const featuresData = [
    {
      icon: BsStars,
      name: 'lorem ipsum',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
    }, {
      icon: BsFillMoonFill,
      name: 'Dolor sit amet',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
    }, {
      icon: BsStars,
      name: 'Consectetur adipiscing elit',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
    }, {
      icon: BsFillMoonFill,
      name: 'Sed do eiusmod',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
    },
  ]
  */

  return (<>
    {/*Navbar*/}

    <Box as={'header'}
         pb={{base: '12', md: '24'}}
         pt={{base: 4, md: 12}}>
      <Container maxW={'1400px'} px={{base: 2, md: 8, xl: 20}}>
        <Flex justify={'space-between'}>
          <Link as={ReachLink}
                to={'/'} ml={{base: 0, xl: 7}}
                mt={{base: 0, md: 0, lg: 1, xl: 3}}
                tabIndex={1}
          >
            <LandingLogo width={{base: 175, md: 350}} height={'55'}/>
          </Link>
          <Button
            mt={{base: 1, md: 0}}
            as={ReachLink}
            to={'/signin'}
            fontSize={{base: 12, md: 18}}
            minHeight={{base: 12, md: 14}}
            variant="primary"
            minW={{base: 'auto', md: 225}}
            backgroundColor={'#4285F4'}
            tabIndex={2}
          >Sign In</Button>
        </Flex>
      </Container>
    </Box>

    {/*Hero*/}


    <Container maxW={'1400px'} px={{base: 2, md: 8, xl: 20}}>
      <Grid
        pt={{base: '5', md: '10'}}
        pb={{base: 8, md: 16, lg: 16, xl: '24'}}
        ml={{base: '0', md: '2', xl: '10'}}
        templateColumns={{base: '1fr', md: '40% 1fr'}}
        gap={'30px'}
      >
        <GridItem>
          <Heading
            as={'h1'}
            size={{base: 'lg', md: '3xl'}}
            lineHeight={'1'}
            fontWeight={'extrabold'}
            letterSpacing={'tight'}
          >
            Coming Soon <br/>{' '}
            <Box as={'mark'}
                 color={'#4285F4'}
                 bg={'transparent'}
            >
              Law Comply.
            </Box>
          </Heading>

{/*
          <Text mt={{base: 2, lg: 12}}
                fontSize={{base: 14, lg: '18px'}}
                fontWeight={'medium'}
                color={'#343B55'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.
          </Text>

          <Stack direction={{base: 'column', sm: 'row'}} spacing={'4'} mt={'7'}>
            <Button size={'lg'}
                    as={ReachLink}
                    to={'/firm/signup'}
                    colorScheme={'blue'}
                    height={'14'}
                    px={'8'}
                    mt={{base: 1, md: 0}}
                    fontSize={{base: 12, md: 18}}
                    minHeight={{base: 12, md: 14}}
                    variant="primary"
                    minW={{base: 'auto', md: 225}}
                    tabIndex={3}
                    backgroundColor={'#4285F4'}
            >
              Sign Up
            </Button>
          </Stack>
*/}

        </GridItem>
        <GridItem>
          <Img
            height={'auto'}
            width={'100%'}
            objectFit={'contain'}
            src={LandingBg}
            alt={'Law Comply'}
            position={'relative'}
            right={{base: '0', md: '-22px'}}
            // left={{base: '0', md: '0'}}
            // marginRight={'-30px'}
          />

        </GridItem>

      </Grid>
    </Container>

    {/*Features*/}
    {/*
    <Box as={'section'}
         bg={'bg-surface'}
    >
      <Container py={{base: 2, md: 2, xl: 24}}
                 maxW={'1400px'}
                 px={{base: 4, md: 8, xl: 120}}>
        <Stack spacing={{base: '12', md: '16'}}>
          <Stack spacing={{base: '4', md: '5'}}
                 align={'center'}
                 textAlign={'center'}
          >
            <Stack spacing={'3'}>
              <Text fontSize={{base: 24, md: 36, lg: 48, xl: 50}}
                    fontWeight={'800'}
                    color={'accent'}

              >
                Features
              </Text>
            </Stack>
          </Stack>
          <SimpleGrid columns={{base: 1, md: 2, lg: 4}}
                      columnGap={{base: 10, md: 30, xl: 15}}
                      rowGap={{base: 10, md: 30, xl: 15}}>

            {featuresData.map((feature) => (<Stack
              key={feature.name}
              spacing={{base: 1, md: 5}}
            >
              <Square
                size={{base: 10, md: '110px'}}
                bg={'#4285F4'}
                color={'#fff'}
                borderRadius={{base: 10, md: '3xl'}}
                marginLeft={{base: 0, md: 14}}
              >
                <Icon as={feature.icon}
                      boxSize={{base: 5, md: 6}}/>

              </Square>
              <Stack spacing={{base: 1, md: 2}}>
                <Text fontSize={{base: 'lg', md: '28px'}}
                      fontWeight={'800'}
                      minH={{base: '48px', md: '84px'}}
                      display={'flex'}
                      alignItems={'flex-end'}
                >
                  {feature.name}
                </Text>
                <Text color={'muted'}
                      pr={{base: 0, xl: 12}}>
                  {feature.text}
                </Text>
              </Stack>
            </Stack>))}

          </SimpleGrid>

        </Stack>
      </Container>
    </Box>
    */}

    {/*About*/}
    {/*

    <Box as={'section'}
         pb={{base: '12', md: '16'}}
         pt={{base: 8, md: 10}}
         display={'flex'}
         justifyContent={'center'}
    >
      <SimpleGrid maxW={'1224px'}
                  px={{base: 2, md: 8, xl: 28}}
                  py={{base: 2, md: 8, xl: 28}}
                  bgColor={'#4285F4'}
                  columns={{base: 1, md: 2}}
                  columnGap={{base: 10, md: 30, xl: 85}}
                  rowGap={{base: 10, md: 30, xl: 15}}>
        <Stack
          p={{base: 4, md: 0}}
        ><Img
          src={LandingAbout}
        /></Stack>
        <Box
          px={{base: 4, md: 15, xl: 7}}
          py={{base: 4, md: 15, xl: 5}}
        >
          <Heading
            color={'#FFFFFF'}
            fontSize={{base: 24, md: 36, lg: 48, xl: 50}}
            fontWeight={'800'}
            pb={{base: 3, md: 4, xl: 5}}
          >About Us</Heading>
          <Text
            color={'#FFFFFF'}
            fontSize={{base: 14, md: 16, lg: 18, xl: 14}}
            pb={{base: 3, md: 5, lg: 6, xl: 8}}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </Text>

          <Button as={ReachLink}
                  variant={'ghost'}
                  to={'/firm/signup'}
                  bgColor={'#FFFFFF'}
                  minWidth={{base: 260}}
                  minH={{base: 55}}
                  fontSize={{base: 12, md: 16, lg: 18}}
                  tabIndex={4}
          >Sign Up Now</Button>
        </Box>

      </SimpleGrid>
    </Box>
    */}

    {/*Pricing*/}
    {/*

    <Box as={'section'}
         pb={{base: '12', md: 16}}
         pt={{base: 2, md: 3}}
         display={'flex'}
         justifyContent={'center'}
    >
      <Grid maxW={'1224px'}
            w={'100%'}
            px={{base: 6, md: 4, xl: 20}}
            py={{base: 2, md: 4, xl: 12}}
            templateColumns={{base: '1fr', lg: '45% 1fr'}}
            gap={{base: 0, md: 0, xl: 2}}
            rowGap={{base: 10, md: 30, xl: 15}}>
        <GridItem

          p={{base: 0, md: 0}}
        >
          <Heading size={'2xl'}
                   fontWeight={'900'}
                   fontSize={{base: 24, md: 36, lg: 48, xl: 50}}
          >Pricing</Heading>
        </GridItem>
        <GridItem
          px={{base: 0, md: 0, xl: 7}}
          py={{base: 4, md: 2, xl: 2}}
        >
          <Text
            fontSize={{base: 14, md: 16, lg: 16, xl: 16}}
            lineHeight={1.8}
            paddingBottom={{base: 2, md: 4, xl: 6}}
          >
            We prepare an exclusively-tailored plan for each <br/> client. Please Sign Up to the system and
            our<br/> manager will
            get you the quote.
          </Text>

          <Button
            mt={{base: 1, md: 0}}
            as={ReachLink}
            to={'/firm/signup'}
            fontSize={{base: 12, md: 18}}
            minHeight={{base: 12, md: 14}}
            px={{base: 3, md: 8}}
            variant="primary"
            minW={{base: 'auto', md: 225}}
            textTransform={'uppercase'}
            tabIndex={5}
            backgroundColor={'#4285F4'}

          >Sign Up and receive individual plan</Button>

        </GridItem>

      </Grid>
    </Box>
    */}


    {/*Request Demo*/}
    {/*

    <Box as={'section'}
         pb={{base: '12', md: '8', xl: '0'}}
         pt={{base: 2, md: 3}}
         display={'flex'}
         justifyContent={'center'}
    >
      <Grid maxW={'1224px'}
            w={'100%'}
            px={{base: 6, md: 4, xl: 0}}
            py={{base: 2, md: 4, xl: 12}}
            templateColumns={{base: '1fr', lg: '45.5% 1fr'}}
            gap={{base: 0, md: 0, xl: 0}}
            rowGap={{base: 10, md: 30, xl: 15}}>
        <GridItem
          paddingLeft={{base: 0, xl: 20}}
          paddingTop={{base: 0, md: 6}}
        >
          <Heading size={'2xl'}
                   fontWeight={'900'}
                   fontSize={{base: 24, md: 36, lg: 48, xl: 50}}
          >Request Demo</Heading>
        </GridItem>

        <GridItem
          m={{base: 0, md: 0, xl: 7}}
          px={{base: 6, md: 8, xl: 12}}
          py={{base: 4, md: 6, xl: 8}}
          boxShadow={'0px 0px 14px rgba(0, 0, 0, 0.15)'}
          borderRadius={10}
        >
          <Heading
            fontSize={{base: 14, md: 16, lg: 16, xl: 24}}
            fontWeight={900}
            lineHeight={1.3}
            paddingBottom={{base: 4, md: 5, xl: 6}}
          >
            Have a question?<br/>
            We would love to hear from you
          </Heading>
          {requestDemoStep === 1 ? <Stack spacing="6">

              <form
                onSubmit={(evt) => {
                  evt.preventDefault()
                  handleSubmit(formik.values, 'demo')
                }}
              >
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel
                      htmlFor="name"
                      fontSize={'16px'}>
                      First & Last Name
                    </FormLabel>
                    <Input
                      tabIndex={6}
                      pattern=".*\S+.*"
                      title="please dont use the white space"
                      id="name"
                      type="text"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter your First & Last Name'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="email" fontSize={'16px'}>Email</FormLabel>
                    <Input
                      tabIndex={7}
                      pattern=".*\S+.*"
                      title="please dont use the white space"
                      id="email"
                      type="email"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter your Email'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="message"
                               fontSize={'16px'}>Message</FormLabel>
                    <Textarea tabIndex={8}
                              id="message"
                              _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                              placeholder={'Enter your Message'}
                              fontSize={'16px'}
                              onChange={formik.handleChange}
                              value={formik.values.message}/>

                  </FormControl>
                </Stack>

                <Stack spacing="6" text-align={'right'} py={'20px'}>>
                  <Button
                    tabIndex={9}
                    type="submit"
                    fontSize={{base: 12, md: 18}}
                    textTransform={'uppercase'}
                    backgroundColor={'#4285F4'}
                    variant="primary">Send</Button>
                </Stack>

              </form>
            </Stack>
            : <Flex justifyContent={'center'}
                    flexDirection={'column'}
                    alignItems={'center'}
            >
              <Box bgColor={'#F9FBFC'}
                   borderRadius={'50%'}
                   padding={8}
                   marginBottom={8}
              >
                <BigBlueCheck width={'96px'} height={'96px'}/>
              </Box>

              <Text fontSize={'18px'}>Your request is being processed.</Text>

            </Flex>}
        </GridItem>

      </Grid>
    </Box>
    */}

    {/*Footer*/}

    {/*

    <Box as={'footer'}
         bgColor={'#343855'}
         color={'#ffffff'}
         pb={{base: '12', md: '0'}}
         pt={{base: 10, md: 12, lg: 12, xl: 24}}
         pl={{base: 4, lg: 0}}
         pr={{base: 4, lg: 0}}
    >
      <Container as={Grid} maxW={'1380px'}
                 px={{base: 2, md: 8, xl: 20}}
                 templateColumns={{lg: '1fr 1fr'}}
      >
        <GridItem
          borderBottom={{lg: '1px solid #D9D9D9'}}
        >
          <Heading
            fontWeight={'900'}
            fontSize={{base: 16, md: 18, lg: 20, xl: 22}}
            pb={{base: 6, lg: 0}}
          >Subscribe to our newsletter</Heading>

        </GridItem>

        <GridItem
          borderBottom={{lg: '1px solid #D9D9D9'}}
          pb={{lg: '50px'}}
        >
          {requestSubscribeNewsletterStep === 1 ? <form
              onSubmit={(evt) => {
                evt.preventDefault()
                handleSubmit(formik.values, 'newsletter')
              }}
            >

              <FormControl
                display={'flex'}>
                <Input
                  tabIndex={10}
                  id="newsletterEmail"
                  type="email"
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  placeholder={'Your email'}
                  fontSize={'16px'}
                  onChange={formik.handleChange}
                  value={formik.values.newsletterEmail}
                  minH={'48px'}
                  h={'48px'}
                  color={'#ffffff'}
                  bgColor={'transparent'}
                  border={'1px solid #FFFFFF'}
                  _placeholder={{color: {base: '#ffffff'}, fontSize: {base: 12, md: 14, lg: 16}}}
                />

                <Button
                  tabIndex={11}
                  type="submit"
                  fontSize={{base: 12, md: 16}}
                  minH={'48px'}
                  h={'48px'}
                  ml={4}
                  bgColor={'#ffffff'}
                  color={'#343B55'}
                  minW={{base: '98px', sm: '108px'}}
                  variant="primary">Send</Button>
              </FormControl>

            </form>
            : <Flex justifyContent={'center'}
                    flexDirection={'row'}
                    alignItems={'center'}
            >
              <Box bgColor={'#F9FBFC'}
                   borderRadius={'50%'}
                   padding={2}
                   marginBottom={1}
                   marginRight={4}
              >
                <BigBlueCheck width={'28px'} height={'28px'}/>
              </Box>

              <Text fontSize={'15px'}>Your request is being processed.</Text>

            </Flex>}
        < /GridItem>

        <GridItem
          pt={8}
        >
          <Text>(c) Law Comply. All rights reserved</Text>
        </GridItem>

        <GridItem
          pt={7}
          pb={{md: 8, lg: 32}}
        >

          <ButtonGroup
            variant="ghost"
            spacing={{lg: 3}}
            fontWeight={'500'}
            fontSize={'sm'}
            w={'100%'}
            justifyContent={{lg: 'flex-end'}}
            flexDirection={{base: 'column', lg: 'row'}}
          >
            <Link tabIndex={12}>Terms & Conditions</Link>
            <Link tabIndex={13}>Privacy Policy</Link>
            <Link tabIndex={14}>Cookies Policy</Link>
            <Link tabIndex={15}>Security Statement</Link>
          </ButtonGroup>

        </GridItem>

      </Container>

    </Box>
    */

    <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/139616865.js"></script>
  }

  </>);
};

export default Landing;
