import React from 'react';
import TextPlaceholder from "./TextPlaceholder";

const ErrorNotFound = () => {
  return (
    <>
      <TextPlaceholder
        content={' It is the 404 error baby, let\'s try again from the beginning.'}/>
    </>
  );
};

export default ErrorNotFound;