import React, {useEffect, useState} from 'react';
import {
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
  Button,
  HStack,
  Flex,
  Box,
  useRadioGroup,
  Menu,
  MenuButton, MenuList, MenuItem, useDisclosure, Stack, FormControl, FormLabel, Text, Input, VStack,
  RadioGroup, Radio
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Alert from "../../components/Alert";
import {Link as ReachLink, Outlet, useParams} from "react-router-dom";
import {CorporateIcon, DoneIcon, IndividualIcon} from "../../assets/icons/sprite";
import {
  getClientsList, setClientErrorMessage,
  setLoadingFlag, setNewClient
} from "../../redux/actions/clients";
import RadioCard from "../../components/RadioCard";
import {useFormik} from "formik";
import {ChevronDownIcon} from "@chakra-ui/icons";
import AlertComponent from "../../components/AlertComponent";

const SolicitorCases = () => {

  const { id } = useParams();

  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('New Private Case');
  const [formMode, setFormMode] = useState('invite');
  const dispatch = useDispatch();
  const [moreThanOne, setMoreThanOne] = useState('false');
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const dispatchSetClientErrorMessage = (message) => {dispatch(setClientErrorMessage(message))}
  const dispatchList = (data) => dispatch(getClientsList(data))
  const dispatchLoadingFlag = (credentials) => dispatch(setLoadingFlag(credentials))
  const dispatchSetNewClient = (data) => dispatch(setNewClient(data))

  const dispatchClientsList = useSelector(state => state.clients);
  const dispatchListPagination = useSelector(state => state.clients?.data?.meta?.pagination);
  const dispatchIsDataLoading = useSelector(state => state.clients?.isDataLoading);
  const dispatchErrorMessage = useSelector(state => state.clients.errorMessage)

  const handleSubmit = async values => {

    if (buttonDisabled && !dispatchErrorMessage) { return; }
    setButtonDisabled(true);

    if (values.formmode === 'reinvite') {
      dispatchSetNewClient({
        "client_id": values.clientid,
        "type": "individual",
      }).then(() => {
        setTitle('Invite has been sent');
        setStep(2);
        dispatch(setLoadingFlag());
        dispatch(getClientsList());
      })
      return;
    }

    dispatchSetNewClient({
      "type": "individual",
      "email": values.email,
      "first_name": values.firstName,
      "last_name": values.lastName,
      "email2": values.email2,
      "first_name2": values.firstName2,
      "last_name2": values.lastName2,
      "morethanone": moreThanOne
    }).then(() => {
      setTitle('Invite has been sent');
      setStep(2);
      dispatch(setLoadingFlag());
      dispatch(getClientsList());
    })

  }

  const {isOpen, onOpen, onClose} = useDisclosure();

  const resetDialog = () => {
    setButtonDisabled(false);
    formik.values.firstName = '';
    formik.values.lastName = '';
    formik.values.email = '';
    formik.values.firstName2 = '';
    formik.values.lastName2 = '';
    formik.values.email2 = '';
    formik.values.moreThanOne = 'false'
    formik.values.formmode = 'invite'
    formik.values.clientid = 0
    setStep(1);
    setFormMode('invite');
    setTitle('New Private Case');
    dispatchSetClientErrorMessage(null);
    onClose();
  }

  const formik = useFormik({
    initialValues: {
      firstName: '', lastName: '', email: '', 
      firstName2: '', lastName2: '', email2: '', 
      moreThanOne: 'false', formmode: 'invite', clientid: 0
    }, onSubmit: handleSubmit
  })

  const {id: hideListing} = useParams();

  const [pagePath, setPagePath] = useState('clients?page=')

  const thStyles = {
    color: '#1A1E29',
    fontSize: '14px',
    textTransform: 'none',
    opacity: '0.5',
    fontWeight: 400,
    border: 'none'
  }

  const options = ['Show All', 'Private', 'Corporate']

  const {getRadioProps} = useRadioGroup({
    name: 'filter',
    defaultValue: 'Show All',
    onChange: (value) => {
      switch (value) {
        case 'Private':
          setPagePath('clients?type=individual&page=')
          getData('clients?type=individual')
          break;
        case 'Corporate':
          setPagePath('clients?type=corporate&page=')
          getData('clients?type=corporate')
          break;
        default:
          setPagePath('clients?page=')
          getData()
          break;
      }
    },
  })

  const getData = (url = 'clients') => {
    // dispatch(checkSolicitorAuth())
    dispatchLoadingFlag();
    dispatchList(url);
  }

  useEffect(() => {
    if (!id) {
      dispatch(setLoadingFlag());
      dispatch(getClientsList());
    }

  }, [dispatch, id])

  const changeMoreThanOne = (value) => {
    setMoreThanOne(value);
  }

  return (<>
    <Outlet/>
    <Alert
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={resetDialog}
      header={title}
      text={step === 1 ? <form
        onSubmit={formik.handleSubmit}
      >
        <Input
              id="formmode"
              type="hidden"
              onChange={formik.handleChange}
              value={formik.values.formmode}
            />

        <Stack spacing="5">

          {dispatchErrorMessage && (<AlertComponent
            message={dispatchErrorMessage}
          />)}

          {formMode === 'invite' ?
          <>

          <Text fontSize={'20'} fontWeight={'600'} align={'left'}>Main Contact</Text>

          <FormControl>
            <FormLabel
              htmlFor="firstName"
              fontSize={'18px'}>
              First name
            </FormLabel>
            <Input
              id="firstName"
              type="text"
              required
              placeholder={'Enter first name'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="lastName"
              fontSize={'18px'}>
              Last name
            </FormLabel>
            <Input
              id="lastName"
              type="text"
              required
              placeholder={'Enter last name'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="email" fontSize={'18px'}>Email</FormLabel>
            <Input
              id="email"
              type="email"
              required
              placeholder={'Enter email'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </FormControl>

          <Text align={'left'}>Is there more than one individual?</Text>

            <RadioGroup
              id={'moreThanOne'}
              name={'moreThanOne'}
              onChange={changeMoreThanOne.bind(this)}
              defaultValue='false'
            >
              <Stack direction='row'>
                <Radio value='false'>No</Radio>
                <Radio value='true'>Yes</Radio> 
              </Stack>
            </RadioGroup>

          { moreThanOne === "true" ? <><Text fontSize={'20'} fontWeight={'600'} align={'left'}>Second Individual</Text>
          
          <FormControl>
            <FormLabel
              htmlFor="firstName2"
              fontSize={'18px'}>
              First name
            </FormLabel>
            <Input
              id="firstName2"
              type="text"
              required
              placeholder={'Enter first name'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.firstName2}
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="lastName2"
              fontSize={'18px'}>
              Last name
            </FormLabel>
            <Input
              id="lastName2"
              type="text"
              required
              placeholder={'Enter last name'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.lastName2}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="email2" fontSize={'18px'}>Email</FormLabel>
            <Input
              id="email2"
              type="email"
              required
              placeholder={'Enter email'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.email2}
            />
          </FormControl></>
          : null }

          </>
          :<>

        <Text
          paddingX={'100px'}
          pb={8}
          align={'center'}>Are you sure you want to re-invite this client?</Text>
          </>}

        </Stack>

        <Stack spacing="6" text-align={'right'} py={'20px'}>

        {formMode === 'invite' ?
          <Button
            type="submit"
            fontSize={'18px'}
            disabled={buttonDisabled && !dispatchErrorMessage}
            variant="primary">Invite Client Now</Button>
          :
          <Button
            type="submit"
            fontSize={'18px'}
            variant="primary">Re-Invite Client Now</Button>
        }

        </Stack>

      </form> : step === 2 ? <VStack
        marginBottom={10}
      >
        <DoneIcon
          width={93}
          height={93}
          marginTop={6}
          marginBottom={4}/>

        <Text
          paddingX={'100px'}
          pb={8}
          align={'center'}>This client will be sent an invitation.</Text>

        <Button
          type="submit"
          fontSize={'18px'}
          variant="primary"
          minWidth={184}
          minHeight={'48px'}
          height={'48px'}
          onClick={resetDialog}>Ok</Button>
      </VStack> : null}
      action={null}
      size={'3xl'}
      contentPaddingY={0}
    />

    {!hideListing ? <Box>

      <HStack as={Flex}>
        <Heading
          fontSize={'32px'}
          lineHeight={'48px'}
          mb={'17px'}
          flexGrow={1}
        >Cases</Heading>

        <Menu>
          <MenuButton
            bgColor={'#4285F4'}
            color={'#ffffff'}
            fontWeight={'700'}
            type="button"
            paddingX={9}
            alignSelf={'baseline'}
            fontSize={'18px'}
            height={'48px'}
            minHeight={'48px'}
            variant="primary"
            transition='all 0.2s'
            borderRadius='md'
            borderWidth='1px'
            _hover={{bg: '#72a9ff'}}
            _expanded={{bg: '#4285F4'}}
            _focus={{boxShadow: 'outline'}}
          >
            New Case <ChevronDownIcon/>
          </MenuButton>
          <MenuList
            width={'210px'}
            minWidth={'210px'}>
            <MenuItem
              fontSize={'18px'}
              justifyContent={'center'}
              onClick={onOpen}
            >Private</MenuItem>
            <MenuItem as={ReachLink}
                      to={'/solicitor/cases/corporate/invite/'}
                      fontSize={'18px'}
                      justifyContent={'center'}>Corporate</MenuItem>
          </MenuList>
        </Menu>        

      </HStack>
      <HStack
        as={Flex}
        mb={10}
      >

        {options.map((value, idx) => {
          const radio = getRadioProps({value})
          return (<RadioCard key={value} {...radio}>
            {value}
          </RadioCard>)
        })}

      </HStack>
      
      {dispatchIsDataLoading
        ? <Loading/>
        : dispatchClientsList.data
          ? <> <TableContainer
            backgroundColor={'#F9FBFC'}
            color={'#1A1E29'}
            pl={'16px'}
            pt={'12px'}
            pr={'20px'}
          >
            <Table
              variant='simple'
            >
              <Thead>
                <Tr>
                  <Th
                    {...thStyles}
                  >Case Ref. No.</Th>
                  <Th
                    {...thStyles}
                  >Client Name</Th>
                  {/*
                  <Th
                    {...thStyles}
                  >Email</Th>
                  */}
                  <Th
                    {...thStyles}
                  >Status</Th>
                  <Th
                    {...thStyles}
                  >Type</Th>
                  <Th
                    {...thStyles}
                    width={'150px'}
                    textAlign={'center'}
                  >&nbsp;</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dispatchClientsList?.data?.data?.map(({
                                                         id, email, status, first_name, last_name, type, name, main_contact, account_form
                                                       }) => {
                  id = id.toString()
                  if (type === 'corporate') { email = main_contact?.email }
                  var companyName = name; var emailTrimmed = email;
                  if (companyName && companyName.length > 25) { companyName = companyName.substr(0,25) + "..." }
                  if (email && emailTrimmed.length > 20) { emailTrimmed = emailTrimmed.substr(0,20) + "..." }

                  var nameToDisplay = ""
                  if (type === 'individual') {
                    if (main_contact) {
                      nameToDisplay = main_contact?.first_name + " " + main_contact?.last_name;
                    }
                    else {
                      nameToDisplay = first_name + " " + last_name; 
                    }
                  } 
                  else {
                    nameToDisplay = companyName;
                  }

                  var caseReferenceNumber = "Not Set"
                  if (account_form?.case_reference_number) {
                    caseReferenceNumber = account_form?.case_reference_number
                  }

                  return (<Tr key={id}>
                    <Td
                      fontSize={'18px'}
                      minWidth={'80px'}
                    >{caseReferenceNumber}</Td>
                    <Td
                      fontSize={'18px'}
                      minWidth={'95px'}
                    ><Link
                      to={type === 'individual' ? id : null}
                      as={ReachLink}
                      color={'#1A1E29'}
                    >{nameToDisplay}</Link></Td>
                    {/*
                    <Td
                      fontSize={'18px'}
                      color={'#B4BAC9'}
                    >
                      {emailTrimmed}
                    </Td>
                    */}
                    <Td
                    >
                      {status.toString() === 'pending' ? <Button
                        colorScheme='orange'
                        backgroundColor={'#C8392D'}
                        color={'#FFFFFF'}
                        size='xs'
                        fontWeight={400}
                        fontSize={'14px'}
                        w={'100%'}
                        // onClick={() => onChangeStatus(id)}
                      >
                        Pending
                      </Button> : <Button
                        colorScheme='green'
                        size='xs'
                        fontWeight={400}
                        fontSize={'14px'}
                        w={'100%'}
                        // onClick={() => onChangeStatus(id, false)}
                      >
                        Onboard
                      </Button>

                      }
                    </Td>
                    <Td
                      fontSize={'18px'}
                    >
                      {type === 'individual'
                        ? <><IndividualIcon
                          w={18}
                          h={18}
                          fill={'none'}
                          mr={'2'}/>Private</>
                        : <><CorporateIcon
                          w={18}
                          h={18}
                          fill={'none'}
                          mr={'2'}/>Corporate</>
                      }
                    </Td>
                    <Td textAlign={'center'}>

                    {type === 'individual' && status.toString() === 'pending' ? 
                    <>
                    <Link
                      to={'/solicitor/cases/' + id}
                      as={ReachLink}
                    >
                    <Button
                        colorScheme='green'
                        size='sm'
                        fontWeight={600}
                        fontSize={'14px'}
                        w={'100%'}
                      >View</Button>
                    </Link>
                    </>:<></>
                  }

                  {type === 'individual' && status.toString() !== 'pending' ? 
                    <>
                    <Link
                      to={'/solicitor/cases/' + id}
                      as={ReachLink}
                    >
                    <Button
                        colorScheme='green'
                        size='sm'
                        fontWeight={600}
                        fontSize={'14px'}
                        w={'100%'}
                      >View</Button>
                    </Link>
                    </>:<></>
                  }

                   {type === 'corporate' ?
                    <Link
                      to={'/solicitor/cases/corporate/' + id}
                      as={ReachLink}
                    >
                    <Button
                        colorScheme='green'
                        size='sm'
                        fontWeight={600}
                        fontSize={'14px'}
                        w={'100%'}
                      >View</Button>
                    </Link>
                    :<></>}
                    </Td>
                  </Tr>)
                })}
              </Tbody>
            </Table>
          </TableContainer>

            <Pagination
              dispatchList={dispatchList}
              dispatchListPagination={dispatchListPagination}
              dispatchLoadingFlag={dispatchLoadingFlag}
              pagePath={pagePath}
              name={'Clients'}
            />
          </>
          : <Loading/>}
    </Box> : null}

  </>);
}
export default SolicitorCases;
