import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import AlertComponent from "../../../../../../components/AlertComponent";
import Loading from "../../../../../../components/Loading";
import Alert from "../../../../../../components/Alert";
import { Button, useDisclosure, FormControl, FormLabel, Stack, VStack, Select, Textarea, Text } from "@chakra-ui/react";
import { DoneIcon } from "../../../../../../assets/icons/sprite"
import { setIdPalReInvite } from "../../../../../../redux/actions/clients";
import { useFormik } from "formik";
import IconReRequest from "../../../../../../assets/icons/icon-rerequest.png"

function IndividualsReRequest(data) {

    const id = data?.id
    const dispatch = useDispatch();  
    const navigate = useNavigate();
    const [clientId, setClientId] = useState(0);
    const { isOpen: isReRequestOpen, onOpen: onOpenReRequest, onClose: onReRequestClose } = useDisclosure();
    const [step, setStep] = useState(1);
    const [title, setTitle] = useState("Re-Request Documents");
    const [typeOfDocument, setTypeOfDocument] = useState("proof_of_address");
    const dispatchSetIdPalReInvite = (clientId, documentType, reason) => dispatch(setIdPalReInvite(clientId, documentType, reason));
    const dispatchErrorMessage = useSelector((state) => state.clients.errorMessage);
    const [isLoading, setIsLoading] = useState(false)

    const resetDialog = () => {
        onReRequestClose();
        navigate(0);
      };    
      const handleSubmit = async (values) => {
        setIsLoading(true)
        dispatchSetIdPalReInvite(clientId, typeOfDocument, values.reason).then(() => {
          setTitle("Re-Request Sent");
          setStep(2).then(() => navigate(0));
          setIsLoading(false)
        });
    };    
    const formik = useFormik({
        initialValues: {
          documentType: typeOfDocument,
          reason: "",
        },
        onSubmit: handleSubmit,
      });
      const updateDocumentType = (e) => {
        setTypeOfDocument(e.target.value);
      };
    const inputStyles = {
        type: 'text',
        required: true,
        _placeholder: {fontSize: {base: 12, md: 14, lg: 16}},
        placeholder: 'Please enter the reason.',
        fontSize: '16px',
    }      

    return (
<>

<Link className="dropdown-item" onClick={() => {
  setClientId(id);
  onOpenReRequest();
}}>
  <img src={IconReRequest} className="individuals__menu-icon" alt=""/> Re-Request
</Link>

<Alert
        isOpen={isReRequestOpen}
        onOpen={onOpenReRequest}
        onClose={onReRequestClose}
        header={title}
        text={
          step === 1 ? (
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing="5">
                {dispatchErrorMessage && (
                  <AlertComponent message={dispatchErrorMessage} />
                )}

                <Text>
                  Please select the document/s below that you want the client to
                  re-submit and we'll send them an email.
                </Text>

                <FormControl>
                  <FormLabel
                    htmlFor="documentType"
                    fontSize={"18px"}
                  ></FormLabel>

                  <Select
                    labelId="documentType"
                    name="documentType"
                    id="documentType"
                    label="documentType"
                    onChange={updateDocumentType}
                    disabled={isLoading}
                  >
                    <option value='proof_of_address'>Proof of Address</option>
                    <option value='both'>ID Document & Proof of Address</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <Textarea
                    disabled={isLoading}
                    id="reason"
                    name="reason"
                    {...inputStyles}             
                    onChange={formik.handleChange}       
                  /> 
                </FormControl>

              </Stack>

              <Stack spacing="6" text-align={"right"} py={"20px"}>
                <Button type="submit" fontSize={"18px"} variant="primary"
                  disabled={isLoading}
                >
                  {isLoading ? 
                  <Loading size={'xs'} marginRight={'15px'} marginBottom={'3px'}/>
                  : ''}
                  Re-Request
                </Button>
              </Stack>
            </form>
          ) : step === 2 ? (
            <VStack marginBottom={10}>
              <DoneIcon width={93} height={93} marginTop={6} marginBottom={4} />

              <Text paddingX={"100px"} pb={8} align={"center"}>
                The client has now been sent an email asking them to resubmit
                their documents.
              </Text>

              <Button
                type="submit"
                fontSize={"18px"}
                variant="primary"
                minWidth={184}
                minHeight={"48px"}
                height={"48px"}
                onClick={resetDialog}
              >
                OK
              </Button>
            </VStack>
          ) : null
        }
        action={null}
        size={"3xl"}
        contentPaddingY={0}
      />
</>);
};

export default IndividualsReRequest;