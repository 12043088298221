import React, { useEffect } from "react";
import { Flex, Grid, GridItem, Heading, HStack, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import StatsCarditem from "../../components/StatsCarditem";
import { AdminClients } from "../../assets/icons/sprite";
import {
  getSolicitorsDashboardMeta,
  setDashboardComponentLoading,
  setSolicitorsDashboardLog,
} from "../../redux/actions/solicitors";
import Loading from "../../components/Loading";
import { getClientsList } from "../../redux/actions/clients";
import { useNavigate } from "react-router-dom";
import { getCase } from "../../services/request";

const SolicitorDashboard = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectSolicitorAuthName = useSelector(
    (state) => state.solicitorAuth?.currentUser?.name
  );
  const selectIsDashboardLogLoading = useSelector(
    (state) => state.solicitors?.isDashboardLogLoading
  );
  const selectIsDashboardMetaLoading = useSelector(
    (state) => state.solicitors?.isDashboardMetaLoading
  );

  const dispatchClientsList = useSelector((state) => state.clients);
  const selectDashboardLog = useSelector(
    (state) => state.solicitors?.dashboardLog
  );

  const getClientCaseId = async (id) => {
    try {
        const res = await getCase(id);        
        if (res.ok) {
            const response = await res.json();
            return response.data.id_client_parent;
        }
    } catch (error) {
        console.error('Failed to fetch case:', error);
    } finally {
        return null;
    }
  }

  const redirectToCase = async (client_id, companyName) => {
    const clientCaseId = await getClientCaseId(client_id);    
    if (clientCaseId) {
      if (companyName) {
        navigate(`/solicitor/cases/corporate/${clientCaseId}`);  
      }
      else {
        navigate(`/solicitor/cases/${clientCaseId}`);
      }
    }
    else {
      alert('Unfortunately that case is not viewable under your account.')
    }
  };

  useEffect(() => {
    dispatch(setDashboardComponentLoading("meta"));
    dispatch(setDashboardComponentLoading("log"));

    dispatch(getSolicitorsDashboardMeta());
    dispatch(setSolicitorsDashboardLog());
    dispatch(getClientsList());
  }, [dispatch]);
  return (
    <>
      <Heading fontSize={"32px"} lineHeight={"48px"}>
        Welcome, {`${selectSolicitorAuthName}`}{" "}
      </Heading>

      <Grid templateColumns={"1fr 1fr"} gap={"25px"} paddingTop={7}>
        {selectIsDashboardMetaLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <GridItem>
              <StatsCarditem
                title={"Cases"}
                subtitle={"number of cases in total"}
                digits={dispatchClientsList?.data?.data.length}
                icon={<AdminClients />}
              />
            </GridItem>
            <GridItem></GridItem>
            <GridItem></GridItem>
          </React.Fragment>
        )}

        {selectIsDashboardLogLoading ? (
          <Loading />
        ) : (
          <GridItem gridColumn={"1 / 3"}>
            <Grid templateColumns={"auto auto 1fr"} gap={"15px"} mb={5}>
              <GridItem>
                <Heading fontSize={"24px"} lineHeight={"59px"}>
                  Notifications
                </Heading>
              </GridItem>

              <GridItem
                as={Flex}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Flex
                  display={"inline-grid"}
                  bgColor={"#599EF2"}
                  borderRadius={"8px"}
                  fontSize={"18px"}
                  lineHeight={"27px"}
                  color={"#ffffff"}
                  height={"32px"}
                  minHeight={"32px"}
                  minWidth={"32px"}
                  textAlign={"center"}
                  fontWeight={"600"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {
                    selectDashboardLog?.data?.filter(
                      (el) => el.name || el.first_name
                    )?.length
                  }
                </Flex>
              </GridItem>

              <GridItem
                as={Flex}
                justifyContent={"right"}
                alignItems={"center"}
              ></GridItem>
            </Grid>

            <GridItem
              gridColumn={"1 / 3"}
              bgColor={"#F9FBFC"}
              py={"36px"}
              px={"48px"}
              borderRadius={"8px"}
            >
              <Grid templateColumns={"1fr auto"} gap={"24px"}>
                {selectDashboardLog?.data?.map(
                  ({
                    name: companyName,
                    first_name: firstName,
                    last_name: lastName,
                    action,
                    created_at: date,
                    id,
                    client_id,
                    client
                  }) => {

                    var clientId = null;
                    if (companyName) {
                      clientId = client?.id
                    }
                    else {
                      clientId = client?.corporate_parent?.id 
                    }

                    if (firstName || companyName) {
                      return (
                        <React.Fragment key={id}>
                          <GridItem>
                            <HStack>
                              <Text
                                onClick={() => redirectToCase(clientId, companyName)}
                                fontWeight={"500"}
                                cursor={"pointer"}
                                color={"#223268"}
                                fontSize={"18px"}
                              >
                                {!firstName
                                  ? companyName
                                  : firstName + " " + lastName}
                              </Text>
                              <Text
                                color={"#223268"}
                                fontSize={"18px"}
                                fontWeight={400}
                              >
                                {action}
                              </Text>
                            </HStack>
                          </GridItem>

                          <GridItem>{new Date(date).toUTCString()}</GridItem>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  }
                )}
              </Grid>
            </GridItem>
          </GridItem>
        )}

        {/*TODO: pagination*/}
        {/*<Pagination*/}
        {/*  dispatchList={null}*/}
        {/*  dispatchListPagination={null}*/}
        {/*  dispatchLoadingFlag={null}*/}
        {/*  pagePath={null}*/}
        {/*  name={null}*/}
        {/*/>*/}
      </Grid>
    </>
  );
};

export default SolicitorDashboard;
