import {
  SET_NEWSLETTER_FORM_SUCCESS,
  SET_NEWSLETTER_FORM_FAILED,
  SET_DEMO_REQUEST_FORM_SUCCESS,
  SET_DEMO_REQUEST_FORM_FAILED,
} from "../actions";

const initialState = {
  error: null,
  isLoading: null,
  response: null,
  data: null
}

export default function landingReducer(state = initialState, action) {
  switch (action.type) {

    case SET_NEWSLETTER_FORM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isDataLoading: false
      }

    case SET_NEWSLETTER_FORM_FAILED:
      return {
        ...state,
        error: action.payload,
        isDataLoading: false
      }

    case SET_DEMO_REQUEST_FORM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isDataLoading: false
      }

    case SET_DEMO_REQUEST_FORM_FAILED:
      return {
        ...state,
        error: action.payload,
        isDataLoading: false
      }


    default:
      return state
  }
}
