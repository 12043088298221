import {makeRequest, API_URL} from "../../services/request";
import {
  SET_DATA_LOADING_FALSE,
  SET_DATA_LOADING_TRUE,
  ACCOUNTS_LIST_DOWNLOADED,
  ACCOUNTS_LIST_NOT_DOWNLOADED,
  ACCOUNTS_GET_BY_ID_SUCCESS,
  ACCOUNTS_SET_ERR_MESSAGE,
  SET_NEW_ACCOUNT_SUCCESS,
  SET_NEW_ACCOUNT_ERR_MESSAGE,
  SET_ACCOUNT_NEW_DATA,
  SET_ACCOUNT_NEW_DATA_FAILED,
  ACCOUNT_DELETE_FAILED, ACCOUNT_DELETE_SUCCESS
} from "./index";

export const getAccountsList = (url = `accounts`) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}${url}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: ACCOUNTS_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: ACCOUNTS_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getAccountById = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}accounts/${id}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: ACCOUNTS_GET_BY_ID_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: ACCOUNTS_SET_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const setAccountData = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}accounts/${data.id}`, data.newData, 'PUT', true)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: SET_ACCOUNT_NEW_DATA, payload: data})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_ACCOUNT_NEW_DATA_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const deleteAccount = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}accounts/${id}`, null, 'DELETE')
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: ACCOUNT_DELETE_SUCCESS, payload: id})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: ACCOUNT_DELETE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setNewAccount = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}accounts`, data, 'POST', true).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: SET_NEW_ACCOUNT_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: SET_NEW_ACCOUNT_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const setAccountErrorMessage = (data) => {
  return ({type: SET_NEW_ACCOUNT_ERR_MESSAGE, payload: data});
}

export const setLoadingFlag = (flag = true) => {
  return (flag
      ? {type: SET_DATA_LOADING_TRUE}
      : {type: SET_DATA_LOADING_FALSE}
  );
}

