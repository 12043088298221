import {
  SOLICITORS_LIST_DOWNLOADED,
  SOLICITORS_LIST_NOT_DOWNLOADED,
  SOLICITORS_ACTIVATE,
  SOLICITORS_DEACTIVATE,
  SOLICITORS_GET_BY_ID_SUCCESS,
  SOLICITORS_SET_ERR_MESSAGE,
  SET_DATA_LOADING_TRUE,
  SET_DATA_LOADING_FALSE,
  SET_NEW_SOLICITOR_ERR_MESSAGE,
  SET_NEW_SOLICITOR_SUCCESS,
  SET_SOLICITOR_NEW_DATA,
  SET_SOLICITOR_NEW_DATA_FAILED,
  GET_SOLICITOR_DETAILS_BY_SECRET_SUCCESS,
  GET_SOLICITOR_DETAILS_BY_SECRET_FAILED,
  GET_SOLICITOR_DASHBOARD_META_SUCCESS,
  GET_SOLICITOR_DASHBOARD_META_FAILED,
  GET_SOLICITOR_DASHBOARD_LOG_SUCCESS,
  GET_SOLICITOR_DASHBOARD_LOG_FAILED,
  SET_SOLICITOR_DASHBOARD_LOG_LOADING,
  SET_SOLICITOR_DASHBOARD_META_LOADING,
  SET_COMPANY_DETAILS_LOADING_TRUE,
  SET_COMPANY_DETAILS_LOADING_FALSE,
  GET_COMPANY_DETAILS_BY_KEYWORD_SUCCESS,
  GET_COMPANY_DETAILS_BY_KEYWORD_FAILED,
  SET_INVITE_CORPORATE_SUCCESS,
  SET_INVITE_CORPORATE_FAILED,
  GET_COMPANY_CONTACTS_BY_ID_SUCCESS,
  GET_COMPANY_CONTACTS_BY_ID_FAILED,
  SET_COMPANY_DOCUMENTS_COMPANY_REPORT_LOADING_TRUE,
  SET_COMPANY_DOCUMENTS_UBO_REPORT_LOADING_TRUE,
  SET_COMPANY_DOCUMENTS_CERT_OF_INCORPORATION_LOADING_TRUE,
  SET_COMPANY_DOCUMENTS_CONSTITUTION_LOADING_TRUE,
  GET_COMPANY_COMPANY_REPORT_FAILED,
  GET_COMPANY_UBO_REPORT_FAILED,
  GET_COMPANY_CERT_OF_INCORPORATION_FAILED,
  GET_COMPANY_CONSTITUTION_FAILED,
  GET_COMPANY_COMPANY_REPORT_SUCCESS,
  GET_COMPANY_UBO_REPORT_SUCCESS,
  GET_COMPANY_CERT_OF_INCORPORATION_SUCCESS,
  GET_COMPANY_CONSTITUTION_SUCCESS,
  GET_COMPANY_DIRECTORS_SUCCESS,
  GET_COMPANY_DIRECTORS_FAILED,
  SET_COMPANY_EMPTY_DETAILS_BY_KEYWORD_SUCCESS,
  SOLICITOR_DELETE_SUCCESS,
  SOLICITOR_DELETE_FAILED

} from "../actions";

const initialState = {
  data: null,
  errorMessage: null,
  isDataLoading: false,
  getById: null,
  solicitorBySecret: null,
  isDashboardLogLoading: false,
  isDashboardMetaLoading: false,
  dashboardLog: null,
  dashboardMeta: null,
  isCompanyDetailsLoading: false,
  companyDetailsByKeyword: null,
  companyContactsById: null,
  // for response?
  inviteCorporate: null,
  isCompanyReportLoading: false,
  isScheduleOfBeneficialInterestsLoading: false,
  isCertOfIncorporationLoading: false,
  isConstitutionLoading: false,
  getCompanyReport: false,
  getScheduleOfBeneficialInterests: false,
  getCertOfIncorporation: false,
  getConstitution: false,
  companyDirectors: null,
}

export default function solicitorsManageReducer(state = initialState, action) {
  switch (action.type) {
    case SOLICITORS_LIST_DOWNLOADED:
      return {
        ...state,
        data: action.payload,
        isDataLoading: false
      }
    case SOLICITORS_LIST_NOT_DOWNLOADED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case SOLICITORS_ACTIVATE:
      return {
        ...state
      }
    case SOLICITORS_DEACTIVATE:
      return {
        ...state
      }
    case SET_DATA_LOADING_TRUE:
      return {
        ...state,
        isDataLoading: true
      }
    case SET_DATA_LOADING_FALSE:
      return {
        ...state,
        isDataLoading: false
      }
    case SOLICITORS_GET_BY_ID_SUCCESS:
      return {
        ...state,
        getById: action.payload,
        isDataLoading: false
      }
    case SOLICITORS_SET_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case SET_NEW_SOLICITOR_SUCCESS:
      return {
        ...state,
      }
    case SET_NEW_SOLICITOR_ERR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    case SET_SOLICITOR_NEW_DATA:

      return {
        ...state,
        data: {
          meta: state.data.meta, data: state.data.data.map(
            (content, i) => state.data.data.findIndex((x) => x.id === action.payload?.data?.id) === i
              ? action.payload.data
              : content
          ), result: state.data.result
        },
        getSolicitorById: action.payload,
        errorMessage: null,
        isDataLoading: false
      }
    case SET_SOLICITOR_NEW_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }
    case GET_SOLICITOR_DETAILS_BY_SECRET_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        solicitorBySecret: action.payload,
        isDataLoading: false
      }
    case GET_SOLICITOR_DETAILS_BY_SECRET_FAILED:
      return {
        ...state,
        getSolicitorBySecret: null,
        errorMessage: action.payload,
        isDataLoading: false
      }

    case GET_SOLICITOR_DASHBOARD_META_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        dashboardMeta: action.payload,
        isDashboardMetaLoading: false
      }

    case GET_SOLICITOR_DASHBOARD_META_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDashboardMetaLoading: false
      }


    case GET_SOLICITOR_DASHBOARD_LOG_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        dashboardLog: action.payload,
        isDashboardLogLoading: false
      }

    case GET_SOLICITOR_DASHBOARD_LOG_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDashboardLogLoading: false
      }
    //
    case SET_SOLICITOR_DASHBOARD_META_LOADING:
      return {
        ...state,
        isDashboardMetaLoading: true
      }

    case SET_SOLICITOR_DASHBOARD_LOG_LOADING:
      return {
        ...state,
        isDashboardLogLoading: true
      }

    // todo

    case SET_COMPANY_DETAILS_LOADING_TRUE:
      return {
        ...state,
        isCompanyDetailsLoading: true,
      }

    case SET_COMPANY_DETAILS_LOADING_FALSE:
      return {
        ...state,
        isCompanyDetailsLoading: false,
      }

    case GET_COMPANY_DETAILS_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        companyDetailsByKeyword: action.payload,
        errorMessage: null,
        isCompanyDetailsLoading: false,
      }

    case GET_COMPANY_DETAILS_BY_KEYWORD_FAILED:
      return {
        ...state,
        companyDetailsByKeyword: null,
        errorMessage: action.payload,
        isCompanyDetailsLoading: false,
      }
    case SET_COMPANY_EMPTY_DETAILS_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        companyDetailsByKeyword: null,
        errorMessage: null,
        isCompanyDetailsLoading: false,
      }

      case GET_COMPANY_DIRECTORS_SUCCESS:
      return {
        ...state,
        companyDirectors: action.payload,
        errorMessage: null,
        isCompanyDetailsLoading: false,
      }

    case GET_COMPANY_DIRECTORS_FAILED:
      return {
        ...state,
        companyDirectors: null,
        errorMessage: action.payload,
        isCompanyDetailsLoading: false,
      }

    case GET_COMPANY_CONTACTS_BY_ID_SUCCESS:
      return {
        ...state,
        companyContactsById: action.payload,
        errorMessage: null,
        isCompanyDetailsLoading: false,
      }

    case GET_COMPANY_CONTACTS_BY_ID_FAILED:
      return {
        ...state,
        companyContactsById: null,
        errorMessage: action.payload,
        isCompanyDetailsLoading: false,
      }

    case SET_INVITE_CORPORATE_SUCCESS:
      return {
        ...state,
        inviteCorporate: action.payload,
        errorMessage: null,
        isCompanyDetailsLoading: false,
      }

    case SET_INVITE_CORPORATE_FAILED:
      return {
        ...state,
        inviteCorporate: null,
        errorMessage: action.payload,
        isCompanyDetailsLoading: false,
      }

    case SET_COMPANY_DOCUMENTS_COMPANY_REPORT_LOADING_TRUE:
      return {
        ...state,
        isCompanyReportLoading: true,
      }
    case SET_COMPANY_DOCUMENTS_UBO_REPORT_LOADING_TRUE:
      return {
        ...state,
        isScheduleOfBeneficialInterestsLoading: true,
      }
    case SET_COMPANY_DOCUMENTS_CERT_OF_INCORPORATION_LOADING_TRUE:
      return {
        ...state,
        isCertOfIncorporationLoading: true,
      }
    case SET_COMPANY_DOCUMENTS_CONSTITUTION_LOADING_TRUE:
      return {
        ...state,
        isConstitutionLoading: true,
      }
    case GET_COMPANY_COMPANY_REPORT_FAILED:
      return {
        ...state,
        isCompanyReportLoading: false,
        getCompanyReport: null,
      }
    case GET_COMPANY_UBO_REPORT_FAILED:
      return {
        ...state,
        isScheduleOfBeneficialInterestsLoading: false,
        getScheduleOfBeneficialInterests: null,
      }
    case GET_COMPANY_CERT_OF_INCORPORATION_FAILED:
      return {
        ...state,
        isCertOfIncorporationLoading: false,
        getCertOfIncorporation: null,
      }
    case GET_COMPANY_CONSTITUTION_FAILED:
      return {
        ...state,
        isConstitutionLoading: false,
        getConstitution: null,
      }
    case GET_COMPANY_COMPANY_REPORT_SUCCESS:
      return {
        ...state,
        isCompanyReportLoading: false,
        getCompanyReport: action.payload,
      }
    case GET_COMPANY_UBO_REPORT_SUCCESS:
      return {
        ...state,
        isScheduleOfBeneficialInterestsLoading: false,
        getScheduleOfBeneficialInterests: action.payload,
      }
    case GET_COMPANY_CERT_OF_INCORPORATION_SUCCESS:
      return {
        ...state,
        isCertOfIncorporationLoading: false,
        getCertOfIncorporation: action.payload,
      }
    case GET_COMPANY_CONSTITUTION_SUCCESS:
      return {
        ...state,
        isConstitutionLoading: false,
        getConstitution: action.payload,
      }

    case SOLICITOR_DELETE_SUCCESS:
      return {
        ...state,
        date: state.data.data.filter((el) => el.id !== action.payload)
      }

    case SOLICITOR_DELETE_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        isDataLoading: false
      }      

    default:
      return state
  }
}
