import React, {useState} from 'react';
import {
  Box, Button, Container, Flex, Grid, GridItem,
  ButtonGroup, FormControl, FormLabel, Input, SimpleGrid,
  Stack, Text, Select, Square,
  Heading,
  Img, Link, 
} from "@chakra-ui/react";
import {BigBlueCheck} from "../../assets/icons/sprite";
import {LandingLogo} from "../../assets/icons/sprite";
import {Link as ReachLink} from "react-router-dom";
import "@fontsource/montserrat/900.css";
import LandingBg from '../../assets/img/landing-hero-bg.png'

import IconCompliance from '../../assets/img/compliance.png'
import IconServices from '../../assets/img/enhanced-services.png'
import IconOverheads from '../../assets/img/reduce-overheads.png'
import IconUnified from '../../assets/img/unified.png'

import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {setDemoForm, setNewsletterForm} from "../../redux/actions/landing";

const LandingFull = () => {

  const dispatch = useDispatch();

  const dispatchSetNewsletterForm = (data) => dispatch(setNewsletterForm(data))
  const dispatchSetDemoForm = (data) => dispatch(setDemoForm(data))

  const [requestDemoStep, setRequestDemoStep] = useState(1);

  const [requestSubscribeNewsletterStep, setRequestSubscribeNewsletterStep] = useState(1);

  const handleSubmit = async (values, type) => {

    if (type === 'demo') {
      dispatchSetDemoForm({
        first_name: values.first_name,
        last_name: values.last_name,
        how_can_we_help: values.how_can_we_help,
        company_name: values.company_name,
        eircode: values.eircode,
        phone: values.phone,
        email: values.email,
        position_in_company: values.position_in_company,
        time_since_last_audit: values.time_since_last_audit,
        no_of_practicing_solicitors: values.no_of_practicing_solicitors
      }).then(setRequestDemoStep(2))
        .catch((err) => console.warn(err))
    } else if (type === 'newsletter') {
      dispatchSetNewsletterForm({email: values.newsletterEmail})
        .then(setRequestSubscribeNewsletterStep(2))
        .catch((err) => console.warn(err))
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '', email: '', message: '', newsletterEmail: ''
    }, onSubmit: handleSubmit
  })

  const featuresData = [
    {
      icon: IconOverheads,
      name: 'Reduce Overheads',
      text: 'Streamline document acquisition, thus reducing operational costs. Say goodbye to time-consuming document chasing. Enjoy quick, efficient, verified onboarding that cuts overheads which benefits both you and your clients.'
    }, {
      icon: IconCompliance,
      name: 'Audit-Ready Compliance',
      text: 'Effortlessly demonstrate regulatory compliance during audits. Present client records with current AML checks, NYC and CDD risk assessments, verified ID and POF, and signed S150 forms that are cross-referenced in your case management system.'
    }, {
      icon: IconServices,
      name: 'Enhanced Services',
      text: 'Improve service levels while meeting regulations. Embrace online engagement, preferred by clients. Digital transformation enhances transparency, accountability, and reduces administrative overhead.'
    }, {
      icon: IconUnified,
      name: 'Unified Platform',
      text: 'LawComply simplifies onboarding for individuals and corporations. Manage client information seamlessly on one platform. Compliance managers and partners can monitor cases awaiting documentation and increasing AML compliance at your firm.'
    },
  ]  

  return (<>
    {/*Navbar*/}

    <Box as={'header'}
         pb={{base: '12', md: '24'}}
         pt={{base: 4, md: 12}}>
      <Container maxW={'1400px'} px={{base: 2, md: 8, xl: 20}}>
        <Flex justify={'space-between'}>
          <Link as={ReachLink}
                to={'/'} ml={{base: 0, xl: 7}}
                mt={{base: 0, md: 0, lg: 1, xl: 3}}
                tabIndex={1}
          >
            <LandingLogo width={{base: 175, md: 350}} height={'55'}/>
          </Link>
          <Button
            mt={{base: 1, md: 0}}
            as={ReachLink}
            to={'/signin'}
            fontSize={{base: 12, md: 18}}
            minHeight={{base: 12, md: 14}}
            variant="primary"
            minW={{base: 'auto', md: 225}}
            backgroundColor={'#4285F4'}
            tabIndex={2}
          >Sign In</Button>
        </Flex>
      </Container>
    </Box>

    {/*Hero*/}

    <Container maxW={'1400px'} px={{base: 2, md: 8, xl: 20}}>
      <Grid
        pt={{base: '5', md: '10'}}
        pb={{base: 2, md: 4, lg: 4, xl: '6'}}
        ml={{base: '0', md: '2', xl: '10'}}
        templateColumns={{base: '1fr', md: '40% 1fr'}}
        gap={'30px'}
      >
        <GridItem>
          <Heading
            as={'h1'}
            size={{base: 'lg', md: '3xl'}}
            lineHeight={'1'}
            fontWeight={'extrabold'}
            letterSpacing={'tight'}
          >
            <Box as={'mark'}
                 color={'#4285F4'}
                 bg={'transparent'}
            >
              Law Comply.
            </Box>
            <br />
            Be audit ready.
          </Heading>


          <Text mt={{base: 2, lg: 12}}
                fontSize={{base: 14, lg: '18px'}}
                fontWeight={'medium'}
                color={'#343B55'}>
            <Text mb={4}>One simple platform for onboarding and regulatory compliance for solicitors.</Text>

            <ul style={{paddingLeft:40}}>          
              <li>AML, NYC and risk assessment management</li>
              <li>Digital onboarding</li>
              <li>Biometric ID verification</li>
              <li>Digital address verification</li>
              <li>Obtain S150 signatures seamlessly</li>
            </ul>

            <Text mt={4}>No sign-up fee, no monthly subscription.</Text>
          </Text>

          <Stack direction={{base: 'column', sm: 'row'}} spacing={'4'} mt={'7'}>
            <Button size={'lg'}
                    as={ReachLink}
                    to={'/firm/signup'}
                    colorScheme={'blue'}
                    height={'14'}
                    px={'8'}
                    mt={{base: 1, md: 0}}
                    fontSize={{base: 12, md: 18}}
                    minHeight={{base: 12, md: 14}}
                    variant="primary"
                    minW={{base: 'auto', md: 225}}
                    tabIndex={3}
                    backgroundColor={'#4285F4'}
            >
              Sign Up
            </Button>
          </Stack>

        </GridItem>
        <GridItem>
          <Img
            height={'auto'}
            width={'100%'}
            objectFit={'contain'}
            src={LandingBg}
            alt={'Law Comply'}
            position={'relative'}
            right={{base: '0', md: '-22px'}}
          />

        </GridItem>

      </Grid>
    </Container>

    {/*Features*/}
    <Box as={'section'}
         bg={'bg-surface'}
    >
      <Container py={{base: 2, md: 2, xl: 24}}
                 maxW={'1400px'}
                 px={{base: 4, md: 8, xl: 120}}>
        <Stack spacing={{base: '12', md: '16'}}>
          <Stack spacing={{base: '4', md: '5'}}
                 align={'center'}
                 textAlign={'center'}
          >
            <Stack spacing={'3'}>
              <Text fontSize={{base: 24, md: 36, lg: 48, xl: 50}}
                    fontWeight={'800'}
                    color={'accent'}

              >
                Features
              </Text>
            </Stack>
          </Stack>
          <SimpleGrid columns={{base: 1, md: 2, lg: 4}}
                      columnGap={{base: 10, md: 30, xl: 15}}
                      rowGap={{base: 10, md: 30, xl: 15}}>

            {featuresData.map((feature) => (<Stack
              key={feature.name}
              spacing={{base: 1, md: 5}}
            >
              <Square
                size={{base: 10, md: '110px'}}
                bg={'#fff'}
                color={'#fff'}
                borderRadius={{base: 10, md: '3xl'}}
                marginLeft={{base: 0, md: 14}}
              >
              <img src={feature.icon} alt={feature.name} />
              }
              </Square>
              <Stack spacing={{base: 1, md: 2}}
                      alignItems={'baseline'}>

                <Text fontSize={{base: 'lg', md: '24px'}}
                      fontWeight={'800'}
                      minH={{base: '48px', md: '84px'}}
                >
                  {feature.name}
                </Text>
                <Text color={'muted'}
                      pr={{base: 0, xl: 12}}>
                  {feature.text}
                </Text>
              </Stack>
            </Stack>))}

          </SimpleGrid>

        </Stack>
      </Container>
    </Box>

    {/*About*/}

    <Box as={'section'}
         pb={{base: '8', md: '8'}}
         pt={{base: 8, md: 10}}
         display={'flex'}
         justifyContent={'center'}
    >
      <SimpleGrid maxW={'1224px'}
                  px={{base: 2, md: 8, xl: 28}}
                  py={{base: 2, md: 8, xl: 28}}
                  bgColor={'#4285F4'}
                  columns={{base: 1, md: 1}}
                  columnGap={{base: 10, md: 30, xl: 85}}
                  rowGap={{base: 10, md: 30, xl: 15}}>
        
        <Box
          px={{base: 4, md: 15, xl: 7}}
          py={{base: 4, md: 15, xl: 5}}
        >
          <Heading
            color={'#FFFFFF'}
            fontSize={{base: 24, md: 36, lg: 48, xl: 50}}
            fontWeight={'800'}
            pb={{base: 3, md: 4, xl: 5}}
          >About Us</Heading>
          <Text
            color={'#FFFFFF'}
            fontSize={{base: 14, md: 16, lg: 18, xl: 14}}
            pb={{base: 3, md: 5, lg: 6, xl: 8}}
          >
            You want to focus on case work, yet you also need to be complaint, fully ready for audit, and operating in accordance with the Law Society’s guidelines. We can help you do that. We want to simplify and reduce your workload, creating more space for the work that you really want to do. We developed LawComply after hundreds of hours of conversations with Irish solicitors and the challenges they face, we can truly say that we understand your business.
          </Text>

           <Text
            color={'#FFFFFF'}
            fontSize={{base: 14, md: 16, lg: 18, xl: 14}}
            pb={{base: 3, md: 5, lg: 6, xl: 8}}
          >           
<Text fontSize={{base: 24, md: 26, lg: 28, xl: 24}}>What We Do.</Text>
<p>With over 23 years’ experience in transformation projects across the communications, technology, and legal sectors, we live by the moto - if it doesn’t make something better, than don’t do it. We create tech solutions that allow you more time to focus on your people, your team and your clients. Talk to us today about how LawComply can work for you <b><a href="mailto: hello@LawComply.ie">hello@LawComply.ie</a></b>. </p>
<br />

<Text fontSize={{base: 24, md: 26, lg: 28, xl: 24}}>Who We Help.</Text>
<p>We are for every solicitor who onboards clients in accordance with AML and compliance guidelines set out by the Law Society of Ireland. If you are subject to regulatory compliance audits, we can help you. With little or zero cost to you, no limits as to how large or small your practice is, no subscription fees, you can begin your journey to being audit ready today.</p>
<br />

<Text fontSize={{base: 24, md: 26, lg: 28, xl: 24}}>Our Values.</Text>
<p>We are an Irish tech company that want to make work-life better for everyone. We value family, the planet, community, happy and harmonious workplaces and harnessing tech to create positive change. We're kinda nice too. Our team is a friendly mix of sea swimmers, big time foodies, yogi's and confessed coffee buffs. Real people with a real solution.</p>

<br />
<p>We're always on the look-out for new talent, so if you have a background in tech or sales and marketing drop your CV and an intro letter to <b><a href="mailto:hireme@lawcomply.ie">hireme@lawcomply.ie</a></b>.</p>

          </Text>

{/*
          <Button as={ReachLink}
                  variant={'ghost'}
                  to={'/firm/signup'}
                  bgColor={'#FFFFFF'}
                  minWidth={{base: 260}}
                  minH={{base: 55}}
                  fontSize={{base: 12, md: 16, lg: 18}}
                  tabIndex={4}
          >Sign Up Now</Button>
*/}
        </Box>

      </SimpleGrid>
    </Box>

    {/*Pricing*/}

{/*}
    <Box as={'section'}
         pb={{base: '12', md: 16}}
         pt={{base: 2, md: 3}}
         display={'flex'}
         justifyContent={'center'}
    >
      <Grid maxW={'1224px'}
            w={'100%'}
            px={{base: 6, md: 4, xl: 20}}
            py={{base: 2, md: 4, xl: 12}}
            templateColumns={{base: '1fr', lg: '45% 1fr'}}
            gap={{base: 0, md: 0, xl: 2}}
            rowGap={{base: 10, md: 30, xl: 15}}>
        <GridItem

          p={{base: 0, md: 0}}
        >
          <Heading size={'2xl'}
                   fontWeight={'900'}
                   fontSize={{base: 24, md: 36, lg: 48, xl: 50}}
          >Pricing</Heading>
        </GridItem>
        <GridItem
          px={{base: 0, md: 0, xl: 7}}
          py={{base: 4, md: 2, xl: 2}}
        >
          <Text
            fontSize={{base: 14, md: 16, lg: 16, xl: 16}}
            lineHeight={1.8}
            paddingBottom={{base: 2, md: 4, xl: 6}}
          >
            We prepare an exclusively-tailored plan for each <br/> client. Please Sign Up to the system and
            our<br/> manager will
            get you the quote.
          </Text>

          <Button
            mt={{base: 1, md: 0}}
            as={ReachLink}
            to={'/firm/signup'}
            fontSize={{base: 12, md: 18}}
            minHeight={{base: 12, md: 14}}
            px={{base: 3, md: 8}}
            variant="primary"
            minW={{base: 'auto', md: 225}}
            textTransform={'uppercase'}
            tabIndex={5}
            backgroundColor={'#4285F4'}

          >Sign Up and receive individual plan</Button>

        </GridItem>

      </Grid>
    </Box>
    */}

    {/*Request Demo*/}

    <Box as={'section'}
         pb={{base: '12', md: '8', xl: '0'}}
         pt={{base: 2, md: 3}}
         display={'flex'}
         justifyContent={'center'}
    >
      <Grid maxW={'1224px'}
            w={'75%'}
            px={{base: 6, md: 4, xl: 0}}
            py={{base: 2, md: 4, xl: 12}}
            templateColumns={{base: '1fr', lg: '75%'}}
            gap={{base: 0, md: 0, xl: 0}}
            rowGap={{base: 10, md: 30, xl: 15}}            
            alignItems="center"
  justifyContent="center"
            >

        <GridItem
          m={{base: 0, md: 0, xl: 7}}
          px={{base: 6, md: 8, xl: 12}}
          py={{base: 4, md: 6, xl: 8}}
          boxShadow={'0px 0px 14px rgba(0, 0, 0, 0.15)'}
          borderRadius={10}
        >
        <Heading size={'2xl'}
                   fontWeight={'900'}
                   fontSize={{base: 24, md: 36, lg: 48, xl: 50}}
          >Say hello!</Heading>

          <Text mt={15}>We’re happy to answer questions and demonstrate how LawComply can help your business.</Text>
          <br />
          {requestDemoStep === 1 ? <Stack spacing="6">

              <form
                onSubmit={(evt) => {
                  evt.preventDefault()
                  handleSubmit(formik.values, 'demo')
                }}
              >
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel
                      htmlFor="first_name"
                      fontSize={'16px'}>
                      First name
                    </FormLabel>
                    <Input
                      tabIndex={6}
                      id="first_name"
                      type="text"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter your first name.'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.first_name}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel
                      htmlFor="last_name"
                      fontSize={'16px'}>
                      Last name
                    </FormLabel>
                    <Input
                      tabIndex={7}
                      id="last_name"
                      type="text"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter your last name.'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>

                  <FormControl>
                  <FormLabel htmlFor="email" fontSize={'16px'}>How can we help?</FormLabel>
                  <Select
                    tabIndex={8}
                    labelId="how_can_we_help"
                    id="how_can_we_help"
                    defaultValue={0}
                    label="Age"
                    onChange={formik.handleChange}
                  >
                      <option value=""></option>
                      <option value="Book a virtual demo">Book a virtual demo.</option>
                      <option value="Get more information">Get more information.</option>
                      <option value="Have someone call me">Have someone call me.</option>
                      <option value="I'd like to sign up">I'd like to sign up.</option>
                    </Select>
                  </FormControl>                  

                  <FormControl>
                    <FormLabel htmlFor="company_name" fontSize={'16px'}>Company name</FormLabel>
                    <Input
                      tabIndex={8}
                      id="company_name"
                      type="text"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter your company name.'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.company_name}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="eircode" fontSize={'16px'}>Eircode</FormLabel>
                    <Input
                      tabIndex={9}
                      id="eircode"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter your eircode.'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.eircode}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="phone" fontSize={'16px'}>Phone number</FormLabel>
                    <Input
                      tabIndex={10}
                      id="phone"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter your phone number.'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>                  

                  <FormControl>
                    <FormLabel htmlFor="email" fontSize={'16px'}>Email</FormLabel>
                    <Input
                      tabIndex={11}
                      pattern=".*\S+.*"
                      id="email"
                      type="email"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter your email.'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>

                  <FormControl>
                  <FormLabel htmlFor="position_in_company" fontSize={'16px'}>Position in company</FormLabel>
                  <Select
                    tabIndex={12}
                    labelId="position_in_company"
                    id="position_in_company"
                    defaultValue={""}
                    label="Position in Company"
                    onChange={formik.handleChange}
                  >
                      <option value=""></option>
                      <option value="Managing Partner">Managing Partner</option>
                      <option value="Partner">Partner</option>
                      <option value="Director">Director</option>
                      <option value="Office Manager">Office Manager</option>
                      <option value="Clerk">Clerk</option>
                      <option value="Legal secretary">Legal secretary</option>
                      <option value="Solicitor">Solicitor</option>
                      <option value="Admin">Admin</option> 
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="time_since_last_audit" fontSize={'16px'}>Time since last audit</FormLabel>
                    <Input
                      tabIndex={13}
                      id="time_since_last_audit"
                      type="text"
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter time since last audit.'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.time_since_last_audit}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="no_of_practicing_solicitors" fontSize={'16px'}>No. of practicing solicitors</FormLabel>
                    <Input
                      tabIndex={14}
                      id="no_of_practicing_solicitors"
                      type="text"
                      required
                      _placeholder={{fontSize: {base: 12, md: 14, lg: 16}}}
                      placeholder={'Enter no of practicing solicitors.'}
                      fontSize={'16px'}
                      onChange={formik.handleChange}
                      value={formik.values.no_of_practicing_solicitors}
                      minH={'44px'}
                      h={'44px'}
                    />
                  </FormControl>
                </Stack>

                <Stack spacing="6" text-align={'right'} py={'20px'}>>
                  <Button
                    tabIndex={15}
                    type="submit"
                    fontSize={{base: 12, md: 18}}
                    textTransform={'uppercase'}
                    backgroundColor={'#4285F4'}
                    variant="primary">Sign me Up!</Button>
                </Stack>

                <Text style={{fontStyle: 'italic'}}>* LawComply is a software product for legal professionals only. Applicants will be vetted.</Text>

              </form>
            </Stack>
            : <Flex justifyContent={'center'}
                    flexDirection={'column'}
                    alignItems={'center'}
            >
              <Box bgColor={'#F9FBFC'}
                   borderRadius={'50%'}
                   padding={8}
                   marginBottom={8}
              >
                <BigBlueCheck width={'96px'} height={'96px'}/>
              </Box>

              <Text fontSize={'18px'}>Thanks! We'll be in touch very soon.</Text>

            </Flex>}
        </GridItem>

      </Grid>
    </Box>

    {/*Footer*/}

    <Box as={'footer'}
         bgColor={'#343855'}
         color={'#ffffff'}
         pb={{base: '12', md: '0'}}
         pt={{base: 10, md: 12, lg: 12, xl: 24}}
         pl={{base: 4, lg: 0}}
         pr={{base: 4, lg: 0}}
    >
      <Container as={Grid} maxW={'1380px'}
                 px={{base: 2, md: 8, xl: 20}}
                 templateColumns={{lg: '1fr 1fr'}}
      >
        <GridItem
          borderBottom={{lg: '1px solid #D9D9D9'}}
        >
          <Heading
            fontWeight={'900'}
            fontSize={{base: 16, md: 18, lg: 20, xl: 22}}
            pb={{base: 6, lg: 0}}
          >Subscribe to our newsletter</Heading>

        </GridItem>

        <GridItem
          borderBottom={{lg: '1px solid #D9D9D9'}}
          pb={{lg: '50px'}}
        >
          {requestSubscribeNewsletterStep === 1 ? <form
              onSubmit={(evt) => {
                evt.preventDefault()
                handleSubmit(formik.values, 'newsletter')
              }}
            >

              <FormControl
                display={'flex'}>
                <Input
                  tabIndex={20}
                  id="newsletterEmail"
                  type="email"
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  placeholder={'Your email'}
                  fontSize={'16px'}
                  onChange={formik.handleChange}
                  value={formik.values.newsletterEmail}
                  minH={'48px'}
                  h={'48px'}
                  color={'#ffffff'}
                  bgColor={'transparent'}
                  border={'1px solid #FFFFFF'}
                  _placeholder={{color: {base: '#ffffff'}, fontSize: {base: 12, md: 14, lg: 16}}}
                />

                <Button
                  tabIndex={21}
                  type="submit"
                  fontSize={{base: 12, md: 16}}
                  minH={'48px'}
                  h={'48px'}
                  ml={4}
                  bgColor={'#ffffff'}
                  color={'#343B55'}
                  minW={{base: '98px', sm: '108px'}}
                  variant="primary">Send</Button>
              </FormControl>

            </form>
            : <Flex justifyContent={'center'}
                    flexDirection={'row'}
                    alignItems={'center'}
            >
              <Box bgColor={'#F9FBFC'}
                   borderRadius={'50%'}
                   padding={2}
                   marginBottom={1}
                   marginRight={4}
              >
                <BigBlueCheck width={'28px'} height={'28px'}/>
              </Box>

              <Text fontSize={'15px'}>Your request is being processed.</Text>

            </Flex>}
        < /GridItem>

        <GridItem
          pt={8}
        >
          <Text>(c) Law Comply. All rights reserved</Text>
        </GridItem>

        <GridItem
          pt={7}
          pb={{md: 8, lg: 32}}
        >

          <ButtonGroup
            variant="ghost"
            spacing={{lg: 3}}
            fontWeight={'500'}
            fontSize={'sm'}
            w={'100%'}
            justifyContent={{lg: 'flex-end'}}
            flexDirection={{base: 'column', lg: 'row'}}
          >
            <Link tabIndex={22}>Terms & Conditions</Link>
            <Link tabIndex={23}>Privacy Policy</Link>
            <Link tabIndex={24}>Cookies Policy</Link>
            <Link tabIndex={25}>Security Statement</Link>
          </ButtonGroup>

        </GridItem>

      </Container>

    </Box>

    <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/139616865.js"></script>

  </>);
};

export default LandingFull;
