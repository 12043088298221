import React from 'react';
import {Button, HStack, Text} from "@chakra-ui/react";

const Pagination = (
  {
    dispatchListPagination,
    dispatchList,
    pagePath,
    dispatchLoadingFlag,
    name = 'Firms'
  }) => {

  const pagination = () => {
    const pages = [];
    for (let i = 1; i <= dispatchListPagination.total_pages; i++) {
      pages.push(i);
    }
    return pages
  }

  const getPage = (url) => {
    dispatchLoadingFlag();
    dispatchList(url);
  }
  return (
    <>
      <Text
        pt={'32px'}
        textAlign={'right'}
      >
        {dispatchListPagination.total} {name} total, showing {dispatchListPagination.count}.
      </Text>

      <HStack
        pt={'18px'}
        justifyContent={'flex-end'}
      >
        <Button
          disabled={!dispatchListPagination.links?.previous}
          onClick={() => getPage(`${pagePath}${dispatchListPagination.links?.previous}`)}
        >Prev</Button>
        {
          pagination().map((el) => (
            <Button
              key={el}
              disabled={el === dispatchListPagination.current_page}
              onClick={() => {
                getPage(`${pagePath}${el}`)
              }}
            >{el}</Button>
          ))
        }

        <Button
          disabled={!dispatchListPagination.links?.next}
          onClick={() => getPage(`${dispatchListPagination.links?.next}`)}
        >Next</Button>

      </HStack>
    </>
  );
};

export default Pagination;