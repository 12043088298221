import React from 'react';
import {Flex, Stack} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {Logo} from "./Logo";

const HeaderFormLogo = ({
                          logoLink = true
                        }) => {
  return (
    <Stack as={Flex} spacing="6" alignItems="center">

      {logoLink
        ? <Link to={'/'}
                tabIndex={1}
        >
          <Logo
            width={'227px'}
            height={'72px'}
          />
        </Link>
        : <Logo
          width={'227px'}
          height={'72px'}
        />
      }

      <Stack
        spacing={{
          base: '7', md: '3',
        }}
        textAlign="center"
      >

      </Stack>
    </Stack>
  );
};

export default HeaderFormLogo;