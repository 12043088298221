import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AddressIcon, EmailIcon, PencilIcon, PhoneIcon} from "../assets/icons/sprite";
import {
  Box, ButtonGroup,
  Editable, EditableInput,
  EditablePreview,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack, IconButton,
  Input,
  Link,
  Text, useDisclosure, useEditableControls
} from "@chakra-ui/react";
import BackButton from "./BackButton";
import {CheckIcon, CloseIcon, SettingsIcon} from "@chakra-ui/icons";
import Loading from "./Loading";
import AlertComponent from "./AlertComponent";
import {getFirmById, setFirmData, setLoadingFlag} from "../redux/actions/firms";
import {getSolicitorById, setSolicitorData} from "../redux/actions/solicitors";
import EditIdpal from "./EditIdpal";

const EditItem = ({nameSpace = 'firm', setAction}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const {id} = useParams();
  const dispatch = useDispatch();

  const dispatchErrorMessage = useSelector(state => state[nameSpace].errorMessage)
  const dispatchIsDataLoading = useSelector(state => state[nameSpace].isDataLoading)
  const dispatchItem = useSelector(state => state[nameSpace].getById)

  const dispatchSetData = (data) => nameSpace === 'firm'
    ? dispatch(setFirmData(data))
    : nameSpace === 'solicitors'
      ? dispatch(setSolicitorData(data))
      : null;

  useEffect(() => {
    dispatch(setLoadingFlag());
    if (nameSpace === 'firm') {
      dispatch(getFirmById(id))
    }
    if (nameSpace === 'solicitors') {
      dispatch(getSolicitorById(id))
    }
  }, [dispatch, id, nameSpace])

  const navigate = useNavigate()

  const editData = [
    {
      icon: <EmailIcon
        w={8}
        h={8}
        fill={'none'}/>,
      label: 'Email:',
      content: dispatchItem?.data?.email,
      actionIcon: null,
      action: nameSpace === 'solicitors' ? 'email' : null,
    }, {
      icon: <PhoneIcon
        w={8}
        h={8}
        fill={'none'}/>,
      label: 'Telephone:',
      content: dispatchItem?.data?.phone,
      actionIcon: <PencilIcon
        fill={'none'}
        w={8}
        h={8}
      />, action: 'phone',
    }, {
      icon: <AddressIcon
        w={8}
        h={8}
        fill={'none'}/>, label: 'Address:', content: dispatchItem?.data?.address, actionIcon: <PencilIcon
        fill={'none'}
        w={8}
        h={8}
      />, action: 'address',
    }
  ]

  const cardStyle = {
    rounded: 'lg',
    bgColor: '#F9FBFC',
    width: '466px',
    p: '24px',
    templateColumns: '50px 1fr 32px',
    minHeight: 26,
    marginTop: 4,
  }

  const EditControls = ({secIcon = null}) => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()

    return isEditing ? (
      <ButtonGroup
        justifyContent='center'
        size='xs'
        display={'flex'}
        flexDirection={'column'}
        pl={'12px'}
      >
        <IconButton
          icon={<CheckIcon/>}
          mb={'6px'}
          ml={'8px'}
          {...getSubmitButtonProps()}
        />
        <IconButton
          icon={<CloseIcon/>}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex
        justifyContent='center'
        mb={'4px'}
      >

        <Box
          pt={1.5}
          spacing={10}>
          <IconButton
            size='xs'
            _hover={{backgroundColor: 'transparent'}}
            backgroundColor={'transparent'}
            icon={<PencilIcon
              fill={'none'}
              w={8}
              h={8}
            />} {...getEditButtonProps()} />
          {nameSpace === 'firm'
            ? secIcon
            : null
          }
        </Box>

      </Flex>
    )
  }

  return (<>{dispatchItem?.data
    ? dispatchIsDataLoading
      ? <Loading/>
      : <Box>
        <EditIdpal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          id={id}
        />

        <BackButton
          tabIndex={9}
          onClickHandler={() => {
            navigate(-1)
          }}
          ml={'-17px'}
        />

        {!isOpen && dispatchErrorMessage && <AlertComponent
          message={dispatchErrorMessage}
        />}

        <HStack mt={'37px'}>

          <Editable
            position={'relative'}
            textAlign='center'
            display={'flex'}
            // alignItems={'flex-end'}
            defaultValue={dispatchItem.data.name ?? 'Not set'}
            fontSize='32px'
            fontWeight='700'
            width={'518px'}
            isPreviewFocusable={false}
            onSubmit={(name) => dispatchSetData(
              {
                newData: {name: name},
                id: dispatchItem.data.id
              })}>

            <EditablePreview as={Heading} fontSize={'32px'}
                             lineHeight={'48px'}
                             mr={5}/>

            <Input
              as={EditableInput}
              fontSize={'32px'}
              lineHeight={'48px'}
            />
            <EditControls secIcon={<Link onClick={onOpen}><SettingsIcon
              w={5}
              h={5}
              mt={'-9px'}
              fill={'#6E7997'}
              color={'#6E7997'}
            /></Link>}/>
          </Editable>

        </HStack>

        <HStack mt={2}>
          <Text fontWeight={'400'} fontSize={'18px'}>
            ID {dispatchItem.data.id ?? 'Not set'}
          </Text>
          <Text fontWeight={'400'}>
            Active: {dispatchItem.data.active.toString() ?? 'Not set'}
          </Text>
        </HStack>

        <Heading
          fontSize={'24px'}
          lineHeight={'48px'}
          mt={'37px'}
        > General Info </Heading>


        {editData.map(({
                         icon, label, content, actionIcon, action
                       }) => {

          return <Grid
            {...cardStyle}
            key={label}
          >
            <GridItem as={Flex}
                      justifyContent={'center'}
                      alignItems={'center'}
            >
              <Flex
                bgColor={'#fff'}
                w={12}
                h={12}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={4}
              >
                {icon}
              </Flex>
            </GridItem>
            <GridItem pl={3}
                      as={Flex}
                      justifyContent={'center'}
                      alignItems={'flex-start'}
                      flexDirection={'column'}
            >
              <Text fontWeight={'700'} fontSize={'18px'}>
                {label}
              </Text>

              {action
                ?
                <Editable
                  textAlign='center'
                  display={'flex'}
                  flexGrow={1}
                  defaultValue={content ?? 'Not set'}
                  fontSize={'18px'}
                  width={'100%'}
                  isPreviewFocusable={false}
                  onSubmit={(newValue) => dispatchSetData(
                    {
                      newData: {[action]: newValue},
                      id: dispatchItem.data.id
                    })}>

                  <EditablePreview
                    flexGrow={1}
                    textAlign={'left'}
                    fontSize={'18px'}
                    mr={5}
                  />

                  <Input
                    as={EditableInput}
                    fontSize={'18px'}
                    height={'36px'}
                    minHeight={'36px'}
                    textAlign={'left'}
                  />

                  <EditControls secIcon={false}/>
                </Editable>

                : <Text fontSize={'18px'}>
                  {content ?? 'Not set'}
                </Text>
              }


            </GridItem>

          </Grid>
        })}
      </Box>

    : <Loading/>}
  </>);
};


export default EditItem;