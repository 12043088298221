function ComplianceNumber(props) {

    if (props.complete) {
        return (
            <img
                alt="circle complete"
                src="/assets/img/circle-complete.png"
                style={{ width: 50 }}/>
        );
    }

    else {
        return <img
                alt="circle {props.number}"
                src={`/assets/img/circle-${props.number}.png`}
                style={{ width: 50 }}
            />
    }
}
export default ComplianceNumber;
