import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  checkAdminAuth, setAdminLoginLoadingFlag
} from "../redux/actions/auth";
import AdminLogin from "../pages/Admin/AdminLogin";
import Loading from "./Loading";

const AdminProtected = ({
                          WrappedComponent
                        }) => {
  const dispatch = useDispatch();

  const dispatchIsDataLoading = useSelector(state => state.adminAuth.isLoading);

  const dispatchAdminAuthAuthChecked = useSelector(state => state.adminAuth.authChecked);
  const dispatchAdminAuthLoggedIn = useSelector(state => state.adminAuth.loggedIn);


  useEffect(() => {
    if (!dispatchIsDataLoading && !dispatchAdminAuthAuthChecked) {
      dispatch(setAdminLoginLoadingFlag())
      dispatch(checkAdminAuth())
    }
  }, [dispatch,
    dispatchIsDataLoading,
    dispatchAdminAuthAuthChecked
  ])

  return (dispatchAdminAuthAuthChecked && dispatchAdminAuthLoggedIn) ? <>{WrappedComponent}</> : dispatchIsDataLoading ?
    <Loading/> : <AdminLogin/>
}

export default AdminProtected;