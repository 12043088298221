import React, {useEffect} from 'react';
import {useNavigate, useParams, Link as ReachLink} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {AddressIcon, EmailIcon, PencilIcon, PhoneIcon} from "../../assets/icons/sprite";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Link,
  Text, useDisclosure,
  TableContainer, Th, Tr, Td, Table, Thead, Tbody, Button
} from "@chakra-ui/react";
import BackButton from "../../components/BackButton";
import Loading from "../../components/Loading";
import AlertComponent from "../../components/AlertComponent";
import {getFirmById, setFirmData, setLoadingFlag} from "../../redux/actions/firms";
import {getSolicitorById, setSolicitorData} from "../../redux/actions/solicitors";
import EditIdpal from "../../components/EditIdpal";

const AuditorFirm = ({nameSpace = 'firm', setAction}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const {id} = useParams();
  const dispatch = useDispatch();

  const dispatchErrorMessage = useSelector(state => state[nameSpace].errorMessage)
  const dispatchIsDataLoading = useSelector(state => state[nameSpace].isDataLoading)
  const dispatchItem = useSelector(state => state[nameSpace].getById)

  // eslint-disable-next-line no-unused-vars
  const dispatchSetData = (data) => nameSpace === 'firm'
    ? dispatch(setFirmData(data))
    : nameSpace === 'solicitors'
      ? dispatch(setSolicitorData(data))
      : null;

  useEffect(() => {
    dispatch(setLoadingFlag());
    if (nameSpace === 'firm') {
      dispatch(getFirmById(id, 'auditor'))
    }
    if (nameSpace === 'solicitors') {
      dispatch(getSolicitorById(id))
    }
  }, [dispatch, id, nameSpace])

  const navigate = useNavigate()

  const editData = [
    {
      icon: <EmailIcon
        w={8}
        h={8}
        fill={'none'}/>,
      label: 'Email:',
      content: dispatchItem?.data?.email,
      actionIcon: null,
      action: null,
    }, {
      icon: <PhoneIcon
        w={8}
        h={8}
        fill={'none'}/>,
      label: 'Telephone:',
      content: dispatchItem?.data?.phone,
      actionIcon: <PencilIcon
        fill={'none'}
        w={8}
        h={8}
      />, action: 'phone',
    }, {
      icon: <AddressIcon
        w={8}
        h={8}
        fill={'none'}/>, label: 'Address:', content: dispatchItem?.data?.address, actionIcon: <PencilIcon
        fill={'none'}
        w={8}
        h={8}
      />, action: 'address',
    }
  ]

  const cardStyle = {
    rounded: 'lg',
    bgColor: '#F9FBFC',
    width: '466px',
    p: '24px',
    templateColumns: '50px 1fr 32px',
    minHeight: 26,
    marginTop: 4,
  }

  const thStyles = {
    color: '#1A1E29',
    fontSize: '14px',
    textTransform: 'none',
    opacity: '0.5',
    fontWeight: 400,
    border: 'none',
  }

  return (<>{dispatchItem?.data
    ? dispatchIsDataLoading
      ? <Loading/>
      : <><Box>
        <EditIdpal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          id={id}
        />

        <BackButton
          tabIndex={9}
          onClickHandler={() => {
            navigate(-1)
          }}
          ml={'-17px'}
        />

        {!isOpen && dispatchErrorMessage && <AlertComponent
          message={dispatchErrorMessage}
        />}

        <HStack mt={'37px'}>
		  <Heading
            fontSize={'32px'}
            lineHeight={'48px'}
          >{dispatchItem.data.name ?? 'Not set'}</Heading>
        </HStack>

        <HStack mt={2}>
          <Text fontWeight={'400'} fontSize={'18px'}>
            ID: {dispatchItem.data.id ?? 'Not set'}
          </Text>
        </HStack>



<HStack alignItems="top">

	<Box>
        <Heading
          fontSize={'24px'}
          lineHeight={'48px'}
          mt={'37px'}
        > General Info</Heading>

        {editData.map(({
                         icon, label, content, actionIcon, action
                       }) => {
          return <Grid align="left"
            {...cardStyle}
            key={label}
          >
            <GridItem as={Flex}
                      justifyContent={'center'}
                      alignItems={'center'}
            >
              <Flex
                bgColor={'#fff'}
                w={12}
                h={12}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={4}
              >
                {icon}
              </Flex>
            </GridItem>
            <GridItem pl={3}
                      as={Flex}
                      justifyContent={'center'}
                      alignItems={'flex-start'}
                      flexDirection={'column'}
            >
              <Text fontWeight={'700'} fontSize={'18px'}>
                {label}
              </Text>

   			  <Text fontSize={'18px'}>
                  {content ?? 'Not set'}
              </Text>
            </GridItem>

          </Grid>
        })}

      </Box>
      <Box>
      <Heading
          fontSize={'24px'}
          lineHeight={'48px'}
          mt={'37px'}
          align={'left'}
        > Solicitors </Heading>

            <TableContainer
                backgroundColor={'#F9FBFC'}
                mt={'14px'}
                color={'#1A1E29'}
              >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th
                        {...thStyles}
                      >Company Name</Th>
                      <Th
                        {...thStyles}
                      >Email</Th>
                      <Th
                        {...thStyles}
                      >Status</Th>
                      <Th
                        {...thStyles}
                      >Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dispatchItem?.data.solicitors.map(({id, name, email, active}) => {
                      return (
                        <Tr key={id}>
                          {/*<Td*/}
                          {/*>{id}</Td>*/}
                          <Td
                            fontSize={'18px'}
                            minWidth={'270px'}
                          >
                            <Link to={id}
                                  as={ReachLink}
                                  color={'#1A1E29'}
                            >{name}</Link>
                          </Td>
                          <Td
                            fontSize={'18px'}
                          >
                            <Link to={id}
                                  as={ReachLink}
                                  color={'#B4BAC9'}
                            >
                              {email}
                            </Link></Td>

                          <Td>

                            {active.toString() === 'false'
                              ? <Button
                                colorScheme='orange'
                                backgroundColor={'#C8392D'}
                                color={'#FFFFFF'}
                                size='xs'
                                fontWeight={400}
                                fontSize={'14px'}
                                w={'100%'}
                              >
                                Inactive
                              </Button>
                              : <Button
                                colorScheme='green'
                                size='xs'
                                fontWeight={400}
                                fontSize={'14px'}
                                w={'100%'}
                              >
                                Active
                              </Button>

                            }
                          </Td>
                          <Td>
                          <Link
                      to={'/auditor/firms/' + dispatchItem.data.id + '/solicitor/' + id + "/clients"}
                      as={ReachLink}
                    >
                    <Button
                        colorScheme='green'
                        size='sm'
                        fontWeight={600}
                        fontSize={'14px'}
                        w={'100%'}
                      >View Clients</Button>
                    </Link>
                    </Td>

                        </Tr>
                      )
                    })
                    }
                  </Tbody>
                </Table>
              </TableContainer>

      </Box>

  </HStack>
            
</Box>
  </>

    : <Loading/>}
  </>);
};


export default AuditorFirm;