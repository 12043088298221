import React, { useEffect, useState } from "react";
import {
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Link,
  Box
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirmStatus,
  getFirmList,
  setLoadingFlag,
  deleteFirm,
} from "../../redux/actions/firms";
import {
  loginAdminAsFirm
} from "../../redux/actions/auth"
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import Alert from "../../components/Alert";
import { Outlet, Link as ReachLink,  useNavigate, useParams } from "react-router-dom";
import { PencilSecondaryIcon, TrashBinIcon, UserLoginIcon } from "../../assets/icons/sprite";

function AdminFirms() {
  const { id: hideListing } = useParams();
  const PAGE_PATH = "admin/firms?page=";

  const thStyles = {
    color: "#1A1E29",
    fontSize: "14px",
    textTransform: "none",
    opacity: "0.5",
    fontWeight: 400,
    border: "none",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hideListing) {
      dispatch(setLoadingFlag());
      dispatch(getFirmList());
    }
  }, [dispatch, hideListing]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDelOpen,
    onOpen: onDelOpen,
    onClose: onDelClose,
  } = useDisclosure();

  const {
    isOpen: isFirmLoginOpen,
    onOpen: onFirmLoginOpen,
    onClose: onFirmLoginClose,
  } = useDisclosure();

  const [firmId, setFirmId] = useState(null);
  const [firmDirectionIsActivate, setFirmDirectionIsActivate] = useState(true);

  const dispatchFirmList = useSelector((state) => state.firm);
  const dispatchListPagination = useSelector(
    (state) => state.firm?.data?.meta?.pagination
  );
  const dispatchIsDataLoading = useSelector(
    (state) => state.firm?.isDataLoading
  );
  const dispatchList = (credentials) => dispatch(getFirmList(credentials));
  const dispatchLoadingFlag = (credentials) =>
    dispatch(setLoadingFlag(credentials));
  const dispatchFirmChangeStatus = (credential) =>
    dispatch(setFirmStatus(credential));
  const dispatchFirmDel = (id) => dispatch(deleteFirm(id));

  const onChangeStatus = (id = null, activate = true) => {
    setFirmId(id);
    activate
      ? setFirmDirectionIsActivate(true)
      : setFirmDirectionIsActivate(false);
    onOpen();
  };

  const onDeleteStatus = (id = null) => {
    setFirmId(id);
    onDelOpen();
  };

  const onFirmLogin = (id = null) => {
    setFirmId(id);
    onFirmLoginOpen();
  };

  return (
    <>
      <Outlet />

      <Alert
        isOpen={isDelOpen}
        onOpen={onDelOpen}
        onClose={onDelClose}
        header={`Delete Firm`}
        text={`Are you sure you want to delete this Law Firm?`}
        action={() => {
          dispatch(setLoadingFlag());
          dispatchFirmDel(firmId).then((res) => {
            dispatchList();
          });
        }}
      />
      <Alert
        isOpen={isFirmLoginOpen}
        onOpen={onFirmLoginOpen}
        onClose={onFirmLoginClose}
        header={`Login as Firm`}
        text={`Are you sure you want to login as this firm?`}
        action={(act) => {
          dispatch(loginAdminAsFirm(firmId)).then(res => navigate('/firm'))
        }}
      ></Alert>
      <Alert
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        header={`Are you sure you want to ${
          firmDirectionIsActivate ? "activate" : "deactivate"
        } this Law Firm?`}
        text={`All solicitors under the law firm will also be ${
          firmDirectionIsActivate ? "activated" : "deactivated"
        }.`}
        action={() => {
          dispatchFirmChangeStatus({
            url: firmId,
            active: firmDirectionIsActivate,
          }).then((res) => {
            // dispatchLoadingFlag();
            dispatchList();
          });
        }}
      />

      <Alert
        isOpen={isDelOpen}
        onOpen={onDelOpen}
        onClose={onDelClose}
        header={`Are you sure you want to delete this Law Firm?`}
        text={`All solicitors under the law firm will also be deleted.`}
        action={() => {
          dispatch(setLoadingFlag());
          dispatchFirmDel(firmId).then((res) => {
            dispatchList();
          });
        }}
      />

      {!hideListing ? (
        <Box>
          <Heading fontSize={"32px"} lineHeight={"48px"}>
            Law Firms
          </Heading>

          {dispatchIsDataLoading ? (
            <Loading />
          ) : dispatchFirmList.data ? (
            <>
              {" "}
              <TableContainer
                backgroundColor={"#F9FBFC"}
                mt={"44px"}
                color={"#1A1E29"}
              >
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      {/*<Th*/}
                      {/*  {...thStyles}*/}
                      {/*>Id</Th>*/}
                      <Th {...thStyles}>Company Name</Th>
                      <Th {...thStyles}>Email</Th>
                      <Th {...thStyles}>Status</Th>
                      <Th {...thStyles}>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dispatchFirmList?.data?.data?.map(
                      ({ id, name, email, active }) => {
                        return (
                          <Tr key={id}>
                            {/*<Td*/}
                            {/*>{id}</Td>*/}
                            <Td fontSize={"18px"} minWidth={"270px"}>
                              <Link to={id} as={ReachLink} color={"#1A1E29"}>
                                {name}
                              </Link>
                            </Td>
                            <Td fontSize={"18px"}>
                              <Link to={id} as={ReachLink} color={"#B4BAC9"}>
                                {email}
                              </Link>
                            </Td>
                            <Td>
                              {active.toString() === "false" ? (
                                <Button
                                  colorScheme="orange"
                                  backgroundColor={"#C8392D"}
                                  color={"#FFFFFF"}
                                  size="xs"
                                  fontWeight={400}
                                  fontSize={"14px"}
                                  w={"100%"}
                                  onClick={() => onChangeStatus(id)}
                                >
                                  Inactive
                                </Button>
                              ) : (
                                <Button
                                  colorScheme="green"
                                  size="xs"
                                  fontWeight={400}
                                  fontSize={"14px"}
                                  w={"100%"}
                                  onClick={() => onChangeStatus(id, false)}
                                >
                                  Active
                                </Button>
                              )}
                            </Td>
                            <Td>
                              <Link to={id} as={ReachLink}>
                                <PencilSecondaryIcon
                                  fill={"none"}
                                  w={8}
                                  h={8}
                                  marginBottom={"-10px"}
                                  stroke={"#223268"}
                                />
                              </Link>                              

                              <Link
                                textAlign={"right"}
                                onClick={() => onDeleteStatus(id)}
                              >
                                <TrashBinIcon
                                  fill={"none"}
                                  w={8}
                                  h={8}
                                  marginBottom={"-10px"}
                                  stroke={"#223268"}
                                />
                              </Link>                              

                              <Link onClick={() => onFirmLogin(id)}>
                                <UserLoginIcon
                                  fill={"none"}
                                  w={6}
                                  h={6}
                                  marginBottom={"-2px"}
                                  stroke={"#b6bcca"}
                                />
                              </Link>                                                                                          

                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
              <Pagination
                dispatchList={dispatchList}
                dispatchListPagination={dispatchListPagination}
                dispatchLoadingFlag={dispatchLoadingFlag}
                pagePath={PAGE_PATH}
              />
            </>
          ) : (
            <Loading />
          )}
        </Box>
      ) : null}
    </>
  );
}

export default AdminFirms;
