import {makeRequest, API_URL} from "../../services/request";
import {
  FIRM_ACTIVATE_FAILED,
  FIRM_ACTIVATE_SUCCESS,
  FIRM_DEACTIVATE_FAILED,
  FIRM_DEACTIVATE_SUCCESS,
  FIRM_GET_BY_ID_SUCCESS,
  FIRM_LIST_DOWNLOADED,
  FIRM_LIST_NOT_DOWNLOADED,
  SET_DATA_LOADING_FALSE,
  SET_DATA_LOADING_TRUE,
  FIRM_SET_ERR_MESSAGE,
  SET_FIRM_NEW_DATA,
  SET_FIRM_NEW_DATA_FAILED,
  FIRM_GET_IDPAL_FAILED,
  FIRM_GET_IDPAL_SUCCESS,
  FIRM_SET_IDPAL_SUCCESS,
  FIRM_SET_IDPAL_FAILED,
  FIRM_DELETE_FAILED, FIRM_DELETE_SUCCESS,
  SET_FIRM_ERR_MESSAGE,
  GET_AUDITOR_FIRM_BY_ID_SUCCESS
} from "./index";

export const setFirmErrorMessage = (data) => {
  return ({type: SET_FIRM_ERR_MESSAGE, payload: data});
}

export const getFirmList = (url = `admin/firms`) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}${url}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: FIRM_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: FIRM_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getAuditorFirmList = (status = null) => {
  return (dispatch) => {
    var url = `auditor/firms`;
    if (status !== null) { url = url + "?status=" + status }
    return makeRequest(`${API_URL}${url}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: FIRM_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: FIRM_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getAuditorFirmById = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auditor/firms/${id}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: GET_AUDITOR_FIRM_BY_ID_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: FIRM_SET_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const requestAccessToFirm = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auditor/firms/${id}/request`, null, 'POST')
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: FIRM_ACTIVATE_SUCCESS, payload: id})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type:FIRM_ACTIVATE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const getFirmById = (id, role = 'admin') => {
  return (dispatch) => {
    var url = `${API_URL}admin/firms/${id}`;
    if (role === "auditor") {
      url = `${API_URL}auditor/firms/${id}`;
    }
    return makeRequest(url).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: FIRM_GET_BY_ID_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: FIRM_SET_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const setFirmStatus = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/firms/${data.url}`, {
      active: data.active
    }, 'PUT', true)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: data.active ? FIRM_ACTIVATE_SUCCESS : FIRM_DEACTIVATE_SUCCESS})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: data.active ? FIRM_ACTIVATE_FAILED : FIRM_DEACTIVATE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const deleteFirm = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/firms/${id}`, null, 'DELETE')
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: FIRM_DELETE_SUCCESS, payload: id})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: FIRM_DELETE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }

}

export const setFirmData = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/firms/${data.id}`, data.newData, 'PUT', true)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: SET_FIRM_NEW_DATA, payload: data})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_FIRM_NEW_DATA_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const getFirmIdpalData = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/firms/${id}/access`)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: FIRM_GET_IDPAL_SUCCESS, payload: data})
              return data
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: FIRM_GET_IDPAL_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setFirmIdpalData = (id, data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/firms/${id}/access`, data, 'POST')
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(() => {
              dispatch({type: FIRM_SET_IDPAL_SUCCESS})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: FIRM_SET_IDPAL_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}




export const setLoadingFlag = (flag = true) => {
  return (flag
      ? {type: SET_DATA_LOADING_TRUE}
      : {type: SET_DATA_LOADING_FALSE}
  );
}

