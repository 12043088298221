import React, {useEffect, useState} from 'react';
import {
    Box,
    Flex,
    Heading, Text, GridItem, Grid, HStack
} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import Loading from "../../components/Loading";
import {getDashboardData} from "../../redux/actions/admin";
import AlertComponent from "../../components/AlertComponent";
import {ContactsIcon} from "../../assets/icons/sprite";

const dataMap = {
    clients: {
        icon: <ContactsIcon/>,
        description: 'number of clients in total'
    },
    firms: {
        icon: <ContactsIcon/>,
        description: 'number of firms in total'
    },
    solicitors: {
        icon: <ContactsIcon/>,
        description: 'number of solicitors in total'
    },
    accounts: {
        icon: <ContactsIcon/>,
        description: 'number of accounts users in total'
    },
    auditors: {
        icon: <ContactsIcon/>,
        description: 'number of auditors in total'
    },
};

function AdminMainPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [logData, setLogData] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const loadData = async () => {
        if (!isLoading) {
            setIsLoading(true);
            try {
                const resp = await getDashboardData();
                if (resp.result === 'success') {
                    setData(resp.data.statistics);
                    setLogData(resp.data.logs);
                } else {
                    setErrorMessage(resp);
                }
            } catch (e) {
                setErrorMessage(e);
            }
            setIsLoading(false);
        }
        return 'done';
    }

    const dispatchCurrentUser = useSelector(state => state.adminAuth.currentUser);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Heading
                fontSize={'32px'}
                lineHeight={'48px'}
            >Welcome, {dispatchCurrentUser.name}! </Heading>
            {errorMessage && <AlertComponent message={errorMessage}/>}
            {isLoading ? <Flex justifyContent={'center'} alignItems={'center'}>
                <Loading/>
            </Flex> : <Flex flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'}>
                {Object.keys(data)
                    .map((type) => {
                    return <Box key={type}  backgroundColor={'#F9FBFC'} mb={4} borderRadius={'8px'} w={'45%'} pt={4} pb={4}>
                        <Flex>
                            <Flex justifyContent={'center'} alignItems={'center'} width={'15%'}>
                                {dataMap[type]?.icon}
                            </Flex>
                            <Flex flexDirection={'column'} alignItems={'flex-start'} width={'80%'}>
                                <Text color={'#223268'} textAlign={'right'} fontSize={'24px'}
                                    fontWeight={'semibold'}>
                                    {type==='accounts'?'Accounts Users':capitalizeFirstLetter(type)}
                                </Text>
                                <Text>{dataMap[type]?.description}</Text>
                            </Flex>
                            <Flex alignItems={'center'} justifyContent={'center'} width={'15%'}>
                               <Text color={'#223268'} textAlign={'right'} fontSize={'32px'} fontWeight={'semibold'}>{data[type]}</Text>
                            </Flex>
                        </Flex>
                    </Box>;
                })}
            </Flex>}

        {isLoading ? <Loading/> :
          <GridItem gridColumn={'1 / 3'}>
            <Grid templateColumns={'auto auto 1fr'}
                  gap={'15px'}
                  mb={5}
            >
              <GridItem>
                <Heading
                  fontSize={'24px'}
                  lineHeight={'59px'}
                >Notifications</Heading>
              </GridItem>

              <GridItem as={Flex}
                        justifyContent={'center'}
                        alignItems={'center'}
              >
                <Flex
                  display={'inline-grid'}
                  bgColor={'#599EF2'}
                  borderRadius={'8px'}
                  fontSize={'18px'}
                  lineHeight={'27px'}
                  color={'#ffffff'}
                  height={'32px'}
                  minHeight={'32px'}
                  minWidth={'32px'}
                  textAlign={'center'}
                  fontWeight={'600'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >{logData?.data?.length}</Flex>
              </GridItem>

              <GridItem as={Flex}
                        justifyContent={'right'}
                        alignItems={'center'}
              >
                {/*<Link*/}
                {/*  color={'#797979'}*/}
                {/*  fontWeight={400}*/}
                {/*  fontSize={'14px'}*/}
                {/*>Clear All</Link>*/}
              </GridItem>
            </Grid>

            <GridItem gridColumn={'1 / 3'}
                      bgColor={'#F9FBFC'}
                      py={'36px'}
                      px={'48px'}
                      borderRadius={'8px'}
            >


              <Grid templateColumns={'1fr auto'}
                    gap={'24px'}
              >
                {/* LOG */}
                <GridItem gridColumn={'1 / 3'}
                          color={'#858CA0'}
                          fontSize={'14px'}
                >
                  Today
                </GridItem>

                {logData?.data?.map(({
                                                  id,
                                                  action,
                                                  auditor,
                                                  firm,
                                                  created_at
                                                }) => (
                  <React.Fragment key={id}>
                    <GridItem>
                        <HStack>
                        <Flex bgColor={'#E4E4E4'}
                              borderRadius={'50%'}
                              width={'27px'}
                              height={'27px'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              color={'#1A1E29'}
                              fontSize={'13px'}
                              mr={2}
                        >
                        {auditor?.name[0]}
                        {firm?.name[0]}
                        </Flex>
                        <Text color={'#223268'}
                              fontSize={'18px'}
                              fontWeight={'500'}
                        >
                          {auditor?.name}
                          {firm?.name}
                        </Text>
                        <Text color={'#223268'}
                              fontSize={'18px'}
                              fontWeight={400}
                        >{action}</Text>
                      </HStack>

                    </GridItem>

                    <GridItem>
                      {new Date(created_at).toUTCString()}
                    </GridItem>
                  </React.Fragment>
                ))}


              </Grid>

            </GridItem>

          </GridItem>}

        </>
    );
}

export default AdminMainPage;
