import React from 'react';
import {Box, HStack, Text} from "@chakra-ui/react";

const UserProfile = ({name, image, email}) => {
  return (
    <HStack spacing={'3'} ps={2}>
      <Box>
        <Text
          fontWeight={'medium'}
          fontSize={'18px'}
          color={'#223268'}
        >
          {name}
        </Text>
        <Text
          fontWeight={'medium'}
          fontSize={'16px'}
          color={'#9a9a9a'}
        >
          {email}
        </Text>
      </Box>
    </HStack>
  );
};

export default UserProfile;