import React, { useEffect } from "react";
import { useNavigate, useParams, Link as ReachLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RiskAssessmentView from "../../components/Solicitor/CaseView/RiskAssessment"
import ComplianceItem from "../../components/Solicitor/CaseView/Compliance/Item"
import ComplianceItemSignedDocuments from "../../components/Solicitor/CaseView/Compliance/Items/SignedDocuments"
import ComplianceItemIndividuals from "../../components/Solicitor/CaseView/Compliance/Items/Individuals"
import GeneralInfo from "../../components/Solicitor/CaseView/GeneralInfo"
import ComplianceHeading from "../../components/Solicitor/CaseView/Compliance/Heading"
import CaseViewHeader from "../../components/Solicitor/CaseView/Header"
import { Box, Button, Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import BackButton from "../../components/BackButton";
import { isDateExpired } from "../../services/utils";
import Loading from "../../components/Loading";
import { getClientById, setLoadingFlag } from "../../redux/actions/clients";
import AlertComponent from "../../components/AlertComponent";
import ClientOpeningFormItem from "../../components/ClientOpeningFormItem";

const CaseView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const dispatchErrorMessage = useSelector((state) => state.clients.errorMessage);
  const dispatchIsDataLoading = useSelector((state) => state.clients.isDataLoading);
  const dispatchItem = useSelector((state) => state.clients.getById);  
  
  const {
    isOpen: isRiskFormOpen,
    onOpen: onRiskFormOpen,
    onClose: onRiskFormClose,
  } = useDisclosure();
  const {
    isOpen: isRiskForm2Open,
    onOpen: onRiskForm2Open,
    onClose: onRiskForm2Close,
  } = useDisclosure();
  const {
    isOpen: isRiskForm3Open,
    onOpen: onRiskForm3Open,
    onClose: onRiskForm3Close,
  } = useDisclosure();
  var loadingArray = {}

  useEffect(() => {
    if (id) {
      dispatch(setLoadingFlag());
      dispatch(getClientById(id)).then((client) => {
        client?.payload?.data?.corporate_children?.map((child, index) => {
          loadingArray[index] = false
          return null;
        })        
        // setLoadingApproveAll(loadingArray)        
      });
    }
    // eslint-disable-next-line
  }, [dispatch, id]);

  const navigate = useNavigate();

  const viewButtonStyle = {
    bgColor: "#ffffff",
    border: "1px solid #6E7997",
    color: "#6E7997",
    fontSize: "16px",
    fontWeight: 500,
    w: "100%",
    minH: "55px",
  };

  var moment = require("moment");
  var dateSecond = null;
  if (dispatchItem?.data?.riskForm) {
    dateSecond = moment(dispatchItem?.data?.riskForm?.date_next);
  }

  var dateThird = null;
  if (dispatchItem?.data?.riskForm2) {
    dateThird = moment(dispatchItem?.data?.riskForm2?.date_next);
  }

  var riskFormsStatus = 6;
  var riskFormsLastSubmittedAt = "Not submitted";

  // if first risk form isn't submitted
  if (!dispatchItem?.data?.riskForm || !dispatchItem?.data?.riskForm?.submitted_at) {
    riskFormsStatus = 8;
  }

  // first one has been submitted
  else {
    riskFormsLastSubmittedAt = new Date(
        dispatchItem?.data?.riskForm?.submitted_at
    ).toUTCString();

    // second form should be there but isn't
    if (dateSecond) {
      if (
        dateSecond.isSameOrBefore(new Date()) &&
        !dispatchItem?.data?.riskForm2
      ) {
        riskFormsStatus = 6;
      } else if (dispatchItem?.data?.riskForm2) {
        riskFormsLastSubmittedAt = new Date(
          dispatchItem?.data?.riskForm2?.submitted_at
        ).toUTCString();
      }
    }

    // third form should be there but isn't
    if (dateThird) {
      if (
        dateThird.isSameOrBefore(new Date()) &&
        !dispatchItem?.data?.riskForm3
      ) {
        riskFormsStatus = 6;
      } else if (dispatchItem?.data?.riskForm3) {
        riskFormsLastSubmittedAt = new Date(
          dispatchItem?.data?.riskForm3?.submitted_at
        ).toUTCString();
      }
    }
  }

  return (
    <>
      {dispatchItem?.data ? (
        dispatchIsDataLoading ? (
          <Loading />
        ) : (
          <Box>
            <BackButton
              tabIndex={9}
              onClickHandler={() => {
                navigate(-1);
              }}
              ml={"-17px"}
            />
            
            {dispatchErrorMessage && (
              <AlertComponent message={dispatchErrorMessage} />
            )}

            <CaseViewHeader dispatchItem={dispatchItem}/>

            <Grid gridTemplateColumns={"300px 1fr"}>              
              <GeneralInfo dispatchItem={dispatchItem}/>

              <GridItem paddingLeft={"25px"}>
                
                <ComplianceHeading />

                <Grid templateColumns={"65px 2fr"} gap={"2px"} style={{ paddingTop: 20 }}>

                <ComplianceItemIndividuals id={id} title={"Individuals"} number={1}/>

                  <GridItem>
                    {!dispatchItem?.data?.form ? (
                      <img alt="circle 2" src="/assets/img/circle-2.png"
                        style={{ width: 50 }}
                      />
                    ) : dispatchItem?.data?.form.status === "Processed" ? (
                      <img alt="circle complete" src="/assets/img/circle-complete.png"
                        style={{ width: 50 }}
                      />
                    ) : (
                      <img alt="circle 2" src="/assets/img/circle-2.png"
                        style={{ width: 50 }}
                      />
                    )}
                  </GridItem>
                  <GridItem>
                    <ClientOpeningFormItem title={"Case Information"} />
                  </GridItem>

                  <ComplianceItemSignedDocuments number={3} />

                  {/* ROW 4 */}
                  <GridItem>
                    {riskFormsStatus === 6 ? (
                      <img alt="circle complete" src="/assets/img/circle-complete.png"
                        style={{ width: 50 }}
                      />
                    ) : (
                      <img alt="circle 4" src="/assets/img/circle-4.png"
                        style={{ width: 50 }}
                      />
                    )}
                  </GridItem>
                  <GridItem>
                    <ComplianceItem
                      title={"Risk Assessments"}
                      subtitle={riskFormsLastSubmittedAt}
                      status={riskFormsStatus}
                      isAlert={true}
                      type={"pdf"}
                      isVerified={false}
                      nestedComponent={
                        <>
                          {/* SHOW FIRST RISK ASSESSMENT */}
                          {dispatchItem?.data?.riskForm?.form_status ===
                          "submitted" ? 
<RiskAssessmentView 
  isRiskFormOpen={isRiskFormOpen}
  onRiskFormOpen={onRiskFormOpen}
  onRiskFormClose={onRiskFormClose}
  dispatchItem={dispatchItem}
  version={1}
/> : 
                          ( "" )}

                          {/* SHOW SECOND RISK ASSESSMENT */}
                          {dispatchItem?.data?.riskForm2?.form_status ===
                            "submitted" &&
                          !isDateExpired(
                            dispatchItem?.data?.riskForm2?.submitted_at,
                            3
                          ) ? 
<RiskAssessmentView 
  isRiskFormOpen={isRiskForm2Open}
  onRiskFormOpen={onRiskForm2Open}
  onRiskFormClose={onRiskForm2Close}
  dispatchItem={dispatchItem}
  version={2}
/> : 
                           ( "" )}

                          {/* SHOW THIRD RISK ASSESSMENT */}
                          {dispatchItem?.data?.riskForm3?.form_status ===
                          "submitted" ? 
<RiskAssessmentView 
  isRiskFormOpen={isRiskForm3Open}
  onRiskFormOpen={onRiskForm3Open}
  onRiskFormClose={onRiskForm3Close}
  dispatchItem={dispatchItem}
  version={3}
/> : 
                           ( "" )}

                          <Box marginTop={"22px"}>
                            {/* FIRST RISK ASSESSMENT BUTTON */}
                            {dispatchItem?.data?.riskForm?.form_status ===
                            "submitted" ? (
                              <Button
                                marginBottom={2}
                                {...viewButtonStyle}
                                onClick={() => {
                                  onRiskFormOpen();
                                }}
                              >
                                View Initial Risk Assessment
                              </Button>
                            ) : (
                              <>
                                <Button
                                  marginTop={5}
                                  as={ReachLink}
                                  to={"risk-assessment"}
                                  {...viewButtonStyle}
                                >
                                  Fill Out Form
                                </Button>
                              </>
                            )}

                            {/* SECOND RISK ASSESSMENT BUTTON */}
                            {dateSecond?.isSameOrBefore(new Date()) &&
                            dispatchItem?.data?.riskForm2?.form_status ===
                              "submitted" ? (
                              <Button
                                marginBottom={2}
                                {...viewButtonStyle}
                                onClick={() => {
                                  onRiskForm2Open();
                                }}
                              >
                                View Interim Risk Assessment
                              </Button>
                            ) : (
                              ""
                            )}
                            {dateSecond?.isSameOrBefore(new Date()) &&
                            dispatchItem?.data?.riskForm2?.form_status !==
                              "submitted" ? (
                              <Button
                                as={ReachLink}
                                to={"risk-assessment?version=2"}
                                {...viewButtonStyle}
                              >
                                Fill Out Interim Risk Assessment
                              </Button>
                            ) : (
                              ""
                            )}

                            {/* THIRD RISK ASSESSMENT BUTTON */}
                            {dateThird?.isSameOrBefore(new Date()) &&
                            dispatchItem?.data?.riskForm3?.form_status ===
                              "submitted" ? (
                              <Button
                                marginBottom={2}
                                {...viewButtonStyle}
                                onClick={() => {
                                  onRiskForm3Open();
                                }}
                              >
                                View Final Risk Assessment
                              </Button>
                            ) : (
                              ""
                            )}

                            {dateThird?.isSameOrBefore(new Date()) &&
                            dispatchItem?.data?.riskForm3?.form_status !==
                              "submitted" ? (
                              <Button
                                as={ReachLink}
                                to={"risk-assessment?version=3"}
                                {...viewButtonStyle}
                              >
                                Fill Out Final Risk Assessment
                              </Button>
                            ) : (
                              ""
                            )}
                          </Box>
                        </>
                      }
                    />
                  </GridItem>
                  {/* END OF ROW 4 */}
                </Grid>
              </GridItem>
            </Grid>
          </Box>
        )
      ) : (
        <>
          {" "}
          {dispatchErrorMessage ? (
            <AlertComponent message={dispatchErrorMessage} />
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  );
};
export default CaseView;

