import {makeRequest, API_URL} from "../../services/request";
import {
  AUDITOR_ACTIVATE_FAILED,
  AUDITOR_ACTIVATE_SUCCESS,
  AUDITOR_DEACTIVATE_FAILED,
  AUDITOR_DEACTIVATE_SUCCESS,
  AUDITOR_GET_BY_ID_SUCCESS,
  AUDITOR_LIST_DOWNLOADED,
  AUDITOR_LIST_NOT_DOWNLOADED,
  SET_DATA_LOADING_FALSE,
  SET_DATA_LOADING_TRUE,
  AUDITOR_SET_ERR_MESSAGE,
  SET_AUDITOR_NEW_DATA,
  SET_AUDITOR_NEW_DATA_FAILED,
  AUDITOR_DELETE_FAILED, AUDITOR_DELETE_SUCCESS,
  SET_NEW_AUDITORS_ERR_MESSAGE,
  SET_NEW_AUDITORS_SUCCESS
} from "./index";

export const setAuditorErrorMessage = (data) => {
  return ({type: SET_NEW_AUDITORS_ERR_MESSAGE, payload: data});
}

export const setNewAuditor = (data) => {
  return (dispatch) => {
    data.expires_value = data.expiry.split("_")[0]
    data.expires_period = data.expiry.split("_")[1]
    return makeRequest(`${API_URL}admin/auditors`, data, 'POST', true).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: SET_NEW_AUDITORS_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: SET_NEW_AUDITORS_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getAuditorList = (url = `admin/auditors`) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}${url}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: AUDITOR_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: AUDITOR_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getAuditorRequestsList = (url = `admin/auditors/requests`) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}${url}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: AUDITOR_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: AUDITOR_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const approveAuditorRequest = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/auditors/requests/${id}/approve`, id, 'POST', true)
      .then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: AUDITOR_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: AUDITOR_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getAuditorById = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/auditors/${id}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: AUDITOR_GET_BY_ID_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: AUDITOR_SET_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const setAuditorStatus = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/auditors/${data.url}`, {
      active: data.active
    }, 'PUT', true)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: data.active ? AUDITOR_ACTIVATE_SUCCESS : AUDITOR_DEACTIVATE_SUCCESS})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: data.active ? AUDITOR_ACTIVATE_FAILED : AUDITOR_DEACTIVATE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const deleteAuditor = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/auditors/${id}`, null, 'DELETE')
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: AUDITOR_DELETE_SUCCESS, payload: id})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: AUDITOR_DELETE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }

}

export const setAuditorData = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/auditors/${data.id}`, data, 'PUT', true)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: SET_AUDITOR_NEW_DATA, payload: data})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_AUDITOR_NEW_DATA_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setLoadingFlag = (flag = true) => {
  return (flag
      ? {type: SET_DATA_LOADING_TRUE}
      : {type: SET_DATA_LOADING_FALSE}
  );
}

