import React from "react";
import {
  Box, Container, Stack
} from "@chakra-ui/react";

import HeaderFormLogo from "./HeaderFormLogo";

const TextPlaceholder = ({content}) => {
  return (<Container
    maxW='2xl'
    py={{
      base: '12', md: '24',
    }}
    px={{
      base: '0',
    }}
  >
    <Stack spacing="8">
      <HeaderFormLogo/>
      <Box
        py={{
          base: '0', sm: '3',
        }}
        px={{
          base: '4', sm: '10',
        }}
        textAlign={'center'}
      >

        {content}
      </Box>
    </Stack>
  </Container>)
}
export default TextPlaceholder;