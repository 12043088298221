import React, {useState} from 'react';
import {
    Box, Button, Grid, GridItem, Heading, Table, TableContainer, Tbody, Td, Text, Tr, useDisclosure
} from "@chakra-ui/react";
import idPalBadge from "../assets/img/idpal-badge.png";
import {useDispatch, useSelector} from "react-redux";
import {getSubmissionContent, setSubmissionLoadingFlag} from "../redux/actions/clients";
import Alert from "./Alert";
import Loading from "./Loading";
import {Document, Page, pdfjs} from "react-pdf";
import ViewAPdfFile from "./ViewAPdfFile";
import ImageZoom from './ImageZoom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const IdPalItem = ({
                       title,
                       subtitle = null,
                       status = null,
                       isAlert = true,
                       isVerified = true,
                       nestedComponent = null,
                       data = null,
                       submissionId = null,
                       type = 'img',
                       unsetBg = false,
                       fullWidth = false,
                       auditor = 0
                   }) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isFullScreen, setIsFullScreen] = useState(false);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const dispatchGetSubmissionContent = (submId, docId, auditor) => dispatch(getSubmissionContent(submId, docId, auditor));

    const selectGetSubmissionContent = useSelector(state => state.clients?.getSubmissionContent)
    const dispatchIsSubmissionDataLoading = useSelector(state => state.clients.isSubmissionDataLoading)

    const dispatch = useDispatch();

    const {isOpen, onOpen, onClose} = useDisclosure();

    const dispatchSetSubmissionLoadingFlag = (flag) => dispatch(setSubmissionLoadingFlag(flag));

    const cardStyle = {
        rounded: 'lg',
        bgColor: '#F9FBFC', // width: '466px',
        p: '24px',
        minHeight: 26,
        marginTop: 2,
        marginBottom: 1,
    }

    const viewButtonStyle = {
        bgColor: '#ffffff',
        border: '1px solid #6E7997',
        color: '#6E7997',
        fontSize: '16px',
        fontWeight: 500,
        w: '100%',
        minH: '55px',
    }

    const tableLeftStyle = {
        color: '#6E7997',
        fontWeight: '400',
        textAlign: 'left',
        fontSize: '18px',
        verticalAlign: 'top',
        width: '40%',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
    }

    const tableRightStyle = {
        color: '#343B55',
        fontWeight: '500',
        textAlign: 'left',
        fontSize: '18px',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
        verticalAlign: 'top',
    }

    var templateColumns = '1fr 1fr'
    if (title.trim() !== "ID Document") {
        templateColumns = '1fr'
    }

    return (
      <>
        {isFullScreen && (
            <Alert
                isOpen={isFullScreen}
                onClose={() => setIsFullScreen(false)}
                text={<>
                {selectGetSubmissionContent && selectGetSubmissionContent.length &&
                    selectGetSubmissionContent.map(({data}) => (
                        <ViewAPdfFile data={data} width={1028} />
                    ))}
                </>}
                action={null}
                size={'6xl'}
                contentPaddingY={0}
            />
        )}

        <GridItem
            {...cardStyle}
            mt={1}
            width={fullWidth ? '50%':null}
            bgColor={unsetBg ? '' : cardStyle.bgColor}   
        >
            {isAlert && <Alert
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                header={title ?? 'Type of document unset'}
                text={dispatchIsSubmissionDataLoading ? <Loading/> : <>
                    <Text color={'#343B55'}
                          fontWeight={'400'}
                          textAlign={'center'}
                          mb={10}
                    >
                        {data?.subtitle ?? 'Type of document unset'}
                    </Text>

                        <Grid templateColumns={templateColumns} gap={6}>

                        <GridItem>

                            {selectGetSubmissionContent && selectGetSubmissionContent.length &&
                                selectGetSubmissionContent.map(({data}, idx) => (
                                    <Box key={data.slice(0, 40)}
                                         borderRadius={'8px'}
                                         mb={5}
                                         border={'1px solid #B4BAC9'}
                                         py={'21px'}
                                         px={'48px'}
                                    >

                                        {type === 'img' &&
                                          <ImageZoom
                                            zoomLevel={3.2}
                                            magnifieWidth={500}
                                            src={`data:image/png;base64, ${data}`}
                                          />
                                        }

                                        {type === 'pdf' && <div>

                                            {numPages && numPages > 1 && <>
                                                <Text> Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</Text>
                                                <Button
                                                    type="button"
                                                    disabled={pageNumber <= 1}
                                                    onClick={previousPage}
                                                >
                                                    Previous
                                                </Button>
                                                <Button
                                                    type="button"
                                                    disabled={pageNumber >= numPages}
                                                    onClick={nextPage}
                                                >
                                                    Next
                                                </Button>
                                            </>}
                                            <Document
                                                file={`data:application/pdf;base64,${data}`}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                            >
                                                <Page pageNumber={pageNumber}
                                                      width={450}
                                                      renderTextLayer={false}
                                                      renderAnnotationLayer={false}
                                                />
                                            </Document>

                                        </div>}

                                    </Box>))
                            }

                        </GridItem>

{title.trim() !== "Proof of Address" ?

                        <GridItem>

                            {data &&
                                <TableContainer
                                >
                                    <Table
                                        width={'100%'}
                                    >
                                        <Tbody>
                                            {
                                                Object.entries(data).map(
                                                    ([key, value], i) => key && key !== 'images' ? (
                                                        value
                                                            ? <Tr key={key}>
                                                                <Td width={'100px'} {...tableLeftStyle}
                                                                    textTransform={'capitalize'}>{key.replaceAll('_', ' ')}</Td>
                                                                <Td {...tableRightStyle}>{Array.isArray(value) && value.length
                                                                    ? value.map((el, index) => (
                                                                        <Text mb={4}
                                                                              key={Object.values(el)}
                                                                              {...tableRightStyle}>{`${index + 1}: ${Object.values(el)} `}
                                                                        </Text>))
                                                                    : value}</Td>
                                                            </Tr>
                                                            : null
                                                    ) : null
                                                )
                                            }

                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            }
                        </GridItem>
:<></>}


                    </Grid>

                    {(title.trim() === "Proof of Address" || title.trim() === "Additional Documents") 
                            && !isFullScreen && (
                        <Button
                            {...viewButtonStyle}
                            width={'auto'}
                            onClick={() => setIsFullScreen(true)}
                        >
                            View Full Screen
                        </Button>
                    )}

                </>}
                action={null}
                size={'6xl'}
                contentPaddingY={0}
            />}

            <Grid templateColumns={'1fr auto'}>

                <GridItem>
                    <Heading
                        as={'h3'}
                        fontWeight={'700'}
                        fontSize={'18px'}
                    >
                        {title}

                    </Heading>

                    {/* subtitle */}
                    <Text
                        color={'#6E7997'}
                        fontWeight={'400'}
                        mt={2}
                        fontSize={'18px'}
                    >
                        {subtitle || data?.subtitle}
                    </Text>
                </GridItem>

                {/* status */}
                <GridItem
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-end'}
                >
                    <Text
                        color={'#fff'}
                        fontSize={'11px'}
                        bgColor={(() => {
                            var arrayProcessedStatus = [4, 6, 10]
                            if (data?.approved === 1 || arrayProcessedStatus.includes(status)) {
                                return '#1CBE7A' // green
                                
                            }
                            else {
                                return '#FFBF00' // orange
                            }
                        })()}
                        paddingX={'10px'}
                        borderRadius={'4px'}
                    >
                        {(() => {
                            switch (status || Number(data?.submission_status)) {
                                case 1:
                                    return 'Submitted, no errors'
                                case 2:
                                    return 'Submitted with errors'
                                case 3:
                                    return 'Alert'
                                case 4: {
                                    if (data?.approved === 1) {
                                        return 'Approved'
                                    }
                                    else if (title.trim() !== "Proof of Address") {
                                        return 'CDD report ready, no errors'
                                    }
                                    else if (title.trim() === "Proof of Address") {
                                        return 'Ready for Approval'
                                    }
                                    return ''
                                }
                                case 5:
                                    return 'CDD report with errors'
                                case 6:
                                    return 'Submitted'
                                case 7:
                                    return 'Not submitted yet'
                                case 8:
                                    return 'Pending'
                                case 9:
                                    return 'Expired'
                                case 10:
                                    return 'Signed'
                                case 11:
                                    return 'Not signed yet'
                                default:
                                    return null
                            }
                        })()}
                    </Text>

                    {(isVerified && title.trim() !== "Proof of Address") && <Box
                        marginTop={5}
                        display={'flex'}
                        color={'#4285F4'}
                        alignItems={'center'}
                    >
                        <Text
                            as={'span'}
                            marginRight={'11px'}
                            fontSize={'14px'}
                        >Verified by </Text>

                        <img
                            src={idPalBadge}
                            width={'30px'}
                            height={'17px'}
                            alt={'ID pal'}

                        /> </Box>}

                        {title.trim() === "Proof of Address" && 
                            <Box marginTop={10}></Box> }
                </GridItem>
            </Grid>

            {nestedComponent ? nestedComponent : <Box marginTop={'22px'}>
                <Button
                    {...viewButtonStyle}
                    onClick={() => {
                        dispatchSetSubmissionLoadingFlag();
                        dispatchGetSubmissionContent(submissionId, Object.values(data.images), auditor);
                        onOpen();
                    }}
                >View Document</Button>
            </Box>}
        </GridItem>
        </>
    );
};

export default IdPalItem;
