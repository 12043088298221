import React, { useState } from "react";
import AlertComponent from "../../../../../AlertComponent";
import Alert from "../../../../../Alert";
import { Button, useDisclosure, Text } from "@chakra-ui/react";
import { reinviteClient } from "../../../../../../redux/actions/clients";

function IndividualsReInvite(data) {

    const id = data?.id
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
    const [errorMessage, setErrorMessage] = useState(null);
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false)

    function handleReInvite(id) {      
      setIsLoading(true)
      setErrorMessage(null)
      reinviteClient({
        "client_id": id,
      }).then(resp => {          
          setIsLoading(false)
          setStep(2).then(() => {        
            onConfirmOpen()
          })
      }).catch(err => {
          onConfirmOpen()
          setIsLoading(false)
          setErrorMessage(err)
      });
    }

    return (
<>
<Button 
  className="button__white individuals_footer-button"
  style={{float:'right', marginRight: '10px'}}
  onClick={() => {
    setErrorMessage(null);
    setStep(1);
    onConfirmOpen();
  }}
  >
    Update Shareholder List
</Button>

<Alert
            isOpen={isConfirmOpen}
            onOpen={onConfirmOpen}
            onClose={onConfirmClose}
            header={`Update Shareholder List`}
            isLoading={isLoading}
            confirmText={step === 1 ? 'Confirm' : 'OK'}
            showCancel={step === 1 ? true : false}
            text={
              step === 1 ? (
<>
{errorMessage && (<AlertComponent message={errorMessage} />)}
<Text>Are you sure you want to email the main contact for this case so that they can update the shareholder list?</Text>
</>
) : (
  <Text>The main contact for this case has now been sent an email asking them to confirm the shareholders list.</Text>
)
            }
            action={() => {
                step === 1 ? handleReInvite(id)
                : onConfirmClose();
            }}    
/>
</>
);
};

export default IndividualsReInvite;