import {
    ADMIN_AUTHENTICATED,
    ADMIN_LOGIN_AS_FIRM,
    ADMIN_CANNOT_LOGIN_AS_FIRM,
    ADMIN_NOT_AUTHENTICATED,
    FIRM_AUTHENTICATED,
    FIRM_NOT_AUTHENTICATED,
    FIRM_LOGIN_AS_SOLICITOR,
    FIRM_CANNOT_LOGIN_AS_SOLICITOR ,
    FIRM_CODE_SEND,
    FIRM_CODE_NOT_SEND,
    FIRM_SET_ERR_MESSAGE,
    FIRM_NOT_REGISTERED,
    FIRM_REGISTERED,
    ADMIN_SET_ERR_MESSAGE,
    FORGOT_CODE_SENT,
    FORGOT_CODE_NOT_SENT,
    FORGOT_CODE_ACCEPTED,
    FORGOT_CODE_NOT_ACCEPTED,
    FORGOT_NEW_PASS_ACCEPTED,
    FORGOT_NEW_PASS_NOT_ACCEPTED,
    SET_ADMIN_LOGIN_DATA_LOADING_TRUE,
    SET_ADMIN_LOGIN_DATA_LOADING_FALSE,
    SET_FIRM_LOGIN_DATA_LOADING_TRUE,
    SET_FIRM_LOGIN_DATA_LOADING_FALSE,
    SOLICITOR_SET_ERR_MESSAGE,
    SET_NEW_SOLICITOR_BY_SECRET_SUCCESS,
    SET_NEW_SOLICITOR_BY_SECRET_ERR_MESSAGE,
    SOLICITOR_AUTHENTICATED,
    SOLICITOR_NOT_AUTHENTICATED,
    SET_SOLICITOR_LOGIN_DATA_LOADING_TRUE,
    SET_SOLICITOR_LOGIN_DATA_LOADING_FALSE,
    SET_NEW_ACCOUNT_BY_SECRET_SUCCESS,
    SET_NEW_AUDITOR_BY_SECRET_SUCCESS,
    ACCOUNT_SET_ERR_MESSAGE,
    ACCOUNT_AUTHENTICATED,
    ACCOUNT_NOT_AUTHENTICATED,
    SET_ACCOUNT_LOGIN_DATA_LOADING_TRUE,
    SET_ACCOUNT_LOGIN_DATA_LOADING_FALSE,
    SET_AUDITOR_LOGIN_DATA_LOADING_TRUE,
    SET_AUDITOR_LOGIN_DATA_LOADING_FALSE,
    AUDITOR_AUTHENTICATED,
    AUDITOR_NOT_AUTHENTICATED,
    AUDITOR_SET_ERR_MESSAGE

} from "./index";

import {setToken} from "../../services/token";
import {makeRequest, API_URL} from "../../services/request";

export const loginAdmin = (credentials) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/auth/sign-in`, credentials, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              setToken(data.token);
              dispatch({type: ADMIN_AUTHENTICATED, payload: data.user})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: ADMIN_NOT_AUTHENTICATED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const loginAdminAsFirm = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/login/${id}`)
    .then((res) => {
      if (res.ok) {
        return res
          .json()
          .then(data => {
            setToken(data.token);
            dispatch({type: ADMIN_LOGIN_AS_FIRM, payload: true})
          })
      } else {
        return res.json().then((err) => {
          dispatch({type: ADMIN_CANNOT_LOGIN_AS_FIRM, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const logoutAdmin = () => {
  return (dispatch) => {
    return makeRequest(`${API_URL}admin/auth/logout`, null, 'POST')
      .then((res) => {
        if (res.ok) {
          return dispatch({type: ADMIN_NOT_AUTHENTICATED, payload: null});
        } else {
          return res.json().then((err) => {
            dispatch({type: ADMIN_NOT_AUTHENTICATED});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setAdminLoginLoadingFlag = (flag = true) => {
  return (flag ? {type: SET_ADMIN_LOGIN_DATA_LOADING_TRUE} : {type: SET_ADMIN_LOGIN_DATA_LOADING_FALSE});
}


// FIXME: admin or law firm check must be separated
export const checkAdminAuth = () => {
  return (dispatch) => {
    return makeRequest(`${API_URL}user`).then((res) => {
      if (res.ok) {
        return res.json().then(user => dispatch({type: ADMIN_AUTHENTICATED, payload: user}))
      } else {
        return Promise.reject(dispatch({type: ADMIN_NOT_AUTHENTICATED}))
      }
    })
  }
}

export const loginFirm = (credentials) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/sign-in`, credentials, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              setToken(data.token);
              dispatch({type: FIRM_AUTHENTICATED, payload: data.user})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: FIRM_NOT_AUTHENTICATED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const loginFirmAsSolicitor = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}solicitors/login/${id}`)
    .then((res) => {
      if (res.ok) {
        return res
          .json()
          .then(data => {
            setToken(data.token);
            dispatch({type: FIRM_LOGIN_AS_SOLICITOR, payload: data})
          })
      } else {
        return res.json().then((err) => {
          dispatch({type: FIRM_CANNOT_LOGIN_AS_SOLICITOR, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const logoutFirm = () => {

  return (dispatch) => {
    return makeRequest(`${API_URL}auth/logout`, null, 'POST')
      .then((res) => {
        if (res.ok) {
          return dispatch({type: FIRM_NOT_AUTHENTICATED, payload: null});
        } else {
          return res.json().then((err) => {
            dispatch({type: FIRM_NOT_AUTHENTICATED});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const signUpFirm = (credentials) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/sign-up`, credentials, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              setToken(data.token);
              dispatch({type: FIRM_REGISTERED, payload: data.user})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: FIRM_NOT_REGISTERED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const checkAuth = (role = 'firm') => {
  role = role.toUpperCase();
  return (dispatch) => {
    return makeRequest(`${API_URL}user`).then((res) => {
      if (res.ok) {
        return res.json().then(user => dispatch({type: `${role}_AUTHENTICATED`, payload: user}))
      } else {
        return Promise.reject(dispatch({type: `${role}_NOT_AUTHENTICATED`}))
      }
    })
  }
}

/**
 * @param data.email
 * @returns {(function(*): void)|*}
 */

export const sendVerificationCode = (data) => {
  // sign up routine
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/send-verification-code`, data, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res.json().then(dispatch({type: FIRM_CODE_SEND}));
        } else {
          return res.json().then((err) => {
            dispatch({type: FIRM_CODE_NOT_SEND, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

/**
 * @param data.email
 * @param data.code
 * @returns {(function(*): void)|*}
 */

export const resetPassword = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/reset-password`, data, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              setToken(data.token);
              dispatch({type: FORGOT_CODE_ACCEPTED, payload: data.user});
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: FORGOT_CODE_NOT_ACCEPTED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

/**
 * @param data.email
 * @returns {(function(*): void)|*}
 */
export const forgotPassword = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/forgot-password`, data, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: FORGOT_CODE_SENT})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: FORGOT_CODE_NOT_SENT, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

/**
 * @param data.code
 * @param data
 * @returns {(function(*): void)|*}
 */
export const emailVerification = (data) => {

  return (dispatch) => {
    return makeRequest(`${API_URL}auth/email-verification`, data, 'POST')
      .then((res) => {
        if (res.ok) {
          return res.json().then(dispatch({type: `SOLICITOR_CODE_AUTHENTICATED`}));
        } else {
          return res.json().then((err) => {
            dispatch({type: `SOLICITOR_CODE_AUTHENTICATED`, payload: err});
            return Promise.reject(err)
          })
        }
      }).catch((err) => {
            alert("Please double check the code you entered.");
            dispatch({type: `SOLICITOR_CODE_AUTHENTICATED`, payload: err});
            return Promise.reject(err)
        }
      )
  }
}

/**
 * @param data.password
 * @param data.password_confirmation
 * @returns {(function(*): void)|*}
 */
export const updatePassword = (data, navigate) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/update-password`, data, 'POST')
      .then((res) => {
        if (res.ok) {
            return res.json().then((data) => {
                dispatch({type: FORGOT_NEW_PASS_ACCEPTED});
                switch (data.user?.type) {
                    case 'solicitor':
                        navigate('/solicitor/dashboard/');
                        break;
                    case 'firm':
                        navigate('/firm/solicitors/');
                        break;
                    case 'account':
                        navigate('/account/forms/');
                        break;
                    case 'auditor':
                        navigate('/auditor/firms/');
                        break;
                    default:
                        navigate(0);
                        break;
                }
                }
            );
        } else {
          return res.json().then((err) => {
            dispatch({type: FORGOT_NEW_PASS_NOT_ACCEPTED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}


export const setFirmLoginLoadingFlag = (flag = true) => {
  return (flag ? {type: SET_FIRM_LOGIN_DATA_LOADING_TRUE} : {type: SET_FIRM_LOGIN_DATA_LOADING_FALSE});
}

export const setFirmErrorMessage = (data) => {
  return ({type: FIRM_SET_ERR_MESSAGE, payload: data});
}

export const setAdminErrorMessage = (data) => {
  return ({type: ADMIN_SET_ERR_MESSAGE, payload: data});
}

export const setSolicitorErrorMessage = (data) => {
  return ({type: SOLICITOR_SET_ERR_MESSAGE, payload: data});
}

export const setAccountErrorMessage = (data) => {
    return ({type: ACCOUNT_SET_ERR_MESSAGE, payload: data});
}
export const setAccountPasswordErrorMessage = (data) => {
    return ({type: ACCOUNT_SET_ERR_MESSAGE, payload: data});
}

export const setSolicitorPasswordErrorMessage = (data) => {
  return ({type: SOLICITOR_SET_ERR_MESSAGE, payload: data});
}

export const setAuditorErrorMessage = (data) => {
    return ({type: AUDITOR_SET_ERR_MESSAGE, payload: data});
}
export const setAuditorPasswordErrorMessage = (data) => {
    return ({type: AUDITOR_SET_ERR_MESSAGE, payload: data});
}

/**
 * @param data.secret
 * @param data.password
 * @param data.password_confirmation
 * @returns {(function(*): void)|*}
 */
export const setPassword = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/set-password`, data, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              setToken(data.token);
              if (data.user.type === 'account') {
                  dispatch({type: SET_NEW_ACCOUNT_BY_SECRET_SUCCESS, payload: data})
              }
              else if (data.user.type === 'auditor') {
                  dispatch({type: SET_NEW_AUDITOR_BY_SECRET_SUCCESS, payload: data})
              }
              else {
                  dispatch({type: SET_NEW_SOLICITOR_BY_SECRET_SUCCESS, payload: data})
              }
              return data.user.type;
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_NEW_SOLICITOR_BY_SECRET_ERR_MESSAGE, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const checkSolicitorAuth = () => {
  return (dispatch) => {
    return makeRequest(`${API_URL}user`).then((res) => {
      if (res.ok) {
        return res.json().then(user => {
            if (user.type === 'solicitor') {
                dispatch({type: SOLICITOR_AUTHENTICATED, payload: user});
            } else {
                return Promise.reject(dispatch({type:  SOLICITOR_NOT_AUTHENTICATED}));
            }
        })
      } else {
        return Promise.reject(dispatch({type:  SOLICITOR_NOT_AUTHENTICATED}))
      }
    })
  }
}

export const checkAccountAuth = () => {
  return (dispatch) => {
    return makeRequest(`${API_URL}user`).then((res) => {
      if (res.ok) {
        return res.json().then(user => {
            if (user.type === 'account') {
                dispatch({type: ACCOUNT_AUTHENTICATED, payload: user});
            } else {
                return Promise.reject(dispatch({type: ACCOUNT_NOT_AUTHENTICATED}));
            }
        })
      } else {
        return Promise.reject(dispatch({type: ACCOUNT_NOT_AUTHENTICATED}))
      }
    })
  }
}

export const checkAuditorAuth = () => {
  return (dispatch) => {
    return makeRequest(`${API_URL}user`).then((res) => {
      if (res.ok) {
        return res.json().then(user => {
            if (user.type === 'auditor') {
                dispatch({type: AUDITOR_AUTHENTICATED, payload: user});
            } else {
                return Promise.reject(dispatch({type: AUDITOR_NOT_AUTHENTICATED}));
            }
        })
      } else {
        return Promise.reject(dispatch({type: AUDITOR_NOT_AUTHENTICATED}))
      }
    })
  }
}

export const setAuditorLoginLoadingFlag = (flag = true) => {
    return (flag ? {type: SET_AUDITOR_LOGIN_DATA_LOADING_TRUE} : {type: SET_AUDITOR_LOGIN_DATA_LOADING_FALSE});
}

export const setAccountLoginLoadingFlag = (flag = true) => {
    return (flag ? {type: SET_ACCOUNT_LOGIN_DATA_LOADING_TRUE} : {type: SET_ACCOUNT_LOGIN_DATA_LOADING_FALSE});
}

export const setSolicitorLoginLoadingFlag = (flag = true) => {
  return (flag ? {type: SET_SOLICITOR_LOGIN_DATA_LOADING_TRUE} : {type: SET_SOLICITOR_LOGIN_DATA_LOADING_FALSE});
}

export const logout = (role = 'firm', navigate = null) => {
  role = role.toUpperCase();
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/logout`, null, 'POST')
      .then((res) => {
        if (res.ok) {
          dispatch({type: `${role}_NOT_AUTHENTICATED`});
          return navigate ? navigate('/signin') : null;
        } else {
          return res.json().then((err) => {
            dispatch({type: `${role}_NOT_AUTHENTICATED`});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const loginUser = (credentials) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/sign-in`, credentials, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
                setToken(data.token);
                dispatch({type: SOLICITOR_AUTHENTICATED, payload: data.user});
            })
        } else {
          return res.json().then((err) => {
              console.log(err);
            dispatch({type:  SOLICITOR_NOT_AUTHENTICATED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const loginAccount = (credentials) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/sign-in`, credentials, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              setToken(data.token);
              dispatch({type: ACCOUNT_AUTHENTICATED, payload: data.user})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: ACCOUNT_NOT_AUTHENTICATED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const loginAuditor = (credentials) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}auth/sign-in`, credentials, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              setToken(data.token);
              dispatch({type: AUDITOR_AUTHENTICATED, payload: data.user})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: AUDITOR_NOT_AUTHENTICATED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setAuthNotFirstCheck = (flag = true, role) => {
  role = role.toUpperCase();
  return (flag ? {type: `SET_${role}_NOT_FIRST_CHECK`} : {type: `SET_${role}_FIRST_CHECK`});
}
