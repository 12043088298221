import {Alert, AlertIcon} from "@chakra-ui/react";
import * as React from "react";
import {WarningIcon} from "@chakra-ui/icons";

const AlertComponent = ({message, status = 'error', ...props}) => {

console.log(message)

  return <Alert
    status={status}
    mb={3}
    {...props}>
    {status === 'error' ? <AlertIcon/> : <WarningIcon color={'orange'}/>}
    <div>{Array.isArray(message.messages)
      ? typeof message.messages[0] === 'object'
        ? message.messages.map(
          (el) => Object.values(el).map((el, i) => {
            return <p>{el}</p>
          })
        )
        : message.messages[0]
      : message.message
        ? message.message
        : 'Unexpected error, please try again later.'}</div>
  </Alert>
}

export default AlertComponent