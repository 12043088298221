import {makeRequest, API_URL} from "../../services/request";
import {
  SET_NEWSLETTER_FORM_SUCCESS,
  SET_NEWSLETTER_FORM_FAILED,
  SET_DEMO_REQUEST_FORM_SUCCESS,
  SET_DEMO_REQUEST_FORM_FAILED,
} from "./index";


export const setNewsletterForm = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}website/subscribenewsletter`, data, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res.json().then(data => dispatch({type: SET_NEWSLETTER_FORM_SUCCESS, payload: data}))
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_NEWSLETTER_FORM_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setDemoForm = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}website/requestdemo`, data, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res.json().then(data => dispatch({type: SET_DEMO_REQUEST_FORM_SUCCESS, payload: data}))
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_DEMO_REQUEST_FORM_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}