import React, {useEffect, useState} from 'react';
import {
  Box, Button, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, Link, Stack, Text,
} from "@chakra-ui/react";

import {useFormik} from "formik";
import {Logo} from "../../components/Logo";
import {
  BigBlueCheck,
  // WhiteCheckIcon
} from "../../assets/icons/sprite";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  getClientDetailsBySecret,
  setClientErrorMessage,
  setLoadingFlag,
  setNewClientBySecret
} from "../../redux/actions/clients";
import Loading from "../../components/Loading";
import InactiveStatusShow from "../../components/InactiveStatusShow";
import AlertComponent from "../../components/AlertComponent";

const AccountOpeningForm = () => {

  const {secret} = useParams();

  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  const dispatchSetClientErrorMessage = (data) => dispatch(setClientErrorMessage(data))
  const dispatchSetNewClientBySecret = (data) => dispatch(setNewClientBySecret(data))

  const selectClientIsFormSubmitted = useSelector(state => state.clients?.getClientBySecret?.data?.account_operation_form_submitted)
  const selectClientFirstName = useSelector(state => state.clients?.getClientBySecret?.data?.first_name)
  const selectClientLastName = useSelector(state => state.clients?.getClientBySecret?.data?.last_name)
  const selectClientAddress = useSelector(state => state.clients?.getClientBySecret?.data?.address)
  const selectClientPhone = useSelector(state => state.clients?.getClientBySecret?.data?.phone)
  const selectClientEmail = useSelector(state => state.clients?.getClientBySecret?.data?.email)
  const selectIsDataLoading = useSelector(state => state.clients?.isDataLoading)
  const selectIsSolicitorActive = useSelector(state => state.clients?.getClientBySecret?.data?.user?.active)
  const selectSolicitorEmail = useSelector(state => state.clients?.getClientBySecret?.data?.user?.email)
  const selectSolicitorName = useSelector(state => state.clients?.getClientBySecret?.data?.user?.name)
  const selectAccountFormSubmitted = useSelector(state => state.clients?.getClientBySecret?.data?.account_operation_form_submitted)

  const selectErrorMessage = useSelector(state => state.clients.errorMessage)

  const FormSubmitted = () => {
    return (<Flex justifyContent={'center'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  minH={'100vh'}
                  minW={'100vw'}
    >
      <Box bgColor={'#F9FBFC'}
           borderRadius={'50%'}
           padding={8}
           marginBottom={8}
      >
        <BigBlueCheck width={'96px'} height={'96px'}/>
      </Box>

      <Text fontSize={'28px'}
            fontWeight={700}
            mb={4}

      >Thank you for filling the form</Text>

    </Flex>)
  }

  const handleSubmit = async values => {

    if (values.name.replaceAll(' ', '').length < 1) {
      dispatchSetClientErrorMessage({
        result: 'error', message: 'All fields must be filled.'
      })
      return
    }

    if (step === 1) {
      dispatchSetNewClientBySecret({
        "phone": values.mobile,
        "address": values.address,
        "name": values.name,
        "surname": values.lastName,
        "email": values.email,
        "secret": secret
      })
        .then(() => setStep(2))
        .catch((err) => dispatchSetClientErrorMessage(err))
    }
  }

  const formik = useFormik({
    enableReinitialize: true, initialValues: {
      name: selectClientFirstName ?? '',
      lastName: selectClientLastName ?? '',
      address: selectClientAddress ?? '',
      mobile: selectClientPhone ?? '',
      email: selectClientEmail ?? ''
    }, onSubmit: handleSubmit
  })

  useEffect(() => {
    dispatch(setLoadingFlag())
    dispatch(getClientDetailsBySecret(secret))
  }, [dispatch, secret,])

  return (selectIsDataLoading
    ? <Loading/>
    : selectAccountFormSubmitted
      ? <FormSubmitted/>
      : selectIsSolicitorActive
        ? !selectClientIsFormSubmitted
          ? <Grid gridTemplateColumns={{md: '344px 1fr'}}
                  minH={'100vh'}
          >
            <GridItem minH={'100vh'}
                      bgColor={'#F9FBFC'}
                      pt={12}
                      pb={12}
                      pl={10}
                      pr={10}
                      display={'flex'}
                      flexDir={'column'}
            >
              <Logo
                width={'183px'}
                height={'41px'}
                mb={'55px'}
              />

              <Box bgColor={'#FFFFFF'}
                   p={4}
                   mb={8}
              >
                <Text fontWeight={700}
                      fontSize={'18px'}>
                  Your solicitor:
                </Text>
                {selectSolicitorName && <Text fontWeight={400}>{selectSolicitorName}</Text>}

              </Box>

              {/*<Grid templateColumns={'56px 1fr'}*/}
              {/*      gap={'32px'}*/}

              {/*>*/}
              {/*  <GridItem bgColor={'#4285F4'}*/}
              {/*            borderRadius={'50%'}*/}
              {/*            display={'flex'}*/}
              {/*            justifyContent={'center'}*/}
              {/*            alignItems={'center'}*/}
              {/*            height={'56px'}*/}
              {/*            width={'56px'}*/}
              {/*  >*/}
              {/*    <WhiteCheckIcon width={'36px'}*/}
              {/*                    height={'36px'}*/}

              {/*    />*/}
              {/*  </GridItem>*/}
              {/*  <GridItem fontSize={'18px'}*/}
              {/*            fontWeight={700}*/}
              {/*            display={'flex'}*/}
              {/*            alignItems={'center'}*/}
              {/*  >*/}
              {/*    Fill in Account Opening Form*/}
              {/*  </GridItem>*/}

              {/*  <GridItem bgColor={'#ffffff'}*/}
              {/*            borderRadius={'50%'}*/}
              {/*            display={'flex'}*/}
              {/*            justifyContent={'center'}*/}
              {/*            alignItems={'center'}*/}
              {/*            border={'2px solid #4285F4'}*/}
              {/*            color={'#4285F4'}*/}
              {/*            fontWeight={700}*/}
              {/*            fontSize={'18px'}*/}
              {/*            height={'56px'}*/}
              {/*            width={'56px'}*/}
              {/*  >*/}
              {/*    2*/}
              {/*  </GridItem>*/}
              {/*  <GridItem fontSize={'18px'}*/}
              {/*            fontWeight={700}*/}
              {/*            display={'flex'}*/}
              {/*            alignItems={'center'}*/}
              {/*  >*/}
              {/*    Sign S150*/}
              {/*  </GridItem>*/}


              {/*</Grid>*/}


              <Box display={'flex'}
                   flexGrow={1}
                   alignItems={'flex-start'}
                   justifyContent={'flex-end'}
                   flexDirection={'column'}
              >

                {selectSolicitorEmail && <>
                  <Text color={'#6E7997'}
                        fontWeight={700}
                  >Have any questions?</Text>

                  <Link color={'#6E7997'}
                        href={`mailto:${selectSolicitorEmail}?subject=feedback from Account Opening Form from ${selectClientFirstName} ${selectClientLastName}`}
                        textDecoration={'underline'}
                  >Contact your lawyer</Link>
                </>}

              </Box>

            </GridItem>

            <GridItem>
              <Flex justifyContent={'center'}
                    alignItems={'center'}
                    minH={'100vh'}
                    flexDir={'column'}
              >
                <Box w={'552px'}>

                  {step === 1 ?

                    <form
                      onSubmit={formik.handleSubmit}
                    >
                      <Heading fontSize={'32'}
                               textAlign={'center'}
                               mb={8}
                      >
                        Fill in Account Opening Form
                      </Heading>
                      <Stack spacing="5">
                        {selectErrorMessage && (<AlertComponent
                          message={selectErrorMessage}
                        />)}
                        <FormControl>
                          <FormLabel
                            htmlFor="name"
                            fontSize={'18px'}>
                            Name
                          </FormLabel>
                          <Input
                            tabIndex={3}
                            id="name"
                            type="text"
                            required
                            placeholder={'Enter your name'}
                            fontSize={'18px'}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel htmlFor="lastName" fontSize={'18px'}>Surname</FormLabel>
                          <Input
                            tabIndex={4}
                            id="lastName"
                            type="text"
                            required
                            placeholder={'Enter your surname'}
                            fontSize={'18px'}
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel htmlFor="address" fontSize={'18px'}>Address</FormLabel>
                          <Input
                            tabIndex={5}
                            id="address"
                            type="text"
                            required
                            placeholder={'Enter your address'}
                            fontSize={'18px'}
                            onChange={formik.handleChange}
                            value={formik.values.address}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel htmlFor="mobile" fontSize={'18px'}>Mobile</FormLabel>
                          <Input
                            tabIndex={6}
                            id="mobile"
                            type="text"
                            required
                            placeholder={'Enter your mobile'}
                            fontSize={'18px'}
                            onChange={formik.handleChange}
                            value={formik.values.mobile}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel htmlFor="email" fontSize={'18px'}>Email</FormLabel>
                          <Input
                            tabIndex={7}
                            id="email"
                            type="email"
                            required
                            placeholder={'Enter your email'}
                            fontSize={'18px'}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                        </FormControl>
                      </Stack>


                      <Stack spacing="6" text-align={'right'} py={'20px'}>>
                        <Button
                          tabIndex={7}
                          type="submit"
                          fontSize={'18px'}
                          variant="primary">Open Account</Button>
                      </Stack>

                    </form> : <Flex justifyContent={'center'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                    >
                      <Box bgColor={'#F9FBFC'}
                           borderRadius={'50%'}
                           padding={8}
                           marginBottom={8}
                      >
                        <BigBlueCheck width={'96px'} height={'96px'}/>
                      </Box>

                      <Text fontSize={'28px'}
                            fontWeight={700}
                            mb={4}

                      >Thank you for filling the form</Text>
                      <Text fontSize={'18px'}>This data will be sent to your solicitor.</Text>
                      <Text fontSize={'18px'}>Your S150 form is being generated</Text>

                    </Flex>}
                </Box>
              </Flex>
            </GridItem>
          </Grid>
          : <FormSubmitted/>
        : <InactiveStatusShow
          logoLink={false}
          role={'client'}
          text={`The form can't be filled at the moment`}
          checkAction={() => dispatch(getClientDetailsBySecret(secret))}
        />);
};

export default AccountOpeningForm;
