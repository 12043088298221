import React, { useEffect, useState } from 'react';

import RiskAssessmentView from "../../components/Solicitor/CaseView/RiskAssessment"
import ComplianceItemSignedDocuments from "../../components/Solicitor/CaseView/Compliance/Items/SignedDocuments"
import ComplianceItemIndividuals from "../../components/Solicitor/CaseView/Compliance/Items/Individuals"
import ComplianceHeading from "../../components/Solicitor/CaseView/Compliance/Heading"
import CaseViewHeader from "../../components/Solicitor/CaseView/Header"
import GeneralInfo from "../../components/Solicitor/CaseView/GeneralInfo"
import ComplianceItemShareholderForm from "../../components/Solicitor/CaseView/Compliance/Items/ShareholderForm"

  import {useNavigate, useParams, Link as ReachLink} from "react-router-dom";
  import {useDispatch, useSelector} from "react-redux";
  import { ExpandDownIcon } from "../../assets/icons/sprite";
  import { Box, Button, Grid, GridItem, Heading, Table, Tbody, Td, Tr, useDisclosure } from "@chakra-ui/react";
  import BackButton from "../../components/BackButton";
  import {isDateExpired} from "../../services/utils";
  import Loading from "../../components/Loading";
  import { getClientById, setLoadingFlag, setCorpDocLoadingFlag, getCorpDocData } from "../../redux/actions/clients";
  import AlertComponent from "../../components/AlertComponent";
  import SolicitorCardItem from "../../components/EditClientCardItem";
  import CorporateDocumentCardItem from "../../components/EditCorporateClientDocumentCardItem";
  import ClientOpeningFormItem from "../../components/ClientOpeningFormItem";
  import Alert from "../../components/Alert";
  import ViewAPdfFile from "../../components/ViewAPdfFile";
  
const CaseViewCorporate = () => {
    const {id} = useParams();
    const dispatch = useDispatch();      
    const dispatchErrorMessage = useSelector(state => state.clients.errorMessage)
    const dispatchIsDataLoading = useSelector(state => state.clients.isDataLoading)
    const dispatchItem = useSelector(state => state.clients.getById)
    const selectIsFormLoading = useSelector(state => state.clients?.isFormLoading)
    let docuSignShareholders = dispatchItem?.data?.shareholders_document  
    const selectIsCorpDocCRLoading = useSelector(state => state.clients?.isCorpDocCRLoading)
    const selectIsCorpDocSBILoading = useSelector(state => state.clients?.isCorpDocSBILoading)
    const selectIsCorpDocCOILoading = useSelector(state => state.clients?.isCorpDocCOILoading)
    const selectIsCorpDocCLoading = useSelector(state => state.clients?.isCorpDocCLoading)
  
    const {isOpen: isCorpDocCROpen, onOpen: onCorpDocCROpen, onClose: onCorpDocCRClose} = useDisclosure();
    // eslint-disable-next-line
    const {isOpen: isCorpDocSBIOpen, onOpen: onCorpDocSBIOpen, onClose: onCorpDocSBIClose} = useDisclosure();
    // eslint-disable-next-line
    const {isOpen: isCorpDocCOIOpen, onOpen: onCorpDocCOIOpen, onClose: onCorpDocCOIClose} = useDisclosure();
    // eslint-disable-next-line
    const {isOpen: isCorpDocCOpen, onOpen: onCorpDocCOpen, onClose: onCorpDocCClose} = useDisclosure();
    const {
      isOpen: isRiskFormOpen,
      onOpen: onRiskFormOpen,
      onClose: onRiskFormClose,
    } = useDisclosure();
    const {
      isOpen: isRiskForm2Open,
      onOpen: onRiskForm2Open,
      onClose: onRiskForm2Close,
    } = useDisclosure();
    const {
      isOpen: isRiskForm3Open,
      onOpen: onRiskForm3Open,
      onClose: onRiskForm3Close,
    } = useDisclosure();
  
    const dispatchSetCorpDocLoading = (flag, type) => dispatch(setCorpDocLoadingFlag(flag, type))
    const [openedDocuments, setOpenedDocuments] = useState(false);
    const dispatchGetCorpDocData = (clientId, documentType) => dispatch(
      getCorpDocData(clientId, documentType)
    )
    const selectGetCorpDocContent = useSelector(state => state.clients?.getCorpDocContent)
    
    var documentCRData = {
      id: 0,
      status: '',
      uploaded: 'false',
      documentType: 'company_report'
    }
    var documentSBIData = {
      id: 0,
      status: '',
      uploaded: 'false',
      documentType: 'ubo_report',
      isOpen: false
    }
    var documentCOIData = {
      id: 0,
      status: '',
      uploaded: 'false',
      documentType: 'cert_of_incorporation',
      isOpen: false
    }
    var documentCData = {
      id: 0,
      status: '',
      uploaded: 'false',
      documentType: 'constitution',
      isOpen: false
    }
  
    useEffect(() => {
      if (id) {
        dispatch(setLoadingFlag())
        dispatch(getClientById(id))
      }
    }, [dispatch, id])
  
    const navigate = useNavigate()
    const viewButtonStyle = {
      bgColor: '#ffffff',
      border: '1px solid #6E7997',
      color: '#6E7997',
      fontSize: '16px',
      fontWeight: 500,
      w: '100%',
      minH: '55px',
    }
    
    const toggleDocuments = () => {
      setOpenedDocuments(!openedDocuments)
    }
    
    var moment = require('moment');
    var dateSecond = null
    if (dispatchItem?.data?.riskForm) {
      dateSecond = moment(dispatchItem?.data?.riskForm?.date_next)
    }
  
    var dateThird = null
    if (dispatchItem?.data?.riskForm2) {
      dateThird = moment(dispatchItem?.data?.riskForm2?.date_next)
    }
  
    var riskFormsStatus = 6;
    var riskFormsLastSubmittedAt = "Not submitted";
  
    // if first risk form isn't submitted
    if (!dispatchItem?.data?.riskForm) {
      riskFormsStatus = 8;
    }
  
    // first one has been submitted
    else {
  
      riskFormsLastSubmittedAt = new Date(dispatchItem?.data?.riskForm?.submitted_at).toUTCString();
  
      // second form should be there but isn't
      if (dateSecond) {
        if (dateSecond.isSameOrBefore(new Date()) && !dispatchItem?.data?.riskForm2) {
          riskFormsStatus = 6;
        }
        else if (dispatchItem?.data?.riskForm2) {
          riskFormsLastSubmittedAt = new Date(dispatchItem?.data?.riskForm2?.submitted_at).toUTCString();      
        }
      }
  
      // third form should be there but isn't
      if (dateThird) {
        if (dateThird.isSameOrBefore(new Date()) && !dispatchItem?.data?.riskForm3) {
          riskFormsStatus = 6;
        }
        else if (dispatchItem?.data?.riskForm3) {
          riskFormsLastSubmittedAt = new Date(dispatchItem?.data?.riskForm3?.submitted_at).toUTCString();
        }
      }
    }
    
    return (<>

      {dispatchItem?.data ?
        dispatchIsDataLoading
          ? <Loading/>
          : <Box>
            <BackButton
              tabIndex={9}
              onClickHandler={() => {
                navigate(-1)
              }}
              ml={'-17px'}
            />
  
            {dispatchErrorMessage && (<AlertComponent
              message={dispatchErrorMessage}
            />)}

            <CaseViewHeader dispatchItem={dispatchItem}/>              

            <Grid gridTemplateColumns={"300px 1fr"}>              
              <GeneralInfo dispatchItem={dispatchItem}/>
  
              <GridItem paddingLeft={"25px"}>
                <ComplianceHeading />                
  
                {/* COMPLIANCE GRID START */}
                <Grid templateColumns={'65px 2fr'} gap={'2px'}>
  
  {/* ROW 1 */}
  <GridItem>
  {
          <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 50}}/>
  }
  </GridItem>
  <GridItem>
       <Heading
                  fontSize={'22px'}
                  lineHeight={'48px'}
                > 
                    <Table>
                    <Tbody>
                      <Tr>
                      <Td style={{borderBottom: 0}}>Company Documents</Td>
                      <Td cursor={'pointer'}  style={{borderBottom:'none'}}
                          onClick={() => {
                        toggleDocuments();
                      }}>
                        <ExpandDownIcon
                          fill={'none'}
                          w={8}
                          h={8}
                        />
                      </Td>
                      </Tr>
                      </Tbody>
                    </Table>
                </Heading>
  </GridItem>
  {/* END OF ROW 1 */}
  
          {/* COMPLIANCE GRID END */}
          </Grid>
  
  
  {/* DOCUMENTS GRID START */}
  <Grid gap={'6px'}>
    {openedDocuments ? 
      
  <GridItem>

                <Grid templateColumns={'1fr 1fr'} gap={'16px'}>
  
  {dispatchItem?.data?.corporate_documents.map(
                 ({
                                 id, document_type, status
                               }) => {
                  if (document_type === "company_report") {
                    documentCRData.id = id
                    documentCRData.status = status
                  }
                  if (document_type === "ubo_report") {
                    documentSBIData.id = id
                    documentSBIData.status = status
                  }
                  if (document_type === "cert_of_incorporation") {
                    documentCOIData.id = id
                    documentCOIData.status = status
                  }
                  if (document_type === "constitution") {
                    documentCData.id = id
                    documentCData.status = status
                  }
                  return ('')
              }
          )}
  
  
                          <CorporateDocumentCardItem title={'Company Report'}
                                     subtitle={''}
                                     id={documentCRData.id}
                                     status={documentCRData.status}
                                     documentType={documentCRData.documentType}
                                     clientId={dispatchItem?.data?.id}
                                     type={'pdf'}
                                     nestedComponent={selectIsFormLoading
                                       ? <Loading/>
                                       :
                                        <>
                                           <Alert
                                             isOpen={isCorpDocCROpen}
                                             onOpen={onCorpDocCROpen}
                                             onClose={onCorpDocCRClose}
                                             header='Company Report'
                                             text={<>
                                               {dispatchErrorMessage && (<AlertComponent
                                                 message={dispatchErrorMessage}
                                               />)}
                                               {selectIsCorpDocCRLoading
                                                 ? <Loading/>
                                                 : <ViewAPdfFile data={selectGetCorpDocContent?.data} width={1028}/>
                                               }
                                             </>
                                             }
                                             action={null}
                                             size={'6xl'}
                                             contentPaddingY={0}
                                           />
                                           <Button
                                             {...viewButtonStyle}
                                             marginTop={5}
                                             disabled={!documentCRData.status}
                                             onClick={() => {
                                               dispatchSetCorpDocLoading(true, "company_report");
                                               dispatchGetCorpDocData(dispatchItem?.data?.id, "company_report")
                                               onCorpDocCROpen();
                                             }}
                                           >View Document</Button></>
                                     }
                          />
  
  
                                  <CorporateDocumentCardItem title={'UBO Report'}
                                     subtitle={''}
                                     id={documentSBIData.id}
                                     status={documentSBIData.status}
                                     documentType={documentSBIData.documentType}
                                     clientId={dispatchItem?.data?.id}
                                     type={'pdf'}
                                     nestedComponent={selectIsFormLoading
                                       ? <Loading/>
                                       :
                                        <>
                                           <Alert
                                             isOpen={isCorpDocSBIOpen}
                                             onOpen={onCorpDocSBIOpen}
                                             onClose={onCorpDocSBIClose}
                                             header='UBO Report'
                                             text={<>
                                               {dispatchErrorMessage && (<AlertComponent
                                                 message={dispatchErrorMessage}
                                               />)}
                                               {selectIsCorpDocSBILoading
                                                 ? <Loading/>
                                                 : <ViewAPdfFile data={selectGetCorpDocContent?.data} width={1028}/>
                                               }
                                             </>
                                             }
                                             action={null}
                                             size={'6xl'}
                                             contentPaddingY={0}
                                           />
                                           <Button
                                             {...viewButtonStyle}
                                             marginTop={5}
                                             disabled={!documentSBIData.status}
                                             onClick={() => {
                                               dispatchSetCorpDocLoading(true, "ubo_report");
                                               dispatchGetCorpDocData(dispatchItem?.data?.id, "ubo_report")
                                               onCorpDocSBIOpen();
                                             }}
                                           >View Document</Button></>
                                     }
                                  />
  
                                  <CorporateDocumentCardItem title={'Cert of Incorporation'}
                                     subtitle={''}
                                     id={documentCOIData.id}
                                     status={documentCOIData.status}
                                     documentType={documentCOIData.documentType}
                                     clientId={dispatchItem?.data?.id}
                                     type={'pdf'}
                                     nestedComponent={selectIsFormLoading
                                       ? <Loading/>
                                       :
                                        <>
                                           <Alert
                                             isOpen={isCorpDocCOIOpen}
                                             onOpen={onCorpDocCOIOpen}
                                             onClose={onCorpDocCOIClose}
                                             header='Cert of Incorporation'
                                             text={<>
                                               {dispatchErrorMessage && (<AlertComponent
                                                 message={dispatchErrorMessage}
                                               />)}
                                               {selectIsCorpDocCOILoading
                                                 ? <Loading/>
                                                 : <ViewAPdfFile data={selectGetCorpDocContent?.data} width={1028}/>
                                               }
                                             </>
                                             }
                                             action={null}
                                             size={'6xl'}
                                             contentPaddingY={0}
                                           />
                                           <Button
                                             {...viewButtonStyle}
                                             marginTop={5}
                                             disabled={!documentCOIData.status}
                                             onClick={() => {
                                               dispatchSetCorpDocLoading(true, "cert_of_incorporation");
                                               dispatchGetCorpDocData(dispatchItem?.data?.id, "cert_of_incorporation")
                                               onCorpDocCOIOpen();
                                             }}
                                           >View Document</Button></>
                                     }
                                  />
  
                                  <CorporateDocumentCardItem title={'Constitution'}
                                     subtitle={''}
                                     id={documentCData.id}
                                     status={documentCData.status}
                                     documentType={documentCData.documentType}
                                     clientId={dispatchItem?.data?.id}
                                     type={'pdf'}
                                     nestedComponent={selectIsFormLoading
                                       ? <Loading/>
                                       :
                                        <>
                                           <Alert
                                             isOpen={isCorpDocCOpen}
                                             onOpen={onCorpDocCOpen}
                                             onClose={onCorpDocCClose}
                                             header='Constitution'
                                             text={<>
                                               {dispatchErrorMessage && (<AlertComponent
                                                 message={dispatchErrorMessage}
                                               />)}
                                               {selectIsCorpDocCLoading
                                                 ? <Loading/>
                                                 : <ViewAPdfFile data={selectGetCorpDocContent?.data} width={1028}/>
                                               }
                                             </>
                                             }
                                             action={null}
                                             size={'6xl'}
                                             contentPaddingY={0}
                                           />
                                           <Button
                                             {...viewButtonStyle}
                                             marginTop={5}
                                             disabled={!documentCData.status}
                                             onClick={() => {
                                               dispatchSetCorpDocLoading(true, "constitution");
                                               dispatchGetCorpDocData(dispatchItem?.data?.id, "constitution")
                                               onCorpDocCOpen();
                                             }}
                                           >View Document</Button></>
                                     }
                                  />
  
                </Grid>
  </GridItem>              
  
              : '' }
  {/* DOCUMENTS GRID END */}  
  </Grid>

  <Grid templateColumns={'65px 2fr'} gap={'2px'} style={{paddingTop:20}}>

    <ComplianceItemIndividuals id={id} title={"Shareholders and Directors"} number={2} corporate={true} />

    <ComplianceItemShareholderForm 
      complete={docuSignShareholders?.status === 'signed'} 
      status={docuSignShareholders?.status}
      dispatchItem={dispatchItem}
      id={id}
    />
  
  {/* ROW 4 */}
  <GridItem>
  {
      !dispatchItem?.data?.form ?
          <img alt="circle 4" src="/assets/img/circle-4.png" style={{width: 50}}/>:
  
          dispatchItem?.data?.form.status_opening_form === "Processed" ?
              <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 50}}/>:
              <img alt="circle 4" src="/assets/img/circle-4.png" style={{width: 50}}/>
  }
  </GridItem>
  <GridItem>
      <ClientOpeningFormItem accountOpeningForm={true} caseInformationForm={false}/>
  </GridItem>
  {/* END OF ROW 4 */}
  
  
  {/* ROW 5 */}
  <GridItem>
  {
      !dispatchItem?.data?.form ?
          <img alt="circle 5" src="/assets/img/circle-5.png" style={{width: 50}}/>:
  
          dispatchItem?.data?.form.status === "Processed" ?
              <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 50}}/>:
              <img alt="circle 5" src="/assets/img/circle-5.png" style={{width: 50}}/>
  }
  </GridItem>
  <GridItem>
      <ClientOpeningFormItem accountOpeningForm={false} caseInformationForm={true}/>
  </GridItem>
  {/* END OF ROW 5 */}
  
  {/* ROW 6 */}
  <ComplianceItemSignedDocuments number={6} />
  {/* END OF ROW 6 */}                  

  {/* ROW 7 */}
  <GridItem>
  {
      (riskFormsStatus === 6) ?
  
       <img alt="circle complete" src="/assets/img/circle-complete.png" style={{width: 50}}/>:
       <img alt="circle 7" src="/assets/img/circle-7.png" style={{width: 50}}/>
   }
  </GridItem>
  
  <GridItem>
      <SolicitorCardItem title={'Risk Assessments'}
                                     subtitle={riskFormsLastSubmittedAt}
                                     status={riskFormsStatus}
                                     isAlert={true}
                                     type={'pdf'}
                                     isVerified={false}
                                     nestedComponent={
                          <>
                            {/* SHOW FIRST RISK ASSESSMENT */}
                            {dispatchItem?.data?.riskForm?.form_status ===
                            "submitted" ? 
  <RiskAssessmentView 
    isRiskFormOpen={isRiskFormOpen}
    onRiskFormOpen={onRiskFormOpen}
    onRiskFormClose={onRiskFormClose}
    dispatchItem={dispatchItem}
    version={1}
  /> : 
                            ( "" )}
  
                            {/* SHOW SECOND RISK ASSESSMENT */}
                            {dispatchItem?.data?.riskForm2?.form_status ===
                              "submitted" &&
                            !isDateExpired(
                              dispatchItem?.data?.riskForm2?.submitted_at,
                              3
                            ) ? 
  <RiskAssessmentView 
    isRiskFormOpen={isRiskForm2Open}
    onRiskFormOpen={onRiskForm2Open}
    onRiskFormClose={onRiskForm2Close}
    dispatchItem={dispatchItem}
    version={2}
  /> : 
                             ( "" )}
  
                            {/* SHOW THIRD RISK ASSESSMENT */}
                            {dispatchItem?.data?.riskForm3?.form_status ===
                            "submitted" ? 
  <RiskAssessmentView 
    isRiskFormOpen={isRiskForm3Open}
    onRiskFormOpen={onRiskForm3Open}
    onRiskFormClose={onRiskForm3Close}
    dispatchItem={dispatchItem}
    version={3}
  /> : 
                             ( "" )}
  
                                      <Box marginTop={'22px'}>
  
                                      {/* FIRST RISK ASSESSMENT BUTTON */}
                                      {(dispatchItem?.data?.riskForm?.form_status === 'submitted')
                                       ?
                                         <Button
                                          marginBottom={2}
                                           {...viewButtonStyle}
                                           onClick={() => {
                                             onRiskFormOpen();
                                           }}
                                         >View Initial Risk Assessment</Button> 
                                       : <><Button
                                         marginTop={5}
                                         as={ReachLink}
                                         to={'risk-assessment'}
                                         {...viewButtonStyle}
                                       >Fill Out Form</Button></>}
                                      
  
                                      {/* SECOND RISK ASSESSMENT BUTTON */}
                                      {dateSecond?.isSameOrBefore(new Date()) && 
                                        (dispatchItem?.data?.riskForm2?.form_status === 'submitted')
                                        ?
                                        <Button
                                          marginBottom={2}
                                           {...viewButtonStyle}
                                           onClick={() => {
                                             onRiskForm2Open();
                                           }}
                                         >View Interim Risk Assessment</Button>
                                       : ''}
                                      {dateSecond?.isSameOrBefore(new Date()) && 
                                        (dispatchItem?.data?.riskForm2?.form_status !== 'submitted')
                                        ?
                                        <Button
                                         as={ReachLink}
                                         to={'risk-assessment?version=2'}
                                         {...viewButtonStyle}
                                       >Fill Out Interim Risk Assessment</Button>
                                       : ''}
  
  
                                      {/* THIRD RISK ASSESSMENT BUTTON */}
                                      {dateThird?.isSameOrBefore(new Date()) && 
                                        dispatchItem?.data?.riskForm3?.form_status === 'submitted'
                                        ?
                                        <Button
                                          marginBottom={2}
                                           {...viewButtonStyle}
                                           onClick={() => {
                                             onRiskForm3Open();
                                           }}
                                         >View Final Risk Assessment</Button>
                                       : ''}
  
                                      {dateThird?.isSameOrBefore(new Date()) && 
                                        (dispatchItem?.data?.riskForm3?.form_status !== 'submitted')
                                        ?
                                        <Button
                                         as={ReachLink}
                                         to={'risk-assessment?version=3'}
                                         {...viewButtonStyle}
                                       >Fill Out Final Risk Assessment</Button>
                                       : ''}
                                        
  
                                       </Box></>
                                     }
                  />
  </GridItem>
  
  
  {/* END OF ROW 7 */}
  
  
  </Grid>
  
              </GridItem>
            </Grid>
          </Box>
          
        :
        <> {dispatchErrorMessage
          ? (<AlertComponent
            message={dispatchErrorMessage}
          />)
          : <Loading/>}</>
      }
    </>)
      ;
  };
  export default CaseViewCorporate;
