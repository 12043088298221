import {
  Box, Button, Container, FormControl, FormLabel, Heading, HStack, Input, Stack, Text
} from "@chakra-ui/react";
import {PasswordField} from "./PasswordField";
import AlertComponent from "./AlertComponent";
import * as React from "react";
import {Link} from "react-router-dom";
import HeaderFormLogo from "./HeaderFormLogo";

const LoginForm = ({
                     formik, link = null, signup = null, dispatchErrorMessage
                   }) => {

  return (<Container
    maxW='2xl'
    py={{
      base: '12', md: '24',
    }}
    px={{
      base: '0',
    }}
  >
    <Stack spacing="8">
      <HeaderFormLogo/>
      <Heading
        marginTop={'46px'}
        fontSize={'54px'}
        fontWeight={'bold'}
        color={'#343B55'}
        textAlign={'center'}
      >
        Sign In
      </Heading>
      <Box
        py={{
          base: '0', sm: '3',
        }}
        px={{
          base: '4', sm: '10',
        }}
      >

        {dispatchErrorMessage && (<AlertComponent
          message={dispatchErrorMessage}
        />)}

        <Stack spacing="6">

          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email" fontSize={'18px'}>Email</FormLabel>
                <Input
                  tabIndex={2}
                  id="email"
                  type="email"
                  required
                  placeholder={'Enter your email'}
                  fontSize={'18px'}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </FormControl>

              <PasswordField
                placeholder={'Enter your password'}
                fontSize={'18px'}
                onChange={formik.handleChange} tabIndex={3}
                iconButtonTabIndex={4}
              />
            </Stack>
            <HStack justify="space-between" justifyContent={'flex-end'} py={'20px'}>

              {link
                ? <Button
                  as={Link}
                  tabIndex={5}
                  variant="link"
                  to={link}
                  fontSize={'18px'}
                  colorScheme="blue"
                  color={'#4285F4'}
                  size="sm">
                  Forgot password?
                </Button>
                : null}

            </HStack>
            <Stack spacing="6" text-align={'right'}>
              <Button
                tabIndex={6}
                type="submit"
                fontSize={'18px'}
                variant="primary">Submit</Button>
            </Stack>
          </form>

        </Stack>

        {signup ? <Text
          color={'#4285F4'}
          textAlign={'center'}
          pt={'34px'}
        >
          <Button
            as={Link}
            color={'#4285F4'}
            to={signup}
            variant="link"
            tabIndex={7}
          > Sign up </Button> for new account.
        </Text> : null}

      </Box>
    </Stack>
  </Container>)
}

export default LoginForm;