import * as React from 'react';
import {useEffect, useState} from "react";
import {useFormik} from "formik";
import LoginForm from "../../components/LoginForm";
import EnterCode from "../../components/EnterCode";
import {
  emailVerification,
  loginUser,
  sendVerificationCode,
  setSolicitorErrorMessage
} from "../../redux/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Text} from "@chakra-ui/react";


const SolicitorSignin = () => {

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.solicitorAuth.currentUser)
  const dispatchLoginSolicitor = (credentials) => dispatch(loginUser(credentials));
  const dispatchEmailVerification = (credentials, type) => dispatch(emailVerification(credentials, type));
  const dispatchSendVerificationCode = (credentials) => dispatch(sendVerificationCode(credentials));
  const dispatchSetSolicitorErrorMessage = (credentials) => dispatch(setSolicitorErrorMessage(credentials));

  const dispatchErrorMessage = useSelector(state => state.solicitorAuth.errorMessage)

  useEffect(() => {
    dispatch(setSolicitorErrorMessage(null));
  }, [dispatch])

  const navigate = useNavigate();

  const [step, setStep] = useState(1)
  const [hideCodeText, setHideCodeText] = useState(false)

  const handleSubmit = async values => {
    if (step === 1) {
      dispatchLoginSolicitor(values)
        .then(() => {
          setStep(2);
          setHideCodeText(false);
        })
        .catch(          
          // Also another method for receiving err
          (err) => err.message ? console.log(err.message) : console.warn('Error')
        )
    }
    if (step === 2) {
      dispatchEmailVerification(values, currentUser?.type).then(
        () => {
          switch (currentUser?.type) {
            case 'solicitor':
              navigate('/solicitor/dashboard/');
              break;
            case 'firm':
              navigate('/firm/solicitors/');              
              break;
            case 'account':
              navigate('/account/forms/');
              break;
            case 'auditor':
              navigate('/auditor/firms/');
              break;
            default:
              navigate(0);
              break;
          }
          window.location.reload(false);          
          // location.pathname === '/solicitor/signin'
          //   ? navigate('/solicitor/dashboard/')
          //   : navigate(0)
        }
      ).catch(
        // Also another method for receiving err
        (err) => err.message
          ? err.message === 'Code expired after 3 attempts, please request a new code.'
            ? setHideCodeText(true)
            : setHideCodeText(false)
          : console.warn('Error')
      )
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '', password: '', code: ''
    }, onSubmit: handleSubmit
  })
  return (step === 1
    ? <><LoginForm
      formik={formik}
      link={'/forgot/'}
      signup={''}
      dispatchErrorMessage={dispatchErrorMessage}
    /><Text>{step}</Text></>
    : step === 2 ? <EnterCode
      dispatchErrorMessage={dispatchErrorMessage}
      setStep={setStep}
      formik={formik}
      hideCodeText={hideCodeText}
      setHideCodeText={setHideCodeText}
      dispatchSendVerificationCode={dispatchSendVerificationCode}
      dispatchSetFirmErrorMessage={dispatchSetSolicitorErrorMessage}
    /> : null)
}

export default SolicitorSignin;
