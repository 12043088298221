import React, {useEffect, useState} from 'react';
import {
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Flex,
  TableContainer, useDisclosure, Box
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {getAuditorRequestsList, setLoadingFlag, approveAuditorRequest} from "../../redux/actions/auditors";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import {Outlet, useParams} from "react-router-dom";

function AdminAuditorRequests() {

  const {id: hideListing} = useParams();

  const thStyles = {
    color: '#1A1E29',
    fontSize: '14px',
    textTransform: 'none',
    opacity: '0.5',
    fontWeight: 400,
    border: 'none',
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (!hideListing) {
      dispatch(setLoadingFlag());
      dispatch(getAuditorRequestsList());
    }
  }, [
    dispatch,
    hideListing
  ])

  const dispatchList = (credentials) => dispatch(getAuditorRequestsList(credentials))
  const dispatchAuditorApproveRequest = (id) => dispatch(approveAuditorRequest(id))

  const dispatchAuditorRequestsList = useSelector(state => state.auditors);
  const dispatchIsDataLoading = useSelector(state => state.auditors?.isDataLoading);

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [requestId, setRequestId] = useState(null)

  const onChangeStatus = (id = null) => {
    setRequestId(id);
    onOpen();
  }

  return (
    <>
      <Outlet/>

      <Alert
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        header={`Approve Access Request`}
        text={`Are you sure you want to approve this access request?`}
        action={() => {
          dispatch(setLoadingFlag());
          dispatchAuditorApproveRequest(requestId)
            .then((res) => {
              dispatchList();
            });
        }}
      />

      {!hideListing ? <Box>

      <HStack as={Flex}>
          <Heading
            fontSize={'32px'}
            lineHeight={'48px'}
          >Access Requests</Heading>
        </HStack>

          {dispatchIsDataLoading
            ? <Loading/>
            :

            dispatchAuditorRequestsList.data
              ? <> <TableContainer
                backgroundColor={'#F9FBFC'}
                mt={'44px'}
                color={'#1A1E29'}
              >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      {/*<Th*/}
                      {/*  {...thStyles}*/}
                      {/*>Id</Th>*/}
                      <Th
                        {...thStyles}
                      >Firm Name</Th>
                      <Th
                        {...thStyles}
                      >Auditor Name</Th>
                      <Th
                        {...thStyles}
                      >Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dispatchAuditorRequestsList?.data?.data?.map(({id, firm_name, auditor_name, status}) => {
                      return (
                        <Tr key={id}>
                          {/*<Td*/}
                          {/*>{id}</Td>*/}
                          <Td
                            fontSize={'18px'}
                            minWidth={'270px'}
                          >
                            {firm_name}
                          </Td>
                          <Td
                            fontSize={'18px'}
                          >
                              {auditor_name}
                            </Td>
                          <Td>
                              <Button
                                colorScheme='green'
                                size='xs'
                                fontWeight={400}
                                fontSize={'14px'}
                                w={'100%'}
                                onClick={() => onChangeStatus(id)}
                              >
                                Approve
                              </Button>
                          </Td>
                        </Tr>
                      )
                    })
                    }
                  </Tbody>
                </Table>
              </TableContainer>

              </>
              : <Loading/>
          }
        </Box>
        : null}

    </>
  );
}

export default AdminAuditorRequests;
