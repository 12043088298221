import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import AlertComponent from "../../../../../AlertComponent";
import Alert from "../../../../../Alert";
import { Button, useDisclosure, FormControl, FormLabel, Stack, VStack, 
        Text, Input, Radio, RadioGroup } from "@chakra-ui/react";
import { DoneIcon } from "../../../../../../assets/icons/sprite"
import IconEdit from "../../../../../../assets/icons/icon-edit.png"
import { setIdPalReInvite, updateClient, addClient } from "../../../../../../redux/actions/clients";
import { useFormik } from "formik";
import Loading from "../../../../../Loading";

function IndividualsEdit(data) {

    const id = data?.id
    const dispatch = useDispatch();  
    const navigate = useNavigate();
    const [clientId, setClientId] = useState(0);
    const { isOpen: isEditOpen, onOpen: onOpenEdit, onClose: onEditClose } = useDisclosure();
    const [step, setStep] = useState(1);
    const [title, setTitle] = useState(null);
    const dispatchSetIdPalReInvite = (clientId, documentType, reason) => dispatch(setIdPalReInvite(clientId, documentType, reason));
    const [errorMessage, setErrorMessage] = useState(null);
    const [reRequestId, setReRequestId] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    if (data?.mode === "add" && title === null) {
      setTitle("Add Person")
    }
    else if (data?.mode === "edit" && title === null) {
      setTitle("Edit Person")
    }

    const resetDialog = () => {
        window.location.reload()
      };    
      const handleSubmit = async (values) => {

        setIsLoading(true)

        if (data?.mode === "edit") {
          updateClient(clientId, {    
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email, 
            _method: 'PUT' }).then(resp => {
              if (reRequestId) {
                dispatchSetIdPalReInvite(clientId, 'both', 'Updating persons details.').then(() => {
                  setStep(2).then(() => {
                    navigate(0)
                    setIsLoading(false)});
                });          
              }
              else {              
                setStep(2).then(() => {
                  navigate(0)
                  setIsLoading(false)
                });
              }
            })
        }

        else if (data?.mode === "add") {

          var type = "individual_child";
          if (data?.corporate) { type = "corporate_child"; }  
          
          addClient(clientId, {    
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            type: type,
          }).then(resp => {
            setStep(2).then(() => {
              navigate(0)
              setIsLoading(false)});
          }).catch(err => {
            setIsLoading(false)
            setErrorMessage(err)
          }); 
        };
    };    
    const formik = useFormik({
        initialValues: {
          first_name: data?.firstName,
          last_name: data?.lastName,
          email: data?.email,
        },
        onSubmit: handleSubmit,
      });
    const inputStyles = {
        type: 'text',
        required: true,
        _placeholder: {fontSize: {base: 12, md: 14, lg: 16}},
        fontSize: '16px',
    }      

    const changeReRequestId = (value) => {
        setReRequestId(value);
    }    

    return (
<>
{data?.mode==="edit" ? 

<Link className="dropdown-item" onClick={() => {
  setClientId(id);
  onOpenEdit();
}}>
    <img src={IconEdit} className="individuals__menu-icon" alt=""/> Edit
</Link>
: 
<Button 
  className="button__white individuals_footer-button"
  style={{float:'right'}}
  onClick={() => {
    setClientId(id);
    onOpenEdit();
  }}
  >
    Add Person
</Button>
}

<Alert
        isOpen={isEditOpen}
        onOpen={onOpenEdit}
        onClose={onEditClose}
        header={title}
        text={
          step === 1 ? (
<>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing="5">
                {errorMessage && (
                  <AlertComponent message={errorMessage} />
                )}

                <FormControl>
                    <FormLabel
                        htmlFor="documentType"
                        fontSize={"18px"}
                    >First Name</FormLabel>
                    <Input
                        defaultValue={data?.firstName}
                        id="first_name"
                        name="first_name"
                        {...inputStyles}             
                        onChange={formik.handleChange}       
                        disabled={isLoading}
                    /> 
                </FormControl>

                <FormControl>
                    <FormLabel
                        htmlFor="documentType"
                        fontSize={"18px"}
                    >Last Name</FormLabel>
                    <Input
                        defaultValue={data?.lastName}
                        id="last_name"
                        name="last_name"
                        {...inputStyles}             
                        onChange={formik.handleChange}       
                        disabled={isLoading}
                    /> 
                </FormControl>

                <FormControl>
                    <FormLabel
                        htmlFor="documentType"
                        fontSize={"18px"}
                    >Email</FormLabel>
                    <Input
                        defaultValue={data?.email}
                        id="email"
                        name="email"
                        {...inputStyles}             
                        onChange={formik.handleChange}       
                        disabled={isLoading}
                    /> 
                </FormControl>

                {data?.mode === "edit" ?
<>
                <Text style={{textAlign: 'left'}}>
                    Do you want to re-request their ID documents?
                </Text>

                <RadioGroup
                    id={'reRequestId'}
                    name={'reRequestId'}
                    onChange={changeReRequestId.bind(this)}
                    defaultValue='false'
                    >
                    <Stack direction='row'>
                        <Radio value='false' disabled={isLoading}>No</Radio>
                        <Radio value='true' disabled={isLoading}>Yes</Radio> 
                    </Stack>
                </RadioGroup>
</>
: ''}
              </Stack>

              <Stack spacing="6" text-align={"right"} py={"20px"}>
                <Button type="submit" fontSize={"18px"} variant="primary" 
                    disabled={isLoading}
                  >
                  {isLoading ? 
                  <Loading size={'xs'} marginRight={'15px'} marginBottom={'3px'}/>
                  : ''}
                  Save
                </Button>
              </Stack>
            </form>
</>

          ) : step === 2 ? (
            <VStack marginBottom={10}>
              <DoneIcon width={93} height={93} marginTop={6} marginBottom={4} />

                {data?.mode === "edit" ?                 
<>
                  {reRequestId ? 
                  <Text paddingX={"100px"} pb={8} align={"center"}>
                    The person has now been updated and they have been sent an email asking them to re-submit their ID documents.
                  </Text>
                  :
                  <Text paddingX={"100px"} pb={8} align={"center"}>
                    The person has now been updated.
                  </Text>}
</>
                :
                <Text paddingX={"100px"} pb={8} align={"center"}>
                  The person has now been added.
                </Text>}

              <Button
                type="submit"
                fontSize={"18px"}
                variant="primary"
                minWidth={184}
                minHeight={"48px"}
                height={"48px"}
                onClick={resetDialog}
              >
                OK
              </Button>
            </VStack>
          ) : null
        }
        action={null}
        size={"3xl"}
        contentPaddingY={0}
      />
</>);
};

export default IndividualsEdit;