import React from 'react';
import {
  Divider,
  Flex,
  Stack,
} from "@chakra-ui/react";
import {LogoWhite} from "./Logo";
import NavButton from "./NavButton";
import {Link, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import { logoutAdmin, logoutFirm } from "../redux/actions/auth";
import {DashboardIcon, FirmsIcon, HelpIcon, LogOutIcon, AuditorsIcon} from "../assets/icons/sprite"

export const Sidebar = () => {

  const location = useLocation()

  const dispatch = useDispatch();

  const dispatchLogoutBoth = (credential) => {
    dispatch(logoutAdmin(credential));
    dispatch(logoutFirm(credential));
  }

  const navStyle = {
    as: Link,
    size: '22px',
    fontSize: '18px',
    color: '#ffffff',
  }

  return (

    <Flex
      flex={1}
      bg={'#3A78F2'}
      color={'on-accent'}
      overflowY={'auto'}
      width={275}
      maxW={{base: 'fill', sm: 'sm'}}
      py={{base: '8', sm: '12'}}
      px={{base: '7', sm: '8'}}
      position={'fixed'}
      minH={'100vh'}
    >
      <Stack
        justify={'space-between'}
        spacing={1}
        pb={'25px'}
      >
        <Stack spacing={{base: '5', sm: '75'}} shouldWrapChildren>
          <Link
            to={'/'}
          >
            <LogoWhite px={{base: '2', sm: '4'}}
            />
          </Link>

          <Stack spacing={10}>
            <NavButton
              {...navStyle}
              fontWeight={location.pathname.includes('dashboard') ? 700 : 500}
              to={'/admin/dashboard/'}
              label={'Dashboard'}
              icon={DashboardIcon}
            />

            <NavButton
              {...navStyle}
              fontWeight={location.pathname.includes('firms') ? 700 : 500}
              to={'/admin/firms/'}
              label={'Law Firms'}
              icon={FirmsIcon}
            />

            <NavButton
              {...navStyle}
              fontWeight={
                (location.pathname.includes('auditors'))
                  ? 700 : 500
            }
              to={'/admin/auditors/'}
              label={'Auditors'}
              icon={AuditorsIcon}
            />

            <NavButton
              {...navStyle}
              fontWeight={location.pathname.includes('requests') ? 700 : 500}
              to={'/admin/requests/'}
              label={'Access Requests'}
              icon={AuditorsIcon}
            />            
          </Stack>
        </Stack>

        <Stack
          spacing={{
            base: '5',
            sm: '6'
          }}
          alignItems={'flex-start'}
        >

          <Divider
            borderColor={'#FFFFFF'}
          />

          <NavButton
            as={Link}
            fontWeight={location.pathname === '/admin/help/' ? 700 : 500}
            size={'24px'}
            fontSize={'18px'}
            to={'/admin/help/'}
            label={'Help'}
            icon={HelpIcon}
            aria-current={'page'}
            color={'#ffffff'}
          />

          <NavButton
            as={Link}
            size={'24px'}
            fontSize={'18px'}
            fontWeight={500}
            label={'Log out'}
            icon={LogOutIcon}
            aria-current={'page'}
            color={'#ffffff'}
            onClick={() => dispatchLogoutBoth()}
          />

        </Stack>
      </Stack>
    </Flex>)

};

export default Sidebar;
