import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  checkAuditorAuth,
  logout,
  setAuditorLoginLoadingFlag, setAuthNotFirstCheck
} from "../redux/actions/auth";
import AuditorSignIn from "../pages/Auditor/AuditorSignIn";
import InactiveStatusShow from "./InactiveStatusShow";
import Loading from "./Loading";
import {useNavigate} from "react-router-dom";

const AuditorProtected = ({
                              WrappedComponent
                            }) => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatchLogout = () => dispatch(logout('auditor', navigate));
  const dispatchIsDataLoading = useSelector(state => state.auditorAuth.isDataLoading);

  const dispatchAuditorAuthAuthChecked = useSelector(state => state.auditorAuth?.authChecked);
  const dispatchAuditorAuthLoggedIn = useSelector(state => state.auditorAuth?.loggedIn);
  const dispatchAuditorAuthCurrentUserEmailVerifiedAt = useSelector(state => state.auditorAuth?.currentUser?.email_verified_at);
  const dispatchAuditorAuthCurrentUserActive = useSelector(state => state.auditorAuth?.currentUser?.active);

  const dispatchAuditorAuthCodeChecked = useSelector(state => state.auditorAuth?.codeChecked);
  const dispatchAuditorAuthIsFirstCheck = useSelector(state => state.auditorAuth?.isFirstCheck);


  useEffect(() => {
    if ((!dispatchIsDataLoading && !dispatchAuditorAuthAuthChecked)) {
      dispatch(setAuditorLoginLoadingFlag())
      dispatch(checkAuditorAuth())
    }
  }, [
    dispatch,
    dispatchIsDataLoading,
    dispatchAuditorAuthAuthChecked
  ])

  useEffect(() => {
    if (dispatchAuditorAuthCodeChecked && dispatchAuditorAuthIsFirstCheck) {
      if (!dispatchIsDataLoading) {
        dispatch(setAuthNotFirstCheck(true, 'account'))
        dispatch(setAuditorLoginLoadingFlag())
        dispatch(checkAuditorAuth())
      }
    }

  }, [
    dispatch,
    dispatchIsDataLoading,
    dispatchAuditorAuthCodeChecked,
    dispatchAuditorAuthIsFirstCheck
  ])

  return (
    dispatchAuditorAuthAuthChecked
    && dispatchAuditorAuthLoggedIn
    && dispatchAuditorAuthCurrentUserEmailVerifiedAt
  ) ?
    dispatchAuditorAuthCurrentUserActive
      ? <>{WrappedComponent}</>
      : <InactiveStatusShow
        onClick={dispatchLogout}
        role={'auditor'}
      />
    : dispatchIsDataLoading
      ? <Loading/>
      : <AuditorSignIn/>
}

export default AuditorProtected;
