import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {
  forgotPassword,
  resetPassword,
  sendVerificationCode,
  setSolicitorErrorMessage,
  updatePassword
} from "../../redux/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import ForgotForm from "../../components/ForgotForm";
import {EnterCode} from "../../components/EnterCode";
import ChangePasswordForm from "../../components/ChangePasswordForm";

const SolicitorForgot = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const dispatchForgotPassword = (credentials) => dispatch(forgotPassword(credentials));
  const dispatchResetPassword = (credentials) => dispatch(resetPassword(credentials));
  const dispatchSendVerificationCode = (credentials) => dispatch(sendVerificationCode(credentials));
  const dispatchUpdatePassword = (credentials) => dispatch(updatePassword(credentials, navigate));
  const dispatchSetSolicitorErrorMessage = (credentials) => dispatch(setSolicitorErrorMessage(credentials));
  const dispatchErrorMessage = useSelector(state => state.solicitorAuth.errorMessage);

  const [step, setStep] = useState(1);
  const [hideCodeText, setHideCodeText] = useState(false);

  useEffect(() => {
    dispatch(setSolicitorErrorMessage(null))
  }, [dispatch]);
  const handleSubmit = async values => {
    switch (step) {
      case 1:
        dispatchForgotPassword({email: values.email, checkEmailInUse: true}).then(() => {
          dispatchSetSolicitorErrorMessage(null);
          setStep(2);
        })
          .catch(
            (err) => err.message ? console.warn(err.message) : console.warn('Error')
          )
        break

      case 2:
        dispatchResetPassword({email: values.email, code: values.code}).then(() => {
          setStep(3);
        })
          .catch(
            (err) => err.message
              ? err.message === 'Code expired after 3 attempts, please request a new code.'
                ? setHideCodeText(true)
                : setHideCodeText(false)
              : console.warn('Error')
          )
        break

      case 3:
        dispatchUpdatePassword(
          {
            "password": values.password,
            "password_confirmation": values.confirmPassword
          }
        ).catch(
          // Also another method for receiving err
          (err) => err.message ? console.warn(err.message) : console.warn('Error')
        )
        break
      default:
        return
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '', password: '', code: ''
    }, onSubmit: handleSubmit
  })


  return (step === 1 ?
      <ForgotForm
        formik={formik}
        type={'solicitor'}
        dispatchErrorMessage={dispatchErrorMessage}
      />
      : step === 2 ? <EnterCode
          dispatchErrorMessage={dispatchErrorMessage}
          setStep={setStep}
          formik={formik}
          hideCodeText={hideCodeText}
          setHideCodeText={setHideCodeText}
          dispatchSendVerificationCode={dispatchSendVerificationCode}
          dispatchSetFirmErrorMessage={dispatchSetSolicitorErrorMessage}
        />
        : <ChangePasswordForm
          dispatchErrorMessage={dispatchErrorMessage}
          setStep={setStep}
          formik={formik}
        />
  )
}

export default SolicitorForgot;
