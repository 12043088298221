import React from "react";
import {Button} from "@chakra-ui/react";

const StatusLabel = ({onClickHandler, ...props}) => {

    var colorScheme = 'orange';
    var backgroundColor = '#C8392D';

    if (props?.status === 'onboarded') {
        colorScheme = 'green';
        backgroundColor = '';
    }

    return (
    <Button
        colorScheme={colorScheme}
        backgroundColor={backgroundColor}
        color={'#FFFFFF'}
        size='xs'
        fontWeight={400}
        fontSize={'14px'}
        w={'100%'}
        _hover={{
            backgroundColor: backgroundColor, // Keeps the background color the same on hover
            color: '#FFFFFF', // Keeps text color the same on hover
        }}
    >
        {props?.status.charAt(0).toUpperCase() + props?.status.slice(1)}
    </Button>
)
}

export default StatusLabel;