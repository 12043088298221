import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useFormik} from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";
import HeaderFormLogo from "../../components/HeaderFormLogo";
import AlertComponent from "../../components/AlertComponent";
import {PasswordField} from "../../components/PasswordField";
import bgImageX2 from "../../assets/img/firm-signin-bg@x2.png";
import {
  getSolicitorDetailsBySecret,
  setLoadingFlag,
} from "../../redux/actions/solicitors";
import Loading from "../../components/Loading";
import ErrorNotFound from "../../components/ErrorNotFound";
import {setPassword, setSolicitorPasswordErrorMessage} from "../../redux/actions/auth";

const RegisterPage = () => {

  const navigate = useNavigate();
  const {secret} = useParams();
  const dispatch = useDispatch();
  const [isSecretCorrect, setIsSecretCorrect] = useState(true);

  const dispatchErrorMessage = useSelector(state => state.solicitorAuth.errorMessage);
  const dispatchIsDataLoading = useSelector(state => state.solicitors.isDataLoading);
  const dispatchSolicitorBySecret = useSelector(state => state.solicitors.solicitorBySecret);
  const dispatchSetSolicitorPasswordErrorMessage = (message) => dispatch(setSolicitorPasswordErrorMessage(message));
  const dispatchSetSolicitorPassword = (credentials) => dispatch(setPassword(credentials));

  useEffect(() => {
    dispatch(setLoadingFlag());
    dispatch(setSolicitorPasswordErrorMessage(null))
    dispatch(getSolicitorDetailsBySecret(secret))
      .catch(() => {
        setIsSecretCorrect(false)
      });
  }, [dispatch, secret, ]);

  const handleSubmit = async values => {

    if (values.password !== values.confirmPassword) {
      dispatchSetSolicitorPasswordErrorMessage({
        result: 'error',
        message: 'The password confirmation does not match.'
      })
      return
    }

    if (values.password.replaceAll(' ', '').length === 0) {
      dispatchSetSolicitorPasswordErrorMessage(
        {
          result: 'error',
          message: 'The password must be not empty.'
        })
      return
    }

    if (values.password.length < 8) {
      dispatchSetSolicitorPasswordErrorMessage(
        {
          result: 'error',
          message: 'The password must be at least 8 characters.'
        })
      return
    }

    try {
      const resp = await dispatchSetSolicitorPassword({
        password: values.password,
        password_confirmation: values.confirmPassword,
        secret: secret
      });
      if (resp === 'account') {
        navigate('/account/forms/');
      } 
      else if (resp === 'auditor') {
        navigate('/auditor/firms/');
      }
      else {
        navigate('/solicitor/dashboard/')
      }
    } catch (e) {
      console.warn('Error');
    }
  }

  const formik = useFormik({
    initialValues: {
      firmName: '',
      email: '',
      password: '',
      confirmPassword: '',
      code: ''
    },
    onSubmit: handleSubmit
  })

  return (isSecretCorrect ? dispatchSolicitorBySecret && !dispatchIsDataLoading
    ? <Grid
      templateColumns='50.8% 49.2%'
      minWidth={'100vw'}
      minHeight={'100vh'}
      m={0}
      p={0}
    >
      <GridItem minHeight='100vh'>
        <Container
          maxW='2xl'
          py={{
            base: '12', md: '24',
          }}
          px={{
            base: '0',
          }}
        >

          <Stack spacing="8">
            <HeaderFormLogo/>
            <Heading
              marginTop={'46px'}
              fontSize={'54px'}
              fontWeight={'bold'}
              color={'#343B55'}
              textAlign={'center'}
            >
              Sign Up
            </Heading>

            <Box
              py={{
                base: '0', sm: '3',
              }}
              px={{
                base: '4', sm: '10',
              }}
            >

              {dispatchErrorMessage && (<AlertComponent
                message={dispatchErrorMessage}
              />)}

              <Grid
                bgColor={'#F9FBFC'}
                mb={3}
                borderRadius={'8px'}
                templateColumns={'73px 1fr'}
                p={'18px 24px'}

              >
                <GridItem as={Text} borderRadius={'50%'}
                          bgColor={'#4285F4'}
                          display={"flex"}
                          w={'44px'}
                          h={'44px'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          color={'#FFFFFF'}>

                  {dispatchSolicitorBySecret.data?.name?.indexOf(' ') < 0
                    ? `${dispatchSolicitorBySecret.data?.name?.split(' ')[0][0]}`
                    : `${dispatchSolicitorBySecret.data?.name?.split(' ')[0][0]}${dispatchSolicitorBySecret.data?.name?.split(' ')[1][0]}`
                  }
                </GridItem>
                <GridItem>
                  <Text
                    fontWeight={600}
                    fontSize={'18px'}
                    lineHeight={1.1}
                  >{dispatchSolicitorBySecret.data?.name}</Text>
                  <Text
                    fontWeight={400}
                    fontSize={'18px'}
                  >{dispatchSolicitorBySecret.data?.email}</Text>
                </GridItem>
              </Grid>

              <Stack spacing="6">

                <form
                  onSubmit={formik.handleSubmit}
                >
                  <Stack spacing="5">

                    <PasswordField
                      placeholder={'Enter password'}
                      fontSize={'18px'}
                      onChange={formik.handleChange}
                      tabIndex={3}
                      iconButtonTabIndex={4}
                      required
                    />

                    <PasswordField
                      placeholder={'Enter password'}
                      label={'Confirm password'}
                      fontSize={'18px'}
                      onChange={formik.handleChange}
                      name={'confirmPassword'}
                      tabIndex={5}
                      iconButtonTabIndex={6}
                      required
                    />

                  </Stack>


                  <Stack spacing="6" text-align={'right'} py={'20px'}>
                    <Button
                      tabIndex={7}
                      type="submit"
                      fontSize={'18px'}
                      variant="primary">Submit</Button>
                  </Stack>

                </form>

              </Stack>
            </Box>
          </Stack>
        </Container>
      </GridItem>

      <GridItem
        minHeight='100vh'
        bg='blue.500'

        backgroundImage={bgImageX2}
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
        backgroundPosition={'center center'}
      />

    </Grid>
    : <Loading/> : <ErrorNotFound/>)
};

export default RegisterPage;
