import { makeRequest, API_URL } from "../../services/request";
import {
  SET_DATA_LOADING_FALSE,
  SET_DATA_LOADING_TRUE,
  SOLICITORS_LIST_DOWNLOADED,
  SOLICITORS_LIST_NOT_DOWNLOADED,
  SOLICITORS_GET_BY_ID_SUCCESS,
  SOLICITORS_SET_ERR_MESSAGE,
  SET_NEW_SOLICITOR_SUCCESS,
  SET_NEW_SOLICITOR_ERR_MESSAGE,
  SET_SOLICITOR_NEW_DATA,
  SET_SOLICITOR_NEW_DATA_FAILED,
  GET_SOLICITOR_DETAILS_BY_SECRET_FAILED,
  GET_SOLICITOR_DETAILS_BY_SECRET_SUCCESS,
  GET_SOLICITOR_DASHBOARD_META_SUCCESS,
  GET_SOLICITOR_DASHBOARD_META_FAILED,
  GET_SOLICITOR_DASHBOARD_LOG_SUCCESS,
  GET_SOLICITOR_DASHBOARD_LOG_FAILED,
  GET_COMPANY_DETAILS_BY_KEYWORD_SUCCESS,
  GET_COMPANY_DETAILS_BY_KEYWORD_FAILED,
  SET_INVITE_CORPORATE_SUCCESS,
  SET_INVITE_CORPORATE_FAILED,
  GET_COMPANY_DIRECTORS_SUCCESS,
  GET_COMPANY_DIRECTORS_FAILED,
  SOLICITOR_DELETE_SUCCESS,
  SOLICITOR_DELETE_FAILED
} from "./index";

export const getSolicitorDetailsBySecret = (secret) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}user/${secret}`, null, 'GET', false).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: GET_SOLICITOR_DETAILS_BY_SECRET_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: GET_SOLICITOR_DETAILS_BY_SECRET_FAILED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getSolicitorsList = (url = `solicitors`) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}${url}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: SOLICITORS_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: SOLICITORS_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getSolicitorById = (id, role = 'solicitor') => {
  return (dispatch) => {
    var urlToSend = `${API_URL}solicitors/${id}`;
    if (role === 'auditor') {
      urlToSend = `${API_URL}auditor/solicitor/${id}`;
    }
    return makeRequest(urlToSend).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: SOLICITORS_GET_BY_ID_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: SOLICITORS_SET_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}
export const setSolicitorData = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}solicitors/${data.id}`, data.newData, 'PUT', true)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: SET_SOLICITOR_NEW_DATA, payload: data})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_SOLICITOR_NEW_DATA_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}
export const setNewSolicitor = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}solicitors`, data, 'POST', true).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: SET_NEW_SOLICITOR_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: SET_NEW_SOLICITOR_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}
export const setSolicitorErrorMessage = (data) => {
  return ({type: SET_NEW_SOLICITOR_ERR_MESSAGE, payload: data});
}
export const setLoadingFlag = (flag = true) => {
  return (flag
      ? {type: SET_DATA_LOADING_TRUE}
      : {type: SET_DATA_LOADING_FALSE}
  );
}
export const getSolicitorsDashboardLog = () => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/log`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: GET_SOLICITOR_DASHBOARD_LOG_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: GET_SOLICITOR_DASHBOARD_LOG_FAILED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}
export const setDashboardComponentLoading = (where = '') => {
  return (
    {type: `SET_SOLICITOR_DASHBOARD_${where.toUpperCase()}_LOADING`}
  );
}
export const getSolicitorsDashboardMeta = () => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/stats`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({ type: GET_SOLICITOR_DASHBOARD_META_SUCCESS, payload: data }))
      } else {
        return res.json().then((err) => {
          dispatch({type: GET_SOLICITOR_DASHBOARD_META_FAILED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}
export const setSolicitorsDashboardLog = () => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/log`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({ type: GET_SOLICITOR_DASHBOARD_LOG_SUCCESS, payload: data }))
      } else {
        return res.json().then((err) => {
          dispatch({type: GET_SOLICITOR_DASHBOARD_LOG_FAILED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const setCompanyDetailsLoading = (where = '', flag = 'TRUE') => {
  return (
    {type: `SET_COMPANY_DETAILS_LOADING_${flag}`}
  );
}

export const getCompanyDetailsByKeyword = (keyword) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/visionnet/${keyword}`, null, 'POST').then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: GET_COMPANY_DETAILS_BY_KEYWORD_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: GET_COMPANY_DETAILS_BY_KEYWORD_FAILED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getCompanyDirectors = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/visionnet/${id}/directors`)
      .then((res) => {
        if (res.ok) {
          return res.json()
            .then(data => dispatch({type: GET_COMPANY_DIRECTORS_SUCCESS, payload: data}))
        } else {
          return res.json().then((err) => {
            dispatch({type: GET_COMPANY_DIRECTORS_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const getCompanyDetails = (id, where = 'directors') => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/visionnet/${id}/document/${where}`).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: `GET_COMPANY_${where.toUpperCase()}_SUCCESS`, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: `GET_COMPANY_${where.toUpperCase()}_FAILED`, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const setCompanyDocumentsLoading = (where = '') => {
  return (
    {type: `SET_COMPANY_DOCUMENTS_${where.toUpperCase()}_LOADING_TRUE`}
  );
}
export const setEmptyResult = (where = '') => {
  return (
    {type: `SET_COMPANY_EMPTY_DETAILS_BY_KEYWORD_SUCCESS`}
  );
}

export const setInviteCorporate = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/invitecorporate`, data, 'POST', true, true)
      .then((res) => {
        if (res.ok) {
          return res.json().then(data => dispatch({type: SET_INVITE_CORPORATE_SUCCESS, payload: data}))
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_INVITE_CORPORATE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const deleteSolicitor = (id) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}firm/solicitors/${id}`, null, 'DELETE')
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(data => {
              dispatch({type: SOLICITOR_DELETE_SUCCESS, payload: id})
            })
        } else {
          return res.json().then((err) => {
            dispatch({type: SOLICITOR_DELETE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }

}