import React, {useLayoutEffect} from 'react';
import ReactDOM from 'react-dom/client';

import {Provider} from "react-redux";
import store from "./redux/store";

import AdminLogin from './pages/Admin/AdminLogin'
import FirmSignUp from './pages/Firm/FirmSignUp'

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "../src/assets/css/styles.css";

import {ChakraProvider, ColorModeScript} from "@chakra-ui/react";
import {BrowserRouter, Route, Navigate, Routes, useLocation} from "react-router-dom";

import TextPlaceholder from "./components/TextPlaceholder";
import AdminProtected from "./components/AdminProtected";
import FirmProtected from "./components/FirmProtected";
import AdminMainPage from "./pages/Admin/AdminMainPage";
import AdminFirms from "./pages/Admin/AdminFirms";
import AdminLayout from "./components/AdminLayout";
import AdminAuditors from "./pages/Admin/AdminAuditors";
import AdminAuditorRequests from "./pages/Admin/AdminAuditorRequests";
import FirmLayout from "./components/FirmLayout";
import FirmSolicitors from "./pages/Firm/FirmSolicitors";
import EditItem from "./components/EditItem";

import theme from './theme'
import RegisterPage from "./pages/Landing/RegisterPage";
import ErrorNotFound from "./components/ErrorNotFound";
import SolicitorProtected from "./components/SolicitorProtected";
import SolicitorLayout from "./components/SolicitorLayout";
import SolicitorSignin from "./pages/Solicitor/SignIn";
import SolicitorCases from "./pages/Solicitor/Cases";
import SolicitorCaseView from "./pages/Solicitor/CaseView";
import SolicitorCaseViewCorporate from "./pages/Solicitor/CaseViewCorporate";
import Landing from "./pages/Landing/Landing";
import LandingFull from "./pages/Landing/LandingFull";
import AccountOpeningForm from "./pages/Account/AccountOpeningForm";
import SolicitorAccounts from "./pages/Solicitor/Accounts";
import AccountProtected from "./components/AccountProtected";
import AccountForms from "./pages/Account/AccountForms";
import AccountLayout from "./components/AccountLayout";
import RiskAssessmentForm from "./components/RiskAssessmentForm";
import CaseOpeningForm from "./components/CaseOpeningForm";
import SolicitorForgot from "./pages/Solicitor/Forgot";
import DocuSignSuccess from "./pages/DocuSignSuccess";
import SolicitorDashboard from "./pages/Solicitor/Dashboard";
import SolicitorInviteCorporate from "./pages/Solicitor/InviteCorporate";
import SolicitorInvitePrivate from "./pages/Solicitor/InvitePrivate";
import CorporateForm from "./pages/CorporateForm";
import AuditorProtected from "./components/AuditorProtected";
import AuditorFirms from "./pages/Auditor/AuditorFirms";
import AuditorLayout from "./components/AuditorLayout";
import AuditorFirm from "./pages/Auditor/AuditorFirm";
import AuditorFirmClients from "./pages/Auditor/AuditorFirmClients";
import AuditorClient from "./pages/Auditor/AuditorClient";

import Cases from "./pages/Cases/Cases";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const Wrapper = ({children}) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<ChakraProvider theme={theme}>
<ColorModeScript initialColorMode={theme.config.initialColorMode}/>
       <BrowserRouter>
       <Wrapper>
       <Provider store={store}>

              <Routes>
                     <Route path='*' element={<ErrorNotFound/>} />
                     <Route path={'/'} element={<LandingFull/>} />
                     <Route path={'/landing'} element={<Landing/>} />

                     <Route path={'/admin/login/'} element={<AdminLogin redirect={true}/>} />
                     <Route path={'/admin/forgot'} element={<TextPlaceholder content={'Admin forgot password page'}/>} />

                     <Route path={'admin'} element={<AdminProtected WrappedComponent={<AdminLayout/>}/>} >
                            <Route path='/admin/' element={<Navigate to='/admin/dashboard/' replace/>} />
                            <Route path={'/admin/dashboard/'} element={<AdminProtected WrappedComponent={<AdminMainPage/>}/>} />
                            <Route path={'firms'} element={<AdminProtected WrappedComponent={<AdminFirms/>}/>} >
                                   <Route path={':id'} element={<EditItem nameSpace={'firm'}/>} />
                            </Route>
                            <Route path={'auditors'} element={<AdminProtected WrappedComponent={<AdminAuditors/>}/>} />
                            <Route path={'requests'} element={<AdminProtected WrappedComponent={<AdminAuditorRequests/>}/>} />
                            <Route path={'help'} element={<AdminProtected WrappedComponent={<TextPlaceholder content={'Please contact support@lawcomply.ie if you need any additional help and support with LawComply. Thank you.'}/>}/>} />
                     </Route>

                     <Route path={'/firm/signup/'} element={<FirmSignUp/>} />
                     <Route path={'/firm/'} element={<FirmProtected WrappedComponent={<FirmLayout/>}/>} >

                            <Route path='/firm/' element={<Navigate to='/firm/solicitors/' replace/>} />

                            <Route path={'solicitors/:solicitor_id/cases'} element={<FirmProtected WrappedComponent={<Cases/>}/>} />
                            {/* <Route path={'solicitors/:solicitor_id/cases'} element={<FirmProtected WrappedComponent={<FirmClients/>}/>} /> */}

                            <Route path={'solicitors/:solicitor_id/cases/:id'} element={<FirmProtected WrappedComponent={<SolicitorCaseView/>}/>} />
                            <Route path={'solicitors/:solicitor_id/cases/corporate/:id'} element={<FirmProtected WrappedComponent={<SolicitorCaseViewCorporate/>}/>} />
                            <Route path={'solicitors/:solicitor_id/cases/corporate/:id/risk-assessment'} element={<FirmProtected WrappedComponent={<RiskAssessmentForm/>}/>} />
                            <Route path={'solicitors/:solicitor_id/cases/:id/risk-assessment'} element={<FirmProtected WrappedComponent={<RiskAssessmentForm/>}/>} />
                            <Route path={'solicitors/:solicitor_id/cases/corporate/:id/case-opening'} element={<FirmProtected WrappedComponent={<CaseOpeningForm/>}/>} />
                            <Route path={'solicitors/:solicitor_id/cases/:id/case-opening'} element={<FirmProtected WrappedComponent={<CaseOpeningForm/>}/>} />
                            <Route path={'solicitors/:solicitor_id/cases/corporate/invite'} element={<FirmProtected WrappedComponent={<SolicitorInviteCorporate/>}/>} />
                            <Route path={'solicitors/:solicitor_id/cases/private/invite'} element={<FirmProtected WrappedComponent={<SolicitorInvitePrivate/>}/>} />
                            <Route path={'solicitors'} element={<FirmProtected WrappedComponent={<FirmSolicitors/>}/>} >
                                   <Route path={':id'} element={<FirmProtected WrappedComponent={<EditItem nameSpace={'solicitors'}/>}/>} />
                            </Route>                            

                            <Route path={'users'} element={<FirmProtected WrappedComponent={<TextPlaceholder content={'Users page'}/>}/>} />
                            <Route path={'help'} element={<FirmProtected WrappedComponent={<TextPlaceholder content={'Please contact support@lawcomply.ie if you need any additional help and support with LawComply. Thank you.'}/>}/>} />
                     </Route>

                     <Route path={'/signin/'} element={<SolicitorSignin redirect={true}/>} />
                     <Route path={'/forgot/'} element={<SolicitorForgot/>} />
                     <Route path={'/solicitor/'} element={<SolicitorProtected WrappedComponent={<SolicitorLayout/>}/>} >
                            <Route path='/solicitor/' element={<Navigate to='/solicitor/dashboard/' replace/>} />
                            <Route path={'cases/corporate/invite'} element={<SolicitorProtected WrappedComponent={<SolicitorInviteCorporate/>}/>} />
                            <Route path={'cases'} element={<SolicitorProtected WrappedComponent={<Cases/>}/>} >
                                   <Route path={':id'} element={<SolicitorProtected WrappedComponent={<SolicitorCaseView/>}/>} />
                                   <Route path={':id/risk-assessment'} element={<SolicitorProtected WrappedComponent={<RiskAssessmentForm/>}/>} />
                                   <Route path={':id/case-opening'} element={<SolicitorProtected WrappedComponent={<CaseOpeningForm/>}/>} />
                            </Route>
                            <Route path={'cases/corporate'} element={<SolicitorProtected WrappedComponent={<SolicitorCases/>}/>} >
                                   <Route path={':id'} element={<SolicitorProtected WrappedComponent={<SolicitorCaseViewCorporate/>}/>} />
                                   <Route path={':id/risk-assessment'} element={<SolicitorProtected WrappedComponent={<RiskAssessmentForm/>}/>} />
                                   <Route path={':id/case-opening'} element={<SolicitorProtected WrappedComponent={<CaseOpeningForm/>}/>} />
                            </Route>
                            <Route path={'cases/private/invite'} element={<FirmProtected WrappedComponent={<SolicitorInvitePrivate/>}/>} />

                            <Route path={'accounts'} element={<SolicitorProtected WrappedComponent={<SolicitorAccounts/>}/>} />
                            <Route path={'dashboard'} element={<SolicitorProtected WrappedComponent={<SolicitorDashboard/>}/>} />
                            <Route path={'help'} element={<SolicitorProtected WrappedComponent={<TextPlaceholder content={'Please contact support@lawcomply.ie if you need any additional help and support with LawComply. Thank you.'}/>}/>} />
                     </Route>

                     <Route path={'/account/'} element={<AccountProtected WrappedComponent={<AccountLayout/>}/>} >
                            <Route path='/account/' element={<Navigate to='/account/forms/' replace/>} />
                            <Route path={'forms'} element={<AccountProtected WrappedComponent={<AccountForms/>}/>} />
                            <Route path={'help'} element={<AccountProtected WrappedComponent={<TextPlaceholder content={'Account help page'}/>}/>} />
                     </Route>

                     <Route path={'/auditor/'} element={<AuditorProtected WrappedComponent={<AuditorLayout/>}/>} >
                            <Route path='/auditor/' element={<Navigate to='/auditor/firms/' replace/>} />
                            <Route path={'firms'} element={<AuditorProtected WrappedComponent={<AuditorFirms/>}/>} >
                                   <Route path={':id'} element={<AuditorProtected WrappedComponent={<AuditorFirm/>}/>} />
                            </Route>
                            <Route path={'/auditor/firms/:firmid/solicitor/:solicitorid/cases'} element={<AuditorProtected WrappedComponent={<AuditorFirmClients/>}/>} />
                            <Route path={'/auditor/firms/:firmid/solicitor/:solicitorid/cases/:clientid'} element={<AuditorProtected WrappedComponent={<AuditorClient/>}/>} />
                            <Route path={'help'} element={<AuditorProtected WrappedComponent={<TextPlaceholder content={'Please contact support@lawcomply.ie if you need any additional help and support with LawComply. Thank you.'}/>}/>} />
                     </Route>

                     <Route path={'register/:secret'} element={<RegisterPage/>} />
                     <Route path={'submit-form/signed'} element={<DocuSignSuccess/>} />
                     <Route path={'submit-form/:secret'} element={<AccountOpeningForm/>} />
                     <Route path={'submit-form-corporate/:secret'} element={<CorporateForm/>} />

              </Routes>

       </Provider>
       </Wrapper>
       </BrowserRouter>
</ChakraProvider>);
