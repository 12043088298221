import {
  Box,
  Button,
  Container,
  Heading,
  Stack
} from "@chakra-ui/react";
import {PasswordField} from "./PasswordField";
import AlertComponent from "./AlertComponent";
import * as React from "react";
import HeaderFormLogo from "./HeaderFormLogo";

const ChangePasswordForm = (
  {
    formik,
    dispatchErrorMessage
  }
) => {

  return (<Container
    maxW='2xl'
    py={{
      base: '12', md: '24',
    }}
    px={{
      base: '0',
    }}
  >
    <Stack spacing="8">
      <HeaderFormLogo/>
      <Box
        py={{
          base: '0', sm: '3',
        }}
        px={{
          base: '4', sm: '10',
        }}
      >
        <Heading
          marginTop={'46px'}
          fontSize={'54px'}
          fontWeight={'bold'}
          color={'#343B55'}
          textAlign={'center'}
          mb={'32px'}
        >
          Change Password
        </Heading>

        {dispatchErrorMessage && (<AlertComponent
          message={dispatchErrorMessage}
        />)}

        <Stack spacing="6">

          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5" mb={'32px'}>

              <PasswordField
                placeholder={'Enter your password'}
                fontSize={'18px'}
                onChange={formik.handleChange} tabIndex={2}
                iconButtonTabIndex={3}
              />
              
              <PasswordField
                placeholder={'Enter your password'}
                fontSize={'18px'}
                onChange={formik.handleChange} tabIndex={4}
                iconButtonTabIndex={5}
                label={'Confirm Password'}
                name={'confirmPassword'}
              />
            </Stack>

            <Stack spacing="6" text-align={'right'}>
              <Button
                tabIndex={6}
                type="submit"
                fontSize={'18px'}
                variant="primary">Submit</Button>
            </Stack>
          </form>

        </Stack>

      </Box>
    </Stack>
  </Container>)
}

export default ChangePasswordForm;