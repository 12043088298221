import {API_URL, makeRequest} from "../../services/request";

export const getDashboardData = async () => {
    return makeRequest(`${API_URL}admin/dashboard`).then((res) => {
        if (res.ok) {
            return res.json().then(data => data);
        } else {
            return res.json().then((err) => {
                return Promise.reject(err)
            })
        }
    })
}
