import React from 'react';
import {Box, useRadio} from "@chakra-ui/react";

const RadioCard = (props) => {

  const {getInputProps, getCheckboxProps} = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box
      as='label'
    >
      <input {...input}/>
      <Box
        {...checkbox}
        paddingTop={'8px'}
        cursor='pointer'
        borderRadius='3px'
        minHeight={'40px'}
        height={'40px'}
        fontSize={'18px'}

        _checked={{
          bg: '#4285F4',
          color: 'white',
          fontWeight: '600',

        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={'10'}
        py={'8px'}
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default RadioCard;