import React, {useEffect, useState} from 'react';
import {Button, FormControl, FormLabel, Input, Stack, Text, VStack} from "@chakra-ui/react";
import AlertComponent from "./AlertComponent";
import {DoneIcon} from "../assets/icons/sprite";
import Alert from "./Alert";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {setFirmErrorMessage} from "../redux/actions/auth";
import {getFirmIdpalData, setFirmIdpalData} from "../redux/actions/firms";

const EditIdpal = ({title = 'ID Pal settings', isOpen, onOpen, onClose, id}) => {

  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  const dispatchErrorMessage = useSelector(state => state.firm.errorMessage);
  const dispatchSetFirmErrorMessage = (message) => dispatch(setFirmErrorMessage(message));
  const dispatchSetFirmIdpalData = (id, data) => dispatch(setFirmIdpalData(id, data))

  const resetDialog = () => {
    if (step === 2) {
      formik.values.client_id = '';
      formik.values.access_token = '';
      formik.values.refresh_token = '';
      formik.values.secret = '';
    }
    setStep(1);
    dispatchSetFirmErrorMessage(null);
    onClose();
  }

  const handleSubmit = async values => {


    dispatchSetFirmIdpalData(id, values).then(() => {
      setStep(2);
    })
  }

  const formik = useFormik({
    initialValues: {
      client_id: '',
      access_token: '',
      refresh_token: '',
      secret: '',
    }, onSubmit: handleSubmit
  })


  useEffect(() => {
    isOpen
      ? dispatch(getFirmIdpalData(id)).then((r) => {
        formik.setFieldValue('client_id', r.data.client_id)
        formik.setFieldValue('access_token', r.data.access_token)
        formik.setFieldValue('refresh_token', r.data.refresh_token)
        formik.setFieldValue('secret', r.data.secret)
      })
      : dispatch(setFirmErrorMessage(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, dispatch, id])


  return (
    <Alert
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={resetDialog}
      header={title}
      text={step === 1 ? <form
        onSubmit={formik.handleSubmit}
      >
        <Stack spacing="5">
          {dispatchErrorMessage && (<AlertComponent
            message={dispatchErrorMessage}
          />)}

          <FormControl>
            <FormLabel
              htmlFor="client_id"
              fontSize={'18px'}>
              Client id
            </FormLabel>
            <Input
              tabIndex={1}
              id="client_id"
              type="text"
              required
              placeholder={'Client id'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.client_id}
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="access_token"
              fontSize={'18px'}>
              Access token
            </FormLabel>
            <Input
              tabIndex={1}
              id="access_token"
              type="text"
              required
              placeholder={'Access token'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.access_token}
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="refresh_token"
              fontSize={'18px'}>
              Refresh token
            </FormLabel>
            <Input
              tabIndex={1}
              id="refresh_token"
              type="text"
              required
              placeholder={'Refresh token'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.refresh_token}
            />

          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="secret"
              fontSize={'18px'}>
              Secret
            </FormLabel>
            <Input
              tabIndex={1}
              id="secret"
              type="text"
              required
              placeholder={'Secret'}
              fontSize={'18px'}
              onChange={formik.handleChange}
              value={formik.values.secret}
            />


          </FormControl>

        </Stack>
        <Stack spacing="6" text-align={'right'} py={'20px'}>>
          <Button
            tabIndex={4}
            type="submit"
            fontSize={'18px'}
            variant="primary">Save</Button>
        </Stack>
      </form> : step === 2 ? <VStack
        marginBottom={10}
      >
        <DoneIcon
          width={93}
          height={93}
          marginTop={6}
          marginBottom={4}/>

        <Text
          paddingX={'100px'}
          pb={8}
          align={'center'}>Connection to ID Pal is successfully set up.</Text>

        <Button
          tabIndex={7}
          type="submit"
          fontSize={'18px'}
          variant="primary"
          minWidth={184}
          minHeight={'48px'}
          height={'48px'}
          onClick={resetDialog}>Ok</Button>
      </VStack> : null}
      action={null}
      size={'3xl'}
      contentPaddingY={0}
    />
  );
};

export default EditIdpal;