import React from "react";
import {Button} from "@chakra-ui/react";
import {HiChevronLeft} from "react-icons/hi";

const Back = ({onClickHandler, ...props}) => {
  return (<Button
    background={'transparent'}
    onClick={onClickHandler}
    ml={'10%'}
    fontSize={'18px'}
    fontWeight={400}
    {...props}
  >
    <HiChevronLeft size={25} display={'flex'}/> &nbsp;Back
  </Button>)
}

export default Back;