import React from 'react';
import {Box, Spinner} from "@chakra-ui/react";

const Loading = (props) => {

  var size = "xl"
  if (props?.size) {
    // alert(props?.size)
    size = props?.size
  }
  return (
    <Box py={5} {...props}>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size={size}
      />
    </Box>
  );
};

export default Loading;