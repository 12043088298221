import React, {useEffect} from 'react';
import {Box, Button, Container, Link, Stack} from "@chakra-ui/react";
import HeaderFormLogo from "./HeaderFormLogo";
import {useDispatch} from "react-redux";
import {checkAuth} from "../redux/actions/auth";

const REFRESH_MS = 10 * 1000

const InactiveStatusShow = ({
                              onClick,
                              role = 'firm',
                              text = 'Please wait for your account to be activated',
                              checkAction = null,
                              logoLink = true,
                            }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (role === 'client') {
        checkAction()
      } else {
        dispatch(checkAuth(role))
      }
    }, REFRESH_MS);

    return () => clearInterval(interval)
  }, [dispatch, role, checkAction])

  return (<Container
    maxW='2xl'
    py={{
      base: '12', md: '24',
    }}
    px={{
      base: '0',
    }}
  >
    <Stack spacing="8">
      <HeaderFormLogo logoLink={logoLink}/>
      <Box
        py={{
          base: '0', sm: '3',
        }}
        px={{
          base: '4', sm: '10',
        }}
        textAlign={'center'}
      >

        {text && text}. {onClick
        ? <Button
          as={Link}
          tabIndex={1}
          variant="link"
          fontSize={'18px'}
          colorScheme="blue"
          color={'#4285F4'}
          size="sm"
          onClick={onClick}>
          Logout.
        </Button>
        : null
      }
      </Box>
    </Stack>
  </Container>)
};

export default InactiveStatusShow;