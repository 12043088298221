import {combineReducers} from "@reduxjs/toolkit";
import adminAuthReducer, {accountAuthReducer, auditorAuthReducer, firmAuthReducer, authReducer} from "./auth";
import firmManageReducer from "./firms";
import solicitorsManageReducer from "./solicitors";
import clientsManageReducer from "./clients";
import accountsManageReducer from "./accounts";
import auditorsManageReducer from "./auditors";
import formsManageReducer from "./forms";
import landingReducer from "./landing";

export default combineReducers({
  adminAuth: adminAuthReducer,
  firmAuth: firmAuthReducer,
  solicitorAuth: authReducer,
  accountAuth: accountAuthReducer,
  firm: firmManageReducer,
  solicitors: solicitorsManageReducer,
  accounts: accountsManageReducer,
  clients: clientsManageReducer,
  auditors: auditorsManageReducer,
  auditorAuth: auditorAuthReducer,
  forms: formsManageReducer,
  landing: landingReducer
})
