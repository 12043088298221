import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  checkSolicitorAuth,
  logout,
  setSolicitorLoginLoadingFlag, setAuthNotFirstCheck
} from "../redux/actions/auth";
import SolicitorSignin from "../pages/Solicitor/SignIn";
import InactiveStatusShow from "./InactiveStatusShow";
import Loading from "./Loading";
import {useNavigate} from "react-router-dom";

const SolicitorProtected = ({
                              WrappedComponent
                            }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatchLogout = () => dispatch(logout('solicitor', navigate));
  const dispatchIsDataLoading = useSelector(state => state.solicitorAuth.isDataLoading);

  const dispatchSolicitorAuthAuthChecked = useSelector(state => state.solicitorAuth?.authChecked);
  const dispatchSolicitorAuthLoggedIn = useSelector(state => state.solicitorAuth?.loggedIn);
  const dispatchSolicitorAuthCurrentUserEmailVerifiedAt = useSelector(state => state.solicitorAuth?.currentUser?.email_verified_at);
  const dispatchSolicitorAuthCurrentUserActive = useSelector(state => state.solicitorAuth?.currentUser?.active);

  const dispatchSolicitorAuthCodeChecked = useSelector(state => state.solicitorAuth?.codeChecked);
  const dispatchSolicitorAuthIsFirstCheck = useSelector(state => state.solicitorAuth?.isFirstCheck);


  useEffect(() => {
    if ((!dispatchIsDataLoading && !dispatchSolicitorAuthAuthChecked)) {
      dispatch(setSolicitorLoginLoadingFlag())
      dispatch(checkSolicitorAuth())
    }
  }, [
    dispatch,
    dispatchIsDataLoading,
    dispatchSolicitorAuthAuthChecked
  ])

  useEffect(() => {
    if (dispatchSolicitorAuthCodeChecked && dispatchSolicitorAuthIsFirstCheck) {
      if (!dispatchIsDataLoading) {
        dispatch(setAuthNotFirstCheck(true, 'solicitor'))
        dispatch(setSolicitorLoginLoadingFlag())
        dispatch(checkSolicitorAuth())
      }
    }

  }, [
    dispatch,
    dispatchIsDataLoading,
    dispatchSolicitorAuthCodeChecked,
    dispatchSolicitorAuthIsFirstCheck
  ])

  return (
    dispatchSolicitorAuthAuthChecked
    && dispatchSolicitorAuthLoggedIn
    && dispatchSolicitorAuthCurrentUserEmailVerifiedAt
  ) ?
    dispatchSolicitorAuthCurrentUserActive
      ? <>{WrappedComponent}</>
      : <InactiveStatusShow
        onClick={dispatchLogout}
        role={'solicitor'}
      />
    : dispatchIsDataLoading
      ? <Loading/>
      : <SolicitorSignin/>
}

export default SolicitorProtected;
