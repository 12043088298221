import React from "react";

const AdvancedDropDownItem = ({ itemTitle, itemName, isChecked, onCheckboxChange }) => {

    const handleChange = (event) => {
        onCheckboxChange(itemName, event.target.checked);
    };

    const handleOnClick = (event) => {
        event.stopPropagation();
        isChecked = event.target.checked
    }

    return (
    <div className="advanced-dropdown-item">
        <input type="checkbox" 
            name={itemName} 
            onClick={handleOnClick}
            checked={isChecked}
            onChange={handleChange}
        /> 
        <label className="advanced-dropdown-label" htmlFor={itemName}
            onClick={(event) => { event.stopPropagation() }}
        >{itemTitle}</label>
    </div>)
}

export default AdvancedDropDownItem;