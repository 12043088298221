import * as React from 'react';
import {useEffect, useState} from "react";
import {useFormik} from "formik";
import LoginForm from "../../components/LoginForm";
import EnterCode from "../../components/EnterCode";
import {emailVerification, loginFirm, sendVerificationCode, setFirmErrorMessage} from "../../redux/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";


const FirmSignIn = () => {

  const dispatch = useDispatch();

  const dispatchLoginFirm = (credentials) => dispatch(loginFirm(credentials));
  const dispatchEmailVerification = (credentials) => dispatch(emailVerification(credentials));
  const dispatchSendVerificationCode = (credentials) => dispatch(sendVerificationCode(credentials));
  const dispatchSetFirmErrorMessage = (credentials) => dispatch(setFirmErrorMessage(credentials));

  const dispatchErrorMessage = useSelector(state => state.firmAuth.errorMessage)

  useEffect(() => {
    dispatch(setFirmErrorMessage(null));
  }, [dispatch])

  const navigate = useNavigate();
  const location = useLocation()

  const [step, setStep] = useState(1)
  const [hideCodeText, setHideCodeText] = useState(false)

  const handleSubmit = async values => {
    if (step === 1) {
      dispatchLoginFirm(values)
        .then(() => {
          setStep(2);
          setHideCodeText(false);
        })
        .catch(
          // Also another method for receiving err
          (err) => err.message ? console.log(err.message) : console.warn('Error')
        )
    }
    if (step === 2) {
      dispatchEmailVerification(values).then(
        () => {
          location.pathname === '/signin'
            ? navigate('/firm/solicitors/')
            : navigate(0)
        }
      ).catch(
        // Also another method for receiving err
        (err) => err.message
          ? err.message === 'Code expired after 3 attempts, please request a new code.'
            ? setHideCodeText(true)
            : setHideCodeText(false)
          : console.warn('Error')
      )
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '', password: '', code: ''
    }, onSubmit: handleSubmit
  })

  return (step === 1
    ? <LoginForm
      formik={formik}
      link={'/forgot/'}
      signup={'/firm/signup/'}
      dispatchErrorMessage={dispatchErrorMessage}
    />
    : <EnterCode
      dispatchErrorMessage={dispatchErrorMessage}
      setStep={setStep}
      formik={formik}
      hideCodeText={hideCodeText}
      setHideCodeText={setHideCodeText}
      dispatchSendVerificationCode={dispatchSendVerificationCode}
      dispatchSetFirmErrorMessage={dispatchSetFirmErrorMessage}
    />)
}

export default FirmSignIn;
