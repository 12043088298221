import {makeRequest, API_URL} from "../../services/request";
import {
  SET_DATA_LOADING_FALSE,
  SET_DATA_LOADING_TRUE,
  CLIENTS_LIST_DOWNLOADED,
  CLIENTS_LIST_NOT_DOWNLOADED,
  SET_NEW_CLIENTS_ERR_MESSAGE,
  SET_NEW_CLIENTS_SUCCESS,
  CLIENTS_GET_BY_ID_SUCCESS,
  CLIENTS_SET_ERR_MESSAGE,
  CLIENTS_GET_SUBMISSION_DATA_SUCCESS,
  SET_SUBMISSION_DATA_LOADING_TRUE,
  SET_SUBMISSION_DATA_LOADING_FALSE,
  CLIENTS_GET_FORBIDDEN,
  GET_CLIENT_BY_SECRET_SUCCESS,
  GET_CLIENT_BY_SECRET_FAILED,
  SET_CLIENT_BY_SECRET_SUCCESS,
  SET_CLIENT_BY_SECRET_FAILED,
  SET_UPDATE_RISK_FORM_SUCCESS,
  SET_NEW_RISK_FORM_SUCCESS,
  SET_UPDATE_RISK_FORM_FAILED,

  SET_NEW_RISK_FORM_FAILED,
  SET_CLIENT_IDPAL_OVERRIDE_SUCCESS,
  SET_CLIENT_IDPAL_OVERRIDE_FAILED,
  SET_SEND_NEW_DOCUSIGN_DOC_SUCCESS,
  SET_SEND_NEW_DOCUSIGN_DOC_FAILED,
  SET_FORM_LOADING_TRUE,
  SET_FORM_LOADING_FALSE,
  SET_S150FORM_PDF_LOADING_TRUE,
  SET_S150FORM_PDF_LOADING_FALSE,
  GET_S150FORM_PDF_SUCCESS,
  GET_S150FORM_PDF_FAILED, 
  SET_CLIENT_IDPAL_REINVITE_FAILED, SET_CLIENT_IDPAL_REINVITE_SUCCESS,

  SET_CORPDOC_CR_PDF_LOADING_TRUE,
  SET_CORPDOC_CR_PDF_LOADING_FALSE,
  SET_CORPDOC_SBI_PDF_LOADING_TRUE,
  SET_CORPDOC_SBI_PDF_LOADING_FALSE,
  SET_CORPDOC_COI_PDF_LOADING_TRUE,
  SET_CORPDOC_COI_PDF_LOADING_FALSE,
  SET_CORPDOC_C_PDF_LOADING_TRUE,
  SET_CORPDOC_C_PDF_LOADING_FALSE,

  GET_CORPDOC_CR_PDF_SUCCESS,
  GET_CORPDOC_CR_PDF_FAILED,
  GET_CORPDOC_SBI_PDF_SUCCESS,
  GET_CORPDOC_SBI_PDF_FAILED,
  GET_CORPDOC_COI_PDF_SUCCESS,
  GET_CORPDOC_COI_PDF_FAILED,
  GET_CORPDOC_C_PDF_SUCCESS,
  GET_CORPDOC_C_PDF_FAILED,

  SET_SHAREHOLDERSFORM_PDF_LOADING_TRUE,
  SET_SHAREHOLDERSFORM_PDF_LOADING_FALSE,
  GET_SHAREHOLDERSFORM_PDF_SUCCESS,
  GET_SHAREHOLDERSFORM_PDF_FAILED,   

  SET_CASE_OPENING_SUCCESS,
  SET_CASE_OPENING_FAILED,
}
  from
    "./index";


export const setClientErrorMessage = (data) => {
  return ({type: SET_NEW_CLIENTS_ERR_MESSAGE, payload: data});
}

export const setNewClient = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/invite`, data, 'POST', true).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: SET_NEW_CLIENTS_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: SET_NEW_CLIENTS_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getClientsList = (url = `clients`, id = null, role = 'solicitor', solicitorId = null) => {
  return (dispatch) => {
    var urlToSend = `${API_URL}${url}`;
    if (solicitorId) {
      if (url.includes("?")) {
        urlToSend = urlToSend + "&"
      }
      else {
        urlToSend = urlToSend + "?"
      }
      urlToSend = urlToSend + "solicitor_id=" + solicitorId
    }
    if (role === 'auditor') {
      urlToSend = `${API_URL}auditor/solicitor/${id}/${url}`;
    }
    return makeRequest(urlToSend).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: CLIENTS_LIST_DOWNLOADED, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: CLIENTS_LIST_NOT_DOWNLOADED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getClientById = (id, role = 'solicitor') => {
  return (dispatch) => {
    var urlToSend = `${API_URL}clients/${id}`;
    if (role === 'auditor') {
      urlToSend = `${API_URL}auditor/client/${id}`;
    }
    return makeRequest(urlToSend).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: CLIENTS_GET_BY_ID_SUCCESS, payload: data}))
      } else if (res.status === 403) {
        return res.json().then((err) => {
          dispatch({type: CLIENTS_GET_FORBIDDEN, payload: err});
          return Promise.reject(err)
        })
      } else {
        return res.json().then((err) => {
          dispatch({type: CLIENTS_SET_ERR_MESSAGE, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const attachFile = async (secret, data) => {
  return makeRequest(`${API_URL}corporatedocument/attach/${secret}`, data, 'POST', false, true)
      .then((res) => {
    if (res.ok) {
      return res.json();
    } else if (res.status === 403) {
      return res.json().then((err) => {
        return Promise.reject(err)
      })
    } else {
      return res.json().then((err) => {
        return Promise.reject(err)
      })
    }
  })
}

export const approveDocumentRequest = async (secret, id) => {
  return makeRequest(`${API_URL}corporatedocument/${id}/mark-approved/${secret}`, null, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          return res.json().then((err) => {
            return Promise.reject(err)
          })
        } else {
          return res.json().then((err) => {
            return Promise.reject(err)
          })
        }
      })
}

export const approveShareholdersRequest = async (secret, data) => {
  return makeRequest(`${API_URL}clients/approveshareholders/${secret}`, data, 'POST', false)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 403) {
          return res.json().then((err) => {
            return Promise.reject(err)
          })
        } else {
          return res.json().then((err) => {
            return Promise.reject(err)
          })
        }
      })
}

export const getSubmissionContent = (submId, docIds, auditor=0) => {

  return (dispatch) => {
    return Promise.all(docIds.map(docId => makeRequest(`${API_URL}submissions/${submId}/document/${docId}/preview/?auditor=${auditor}`)))
      .then((res) => {
        Promise.all(res.map(r => r.json()))
          .then((res) => dispatch({type: CLIENTS_GET_SUBMISSION_DATA_SUCCESS, payload: res}))
          .catch(err => {
            dispatch({type: CLIENTS_SET_ERR_MESSAGE, payload: err})
            return Promise.reject(err)
          })
      })
  }
}

export const setLoadingFlag = (flag = true) => {
  return (flag
      ? {type: SET_DATA_LOADING_TRUE}
      : {type: SET_DATA_LOADING_FALSE}
  );
}
export const setFormLoadingFlag = (flag = true) => {
  return (flag
      ? {type: SET_FORM_LOADING_TRUE}
      : {type: SET_FORM_LOADING_FALSE}
  );
}
export const setSubmissionLoadingFlag = (flag = true) => {
  return (flag
      ? {type: SET_SUBMISSION_DATA_LOADING_TRUE}
      : {type: SET_SUBMISSION_DATA_LOADING_FALSE}
  );
}

export const getClientDetailsBySecret = (secret) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/secret/${secret}`, null, 'GET', false).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: GET_CLIENT_BY_SECRET_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: GET_CLIENT_BY_SECRET_FAILED, payload: err})
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getCorporateFile = async (secret, document) => {
  return makeRequest(`${API_URL}corporatedocument/file/${secret}?client_id=${document.client_id}&document_type=${document.document_type}&base64=true`,
      null, 'GET', false).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return res.json();
    }
  })
}

export const getCorporateClientDetailsBySecret = (secret, getState = null) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}clients/corporate/secret/${secret}`, null, 'GET', false).then((res) => {
      if (res.ok) {
        return res.json().then(data => {
          dispatch({type: GET_CLIENT_BY_SECRET_SUCCESS, payload: data})
          if (getState) {
            getState(data?.data?.corporate_documents, data?.data?.check_children, data?.data?.account_form_submitted);
          }
        })
      } else {
        return res.json().then((err) => {
          dispatch({type: GET_CLIENT_BY_SECRET_FAILED, payload: err})
          return Promise.reject(err)
        })
      }
    })
  }
}


export const setCaseOpeningForm = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}account-form`, data, 'POST', true).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: SET_CASE_OPENING_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
            dispatch({type: SET_CASE_OPENING_FAILED, payload: err});
            return Promise.reject(err)
          }
        )
      }
    })
  }
}


export const setNewClientBySecret = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}account-form/corporate`, data, 'POST', false).then((res) => {
      if (res.ok) {
        return res.json().then(data => dispatch({type: SET_CLIENT_BY_SECRET_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
            dispatch({type: SET_CLIENT_BY_SECRET_FAILED, payload: err});
            return Promise.reject(err)
          }
        )
      }
    })
  }
}

export const setNewClientBySecretNoDispatch = (data) => {
    return makeRequest(`${API_URL}account-form/corporate`, data, 'POST', false).then((res) => {
        if (res.ok) {
            return res.json();
        } else {
            return res.json().then((err) => {
                    return Promise.reject(err)
                }
            )
        }
    })
}

export const setRiskFormUpdate = (data, method = 'POST', id) => {

  method = method.toUpperCase()
  const formId = id ?? ''
  var url = "";

  if (formId === "") {
    url = `${API_URL}risk-form`;
  }
  else {
    url = `${API_URL}risk-form/${formId}`;
  }

  if (data['risk_status'] === "") {
    data['risk_status'] = "low"
  }

  return (dispatch) => {
    return makeRequest(url, data, method, true)
      .then((res) => {
        if (res.ok) {
          return res.json()
            .then(data => dispatch({
              type: method === 'PUT'
                ? SET_UPDATE_RISK_FORM_SUCCESS
                : SET_NEW_RISK_FORM_SUCCESS, payload: data
            }))
        } else {
          return res.json()
            .then((err) => {
              dispatch({
                type: method === 'PUT'
                  ? SET_UPDATE_RISK_FORM_FAILED
                  : SET_NEW_RISK_FORM_FAILED, payload: err
              });
              return Promise.reject(err)
            })
        }
      })
  }
}

export const getS150FormData = (clientId, isSigned = 1, base64 = 1, auditor = 0, documentId = null) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}docusign/file?client_id=${clientId}&signed=${isSigned}&base64=${base64}&auditor=${auditor}&id=${documentId}`)
      .then((res) => {
        if (res.ok) {
          return res.json().then(data => 
              dispatch({type: GET_S150FORM_PDF_SUCCESS, payload: data})
            )
        } else {
          return res.json().then((err) => {
            dispatch({type: GET_S150FORM_PDF_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setS150FormLoading = (flag = true) => {
  return (flag
      ? {type: SET_S150FORM_PDF_LOADING_TRUE}
      : {type: SET_S150FORM_PDF_LOADING_FALSE}
  );
}

export const getShareholdersFormData = (clientId, isSigned = 1, base64 = 1, auditor = 0) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}docusign/file?client_id=${clientId}&signed=${isSigned}&base64=${base64}&auditor=${auditor}&type=shareholders`)
      .then((res) => {
        if (res.ok) {
          return res.json().then(data => dispatch({type: GET_SHAREHOLDERSFORM_PDF_SUCCESS, payload: data}))
        } else {
          return res.json().then((err) => {
            dispatch({type: GET_SHAREHOLDERSFORM_PDF_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setShareholdersFormLoading = (flag = true) => {
  return (flag
      ? {type: SET_SHAREHOLDERSFORM_PDF_LOADING_TRUE}
      : {type: SET_SHAREHOLDERSFORM_PDF_LOADING_FALSE}
  );
}

export const setIdPalOverride = (submissionId) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}submissions/${submissionId}/override`, '', 'POST', true)
      .then((res) => {
        if (res.ok) {
          return res.json().then(data => dispatch({type: SET_CLIENT_IDPAL_OVERRIDE_SUCCESS, payload: data}))
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_CLIENT_IDPAL_OVERRIDE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setIdPalReInvite = (clientId, documentType, reason) => {

  return (dispatch) => {
    return makeRequest(`${API_URL}clients/reinvite`, {client_id: clientId, document_type: documentType, reason: reason}, 'POST', true)
      .then((res) => {
        if (res.ok) {
          return res.json().then(data => dispatch({type: SET_CLIENT_IDPAL_REINVITE_SUCCESS, payload: data}))
        } else {
          return res.json().then((err) => {
            dispatch({type: SET_CLIENT_IDPAL_REINVITE_FAILED, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setSendNewDocusignDoc = (data) => {
  return (dispatch) => {
    return makeRequest(`${API_URL}docusign/attach`, data, 'POST', true, true).then((res) => {
      if (res.ok) {
        return res.json()
          .then(data => dispatch({type: SET_SEND_NEW_DOCUSIGN_DOC_SUCCESS, payload: data}))
      } else {
        return res.json().then((err) => {
          dispatch({type: SET_SEND_NEW_DOCUSIGN_DOC_FAILED, payload: err});
          return Promise.reject(err)
        })
      }
    })
  }
}

export const getCorpDocData = (clientId, docType) => {

    let passType = '';
    let failType = '';
    switch (docType) {
        case 'company_report':
            passType = GET_CORPDOC_CR_PDF_SUCCESS
            failType = GET_CORPDOC_CR_PDF_FAILED
            break
        case 'ubo_report':
            passType = GET_CORPDOC_SBI_PDF_SUCCESS
            failType = GET_CORPDOC_SBI_PDF_FAILED
            break
        case 'cert_of_incorporation':
            passType = GET_CORPDOC_COI_PDF_SUCCESS
            failType = GET_CORPDOC_COI_PDF_FAILED
            break
        case 'constitution':
            passType = GET_CORPDOC_C_PDF_SUCCESS
            failType = GET_CORPDOC_C_PDF_FAILED
            break
        default:
            break
    }

  return (dispatch) => {
    return makeRequest(`${API_URL}corporatedocument/file/?client_id=${clientId}&document_type=${docType}&base64=true`)
      .then((res) => {
        if (res.ok) {
          return res.json().then(data => dispatch({
                type: passType, payload: data
            }))
        } else {
          return res.json().then((err) => {
            dispatch({type: failType, payload: err});
            return Promise.reject(err)
          })
        }
      })
  }
}

export const setCorpDocLoadingFlag = (flag = true, type = null) => {

    switch (type) {
        case 'company_report':
            return (flag
                ? {type: SET_CORPDOC_CR_PDF_LOADING_TRUE}
                : {type: SET_CORPDOC_CR_PDF_LOADING_FALSE}
            );
        case 'ubo_report':
            return (flag
                ? {type: SET_CORPDOC_SBI_PDF_LOADING_TRUE}
                : {type: SET_CORPDOC_SBI_PDF_LOADING_FALSE}
            );
        case 'cert_of_incorporation':
            return (flag
                ? {type: SET_CORPDOC_COI_PDF_LOADING_TRUE}
                : {type: SET_CORPDOC_COI_PDF_LOADING_FALSE}
            );
        case 'constitution':
            return (flag
                ? {type: SET_CORPDOC_C_PDF_LOADING_TRUE}
                : {type: SET_CORPDOC_C_PDF_LOADING_FALSE}
            );
        default:
            return false;
    }
}

export const bypassSignedDocuments = async (data) => {
  return await makeRequest(`${API_URL}docusign/bypass`, data, 'POST', true, false);
}
export const undoBypassSignedDocuments = async (data) => {
  return await makeRequest(`${API_URL}docusign/undobypass`, data, 'POST', true, false);
}
export const updateClient = async (id, data) => {
  return await makeRequest(`${API_URL}clients/${id}`, data, 'POST', true, false);
}
export const addClient = async (id, data) => {
  return makeRequest(`${API_URL}clients/addperson/${id}`, data, 'POST', true, false).then((res) => {
    if (res.ok) {
      return res.json()
    } else {
      return res.json().then((err) => {
        return Promise.reject(err)
      })
    }
  })  
}
export const deleteClient = async (id, data) => {
  return makeRequest(`${API_URL}clients/${id}`, data, 'DELETE', true, false).then((res) => {
    if (res.ok) {
      return res.json()
    } else {
      return res.json().then((err) => {
        return Promise.reject(err)
      })
    }
  })  
}

export const reinviteClient = async (data) => {
  return makeRequest(`${API_URL}clients/reinvitecorporate`, data, 'POST', true).then((res) => {
    if (res.ok) {
      return res.json()
    } else {
      return res.json().then((err) => {
        return Promise.reject(err)
      })
    }
  })  
}

export const resendShareHolderForm = async (data) => {
  return makeRequest(`${API_URL}clients/resendshareholders`, data, 'POST', true).then((res) => {
    if (res.ok) {
      return res.json()
    } else {
      return res.json().then((err) => {
        return Promise.reject(err)
      })
    }
  })  
}
