import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  checkAuth,
  setFirmLoginLoadingFlag, setAuthNotFirstCheck, logout
} from "../redux/actions/auth";
import FirmSignIn from "../pages/Firm/FirmSignIn";
import InactiveStatusShow from "./InactiveStatusShow";
import Loading from "./Loading";
import {useNavigate} from "react-router-dom";

const FirmProtected = ({
                         WrappedComponent
                       }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dispatchLogout = () => dispatch(logout('firm', navigate));
  const dispatchIsDataLoading = useSelector(state => state.firmAuth?.isLoading);

  const dispatchFirmAuthAuthChecked = useSelector(state => state.firmAuth?.authChecked);
  const dispatchFirmAuthLoggedIn = useSelector(state => state.firmAuth?.loggedIn);
  // const dispatchFirmAuthCurrentUserEmailVerifiedAt = useSelector(state => state.firmAuth?.currentUser?.email_verified_at);
  const dispatchFirmAuthCurrentUserActive = useSelector(state => state.firmAuth?.currentUser?.active);

  const dispatchFirmAuthCodeChecked = useSelector(state => state.firmAuth?.codeChecked);
  const dispatchFirmAuthIsFirstCheck = useSelector(state => state.firmAuth?.isFirstCheck);


  useEffect(() => {
    if (!dispatchIsDataLoading && !dispatchFirmAuthAuthChecked) {
      dispatch(setFirmLoginLoadingFlag())
      dispatch(checkAuth())
    }
  }, [
    dispatch,
    dispatchIsDataLoading,
    dispatchFirmAuthAuthChecked
  ])

  useEffect(() => {
    if (dispatchFirmAuthCodeChecked && dispatchFirmAuthIsFirstCheck) {
      if (!dispatchIsDataLoading) {
        dispatch(setAuthNotFirstCheck(true, 'firm'))
        dispatch(setFirmLoginLoadingFlag())
        dispatch(checkAuth())
      }
    }

  }, [
    dispatch,
    dispatchIsDataLoading,
    dispatchFirmAuthCodeChecked,
    dispatchFirmAuthIsFirstCheck
  ])
  
  return (
    dispatchFirmAuthAuthChecked
    && dispatchFirmAuthLoggedIn
    // && dispatchFirmAuthCurrentUserEmailVerifiedAt
  ) ?
    dispatchFirmAuthCurrentUserActive
      ? <>{WrappedComponent}</>
      : <InactiveStatusShow
        onClick={dispatchLogout}
        role={'firm'}/>
    :
    dispatchIsDataLoading
      ? <Loading/>
      : <FirmSignIn/>
}

export default FirmProtected;
