import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Heading,
  HStack,
  // Input,
  PinInput,
  PinInputField,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";
import * as React from "react";
import Countdown from "./Countdown";
import BackButton from "./BackButton";
import AlertComponent from "./AlertComponent";
import Resend from "./Resend";
import HeaderFormLogo from "./HeaderFormLogo";

export const EnterCode = ({
                            dispatchErrorMessage,
                            setStep,
                            formik,
                            hideCodeText,
                            setHideCodeText,
                            dispatchSetFirmErrorMessage: dispatchSetErrorMessage,
                            dispatchSendVerificationCode
                          }) => {

  const childRef = React.useRef();
  const time = new Date();


  time.setSeconds(time.getSeconds() + 300)

  const resendCode = () => {
    dispatchSendVerificationCode(
      {email: formik.values.email}
    )
      .then(() => {
        childRef.current.restartTimer();
        setHideCodeText(false);
        formik.setFieldValue('code', '')
      })
  }

  const commonPinInputFieldStyles = {
    border: 'none',
    borderBottom: '1px',
    borderRadius: 0,
    focusBorderColor: '#C4C4C4',
    marginRight: '56px',
    w: '65px',
    h: '35px',
    minH: '35px'
  }

  return (<><BackButton
    tabIndex={9}
    onClickHandler={() => {
      dispatchSetErrorMessage(null);
      setHideCodeText(false);
      setStep(1);
    }}
    mt={'20px'}
  />
    <Container
      maxW='2xl'
      py={{
        base: '12', md: '24',
      }}
      px={{
        base: '0',
      }}
    >
      <Stack spacing="8">
        <HeaderFormLogo/>


        <Stack as={Flex} alignItems="center">
          <Stack
            spacing={{
              base: '7', md: '3',
            }}
            textAlign="center"
          >
            <Heading
              fontSize={'54px'}
              fontWeight={'bold'}
              color={'#343B55'}
            >
              Enter Code
            </Heading>

            <VStack
              visibility={!hideCodeText ? 'visible' : 'hidden'}
            >
              <Text>We have sent your 5-digit numeric code to email </Text>
              <Text><strong>{formik.values.email}</strong>.
                The code will be valid for <Countdown
                  resendCode={resendCode}
                  ref={childRef}
                  expiryTimestamp={time}/>
              </Text>
            </VStack>
          </Stack>
        </Stack>


        <Box
          py={{
            base: '0', sm: '1',
          }}
          px={{
            base: '4', sm: '10',
          }}
        >
          {dispatchErrorMessage && (<AlertComponent
            message={dispatchErrorMessage}
          />)}

          <Stack
            as={Flex}
            spacing="6"
          >
            <form
              onSubmit={formik.handleSubmit}
            >
              <Flex
                mt={0}
              >
                <FormControl as={Flex}
                             justifyContent={'center'}
                             mt={'1px'}
                >

                  <PinInput
                    required
                    size='lg'
                    id="code"
                    placeholder={'X'}
                    onChange={(value) => {
                      formik.setFieldValue('code', value)
                    }}
                    value={formik.values.code}
                    fontSize={'18px'}

                  >
                    <PinInputField
                      tabIndex={2}
                      {...commonPinInputFieldStyles}
                    />
                    <PinInputField
                      tabIndex={3}
                      {...commonPinInputFieldStyles}
                    />
                    <PinInputField
                      tabIndex={4}
                      {...commonPinInputFieldStyles}
                    />
                    <PinInputField
                      tabIndex={5}
                      {...commonPinInputFieldStyles}
                    />
                    <PinInputField
                      tabIndex={6}
                      {...commonPinInputFieldStyles}
                      mr={0}
                    />
                  </PinInput>

                </FormControl>
              </Flex>
              <HStack
                justify="space-between"
                justifyContent={'flex-end'}
                py={'20px'}>
                <Text>Didn't receive the code?</Text> <Resend
                onClick={resendCode}
                tabIndex={7}
              />
              </HStack>
              <Stack
                spacing="6"
                text-align={'right'}
                mt={'20px'}
              >
                <Button
                  tabIndex={8}
                  type="submit"
                  fontSize={'18px'}
                  variant="primary">Submit</Button>
              </Stack>
            </form>

          </Stack>
        </Box>
      </Stack>
    </Container></>)
}


export default EnterCode;