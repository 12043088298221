import {Button, Link} from "@chakra-ui/react";
import React from "react";

const Resend = ({onClick, ...props}) => {
  return (<Button
    as={Link}
    onClick={onClick}
    // tabIndex={5}
    variant="link"
    fontSize={'18px'}
    colorScheme="blue"
    color={'#4285F4'}
    {...props}
    size="sm">
    Resend
  </Button>)
}

export default Resend;