import React, {useEffect, useState} from 'react';
import {Box, GridItem, Img} from "@chakra-ui/react";
import Loading from "./Loading";
import verified from '../assets/icons/verified.png'
import cancel from '../assets/icons/cancel.png'
import corner from "../assets/img/corner.png"

const CorpClientFetchCard = ({
                               title,
                               isLoading = false,
                               data = null,
                               fetchFunction = () => {

                               },
                               setLoadingFlagFunction = () => {

                               },
                             }) => {

  const [firstRun, setFirstRun] = useState(true)

  useEffect(() => {
    if (firstRun && !isLoading) {
      setLoadingFlagFunction()
      setFirstRun((prevState) => false)
      fetchFunction();
    }
  }, [firstRun, setLoadingFlagFunction, isLoading, fetchFunction])

  return (<GridItem bgColor={'#F9FBFC;'}
                    minHeight={'321px'}
                    fontWeight={'700'}
                    fontSize={'18px'}
                    display={'flex'}
                    flexDir={'column'}
                    padding={'20px'}
                    textAlign={'center'}
                    justifyContent={'space-between'}
                    backgroundImage={corner}
                    backgroundPosition={'top right'}
                    backgroundRepeat={'no-repeat'}
                    borderRadius={'8px'}
    >
      <Box flexGrow={1}
           display={'flex'}
           justifyContent={'center'}
           alignItems={'center'}>
        {isLoading
          ? <Loading/>
          : data
            ? <Img src={verified} width={'60px'} height={'60px'}/>
            : <Img src={cancel} width={'60px'} height={'60px'}/>
        }
      </Box>
      <Box minHeight={'83px'}>{title}</Box>
    </GridItem>
  );
}


export default CorpClientFetchCard;