import React from 'react';
import { getCases, getCase } from '../../services/request';
import { useEffect, useState, useRef } from 'react';
import StatusLabel from '../../components/ui/StatusLabel';
import {CorporateIcon, IndividualIcon} from "../../assets/icons/sprite";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import { Link as ReachLink } from 'react-router-dom';
import { Heading, Menu, MenuButton, MenuList, MenuItem, TableContainer, Table, Select, Input } 
    from "@chakra-ui/react";import AdvancedDropDownItem from '../../components/ui/AdvancedDropDownItem';

import { FaCalendarAlt } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import ReactDatePicker from 'react-datepicker';

const Cases = () => {

    const [showAdvancedDropDown, setShowAdvancedDropDown] = React.useState(false);
    const [cases, setCases] = React.useState([]);
    const {solicitor_id, id} = useParams();
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const navigate = useNavigate();

    const [selectedType, setSelectedType] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [advancedItems, setAdvancedItems] = useState({
        unapproved: false,
        unsigned: false,
        risk_assessment_1: false,
        risk_assessment_2: false,
        risk_assessment_3: false,
    });  
    const [addedDate, setAddedDate] = useState(false);
    const [updatedDate, setUpdatedDate] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // If your API provides total pages    

    const handleTypeChange = (event) => { 
        localStorage.setItem('selectedType', event.target.value);
        setSelectedType(event.target.value);
    };
    const handleStatusChange = (event) => { 
        localStorage.setItem('selectedStatus', event.target.value);
        setSelectedStatus(event.target.value); 
    };
    const handleCheckboxChange = (name, checked) => {
        localStorage.setItem(name, checked);
        setAdvancedItems(prevState => ({
          ...prevState,
          [name]: checked,
        }));
    };
    const handleAddedDateChange = (date) => {
        if (!date) { localStorage.removeItem('addedDate'); }
        else { localStorage.setItem('addedDate', date); }
        setAddedDate(date);
    };    
    const handleUpdatedDateChange = (date) => {
        if (!date) { localStorage.removeItem('updatedDate'); }
        else { localStorage.setItem('updatedDate', date); }
        setUpdatedDate(date);
    };    

    useEffect(() => {
        const savedType = localStorage.getItem('selectedType');
        const savedStatus = localStorage.getItem('selectedStatus');
        const addedDateString = localStorage.getItem('addedDate');
        const addedDate = addedDateString ? new Date(addedDateString) : null;
        
        const updatedDateString = localStorage.getItem('updatedDate');
        const updatedDate = updatedDateString ? new Date(updatedDateString) : null;

        console.log('LOCAL DATE: ' + addedDate)

        const advancedItemsKeys = ['unapproved', 'unsigned', 'risk_assessment_1'];
        const savedAdvancedItems = {};
        advancedItemsKeys.forEach(key => {
            const savedValue = localStorage.getItem(key);
            if (savedValue !== null) {
                savedAdvancedItems[key] = savedValue === 'true'; // Convert string to boolean
            }
        });
        setAdvancedItems(savedAdvancedItems);

        if (savedType) { setSelectedType(savedType); }
        if (savedStatus) { setSelectedStatus(savedStatus); }

        if (addedDate) { setAddedDate(addedDate); }
        if (updatedDate) { setUpdatedDate(updatedDate); }

    }, []);

    const [showCloseIcon, setShowCloseIcon] = useState(false);

    const keywordInputRef = useRef(null);    
    const clearKeyword = () => {
        console.log('Clearing keyword');
        // Blur the input field programmatically
        if (keywordInputRef.current) keywordInputRef.current.blur();
        // Clear the keyword state
        setKeyword('');
        setShowCloseIcon(false);
    };

    const loadData = async (type, status, advancedItems, addedDate, updatedDate, keyword, page, solicitor_id) => {

        console.log('type: ' + type)
        console.log('status: ' + status)
        console.log('advancedItems: ' + JSON.stringify(advancedItems))
        console.log('keyword: ' + keyword)
        console.log('addedDate1: ' + addedDate)
        console.log('updatedDate1: ' + updatedDate)
        console.log('solicitor_id: ' + solicitor_id)

        // Convert dates to YYYY-MM-DD format
        if (addedDate) {
            const date = new Date(addedDate);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            addedDate = `${year}-${month}-${day}`;
        } 
        if (updatedDate) {
            const date = new Date(updatedDate);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            updatedDate = `${year}-${month}-${day}`;
        }        

        console.log('addedDate2: ' + addedDate)
        console.log('updatedDate2: ' + updatedDate)

        setIsLoading(true); // Loading state

        try {
            const res = await getCases(type, status, advancedItems['unapproved'], advancedItems['unsigned'],
                advancedItems['risk_assessment_1'], advancedItems['risk_assessment_2'], 
                advancedItems['risk_assessment_3'], addedDate, updatedDate, keyword, page,
                solicitor_id
            );
            
            if (res.ok) {
                const response = await res.json();
                console.log(response);
                setCases(response?.data); 
                setTotalPages(response?.meta?.pagination?.total_pages);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                console.error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Failed to fetch cases:', error);
        } finally {
            setIsLoading(false);
        }
    }
  
    useEffect(() => {        
        const addedDate = localStorage.getItem('addedDate');

        console.log('useEffect1.. load data with: ' + addedDate)
        loadData(selectedType, selectedStatus, advancedItems, addedDate, updatedDate, debouncedKeyword, currentPage, solicitor_id)
    }, [selectedType, selectedStatus, advancedItems, addedDate, updatedDate, debouncedKeyword, currentPage, solicitor_id])

    useEffect(() => {
        console.log('useEffect2')
        setCurrentPage(1);
    }, [selectedType, selectedStatus, advancedItems, addedDate, updatedDate, debouncedKeyword]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedKeyword(keyword);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [keyword]);

    const handleAdvancedInputDivClick = (event) => {
        event.stopPropagation();
        setShowAdvancedDropDown(!showAdvancedDropDown);
    };
      
    const handleMainContainerClick = () => {
        setShowAdvancedDropDown(false);
    };    
        
    const advancedCount = Object.values(advancedItems).filter(value => value).length;
    const advancedCountLabel = advancedCount > 0 ? `(${advancedCount})` : '';

    const getClientCaseId = async (id) => {
        setIsLoading(true);
        try {
            const res = await getCase(id);            
            if (res.ok) {
                const response = await res.json();
                setIsLoading(false);
                return response.data.id_client_parent;
            } else {
                setIsLoading(false);
                console.error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Failed to fetch case:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const navigateToCase = async (id, type) => {
        var clientCaseId = await getClientCaseId(id);        

        // assume this id is the parent id
        clientCaseId = clientCaseId || id;

        // if the url contains "firm/solicitors" then it is a firm user
        if (window.location.href.includes('firm/solicitors')) {
          if (type === 'corporate') {
            navigate(`/firm/solicitors/${solicitor_id}/cases/corporate/${clientCaseId}`);  
          }
          else {
            navigate(`/firm/solicitors/${solicitor_id}/cases/${clientCaseId}`);
          }
        }

        // otherwise it is a solicitor user
        else {
            if (clientCaseId) {
                if (type === 'corporate') {
                  navigate(`/solicitor/cases/corporate/${clientCaseId}`);  
                }
                else {
                  navigate(`/solicitor/cases/${clientCaseId}`);
                }
              }
              else {
                alert('Unfortunately that case is not viewable under your account.')
              }              
        }
    }

    return <>
<div className="container" onClick={(event) => handleMainContainerClick(event)}>

{!id && (
<>
    <div className="header-container">
        <Heading className="heading">Cases</Heading>
        <Menu>
            <MenuButton className="menu-button">New Case <ChevronDownIcon/></MenuButton>
            <MenuList className="menu-list">
                <MenuItem className="menu-item" as={ReachLink} 
                    to={solicitor_id ? `/firm/solicitors/${solicitor_id}/cases/private/invite` : '/solicitor/cases/private/invite'}
                >Private
                </MenuItem>
                <MenuItem className="menu-item" as={ReachLink} 
                    to={solicitor_id ? `/firm/solicitors/${solicitor_id}/cases/corporate/invite` : '/solicitor/cases/corporate/invite'}
                >Corporate
                </MenuItem>
            </MenuList>
        </Menu>
    </div>    

    <div className="filters-container">
        <Select value={selectedType} onChange={handleTypeChange}>
            <option value="">All Types</option>
            <option>Private</option>
            <option>Corporate</option>
        </Select>
        <Select value={selectedStatus} onChange={handleStatusChange}>  
            <option value="">All Statuses</option>
            <option>Pending</option>
            <option>Onboarded</option>
        </Select>
        
        <div className="advanced-container">
            <div className="advanced-input-div" onClick={(event) => handleAdvancedInputDivClick(event)}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Advanced {advancedCountLabel}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 10 }}>
                        <ChevronDownIcon />
                    </div>
                </div>
            </div>

            {showAdvancedDropDown && (
            <div className="advanced-dropdown">
                <AdvancedDropDownItem itemTitle="Unapproved individuals." itemName="unapproved" 
                    isChecked={advancedItems['unapproved'] || false} onCheckboxChange={handleCheckboxChange} />
                <AdvancedDropDownItem itemTitle="Unsigned documents." itemName="unsigned" 
                    isChecked={advancedItems['unsigned'] || false} onCheckboxChange={handleCheckboxChange} />
                <AdvancedDropDownItem itemTitle="RA1 incomplete." itemName="risk_assessment_1"
                    isChecked={advancedItems['risk_assessment_1'] || false} onCheckboxChange={handleCheckboxChange} />
                <AdvancedDropDownItem itemTitle="RA2 incomplete." itemName="risk_assessment_2"
                    isChecked={advancedItems['risk_assessment_2'] || false} onCheckboxChange={handleCheckboxChange} />
                <AdvancedDropDownItem itemTitle="RA3 incomplete." itemName="risk_assessment_3"
                    isChecked={advancedItems['risk_assessment_3'] || false} onCheckboxChange={handleCheckboxChange} />
            </div>)}
        </div>

        <div className="input-icon-wrapper">
            <ReactDatePicker
                placeholderText={'Added'} 
                className="form-control date-picker"
                selected={addedDate}
                onChange={(date) => handleAddedDateChange(date)}
            />
            {addedDate && <FaTimes onClick={() => handleAddedDateChange('')} className="input-calendar-icon-close" />}
            {!addedDate && <FaCalendarAlt className="input-calendar-icon" />}
        </div>
        <div className="input-icon-wrapper">
            <ReactDatePicker
                placeholderText={'Updated'} 
                className="form-control date-picker"
                selected={updatedDate}
                onChange={(date) => handleUpdatedDateChange(date)}
            />
            {updatedDate && <FaTimes onClick={() => handleUpdatedDateChange('')} className="input-calendar-icon-close" />}
            {!updatedDate && <FaCalendarAlt className="input-calendar-icon" />}        
        </div>

        <div className="input-icon-wrapper">
            <Input placeholder="Keyword" style={{minWidth: 150, minHeight: 0, paddingRight: '30px'}} 
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                ref={keywordInputRef}
                onFocus={() => setShowCloseIcon(false)} // Hide close icon on focus
                onBlur={() => setShowCloseIcon(true)} // Show close icon on blur if there is a keyword
            />
            {keyword && showCloseIcon && <FaTimes onClick={clearKeyword} className="input-calendar-icon-close" />}
            {!showCloseIcon && <FaSearch className="input-calendar-icon" />}                    
        </div>
    </div>

    {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <div className="spinner"></div>
                </div>
    ) : cases.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>No results found.</div>
    ) : (
<>
    <TableContainer
            backgroundColor={'#F9FBFC'}
            color={'#1A1E29'}
            pl={'16px'}
            pt={'12px'}
            pr={'20px'}
          >
    <Table className="table table-hover table-data" variant='simple'>
        <thead className="thead-light">
            <tr>
                <th>Id</th>
                {/* <th>User Type</th> */}

                <th>Case Ref No</th>
                <th>Client Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Type</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {cases.map((c) => {

                // case reference number
                var caseReference = c?.account_form?.case_reference_number ?? "Not Set";
                
                // client name
                var clientName = c.first_name + " " + c.last_name; 
                if (c.type === 'individual' && c.main_contact) {                    
                    clientName = c.main_contact?.first_name + " " + c.main_contact?.last_name;
                } 
                else if (c.type === 'corporate') {
                    if (c.name && c.name.length > 25) { clientName = c.name.substr(0,25) + "..." }    
                    else { clientName = c.name; }
                }

                // client email
                var clientEmail = c.email;
                if (c.type === 'corporate') { clientEmail = c.main_contact?.email }                
                if (clientEmail && clientEmail.length > 20) { clientEmail = clientEmail.substr(0,20) + "..." }

                // console.log(c)

                return (
                <tr style={{ cursor: 'pointer' }}
                    onClick={() => navigateToCase(c.id, c.type) }                
                    >
                    <td>{c.id}</td>
                    {/* <td>{c.type}</td> */}

                    <td>{caseReference}</td>
                    <td>{clientName}</td>
                    <td>{clientEmail}</td>
                    <td><StatusLabel status={c.status}/></td>
                    <td>
                    {c.type === 'individual' || c.type === 'individual_child' ? (
                        <>
                        <IndividualIcon w={18} h={18} fill={'none'} mr={'2'} />
                        {c.type === 'individual_child' ? 'Private (child)' : 'Private'}
                        </>
                    ) : (
                        <>
                        <CorporateIcon w={18} h={18} fill={'none'} mr={'2'} />
                        {c.type === 'corporate_child' ? 'Corporate (child)' : 'Corporate'}
                        </>
                    )}
                    </td>
                </tr>
            );
        })}        
        
        </tbody>
    </Table>
    </TableContainer>    

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
    {currentPage > 1 && (
        <button 
            onClick={() => setCurrentPage(currentPage - 1)} 
            style={{ border: 'none', background: 'transparent', cursor: 'pointer', marginRight: '10px' }}
        >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
    )}
    <span>Page {currentPage} of {totalPages}</span>
    {currentPage < totalPages && (
        <button 
            onClick={() => setCurrentPage(currentPage + 1)} 
            style={{ border: 'none', background: 'transparent', cursor: 'pointer', marginLeft: '10px' }}
        >
            <FontAwesomeIcon icon={faArrowRight} size="lg" />
        </button>
    )}
    </div>
</>
    )}
</>    
)}

    <Outlet />
</div>    
</>
}

export default Cases;