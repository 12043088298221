import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {loginAdmin, setAdminErrorMessage} from "../../redux/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import LoginForm from "../../components/LoginForm";
import {useEffect} from "react";

const AdminLogin = (props) => {

  const dispatch = useDispatch()
  const dispatchLoginAdmin = (credentials) => dispatch(loginAdmin(credentials))
  const dispatchErrorMessage = useSelector(state => state.adminAuth.errorMessage)

  useEffect(() => {
    dispatch(setAdminErrorMessage(null))
  }, [dispatch])


  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '', password: ''
    }, onSubmit: (values) => {
      if (props.redirect) {
        dispatchLoginAdmin(values).then(() => navigate('/admin/dashboard/'))
      } else {
        dispatchLoginAdmin(values);
      }
    },
  })

  return (<LoginForm
    formik={formik}
    dispatchErrorMessage={dispatchErrorMessage}
  />)
}

export default AdminLogin;